import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj } from '../services/ServerError';
import {
    getFlightAirport,
    getFlightEventsAndConstraints,
    getFlightCargo,
    getFlightPlanning,
    getFlightPax,
    getFlightCrew,
    getFlightSchedule,
    getFlightADDs,
    getFlightCargoPTS,
} from '../services/gantt';
import { AircraftDefect } from './ganttSlice';
import { DetailedEvent } from './eventSlice';
import { COEDetailedEvent } from './coeEventSlice';

type Contact = {
    surname?: string;
    faxNumber?: string;
    givenName?: string;
    roleTitle?: string;
    phoneNumber?: string;
};

type AirportInfo = {
    airportGate?: string;
    requiredConnectionTime?: string;
    dutyContact?: Contact[];
    airportCode?: string;
    timezone?: string;
    minimumGroundTime?: number;
};

export type FlightAirportData = {
    ufi?: string;
    departureAirport?: AirportInfo;
    arrivalAirport?: AirportInfo;
};

type Record = {
    flightDateUTC?: string;
    departure?: string;
    arrival?: string;
    weight?: string;
    createdAt?: string;
    updatedAt?: string;
    flightTimeEET?: string;
};

export type FlightPlanningData = {
    ufi?: string;
    mzfw?: {
        record?: Record[];
        lastUpdatedAt?: string;
    };
    ezfw?: {
        passenger?: string;
        baggage?: string;
        generalCargo?: string;
        expressCargo?: string;
        total?: string;
        lastUpdatedAt?: string;
    };
};

export type FlightADDsData = {
    ufi?: string;
    registration?: string;
    openDefects?: AircraftDefect[];
    closedDefects?: AircraftDefect[];
};

type CargoSpot = {
    itemName?: string;
    codes?: string[];
    totalWeight?: string;
    details?: string[];
};

export type FlightCargoData = {
    ufi?: string;
    dept?: string;
    arr?: string;
    generalCargo?: string;
    expressCargo?: string;
    totalNormalCargo?: string;
    bookingHighLights?: {
        [key: string]: {
            dangerousCargoSpots?: CargoSpot[];
            dangerousTotalWeight?: number;
            nonDangerousCargoSpots?: CargoSpot[];
            nonDangerousTotalWeight?: number;
            lastUpdatedTime?: string;
        };
    };
    normalCargoLastUpdate?: string;
};

export type PaxClasses = {
    F?: number;
    J?: number;
    W?: number;
    Y?: number;
};

type PaxTransit = {
    ufi?: string;
    flightNumber?: string;
    flightDate?: string;
    departure?: string;
    arrival?: string;
    type?: string;
    subtype?: string;
    reg?: string;
    state?: string;
    scheduledDepDate?: string;
    scheduledDepTime?: string;
    scheduledArrDate?: string;
    scheduledArrTime?: string;
    ltdUTC?: string;
    ltaUTC?: string;
    vip?: PaxClasses;
    mpo?: PaxClasses;
    group?: PaxClasses;
    wheelChair?: PaxClasses;
    total?: PaxClasses;
    booked?: PaxClasses;
    connectTime?: number;
    displayConnectTime?: string;
    minConnectTime?: number;
};

export type FlightPaxData = {
    ufi?: string;
    total?: PaxClasses;
    booked?: PaxClasses;
    accepted?: PaxClasses;
    boarded?: PaxClasses;
    vip?: PaxClasses;
    mpo?: PaxClasses;
    transit?: PaxTransit[];
    inOut?: string;
};

export type FlightScheduleData = {
    ufi?: string;
    scheduleOut?: string;
    scheduleIn?: string;
    scheduleOutLocal?: string;
    scheduleInLocal?: string;
    estimateOut?: string;
    estimateOff?: string;
    estimateOn?: string;
    estimateIn?: string;
    estimateOutLocal?: string;
    estimateOffLocal?: string;
    estimateOnLocal?: string;
    estimateInLocal?: string;
    actualOut?: string;
    actualOff?: string;
    actualOn?: string;
    actualIn?: string;
    actualOutLocal?: string;
    actualOffLocal?: string;
    actualOnLocal?: string;
    actualInLocal?: string;
    initialisation?: string;
    initialisationLocal?: string;
    flightTimeSBT?: string;
    flightTimeEET?: string;
    flightTimeAET?: string;
    delayDeparture?: string;
    delayArrival?: string;
    preDepGroundTimeSchedule?: string;
    preDepGroundTimeActual?: string;
    postArrGroundTimeSchedule?: string;
    postArrGroundTimeActual?: string;
    flightTimeDiff?: string;
};

export type EventsAndConstraintsData = {
    aogEvents: COEDetailedEvent[];
    coeEvents: COEDetailedEvent[];
    iocEvents: DetailedEvent[];
    constraints: any[];
    totalEvents: number;
};

type Crew = {
    crewName?: string;
    currentErn?: string;
    integratedPattenHK?: boolean;
    isPositioning?: boolean;
    legRankCode?: string;
    mobilePhone?: string;
    primaryPhone?: string;
    profileAvaliable?: boolean;
    specialDutyCode?: string;
    staffRankCode?: string;
    next?: CrewFlight[];
    previous?: CrewFlight[];
};

type CrewFlight = {
    STA?: string;
    STD?: string;
    arrivalPort?: string;
    departurePort?: string;
    flightDate?: string;
    flightNumber?: string;
};

export type FlightCrewData = {
    ufi?: string;
    crew?: Crew[];
};

type PTS = {
    activity?: string;
    time?: string;
    timeDesc?: string;
    status?: boolean;
};

export type FlightCargoPTSData = {
    ufi?: string;
    scheduleDepartureAirport?: string;
    scheduleArrivalAirport?: string;
    arrivalLastUpdateTime?: string;
    departureLastUpdateTime?: string;
    departurePtsList?: PTS[];
    arrivalPtsList?: PTS[];
};

interface FlightTabsPanelSlice {
    isDetailLoading: boolean;
    flightAirport: FlightAirportData;
    flightPlanning: FlightPlanningData;
    flightADDs: FlightADDsData;
    flightCargo: FlightCargoData;
    flightCargoPTS: FlightCargoPTSData;
    flightPax: FlightPaxData;
    flightSchedule: FlightScheduleData;
    flightEventsAndConstraints: EventsAndConstraintsData;
    flightCrew: FlightCrewData;
    flightErrorObj: ApiErrorObj;
}

const initialState: FlightTabsPanelSlice = {
    isDetailLoading: false,
    flightAirport: null,
    flightPlanning: null,
    flightADDs: null,
    flightCargo: null,
    flightPax: null,
    flightCrew: null,
    flightCargoPTS: null,
    flightSchedule: null,
    flightEventsAndConstraints: null,
    flightErrorObj: null,
};

export const getFlightEventsAndConstraintsThunk = createAsyncThunk<
EventsAndConstraintsData,
{ ufi?: string; registration?: string },
{ rejectValue: ApiErrorObj }
>('flightTabsPanel/getEventsAndConstraints', async ({ ufi, registration }, { rejectWithValue }) => {
    const [err, data] = await getFlightEventsAndConstraints({
        ufi,
        registration,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { ...data, ufi };
});

export const getFlightScheduleThunk = createAsyncThunk<
FlightScheduleData,
{ ufi: string },
{ rejectValue: ApiErrorObj }
>('flightTabsPanel/getSchedule', async ({ ufi }, { rejectWithValue }) => {
    const [err, data] = await getFlightSchedule({
        ufi,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { ...data, ufi };
});

export const getFlightAirportThunk = createAsyncThunk<
FlightAirportData,
{ ufi: string; dept: string; arr: string; scheduledDepDateTime: string; scheduledArrDateTime: string },
{ rejectValue: ApiErrorObj }
>(
    'flightTabsPanel/getAirport',
    async ({ ufi, dept, arr, scheduledDepDateTime, scheduledArrDateTime }, { rejectWithValue }) => {
        const [err, data] = await getFlightAirport({
            ufi,
            dept,
            arr,
            scheduledDepDateTime,
            scheduledArrDateTime,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }
);

export const getFlightADDsThunk = createAsyncThunk<
FlightADDsData,
{ ufi: string; registration: string },
{ rejectValue: ApiErrorObj }
>('flightTabsPanel/getADDs', async ({ ufi, registration }, { rejectWithValue }) => {
    const [err, data] = await getFlightADDs({ ufi, registration });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { ...data, ufi, registration };
});

export const getFlightCargoThunk = createAsyncThunk<
FlightCargoData,
{ ufi: string; dept: string; arr: string },
{ rejectValue: ApiErrorObj }
>('flightTabsPanel/getCargo', async ({ ufi, dept, arr }, { rejectWithValue }) => {
    const [err, data] = await getFlightCargo({
        ufi,
        dept,
        arr,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { ...data, ufi };
});

export const getFlightPlanningThunk = createAsyncThunk<
FlightPlanningData,
{ ufi: string; flightNumber: string; originFlightDateUtc: string },
{ rejectValue: ApiErrorObj }
>('flightTabsPanel/getPlanning', async ({ ufi, flightNumber, originFlightDateUtc }, { rejectWithValue }) => {
    const [err, data] = await getFlightPlanning({
        ufi,
        flightNumber,
        originFlightDateUtc,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { ...data, ufi };
});

export const getFlightPaxThunk = createAsyncThunk<FlightPaxData, { ufi: string }, { rejectValue: ApiErrorObj }>(
    'flightTabsPanel/getPax',
    async ({ ufi }, { rejectWithValue }) => {
        const [err, data] = await getFlightPax({
            ufi,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return { ...data, ufi };
    }
);

export const getFlightCrewThunk = createAsyncThunk<FlightCrewData, { ufi: string }, { rejectValue: ApiErrorObj }>(
    'flightTabsPanel/getCrew',
    async ({ ufi }, { rejectWithValue }) => {
        const [err, data] = await getFlightCrew({
            ufi,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return { crew: data, ufi };
    }
);

export const getFlightCargoPTSThunk = createAsyncThunk<
FlightCargoPTSData,
{ ufi: string },
{ rejectValue: ApiErrorObj }
>('flightTabsPanel/getCargoPTS', async ({ ufi }, { rejectWithValue }) => {
    const [err, data] = await getFlightCargoPTS({
        ufi,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { ...data, ufi };
});

export const flightTabsPanelSlice = createSlice({
    name: 'flightTabsPanel',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFlightAirportThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightAirportThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightErrorObj = payload;
        });
        builder.addCase(getFlightAirportThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightAirport = payload;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightCargoThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightCargoThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightErrorObj = payload;
        });
        builder.addCase(getFlightCargoThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightCargo = payload;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightPlanningThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightPlanningThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightErrorObj = payload;
        });
        builder.addCase(getFlightPlanningThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightPlanning = payload;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightPaxThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightPaxThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightErrorObj = payload;
        });
        builder.addCase(getFlightPaxThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightPax = payload;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightScheduleThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightScheduleThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightErrorObj = payload;
        });
        builder.addCase(getFlightScheduleThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightSchedule = payload;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightEventsAndConstraintsThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightEventsAndConstraintsThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightErrorObj = payload;
        });
        builder.addCase(getFlightEventsAndConstraintsThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightEventsAndConstraints = payload;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightADDsThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightADDsThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightErrorObj = payload;
        });
        builder.addCase(getFlightADDsThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightADDs = payload;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightCrewThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightCrewThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightErrorObj = payload;
        });
        builder.addCase(getFlightCrewThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightCrew = payload;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightCargoPTSThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.flightErrorObj = null;
        });
        builder.addCase(getFlightCargoPTSThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightErrorObj = payload;
        });
        builder.addCase(getFlightCargoPTSThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.flightCargoPTS = payload;
            state.flightErrorObj = null;
        });
    },
});

export const selectFlightTabsPanel = (state: RootState) => state.flightTabsPanel;

export default flightTabsPanelSlice.reducer;
