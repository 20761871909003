import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { getMaintenance } from '../services/maintenance';
import { Aircraft, LMTTHeader } from './coeEventSlice';
import { timeStringToDateTime, formatDefaultDisplay, DateFormat } from '../helper/dateHelper';
import { getTimeWithMinDigitInString } from '../helper/stringHelper';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';

export type MaintenanceSearchFilter = {
    registration: string;
    checkName?: string;
    startDate: string;
    endDate: string;
    size?: number;
    page?: number;
};

export type MaintenanceObj = {
    id: string;
    subtype: string;
    registration: string;
    name: string;
    checkDesc: string;
    checkComment: string;
    port: string;
    dateTime: string;
    hours: string;
    minutes: string;
    start: string;
    end: string;
    legNo: number;
};

export type Maintenance = {
    id: string;
    messageAction: string;
    legNo: number;
    whatIf: string;
    workPackage: string;
    airport: string;
    aircraft: string;
    name: string;
    seq: number;
    checkKind: string;
    start: string;
    end: string;
    startLocal: string;
    endLocal: string;
    carriedOut: string;
    checkComment: string;
    userId: string;
    timestamp: string;
    updateKey: string;
    updateNo: number;
    updatedAt: string;
    createdAt: string;
    active: boolean;
    craft: Aircraft;
    checkBasic: CheckBasic;
};

export type MaintenanceResponse = {
    maint: Maintenance[];
    total: number;
};

type CheckBasic = {
    id: number;
    checkCode: string;
    checkName: string;
    checkDesc: string;
    checkType: string;
    checkKind: string;
    lastUpdateUserId: string;
};

type MaintenanceSlice = {
    maintenanceObjList: MaintenanceObj[];
    isMaintenanceLoading: boolean;
    totalMaint: number;
};

export type PackageIdObj = {
    packageId?: string;
};

const initialState: MaintenanceSlice = {
    maintenanceObjList: [],
    isMaintenanceLoading: false,
    totalMaint: 0,
};

export const getMaintenanceThunk = createAsyncThunk<
MaintenanceResponse,
MaintenanceSearchFilter,
{ state: RootState; rejectValue: ApiErrorObj }
>(
    'coeevents/getMaintenance',
    async ({ registration, checkName, startDate, endDate, page, size }, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/getMaintenances')) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await getMaintenance({
            filters: { registration, checkName, startDate, endDate, page, size },
            headers: LMTTHeader,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as MaintenanceResponse;
    }
);

export const maintenanceSlice = createSlice({
    name: 'maintenance',
    initialState,
    reducers: {
        cleanMaintList: (state) => {
            state.maintenanceObjList = [];
            state.totalMaint = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMaintenanceThunk.pending, (state) => {
                state.isMaintenanceLoading = true;
            })
            .addCase(getMaintenanceThunk.fulfilled, (state, { payload }) => {
                const { maint, total } = payload;
                state.totalMaint = total;
                state.maintenanceObjList =
                    maint?.map((maintenance) => {
                        const {
                            craft,
                            checkBasic,
                            id,
                            aircraft: registration,
                            airport,
                            checkComment,
                            start,
                            end,
                            legNo,
                        } = maintenance;
                        const dateTimeDisplay = formatDefaultDisplay(start, DateFormat.nonUtcFormat);
                        const startDateTime = timeStringToDateTime(start);
                        const endDateTime = timeStringToDateTime(end);
                        const diff = endDateTime.diff(startDateTime, ['hours', 'minutes']);
                        const { hours, minutes } = diff || {};
                        const hoursInString = getTimeWithMinDigitInString(hours);
                        const minutesInString = getTimeWithMinDigitInString(minutes);
                        const { subtype } = craft || {};
                        const { checkName, checkDesc } = checkBasic;

                        return {
                            id,
                            subtype,
                            registration,
                            name: checkName,
                            checkDesc,
                            checkComment,
                            port: airport,
                            dateTime: dateTimeDisplay,
                            hours: hoursInString,
                            minutes: minutesInString,
                            start,
                            end,
                            legNo,
                        } as MaintenanceObj;
                    }) || [];
                state.isMaintenanceLoading = false;
            })
            .addCase(getMaintenanceThunk.rejected, (state) => {
                state.isMaintenanceLoading = false;
            });
    },
});

export const { cleanMaintList } = maintenanceSlice.actions;
export const selectMaintenance = (state: RootState) => state.maintenance;

export default maintenanceSlice.reducer;
