import React, { useEffect, useState } from 'react';
import { Stack, Box, FormHelperText, SxProps, StackTypeMap } from '@mui/material';
import { DateTime } from 'luxon';

import DatePicker, { DatePickerProps } from './DatePicker';
import { DateFormat } from '../../../../helper/dateHelper';
import TimeInput from '../../input/v1/TimeInput';
import COLORS from '../../../../style/color';

interface DateTimePickerProps extends Omit<DatePickerProps, 'inputSx' | 'label'> {
    spacing?: StackTypeMap['props']['spacing'];
    datePickerSx?: SxProps;
    timeSx?: SxProps;
    value?: string | DateTime;
    dateLabel?: string;
    timeLabel?: string;
    variant?: 'standard' | 'packed';
}

const defaultObj = { dateTime: null, dateString: '', timeString: '' };

const convertValueToDateTime = (value: string | DateTime) => {
    if (!value) return defaultObj;

    const dateTimeObj = typeof value === 'string' ? DateTime.fromISO(value, { setZone: true }) : value;

    if (!dateTimeObj.isValid) {
        console.error('Invalid Date');
        return defaultObj;
    }

    return {
        dateTime: dateTimeObj,
        dateString: dateTimeObj.toFormat(DateFormat.ServerQueryFormat),
        timeString: dateTimeObj.toFormat(DateFormat.timeOnly),
    };
};

const DateTimePicker = (props: DateTimePickerProps) => {
    const {
        defaultValue,
        value,
        sx,
        datePickerSx,
        timeSx,
        spacing = 1,
        helperText,
        onChange,
        dateLabel,
        timeLabel,
        variant = 'standard',
        ...otherDatePickerProps
    } = props;
    const { error, disabled, isClearable } = otherDatePickerProps;
    const [dateTimeInfo, setDateTimeInfo] = useState<{
        dateTime: DateTime;
        dateString: string;
        timeString: string;
    } | null>(() => convertValueToDateTime(value || defaultValue));

    const onDateTimeChange = (date?: DateTime, time?: string) => {
        if ((!date || !date.isValid) && !time) {
            if (isClearable) setDateTimeInfo(defaultObj);
            return;
        }

        setDateTimeInfo((pre) => {
            const dateTimeObj = date || pre.dateTime || DateTime.now();
            const timeString = (time || pre.timeString || '0000').replace(/[^0-9]/g, '').padEnd(4, '0');
            const hour = parseInt(timeString.substring(0, 2));
            const minute = parseInt(timeString.substring(2, 4));

            return convertValueToDateTime(dateTimeObj.set({ hour, minute, second: 0 }));
        });
    };

    useEffect(() => {
        const currentStringValue = dateTimeInfo.dateTime?.toISO();
        const newValue = (value as DateTime)?.toISO?.() || value;
        if (newValue && newValue !== currentStringValue) {
            setDateTimeInfo(convertValueToDateTime(newValue));
        }
    }, [value]);

    useEffect(() => {
        onChange?.(dateTimeInfo.dateTime?.toISO() || null, dateTimeInfo.dateTime);
    }, [dateTimeInfo.dateString, dateTimeInfo.timeString]);

    return (
        <Box sx={sx}>
            <Stack spacing={variant === 'standard' ? spacing : 0} direction='row'>
                <DatePicker
                    label={dateLabel}
                    value={dateTimeInfo?.dateTime}
                    inputSx={{
                        ...datePickerSx,
                        ...(variant === 'packed' && {
                            '& .MuiFilledInput-root': {
                                borderTopRightRadius: '0px',
                                borderBottomRightRadius: '0px',
                                ...datePickerSx?.['& .MuiFilledInput-root'],
                            },
                        }),
                    }}
                    {...otherDatePickerProps}
                    onChange={(dateString, dateTimeObj) => {
                        onDateTimeChange(dateTimeObj);
                    }}
                />
                <TimeInput
                    value={dateTimeInfo?.timeString}
                    label={timeLabel}
                    sx={{
                        ...timeSx,
                        ...(variant === 'packed' && {
                            '& .MuiFilledInput-root': {
                                borderLeftColor: COLORS.transparent,
                                borderTopLeftRadius: '0px',
                                borderBottomLeftRadius: '0px',
                                ...timeSx?.['& .MuiFilledInput-root'],
                            },
                        }),
                    }}
                    error={error}
                    disabled={disabled}
                    onChange={(e, hour, min) => {
                        if (e.target.value !== dateTimeInfo.timeString) onDateTimeChange(null, e.target.value);
                    }}
                />
            </Stack>
            {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : false}
        </Box>
    );
};

export default DateTimePicker;
