import React from 'react';
import { SxProps, styled } from '@mui/system';
import { Button } from '@mui/material';
import COLORS from '../../style/color';

type LinkTextProps = {
    label: string;
    sx?: SxProps;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    onClick?: () => void;
};

const StyledTextLink = styled(Button)({
    fontSize: '12px',
    color: COLORS.hoverGreen,
    textDecoration: 'none',
    textTransform: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    '&:hover': {
        color: COLORS.hoverText,
        background: 'transparent',
        textDecoration: 'underline',
    },
    '&:active': {
        color: COLORS.buttonBlue,
        textDecoration: 'underline',
        background: 'transparent',
    },
});
const LinkText = (props: LinkTextProps) => {
    const { label, sx, startIcon, endIcon, onClick } = props;
    return (
        <StyledTextLink
            disableRipple
            startIcon={startIcon}
            endIcon={endIcon}
            sx={sx}
            onClick={() => {
                onClick && onClick();
            }}
        >
            {label}
        </StyledTextLink>
    );
};

export default LinkText;
