import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, CHECKLIST_DASHBOARD_API } from '../constants/api';
import { IStatisticsRequest } from '../interface/ChecklistDashboard';

const getChecklistStatistics = async (params: IStatisticsRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, CHECKLIST_DASHBOARD_API.getChecklistStatistics, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getChecklistStatistics };
