import React, { useEffect, useState } from 'react';
import { Autocomplete, ListItemText, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import isEqual from 'lodash/isEqual';

import { useAppDispatch } from '../../../../app/hooks';
import { getFleetSubtypeListThunk } from '../../../../slices/ganttSlice';
import subtypeFilterStyles from './SubtypeFilterStyles';
import { ReactComponent as Cross } from '../../../../assets/icons/Cross.svg';
import StyledCheckbox from '../../CheckBox';
import ChipForDisplay from '../../ChipForDisplay';

import colors from '../../../../style/color';

interface SubtypeFilterProps {
    value?: string[];
    searchMinChar?: number;
    onChange?: (selectedSubtypes: string[]) => void;
}

const SubtypeFilter = (props: SubtypeFilterProps) => {
    const { onChange, searchMinChar = 2, value } = props;
    const dispatch = useAppDispatch();
    const [fleetSubtypeList, setFleetSubtypeList] = useState<readonly string[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedValues, setSelectedValues] = useState(value || []);
    const { classes } = subtypeFilterStyles();

    const updateSearchKey = debounce(async (inputValue: string) => {
        dispatch(getFleetSubtypeListThunk({ keySearch: inputValue }))
            .unwrap()
            .then((payload) => {
                setFleetSubtypeList(payload);
            });
    }, 500);

    useEffect(() => {
        if (!isEqual(selectedValues, value)) {
            setSelectedValues(value || []);
        }
    }, [value]);

    useEffect(() => {
        return () => {
            setFleetSubtypeList([]);
        };
    }, []);

    return (
        <Autocomplete
            classes={{
                root: classes.root,
                paper: classes.paper,
                option: classes.option,
                endAdornment: classes.endAdornment,
            }}
            multiple
            value={selectedValues}
            options={fleetSubtypeList}
            disableCloseOnSelect
            forcePopupIcon={false}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <StyledCheckbox checked={selected} />
                    <ListItemText primary={option} sx={{ color: colors.grayDark }} disableTypography />
                </li>
            )}
            open={isOpen}
            onInputChange={(event, newInputValue, reason) => {
                if (reason === 'reset') return;

                const isOpen = newInputValue?.length >= searchMinChar;
                setIsOpen(isOpen);
                if (isOpen) {
                    updateSearchKey(newInputValue);
                }
            }}
            onBlur={() => setIsOpen(false)}
            renderTags={() => {
                return selectedValues?.map((subtype) => (
                    <ChipForDisplay
                        key={subtype}
                        label={subtype}
                        onDelete={() => {
                            const remaintingItems = selectedValues.filter((item) => item !== subtype);
                            setSelectedValues(remaintingItems);
                            onChange && onChange(remaintingItems);
                        }}
                        deleteIcon={<Cross width='10.5px' height='10.5px' fill={colors.mediumJade} />}
                        variant='outlined'
                    />
                ));
            }}
            onChange={(event, value: string[]) => {
                onChange && onChange(value);
            }}
            sx={{ width: '380px', mx: '0px !important' }}
            renderInput={(params) => (
                <TextField
                    classes={{ root: classes.inputRoot }}
                    {...params}
                    label={`Subtype ${selectedValues?.length ? `(${selectedValues.length})` : ''}`}
                    variant='filled'
                    size='small'
                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
            )}
        />
    );
};

export default SubtypeFilter;
