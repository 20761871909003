import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, ADD_API } from '../constants/api';
import { AddParam } from '../slices/addSlice';

const getAdds = async (param: AddParam) => {
    const { tail, addStatus, page, size } = param;
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, ADD_API.getAdd, {
        tail,
        status: addStatus,
        page,
        size,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getAdds };
