import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, EVENTS_API } from '../constants/api';
import { MaintenanceSearchFilter } from '../slices/maintenanceSlice';

const getMaintenance = async ({ filters, headers }: { filters: MaintenanceSearchFilter; headers?: any }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.getMaintenance, filters, {
        headers,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getMaintenance };
