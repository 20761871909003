import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, EVENT_TASK_API } from '../constants/api';
import { sendHttpRequest } from './api';
import { SpareDetailType } from '../slices/eventSlice';

const getRecoveryPlanByEventIdAndType = async ({ eventId, types }: { eventId: string; types: string[] }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENT_TASK_API.eventTasks, {
        eventId,
        'types[]': types,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const saveRecoveryPlan = async ({
    userId,
    eventId,
    details,
}: {
    userId: string;
    eventId: string;
    details: SpareDetailType[];
}) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENT_TASK_API.eventTasks, {
        userId,
        eventId,
        details,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { saveRecoveryPlan, getRecoveryPlanByEventIdAndType };
