import React from 'react';
import { Typography, Box, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactComponent as WarningNoBorder } from '../../assets/icons/WarningNoBorder.svg';
import colors from '../../style/color';

type WarningSecondaryProps = {
    displayText: string;
    sx?: SxProps;
};

const WarningContainer = styled(Box)({
    '&': {
        display: 'flex',
        height: '32px',
        paddingLeft: '14px',
        backgroundColor: colors.secondWarning,
        alignItems: 'center',
    },
});

const WarningSecondary = ({ displayText, sx }: WarningSecondaryProps) => {
    return (
        <WarningContainer sx={sx}>
            <WarningNoBorder fill={colors.warningYellow} />
            <Typography variant='boldGrayDark14' ml='8px'>
                {displayText}
            </Typography>
        </WarningContainer>
    );
};

export default WarningSecondary;
