import React, { useEffect } from 'react';
import { Portal } from '@mui/material';
import { useFieldArray } from 'react-hook-form';

import TitledSection from './TitledSection';
import StyledButton from '../../common/Button';
import IndividualStopInfo from './IndividualStopInfo';
import { ReactComponent as IconPlus } from '../../../assets/icons/IconPlus.svg';

import { IOCAlertTypes } from '../constants';

interface StopsInfoProps {
    currentAlertType: IOCAlertTypes;
    portalBoxRef: HTMLElement;
    registerNamePrefix: string;
    shouldShowStopsInfo: boolean;
}

const StopSectionText = {
    [IOCAlertTypes.flightReturn]: {
        title: 'Flight Return Information',
        addStopButtonTxt: 'Flight Return',
    },
    [IOCAlertTypes.techStop]: {
        title: 'Tech Stop Information',
        addStopButtonTxt: 'Tech Stop',
    },
    [IOCAlertTypes.diversion]: {
        title: 'Diversion Information',
        addStopButtonTxt: 'Diversion Return',
    },
};

const StopsInfo = (props: StopsInfoProps) => {
    const { currentAlertType, portalBoxRef, registerNamePrefix, shouldShowStopsInfo } = props;

    const { addStopButtonTxt = '', title = '' } = StopSectionText[currentAlertType] || {};
    const stopRegisterName = `${registerNamePrefix}.stops`;
    const { fields, append, remove } = useFieldArray({ name: stopRegisterName });

    const onDeleteStop = (index: number) => {
        if (fields.length <= 1) return;
        remove(index);
    };

    useEffect(() => {
        if (!shouldShowStopsInfo) {
            remove();
        }
    }, [shouldShowStopsInfo]);

    return (
        <>
            {shouldShowStopsInfo ? (
                <TitledSection title={title}>
                    {fields.map((stop, index) => (
                        <IndividualStopInfo
                            key={stop.id}
                            index={index}
                            stop={stop}
                            onDelete={onDeleteStop}
                            canDelete={fields.length > 1}
                            registerNamePrefix={`${stopRegisterName}.${index}`}
                        />
                    ))}
                    <Portal container={portalBoxRef}>
                        <StyledButton
                            sx={{ alignSelf: 'flex-start' }}
                            label={addStopButtonTxt}
                            startIcon={<IconPlus />}
                            variant='secondary'
                            onClick={() => {
                                append({});
                            }}
                        />
                    </Portal>
                </TitledSection>
            ) : (
                false
            )}
        </>
    );
};

export default StopsInfo;
