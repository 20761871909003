import React from 'react';
import { Box, SxProps } from '@mui/system';
import { EventStatusType } from '../../../../constants/constants';
import COLORS from '../../../../style/color';

interface IndicatorBarProps {
    state: string;
    sx?: SxProps;
}

const getColorByStatus = (state: string): string => {
    switch (state) {
        case EventStatusType.open: {
            return COLORS.brightYellow;
        }
        case EventStatusType.closed: {
            return COLORS.grayNormal;
        }
        default:
            return COLORS.transparent;
    }
};

const IndicatorBar = (props: IndicatorBarProps) => {
    const { state, sx = {} } = props;
    return (
        <Box sx={{ height: '4px', backgroundColor: getColorByStatus(state), position: 'relative', zIndex: 1200, ...sx }} />
    );
};

export default IndicatorBar;
