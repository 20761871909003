import React, { useState, Fragment } from 'react';
import {
    IconButton,
    Link,
    ListItemText,
    MenuItem,
    Paper,
    Popper,
    PopperPlacementType,
    Select,
    ClickAwayListener,
    Box,
} from '@mui/material';
import { SxProps } from '@mui/system';

import filterStyles, { menuProps } from './FilterStyles';
import { ReactComponent as ArrowDown } from '../../assets/icons/ArrowDown.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/Filter.svg';
import sortingStyles from './SortStyles';
import COLORS from '../../style/color';
import StyledCheckbox from './CheckBox';

export type FilterItem = {
    name: string;
    value: string;
    defaultChecked?: boolean;
};
type FilterProps = {
    placeholderText?: string;
    clearText?: string;
    availableItems: FilterItem[];
    sx?: SxProps;
    onChange: (selectedItems: string[]) => void;
    variant?: 'standard' | 'iconOnly';
    value?: string[];
};

interface FilterClearItemProps {
    clearText: string;
    handleChange: (value: string) => void;
    sx?: SxProps;
}

const FilterMenuItem = ({
    item,
    handleChange,
    isChecked,
    sx,
}: {
    item: { name: string; value: string };
    handleChange: (value: string) => void;
    isChecked: boolean;
    sx?: SxProps;
}) => {
    const { name, value } = item;
    return (
        <MenuItem
            onClick={() => handleChange(value)}
            value={value}
            disableRipple
            sx={{ ...filterStyles.menuItem, ...sx }}
        >
            <StyledCheckbox checked={isChecked} />
            <ListItemText
                primary={name}
                sx={filterStyles.listItemText}
                disableTypography
                arial-name='listItemText'
            ></ListItemText>
        </MenuItem>
    );
};

const FilterClearItem = ({ clearText, handleChange, sx }: FilterClearItemProps) => {
    return (
        <MenuItem onClick={() => handleChange('clear')} value='clear' sx={sx} disableRipple>
            <Link component='button' underline='always' sx={filterStyles.resetButton} arial-name='listItemText'>
                {clearText}
            </Link>
        </MenuItem>
    );
};

const Filter = ({
    placeholderText = 'Filter',
    clearText = 'Clear all filters',
    availableItems,
    onChange,
    sx,
    variant = 'standard',
    value = [],
}: FilterProps) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const togglePopper = (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };

    const handleChange = (val: string) => {
        if (val === 'clear') {
            onChange && onChange([]);
        } else {
            const hasItem = value.find((item) => item === val);
            let updatedList: string[] = [];
            if (hasItem) {
                updatedList = value.filter((item) => item !== val);
            } else {
                updatedList = value.concat([val]);
            }
            onChange && onChange(updatedList);
        }
    };

    return (
        <Fragment>
            {variant === 'standard' && (
                <Select
                    sx={{ ...filterStyles.select, ...sx }}
                    IconComponent={ArrowDown}
                    displayEmpty
                    multiple
                    MenuProps={menuProps}
                    value={value}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <span>{placeholderText}</span>;
                        }

                        return (
                            <span>
                                {`${placeholderText} (${
                                    selected.length >= availableItems.length ? availableItems.length : selected.length
                                })`}
                            </span>
                        );
                    }}
                >
                    {availableItems.map((item) => {
                        const isChecked = value.includes(item.value);
                        return (
                            <FilterMenuItem
                                key={item.value}
                                item={item}
                                handleChange={handleChange}
                                isChecked={isChecked}
                            />
                        );
                    })}
                    <FilterClearItem clearText={clearText} handleChange={handleChange} />
                </Select>
            )}
            {variant === 'iconOnly' && (
                <ClickAwayListener
                    onClickAway={() => {
                        setOpen(false);
                    }}
                >
                    <Box sx={sx}>
                        <IconButton
                            onClick={togglePopper('bottom')}
                            arial-name='filterIcon'
                            disableRipple
                            sx={{
                                ...sortingStyles.iconButton,
                                '&:hover': {
                                    bgcolor: COLORS.buttonBgWhite,
                                },
                            }}
                        >
                            <FilterIcon />
                        </IconButton>
                        <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 9999 }}>
                            <Paper sx={sortingStyles.paper}>
                                {availableItems.map((item) => {
                                    const isChecked = value.includes(item.value);
                                    return (
                                        <FilterMenuItem
                                            key={item.value}
                                            item={item}
                                            handleChange={handleChange}
                                            isChecked={isChecked}
                                            sx={sx}
                                        />
                                    );
                                })}
                                <FilterClearItem clearText={clearText} handleChange={handleChange} sx={sx} />
                            </Paper>
                        </Popper>
                    </Box>
                </ClickAwayListener>
            )}
        </Fragment>
    );
};

export default Filter;
