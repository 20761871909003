const COLORS = {
    // common
    black: '#000000',
    mediumJade: '#387E79',
    cathayJade: '#005D63',
    brightJade: '#027400',
    paleJade: '#E5EDEC',
    ldYellow: '#D7B80C',
    schoolBusYellow: '#FFD911',
    darkshadeYellow: '#988100',
    lightJade: '#A8C6B7',
    ultraLightJade: '#E6Efef',
    lightShadeGray: '#C4C4C4',
    veryLightShadeGray: '#EDEDED',
    grayDark: '#4C4C4C',
    grayNormal: '#808285',
    grayLight: '#D7D7D7',
    paleGray: '#EBEDEC',
    ultraLightGray: '#F0F0F0',
    grayWhite: '#BCBEC0',
    cyanMediumLight: '#AFCBC9',
    cyanBlueLight: '#D6D8D9',
    paleBlue: '#CAE3F5',
    steelBlue: '#2A6196',
    pastelIcyBlue: '#CCE2F7',
    doveFeather: '#755D5D',
    melExtensionBg: '#FFBB6A',
    cyanLight: '#D7E5E4',
    shadowGray: '#979797',
    brightRed: '#F43131',
    purpleHepatica: '#D4A8FF',
    white: '#FFFFFF',
    backgroundWhite: '#F9F9F9',
    buttonBgWhite: '#E9EAEC',
    borderGray: '#C9CBCC',
    pastelLightGray: '#D2D2D2',
    warningYellow: '#CBAC00',
    hoverGreen: '#116F9A',
    buttonBlue: '#408Bae',
    buttonDarken20: '#005cbf',
    buttonDarken40: '#0d587b',
    buttonLighten20: '#e7f0f5',
    buttonLighten40: '#cfe2ea',
    unselectable: '#4e555b',
    unselectableLighten20: '#c1c1c1',
    warning: '#Fed700',
    backgroundGray: '#E6E7E8',
    errorRed: '#FF1C1C',
    error: '#D11F00',
    secondError: '#AD9306',
    ganttSidePanelTableHeader: '#Ebf2F0',
    ganttSidePanelTableHeaderHover: '#Ccdedf',
    twoColorTable1Dark: '#E5E6E6',
    twoColorTable2Dark: '#Ededed',
    ganttOverlappingWithMaintenance: '#E57F07',
    flightBarPax: '#EFFFFD',
    flightBarPaxIcon: '#51CFC4',
    freightBar: '#EDFAFF',
    freightBarIcon: '#34CCFF',
    otherBar: '#EEF4FF',
    otherBarIcon: '#84A6FD',
    mtceBar: '#FFFCED',
    mtceBarIcon: '#FCDD3E',
    summaryBg: '#F3F2EF',
    summaryBgYellow: '#F9F7EC',
    summaryBgRed: '#FAE9E5',
    summaryBorder: '#DAD2BC',
    summaryShades60: '#877A5C',
    transparent: 'transparent',
    borderGold: '#DAD2BC',
    borderYellow: '#AD9306',
    borderRed: '#D11F00',
    oxleyLighten: '#6FA088',
    lavenderLighten: '#FBF8E7',
    brightGray: '#E5EFEF',
    backgroundJade: '#E6EFEF',
    selectedCardBorder: '#505050',
    tableBorder: '#D7D7D7',
    chatQuoteBackground: '#E5EDEC80',
    itemHighlight: '#FFFDF4',
    chipJade: '#EBF2F1',
    summaryBgBlue: '#E7F0F5',
    eventToHxBgBlue: '#609EEC',
    // banner
    mainWarning: '#988100',
    secondWarning: '#FEFBE6',
    tableSortArrow: '#B7B7B7',

    // text
    textDark: '#2D2D2D',
    textLightGray: '#4D4D4D',

    // shadow
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.36)',
    radioBoxShadow: '#D4D4D4',
    radioInnerBoxShadow: '#FBFBFB',

    // border
    get borderColor() {
        return `1px solid ${this.grayLight}`;
    },

    // linkText
    hoverText: '#0D587B',
    activeText: '#408BAE',

    // dashboard / report
    paxFlight: '#4C86A0',
    paxFlightLight: '#B7CED9',
    cargoFlight: '#C0B498',
    palePremium: '#EDF3F5',
    lightSand: '#F3F1EF',
    innerBorder: '#7D1200',
    airHongKongRed: '#E4001C',
    disabledTab: '#C6C6C6',
    cargoFlightLight: '#D9D2C1',
    paleError: '#FAE9E5',
    delay: '#EB9B89',

    // CRD
    green: '#2AE227',
    amber: '#FF990A',

    // MCLOG
    lightBrown: '#F7F6F1',
    grayChip: '#DBDBDB',
    brightYellow: '#FEDF33',

    // react-datepicker
    activeOptionBg: '#007aff',
    mediumGray: '#242424',
    browseFilesText: '#116F9F',
};

export default COLORS;
