import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import StyledButton from '../../common/Button';
import AffectedFlightInfo from './AffectedFlightInfo';
import CreateEventFormBlockLayout from '../../createEventFormBlock/CreateEventFormBlockLayout';
import FormBlockDivider from '../../common/FormBlockDivider';
import { MemorizedFlightSearch } from '../../flightSearch';
import { IFlightBar } from '../../common/FlightBarInfo';
import { useAppDispatch } from '../../../app/hooks';
import { getFlightPortInfoThunk } from '../../../slices/flightSlice';
import { getFormFlightInfo } from '../../../helper/iocAlertHelper';
import { PortInfo } from '../../../interface/Flight';
import { ImpactedFlightInfo } from '../../../interface/IocAlert';

import { ReactComponent as PlusIcon } from '../../../assets/icons/IconPlus.svg';
import { SECTION_SEPARATION } from '../constants';
import colors from '../../../style/color';

interface FormFlightInfo extends ImpactedFlightInfo {
    flightBarInfo: IFlightBar;
    flightPortInfo: PortInfo;
}

interface AffectedFlightsProps {
    flightSearchTitle: string;
    registerNamePrefix: string;
    sectionLabel: string;
    addFlightBtnLabel: string;
    onFlightChange?: (flights: Partial<FormFlightInfo>[]) => void;
}

const AffectedFlights = (props: AffectedFlightsProps) => {
    const { flightSearchTitle, registerNamePrefix, onFlightChange, sectionLabel, addFlightBtnLabel } = props;
    const [openFlightSearch, setOpenFlightSearch] = useState(false);

    const methods = useFormContext();
    const dispatch = useAppDispatch();

    const {
        append,
        fields: affectedFlightInfos,
        remove,
    } = useFieldArray<{ [key: string]: Partial<FormFlightInfo>[] }>({
        name: registerNamePrefix,
        control: methods.control,
    });

    const selectedFlightBars = affectedFlightInfos.map((item) => item.flightBarInfo);

    const toggleFlightSearch = (open: boolean) => {
        setOpenFlightSearch(open);
    };

    const onFlightSelect = (flights: IFlightBar[]) => {
        const newFlights: IFlightBar[] = flights.reduce((accumulate, current) => {
            const isFlightExist = affectedFlightInfos.some((flight) => flight.flightBarInfo.ufi === current.ufi);
            if (isFlightExist) return accumulate;
            return [...accumulate, current];
        }, []);

        if (newFlights.length > 0) {
            const newUfis = newFlights.map((flight) => flight.ufi);
            dispatch(getFlightPortInfoThunk(newUfis))
                .unwrap()
                .then((portInfos) => {
                    const flightAllInfo = newFlights.map((flightBar) => getFormFlightInfo(portInfos, flightBar));

                    append(flightAllInfo);
                });
        }
    };

    useEffect(() => {
        onFlightChange?.(affectedFlightInfos);
    }, [affectedFlightInfos]);

    return (
        <>
            <Stack rowGap={SECTION_SEPARATION}>
                <CreateEventFormBlockLayout title={sectionLabel} isRequired={false}>
                    <StyledButton
                        label={addFlightBtnLabel}
                        variant='secondary'
                        startIcon={<PlusIcon fill={colors.hoverGreen} />}
                        onClick={() => toggleFlightSearch(true)}
                    />
                </CreateEventFormBlockLayout>

                {affectedFlightInfos.length > 0 ? (
                    <Stack divider={<FormBlockDivider />}>
                        {affectedFlightInfos.map((flight, index) => (
                            <AffectedFlightInfo
                                {...{ remove }}
                                {...methods}
                                key={flight.id}
                                registerNamePrefix={`${registerNamePrefix}.${index}`}
                                index={index}
                            />
                        ))}
                    </Stack>
                ) : (
                    false
                )}
            </Stack>
            <MemorizedFlightSearch
                title={flightSearchTitle}
                defaultFlightBars={selectedFlightBars}
                disableDefault={true}
                multiple={true}
                isOpen={openFlightSearch}
                onClose={() => toggleFlightSearch(false)}
                onConfirm={onFlightSelect}
            />
        </>
    );
};

export default AffectedFlights;
