import React from 'react';
import { Stack, SxProps, Typography } from '@mui/material';

import { SubtitleDisplay } from '../../common/view/';
import { PaxCountByCabinCode } from '../../../interface/Flight';

import { getDisplayText } from '../../../helper/stringHelper';
import COLORS from '../../../style/color';

interface PaxCountProps {
    label: string;
    value: number;
    sx?: SxProps;
}

const PADDING_WIDTH = '24px';

const PaxCount = (props: PaxCountProps) => {
    const { label, value, sx } = props;
    return (
        <SubtitleDisplay
            label={label}
            labelVariant='body5'
            value={getDisplayText(value?.toString())}
            labelSx={{ color: COLORS.grayDark, lineHeight: '20px', whiteSpace: 'nowrap' }}
            containerSx={sx}
        />
    );
};

const PreviewPaxBlock = (props: PaxCountByCabinCode) => {
    const { F, W, J, Y, total } = props;
    return (
        <Stack>
            <Typography
                variant='body3'
                sx={{
                    color: COLORS.shadowGray,
                    lineHeight: '16px',
                    whiteSpace: 'nowrap',
                }}
                children='Pax Count'
            />
            <Stack direction='row'>
                <PaxCount label='F' value={F} />
                <PaxCount label='J' value={J} sx={{ ml: PADDING_WIDTH }} />
                <PaxCount label='W' value={W} sx={{ ml: PADDING_WIDTH }} />
                <PaxCount label='Y' value={Y} sx={{ ml: PADDING_WIDTH }} />
                <PaxCount label='Total' value={total} sx={{ ml: PADDING_WIDTH }} />
            </Stack>
        </Stack>
    );
};

export default PreviewPaxBlock;
