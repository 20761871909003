import React from 'react';
import FlightBar from '../common/FlightBarInfo';
import { IFlightBarProps } from '../common/FlightBarInfo';

const MemoFlightBar = (props: IFlightBarProps) => {
    const { flightBar, onClick, checked, disabled, multiple, showLabel } = props;
    return (
        <FlightBar
            multiple={multiple}
            checked={checked}
            disabled={disabled}
            flightBar={flightBar}
            selectable={true}
            onClick={onClick}
            showLabel={showLabel}
        />
    );
};

export default React.memo(MemoFlightBar, (preProps, curProps) => {
    return preProps.checked === curProps.checked;
});
