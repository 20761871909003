import { Tab, styled } from '@mui/material';
import COLORS from '../../../../style/color';

const StyledTab = styled(Tab)({
    color: COLORS.grayNormal,
    '&.Mui-selected': {
        color: COLORS.cathayJade,
    },
    textTransform: 'none',
});

export default StyledTab;
