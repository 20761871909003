import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { RootState } from '../app/store';
import {
    getEventSetting,
    createEvent,
    reopenEvent,
    cancelEvent,
    getEventByIdV2,
    getAllEvents,
    getIocEvents,
    getEventTasksById,
    updateEtsById,
    updateAtsById,
    getConflictEventTasks,
    closeEvent,
    getEventReportBlob,
    getOwnerList,
    getAircraftList,
    getProperties,
    updateEventDetails,
    getHandoverNoteBlob,
    generateEventsReport,
    getEventMedia,
} from '../services/events';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { IFlightBar } from '../components/common/FlightBar';
import {
    TaskType,
    RECOVERY_PLAN_TASK_TYPES,
    EVENT_STATUS,
    SORT_FIELD,
    PropertiesTypes,
    EventOwnerCategory,
} from '../constants/constants';
import { ThumbnailItem } from './fileSlice';
import { FlightFilter, Filter, FlightObj } from './flightSlice';
import { SparesAndTooling } from './recoveryPlanSlice';
import { DisMessageRes } from '../slices/disMessageSlice';
import { ReportCardType } from '../slices/reportSlice';
import { StatusList, RiskLevel, EventContentBlockName } from '../constants/constants';
import UserInfo from '../interface/UserInfo';
import { getRegNoList, getAirportList } from '../services/events';
import { getRegDescriptionWithPrefix } from '../helper/stringHelper';
import { DateFormat } from '../helper/dateHelper';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import {
    AircraftFilter,
    CloseEventExtraData,
    EventAssessment,
    IOCAlertReport,
    PropertiesDetails,
} from '../interface/event.interface';
import { EventReportRequestParams } from '../interface/MCExportEvent';
import { EVENT_TASK_ENDPOINTS } from '../constants/api';
import { updateEventTasksByEventId } from '../services/eventSubtask';
import { downloadBlob } from './fileSlice';
import { IOCZipData } from '../interface/IOCLog';
import { IOCAlertType } from '../interface/IocAlert';
import { IOCAlertStatus } from '../components/iocAlert';
import { Groups } from './userProfileSlice';
import { Log, WorkRequestDetail } from '../interface/WorkRequest';
export interface EventUpdateETS {
    userId: string;
    eventId: string;
    dateTime: string;
}
export interface EventStatusUpdate {
    userId: string;
    eventId: string;
    dateTime: string;
    remarks?: string;
    eventTaskType?: TaskType;
    status?: StatusList;
    subtypeStatus?: EventSubtypeStatus;
}

export enum EventState {
    open = 'open',
    closed = 'closed',
}
export enum EventSubtypeStatus {
    serviceable = 'Serviceable',
    unserviceable = 'Unserviceable',
}

export interface EventSetting {
    id: number;
    accessType: string;
    type: string;
    subType: EventSettingSubType[];
}

export interface EventSettingSubType {
    id: number;
    name: string;
    value: string;
    initial: string;
    content: string[];
    landingPage: string;
    eventFields: EventFields[];
}

export interface EventFields {
    component: EventContentBlockName;
    label: string;
    isRequired: boolean;
    details: EventFieldDetails[];
}

export interface EventFieldDetails {
    isMultiple: boolean;
    submitName: string;
    submitFormat: string;
    isRequired: boolean;
    onWatch: boolean;
    isEditable: boolean;
    seq: number;
}

export interface Event {
    userId: string;
    groupId: number;
    details: EventDetails;

    // for edit event
    id?: number;
    state?: string;
    subtypeStatus?: EventSubtypeStatus.unserviceable | EventSubtypeStatus.serviceable | string;
}

export interface EventDetails {
    typeId: number;
    subTypeId: number;
    affectedFlights?: string[] | IFlightBar[];
    registration: string;
    startTime: string;
    endTime?: string;
    duration?: string;
    etsTime: string; // estimate end time for AOG event
    owner: string | number;
    // issueSummary
    reporter?: string;
    description?: string;
    eventLabels?: string[];
    isHandoverReport?: boolean;
}

export interface DetailedEvent {
    id: string;
    eventId: string;
    groupId: number;
    typeId: number;
    subTypeId: number;
    type: string;
    subType: string;
    state: string;
    flightStatus: string;
    station: string;
    initial: string;
    registration: string;
    owner: string | Groups;
    airline: string;
    aircraftType: string;
    location: string;
    description: string;
    nextDeparture: string;
    aircraftSubType: string;
    startTime: string;
    endTime: string;
    ageOfAOG: string;
    eventRisk: RiskLevel;
    etsTime: string; // estimate end time for AOG event
    atsTime: string;
    flightNumber?: string;
    updatedBy: UserInfo;
    createdBy: UserInfo;
    updatedAt: string;
    createdAt: string;
    serviceType: string;
    manufacturer: string;
    affectedFlights?: string[];
    subtypeStatus: EventSubtypeStatus.unserviceable | EventSubtypeStatus.serviceable;
    scheduleArrivalAirport: string;
    scheduleDepartureAirport: string;
    duration: string;
    flights: FlightObj[];
    impactedPorts?: string[];
    routes?: string[];
    estEndTime: string; // estimate end time for non AOG event
    assessmentDetails: EventAssessment;
    iocAlerts?: IOCAlertReport[];
    iocAlertLatestVersion?: number;
    ataChapter?: string;
    availableGroundTime?: string;
    reporter: string;
    eventLabels: string[];
    isHandoverReport: boolean;
    nextDepartureSTD?: string;
    port?: string;
    carrier?: string;
    eventTitle?: string;
    disMessage?: DisMessageRes;
    workRequests?: Log<WorkRequestDetail>[];
    statusReports?: ReportCardType[];
    pendingStatusReportCount?: number;
}

export interface IssueSummaryDetailType {
    id?: number;
    status?: string;
    endTime?: string;
    etsTime?: string;
    taskType: string;
    startTime?: string;
    reporter?: string;
    reportTime?: string;
    ataCode?: string;
    description?: string;
    issueUpdate?: IssueUpdate;
    deferDisplay?: string;
    melReference?: string;
    melCategory?: string;
    daysRemaining?: string;
    openDate?: string;
    addDescription?: string;
    addRestriction?: string;
}
export interface SpareDetailType {
    id?: number; // eventTaskId for update
    title?: string;
    active: boolean; // false if deleted, true othewise
    status?: string;
    airline?: string;
    etsTime?: string; // "YYYY-MM-DD HH:mm",
    endTime?: string; // "YYYY-MM-DD HH:mm",
    comments?: string;
    quantity?: string;
    taskType: string;
    flightDate?: string;
    partNumber?: string;
    description?: string;
    flightNumber?: string;
    trackingNumber?: string;
    transportMethod?: string;
    affectedFlight?: string; // ufi
}
interface ManPowerType {
    status?: string;
    airline?: string;
    comments?: string;
    duration?: string;
    taskType: string;
    engineers?: string;
    startTime?: string;
    travelInfo?: [];
    affectedFlight?: string;
    specialRequired?: string;
}
export interface IssueUpdate {
    id?: number;
    taskId?: number;
    atsTime?: string;
    etsTime?: string;
    remarks?: string;
    status?: string;
    createdAt?: string;
    createdBy?: UserInfo;
    updatedAt?: string;
    updatedBy?: UserInfo;
    upload?: number[];
    uploadFiles?: ThumbnailItem[];
}
export interface EventTasks {
    id?: number;
    eventId?: number;
    status?: string;
    taskType?: string;
    details?: IssueSummaryDetailType | SpareDetailType | ManPowerType | IOCAlertType;
    startTime?: string;
    etsTime?: string;
    endTime?: string;
    etsTimeDisplay?: string;
    endTimeDisplay?: string;
    duration?: string;
    processStatus?: string;
    eventRisk?: RiskLevel;
    updatedBy?: UserInfo;
    createdBy?: UserInfo;
    updatedAt?: string;
    createdAt?: string;
    flight?: FlightObj;
    issueUpdates?: IssueUpdate[];
}

export type EtsConflictEvent = {
    eventId: string;
    etsTime: string;
    startTime: string;
    eventTask: Array<EtsConflictEventTask>;
};

export type EtsConflictEventTask = {
    taskType: string;
    etsTimeUTC?: string;
    startTimeUTC?: string;
    endTimeUTC?: string;
    details: EtsConflictEventTaskDetails;
};

type EtsConflictEventTaskDetails = {
    id: number;
    status: string;
    airline: string;
    comment: string;
    endTime: string;
    etsTime: string;
    quantity: string;
    taskType: string;
    partNumber: number;
    description: string;
    affectedFlight: string;
    etsTimeDisplay: string;
    trackingNumber: string;
    transportMethod: string;
};

export interface IocEventListFilters {
    startDate: string;
    endDate: string;
    sorting?: string;
    keyWords?: string;
    eventStatuses?: string[];
    eventTypes?: string[];
    hasAssessment?: boolean;
    page?: number;
    size?: number;
    eventPage?: string;
}

interface CloseMcEventStatus {
    isOpen: boolean;
    data: { eventId?: string; startTime?: string } | null;
}

export const getEventSettingThunk = createAsyncThunk<
    { eventSetting: EventSetting[] },
    null,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/getEventSetting', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/setting')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getEventSetting();

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as { eventSetting: EventSetting[] };
});

export const getZippedIOCReportThunk = createAsyncThunk<
    { blob: Blob; header },
    IOCZipData,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/getEventReport', async (zipSource: IOCZipData, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/report/iocevent')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, blob, header] = await getEventReportBlob(zipSource);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return { blob, header };
});

export const downloadHandoverNoteThunk = createAsyncThunk<
    { blob: Blob; header: Headers },
    string,
    { state: RootState; rejectValue: ApiErrorObj }
>('handovernote/export', async (eventId: string, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/report/handoverNote')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, blob, header] = await getHandoverNoteBlob(eventId);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { blob, header };
});

export const createEventThunk = createAsyncThunk<
    { event: DetailedEvent[] },
    Event,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/createEvent', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentGroupId: groupId, currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const eventData = {
        userId,
        groupId,
        ...params,
    };
    const [err, data] = await createEvent({ param: eventData });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as { event: DetailedEvent[] };
});

export const updateEventDetailsThunk = createAsyncThunk<
    DetailedEvent,
    { details: Object },
    { state: RootState; rejectValue: ApiErrorObj }
>('event/details', async ({ details }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/details', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const { selectedEvent } = getState().event;
    const { id: eventId } = selectedEvent;
    const eventData = {
        eventId,
        details,
    };

    const [err, data] = await updateEventDetails(eventData);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const updateIsHandoverReportByEventIdThunk = createAsyncThunk<
    DetailedEvent,
    { eventId: string; isHandoverReport: boolean },
    { state: RootState; rejectValue: ApiErrorObj }
>('event/handoverNote', async ({ eventId, isHandoverReport }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/details', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const eventData = {
        eventId,
        details: {
            isHandoverReport,
        },
    };

    const [err, data] = await updateEventDetails(eventData);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const editEventThunk = createAsyncThunk<
    { event: DetailedEvent[] },
    Event,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/editEvent', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentGroupId: groupId, currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const eventData = {
        userId,
        groupId,
        ...params,
    };
    const [err, data] = await createEvent({ param: eventData });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as { event: DetailedEvent[] };
});

interface AllEventsInput {
    eventStatus: string;
    startDate: string;
    endDate: string;
    eventPage?: string;
}

export const closeEventThunk = createAsyncThunk<
    { id: string; description?: string },
    { eventId: string; extraData: CloseEventExtraData },
    { state: RootState; rejectValue: ApiErrorObj }
>(
    'events/closeEvent',
    async (param: { eventId: string; extraData: CloseEventExtraData }, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { userId, currentPermissionList } = userProfile;

        if (!resourceCheck(currentPermissionList, ResourceType.API, '/closeEvent')) {
            return rejectWithValue(ForbiddenObj);
        }
        const { eventId, extraData } = param;
        const { closeDateTime: dateTime, assessment, statusRemarks, isHandoverReport } = extraData;

        const [err, data] = await closeEvent({
            eventId,
            userId,
            dateTime,
            assessment,
            statusRemarks,
            isHandoverReport,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }
);

export const reopenEventThunk = createAsyncThunk<
    void,
    {
        selectedEvent: DetailedEvent;
    },
    { state: RootState; rejectValue: ApiErrorObj }
>('event/reopen', async ({ selectedEvent }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    const { id: eventId } = selectedEvent;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/reopen', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await reopenEvent({
        eventId: eventId,
    });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data;
});

export const cancelEventThunk = createAsyncThunk<
    void,
    {
        selectedEvent: DetailedEvent;
    },
    { state: RootState; rejectValue: ApiErrorObj }
>('event/cancel', async ({ selectedEvent }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    const { id: eventId } = selectedEvent;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/cancel', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await cancelEvent({
        eventId: eventId,
    });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data;
});

export const getAllEventsThunk = createAsyncThunk<
    DetailedEvent[],
    AllEventsInput,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/getAllEvents', async ({ eventStatus, startDate, endDate, eventPage }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/getEvents', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getAllEvents({
        eventStatus,
        startDate,
        endDate,
        eventPage,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as DetailedEvent[];
});

export const getIocEventsThunk = createAsyncThunk<
    { events: DetailedEvent[]; total: number },
    void,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/getEventsByPost', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/events/ioc', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const { iocEventListFilters } = getState().event;
    const [err, data] = await getIocEvents(iocEventListFilters);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const getEventByIdThunk = createAsyncThunk<
    DetailedEvent,
    {
        id: string;
        skipRedux?: boolean;
    },
    { state: RootState; rejectValue: ApiErrorObj }
>('events/getEventById', async ({ id, skipRedux }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, result] = await getEventByIdV2({ id });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    // ioc alert version calculate
    if (result.iocAlerts) {
        const latestAlert = result.iocAlerts.find((item) => item.status === IOCAlertStatus.sent);
        const iocAlertLatestVersion = latestAlert ? parseInt(latestAlert.version.replace('v', '')) : 0;
        result.iocAlertLatestVersion = iocAlertLatestVersion;
    } else {
        result.iocAlertLatestVersion = 0;
    }

    return result;
});

export const getEventTasksByIdThunk = createAsyncThunk<
    EventTasks[],
    string,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/getEventTasksById', async (eventId: string, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getEventTasksById(eventId);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as EventTasks[];
});

export const updateEtsThunk = createAsyncThunk<
    EventUpdateETS,
    { dateTime: string },
    { state: RootState; rejectValue: ApiErrorObj }
>('events/updateEtsById', async (params: EventStatusUpdate, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentPermissionList } = userProfile;
    const { selectedEventId: eventId } = getState().event;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/updateETS')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await updateEtsById({
        ...params,
        userId,
        eventId,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as EventUpdateETS;
});

export const editEventAssessmentThunk = createAsyncThunk<
    EventTasks,
    { assessment: string },
    { state: RootState; rejectValue: ApiErrorObj }
>('events/editEventAssessment', async (params: { assessment: string }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, EVENT_TASK_ENDPOINTS.eventTasks)) {
        return rejectWithValue(ForbiddenObj);
    }

    const { selectedEvent } = getState().event;
    const { id: eventId, assessmentDetails } = selectedEvent || {};
    const { id: assessmentId } = assessmentDetails || {};

    const [err, data] = await updateEventTasksByEventId({
        details: {
            id: assessmentId,
            taskType: TaskType.assessment,
            ...params,
        },
        userId,
        eventId,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const updateAtsThunk = createAsyncThunk<{}, EventStatusUpdate, { state: RootState; rejectValue: ApiErrorObj }>(
    'events/updateAtsById',
    async (params: EventStatusUpdate, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { userId } = userProfile;
        const { selectedEventId: eventId } = getState().event;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/updateATS')) {
            return rejectWithValue(ForbiddenObj);
        }

        const updateAtsObject = {
            ...params,
            eventTaskType: TaskType.issueSummary,
            status: StatusList.aircraftServiceable,
            subtypeStatus: EventSubtypeStatus.serviceable,
            userId: userId,
            eventId: eventId,
        };

        const [err, data] = await updateAtsById(updateAtsObject);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }
);

export const getConflictEventTasksThunk = createAsyncThunk<
    { data: EtsConflictEvent; hasConflict: boolean },
    null,
    { state: RootState; rejectValue: ApiErrorObj }
>('etsCompare', async (_, { getState, rejectWithValue }) => {
    const { selectedEventId: eventId } = getState().event;
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/etsCompare')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getConflictEventTasks(eventId);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    const { eventTask } = (data as EtsConflictEvent) || {};
    const recoveryPlanTasks = eventTask.filter((tasks) => RECOVERY_PLAN_TASK_TYPES.includes(tasks.taskType)) || [];

    return { data: data as EtsConflictEvent, hasConflict: recoveryPlanTasks.length > 0 };
});

export const getRegNoListThunk = createAsyncThunk<string[], void, { state: RootState; rejectValue: ApiErrorObj }>(
    'events/getRegNo',
    async (_, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/reglist')) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await getRegNoList();
        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }
        return data as string[];
    }
);

export const getAircraftListThunk = createAsyncThunk<
    AircraftFilter[],
    void,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/getAircraftFilterOptionsThunk', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/aircraftList')) {
        return rejectWithValue(ForbiddenObj);
    }
    const { aircraftOptions } = getState().event;
    if (aircraftOptions?.length > 0) {
        return;
    }
    const [err, data] = await getAircraftList();
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as AircraftFilter[];
});

export const getEventLabelListThunk = createAsyncThunk<
    PropertiesDetails,
    void,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/getEventLabelListThunk', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/properties')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getProperties({ types: [PropertiesTypes.AOGEvent] });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as PropertiesDetails;
});

const fetchAirportList = async (
    portName: string,
    getState: () => RootState,
    rejectWithValue: (value: ApiErrorObj) => any
): Promise<string[] | ReturnType<typeof rejectWithValue>> => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/airport')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getAirportList(portName);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data as string[];
};

export const getAirportListThunk = createAsyncThunk<string[], string, { state: RootState; rejectValue: ApiErrorObj }>(
    'events/getAirportList',
    async (portName: string, { getState, rejectWithValue }) => {
        return fetchAirportList(portName, getState, rejectWithValue);
    }
);

export const getAirportListNoLoadingThunk = createAsyncThunk<
    string[],
    string,
    { state: RootState; rejectValue: ApiErrorObj }
>('events/getAirportListNoLoading', async (portName: string, { getState, rejectWithValue }) => {
    return fetchAirportList(portName, getState, rejectWithValue);
});

export const getIOCTOwnerListThunk = createAsyncThunk<Groups[], void, { state: RootState; rejectValue: ApiErrorObj }>(
    'events/getOwnerList',
    async (_, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/getCategoryGroups')) {
            return rejectWithValue(ForbiddenObj);
        }

        const [err, data] = await getOwnerList({
            category: EventOwnerCategory.ioct,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }
        return data;
    }
);

export const getMCOwnerListThunk = createAsyncThunk<Groups[], void, { state: RootState; rejectValue: ApiErrorObj }>(
    'events/getMCOwnerList',
    async (_, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/getCategoryGroups')) {
            return rejectWithValue(ForbiddenObj);
        }

        const [err, data] = await getOwnerList({
            category: EventOwnerCategory.mc,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }
        return data;
    }
);

export const generateEventsReportThunk = createAsyncThunk<
    { blob: Blob | null; header: Headers },
    EventReportRequestParams,
    { state: RootState; rejectValue: ApiErrorObj }
>('report/multiMCEvents', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/report/mcevent', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, blob, header] = await generateEventsReport(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { blob, header };
});

export const getEventMediaThunk = createAsyncThunk<
    ThumbnailItem[],
    string,
    { state: RootState; rejectValue: ApiErrorObj }
>('event/media', async (eventId, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/media', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getEventMedia(eventId);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data;
});

const IOC_EVENT_LIST_DEFAULT_PAGE_SIZE = 50;

export interface EventSlice {
    isEventLoading: boolean;
    isGettingAllEvents: boolean;
    isGettingIocEvents: boolean;
    isCloseEventDialogOpen: boolean;
    isReopenEventDialogOpen: boolean;
    isCancelEventDialogOpen: boolean;
    isEventAssessmentOpen: boolean;
    eventSetting: EventSetting[];
    eventTypesList: { display: string; value: string; accessType: string }[];
    ownerList: Groups[];
    flightStatusList: { display: string; value: string }[];
    eventErrorObject: ApiErrorObj;
    createdEvent?: DetailedEvent;
    selectedEventId?: string;
    selectedEvent?: DetailedEvent;
    contentNameListInSelectedEvent?: string[];
    contentNameListDisplayLabel?: { [key: string]: string };
    allEvents?: DetailedEvent[];
    allIocEvents?: DetailedEvent[];
    selectedFlights: IFlightBar[];
    selectedFlightFilter: FlightFilter;
    isIssueSummaryLoading: boolean;
    issueSummary?: EventTasks[];
    spares?: SparesAndTooling[];
    updateEtsStatus: {
        isLoading?: boolean;
    };
    etsConflictEventState: {
        isEtsConflictNeedUIUpdate: boolean;
        shouldSkipEtsConflictDialog: boolean;
        etsConflictEvent: EtsConflictEvent;
    };
    regOptions: { label: string; value: string }[];
    iocEventListFilters: IocEventListFilters;
    createEventFormStatus: { isOpen: boolean; isEdit: boolean; event?: DetailedEvent | null };
    iocListTotal?: number;
    aircraftOptions: { label: string; value: string }[];
    regAircraftMapping?: { [key: string]: string };
    eventLabelList: { label: string; value: string }[];
    createHandoverNoteStatus: { isOpen: boolean; isEdit: boolean; event?: DetailedEvent | null };
    closeMcEventDialogStatus: CloseMcEventStatus;
    eventMediaList: ThumbnailItem[];
}

export const initialState: EventSlice = {
    isEventLoading: false,
    isGettingAllEvents: false,
    isGettingIocEvents: false,
    isCloseEventDialogOpen: false,
    isReopenEventDialogOpen: false,
    isCancelEventDialogOpen: false,
    isEventAssessmentOpen: false,
    eventSetting: [],
    eventTypesList: [],
    createdEvent: null,
    selectedEvent: null,
    iocEventListFilters: {
        startDate: DateTime.now().toUTC().minus({ days: 60 }).toFormat(DateFormat.ServerQueryFormat),
        endDate: DateTime.now().toUTC().toFormat(DateFormat.ServerQueryFormat),
        sorting: SORT_FIELD.desc,
        keyWords: '',
        eventStatuses: [EVENT_STATUS.open],
        eventTypes: [],
        page: 1,
        size: IOC_EVENT_LIST_DEFAULT_PAGE_SIZE,
        hasAssessment: false,
        eventPage: '/ioceventlist',
    },
    contentNameListInSelectedEvent: [],
    contentNameListDisplayLabel: null,
    ownerList: [],
    flightStatusList: [
        { display: 'Late ex. Maintenance', value: 'Late ex. Maintenance' },
        { display: 'Inflight return', value: 'Inflight return' },
        { display: 'Ground return', value: 'Ground return' },
        { display: 'Diversion', value: 'Diversion' },
        { display: 'Maintenance finding', value: 'Maintenance finding' },
        { display: 'Crew flight finding', value: 'Crew flight finding' },
    ],
    selectedFlights: [],
    selectedFlightFilter: {
        searchKey: '',
        startDate: DateTime.now(),
        endDate: DateTime.now().plus({ days: 1 }),
        searchTrigger: false,
    },
    updateEtsStatus: {
        isLoading: null,
    },
    isIssueSummaryLoading: false,
    issueSummary: [],
    spares: [],
    etsConflictEventState: {
        isEtsConflictNeedUIUpdate: false,
        shouldSkipEtsConflictDialog: true,
        etsConflictEvent: null,
    },
    eventErrorObject: null,
    regOptions: [],
    aircraftOptions: [],
    eventLabelList: [],
    regAircraftMapping: {},
    createEventFormStatus: {
        isOpen: false,
        isEdit: false,
        event: null,
    },
    createHandoverNoteStatus: {
        isOpen: false,
        isEdit: false,
        event: null,
    },
    iocListTotal: 0,
    closeMcEventDialogStatus: { isOpen: false, data: null },
    eventMediaList: [],
};

export const eventSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        selectEventId: (state, action: PayloadAction<string>) => {
            state.selectedEventId = action.payload;
        },
        deselectEvent: (state) => {
            state.selectedEvent = null;
            state.contentNameListInSelectedEvent = [];
            state.contentNameListDisplayLabel = {};
            state.selectedEventId = null;
            state.updateEtsStatus.isLoading = null;
        },
        selectFlight: (state, action: PayloadAction<IFlightBar>) => {
            const findSelectedFlight = state.selectedFlights.find((flight) => flight.ufi === action.payload.ufi);
            if (findSelectedFlight) {
                state.selectedFlights = state.selectedFlights.filter((flight) => flight.ufi !== action.payload.ufi);
            } else {
                state.selectedFlights = [...state.selectedFlights, action.payload];
            }
        },
        changeFlightFilter: (state, action: PayloadAction<Filter>) => {
            state.selectedFlightFilter[action.payload.type] = action.payload.value;
        },
        initUpdateEtsDialog: (state) => {
            state.etsConflictEventState.isEtsConflictNeedUIUpdate = false;
            state.etsConflictEventState.shouldSkipEtsConflictDialog = false;
        },
        initResolveEtsConflictDialog: (state) => {
            state.etsConflictEventState.isEtsConflictNeedUIUpdate = false;
            state.etsConflictEventState.shouldSkipEtsConflictDialog = true;
        },
        completedEtsConflictUIUpdate: (state) => {
            state.etsConflictEventState.isEtsConflictNeedUIUpdate = false;
        },
        toggleCloseEventDialog: (state, action: PayloadAction<boolean>) => {
            if (action.payload !== undefined) {
                state.isCloseEventDialogOpen = action.payload;
            } else {
                state.isCloseEventDialogOpen = !state.isCloseEventDialogOpen;
            }
        },
        toggleEventAssessmentDialog: (state, action: PayloadAction<boolean>) => {
            if (action.payload !== undefined) {
                state.isEventAssessmentOpen = action.payload;
            } else {
                state.isEventAssessmentOpen = !state.isEventAssessmentOpen;
            }
        },
        toggleReopenEventDialog: (state, action: PayloadAction<boolean>) => {
            if (action.payload !== undefined) {
                state.isReopenEventDialogOpen = action.payload;
            } else {
                state.isReopenEventDialogOpen = !state.isReopenEventDialogOpen;
            }
        },
        toggleCancelEventDialog: (state, action: PayloadAction<boolean>) => {
            if (action.payload !== undefined) {
                state.isCancelEventDialogOpen = action.payload;
            } else {
                state.isCancelEventDialogOpen = !state.isCancelEventDialogOpen;
            }
        },
        updateIocEventListFilters: (state, action: PayloadAction<IocEventListFilters>) => {
            state.iocEventListFilters = action.payload;
        },
        resetIocEventListFilters: (state) => {
            state.iocEventListFilters = {
                ...initialState.iocEventListFilters,
                startDate: DateTime.now().toUTC().minus({ days: 60 }).toFormat(DateFormat.ServerQueryFormat),
                endDate: DateTime.now().toUTC().toFormat(DateFormat.ServerQueryFormat),
            };
        },
        updateCreateEventFormStatus: (
            state,
            action: PayloadAction<{ isOpen: boolean; isEdit: boolean; event?: DetailedEvent | null }>
        ) => {
            const { isOpen = true, isEdit = false, event } = action.payload;
            state.createEventFormStatus = { isOpen, isEdit, event };
        },
        updateCreateHandoverNoteStatus: (
            state,
            action: PayloadAction<{ isOpen: boolean; isEdit: boolean; event?: DetailedEvent | null }>
        ) => {
            const { isOpen = true, isEdit = false, event } = action.payload;
            state.createHandoverNoteStatus = { isOpen, isEdit, event };
        },
        updateCloseMcEventDialogStatus: (state, action: PayloadAction<CloseMcEventStatus>) => {
            const { isOpen = false, data = null } = action.payload;
            state.closeMcEventDialogStatus = { isOpen, data };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEventSettingThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(getEventSettingThunk.fulfilled, (state, { payload }) => {
                const { eventSetting } = payload;
                state.eventSetting = eventSetting.map((es) => {
                    const { subType } = es;
                    const updatedSubType = subType.map((st) => {
                        const { eventFields } = st;
                        const updatedEventFields = eventFields.map((ef) => {
                            const { details } = ef;
                            details.sort((a, b) => (a.seq > b.seq ? 1 : -1));
                            return { ...ef, details };
                        });
                        return { ...st, eventFields: updatedEventFields };
                    });
                    return { ...es, subType: updatedSubType };
                });

                state.eventTypesList = eventSetting.map((item) => ({
                    display: item.type,
                    value: item.id.toString(),
                    accessType: item.accessType,
                }));

                state.isEventLoading = false;
            })
            .addCase(getEventSettingThunk.rejected, (state) => {
                state.isEventLoading = false;
            })
            .addCase(createEventThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(createEventThunk.fulfilled, (state, { payload }) => {
                const { event } = payload;
                state.isEventLoading = false;
                state.createdEvent = event?.[0];
                // TODO need to update allEvents to refresh event list
            })
            .addCase(createEventThunk.rejected, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(reopenEventThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(reopenEventThunk.fulfilled, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(reopenEventThunk.rejected, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(cancelEventThunk.pending, (state, { payload }) => {
                state.isEventLoading = true;
            })
            .addCase(cancelEventThunk.fulfilled, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(cancelEventThunk.rejected, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(editEventThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(editEventThunk.fulfilled, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(editEventThunk.rejected, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(updateEventDetailsThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(updateEventDetailsThunk.fulfilled, (state) => {
                state.isEventLoading = false;
            })
            .addCase(updateEventDetailsThunk.rejected, (state) => {
                state.isEventLoading = false;
            })
            .addCase(updateIsHandoverReportByEventIdThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(updateIsHandoverReportByEventIdThunk.fulfilled, (state) => {
                state.isEventLoading = false;
            })
            .addCase(updateIsHandoverReportByEventIdThunk.rejected, (state) => {
                state.isEventLoading = false;
            })
            .addCase(getEventByIdThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(getEventByIdThunk.fulfilled, (state, { payload, meta }) => {
                state.isEventLoading = false;
                const { skipRedux } = meta.arg || {};
                if (skipRedux) {
                    return;
                }
                const selectedEvent: DetailedEvent = payload;
                state.selectedEvent = selectedEvent;

                const selectedEventFields = state.eventSetting
                    ?.find((item) => item.id === selectedEvent.typeId)
                    ?.subType?.find((item) => item.id === selectedEvent.subTypeId).eventFields;
                state.contentNameListInSelectedEvent = selectedEventFields?.map((item) => item.component);
                state.contentNameListDisplayLabel = selectedEventFields?.reduce((contentToDisplay, field) => {
                    const { component, label } = field;
                    return { ...contentToDisplay, [component]: label };
                }, {});
            })
            .addCase(getEventByIdThunk.rejected, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(getAllEventsThunk.pending, (state) => {
                state.isEventLoading = true;
                state.isGettingAllEvents = true;
            })
            .addCase(getAllEventsThunk.fulfilled, (state, { payload }) => {
                state.allEvents = payload;
                state.isEventLoading = false;
                state.isGettingAllEvents = false;
            })
            .addCase(getAllEventsThunk.rejected, (state) => {
                state.isEventLoading = false;
                state.isGettingAllEvents = false;
            })
            .addCase(getIocEventsThunk.pending, (state) => {
                state.isEventLoading = true;
                state.isGettingIocEvents = true;
            })
            .addCase(getIocEventsThunk.fulfilled, (state, { payload }) => {
                state.allIocEvents = payload.events;
                state.iocListTotal = payload.total;
                state.isEventLoading = false;
                state.isGettingIocEvents = false;
            })
            .addCase(getIocEventsThunk.rejected, (state) => {
                state.isEventLoading = false;
                state.isGettingIocEvents = false;
            })
            .addCase(getEventTasksByIdThunk.pending, (state, { payload }) => {
                state.isIssueSummaryLoading = true;
                state.issueSummary = [];
                state.spares = [];
            })
            .addCase(getEventTasksByIdThunk.fulfilled, (state, { payload }) => {
                state.isIssueSummaryLoading = false;
                state.issueSummary = payload.filter((tasks) => tasks.taskType === TaskType.issueSummary) || [];
                state.spares =
                    (payload.filter(
                        (tasks) => tasks.taskType === TaskType.spare || tasks.taskType === TaskType.tooling
                    ) as SparesAndTooling[]) || [];
            })
            .addCase(getEventTasksByIdThunk.rejected, (state) => {
                state.isIssueSummaryLoading = false;
            })
            .addCase(updateEtsThunk.pending, (state) => {
                state.updateEtsStatus.isLoading = true;
            })
            .addCase(updateEtsThunk.fulfilled, (state, { payload }) => {
                state.updateEtsStatus.isLoading = false;
            })
            .addCase(updateEtsThunk.rejected, (state, { payload }) => {
                state.updateEtsStatus.isLoading = false;
                state.eventErrorObject = payload;
            })
            .addCase(getConflictEventTasksThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(getConflictEventTasksThunk.fulfilled, (state, { payload }) => {
                const { data } = payload;
                const { hasConflict } = payload;
                const { eventTask } = data;
                const recoveryPlanTasks =
                    eventTask.filter((tasks) => RECOVERY_PLAN_TASK_TYPES.includes(tasks.taskType)) || [];
                if (hasConflict) {
                    state.etsConflictEventState.etsConflictEvent = {
                        ...data,
                        eventTask: recoveryPlanTasks,
                    };
                } else {
                    state.etsConflictEventState.etsConflictEvent = null;
                }
                state.etsConflictEventState.isEtsConflictNeedUIUpdate = true;
                state.isEventLoading = false;
            })
            .addCase(getConflictEventTasksThunk.rejected, (state, { payload }) => {
                state.eventErrorObject = payload;
                state.etsConflictEventState.isEtsConflictNeedUIUpdate = true;
                state.etsConflictEventState.etsConflictEvent = null;

                state.isEventLoading = false;
            })
            .addCase(getRegNoListThunk.fulfilled, (state, { payload }) => {
                state.regOptions = payload.map((reg: string) => ({
                    label: getRegDescriptionWithPrefix(reg),
                    value: reg,
                }));
            })
            .addCase(getRegNoListThunk.rejected, (state) => {
                state.regOptions = [];
            })
            .addCase(closeEventThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(closeEventThunk.fulfilled, (state) => {
                state.isEventLoading = false;
            })
            .addCase(closeEventThunk.rejected, (state) => {
                state.isEventLoading = false;
            })
            .addCase(getZippedIOCReportThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(getZippedIOCReportThunk.fulfilled, (state, { payload }) => {
                state.isEventLoading = false;
                const { blob, header } = payload;
                const encodedFileName = header['content-disposition'].split('attachment;filename=')[1];
                const fileName = decodeURI(encodedFileName);
                downloadBlob({
                    blob,
                    fileName,
                });
            })
            .addCase(downloadHandoverNoteThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(downloadHandoverNoteThunk.fulfilled, (state, { payload }) => {
                const { blob, header } = payload;
                const encodedFileName = header['content-disposition']?.split('attachment;filename=')[1];
                const fileName = decodeURI(encodedFileName);
                downloadBlob({
                    blob,
                    fileName,
                });
                state.isEventLoading = false;
            })
            .addCase(downloadHandoverNoteThunk.rejected, (state) => {
                state.isEventLoading = false;
            })
            .addCase(getZippedIOCReportThunk.rejected, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(getMCOwnerListThunk.fulfilled, (state, { payload = [] }) => {
                state.ownerList = payload;
            })
            .addCase(getMCOwnerListThunk.rejected, (state) => {
                state.ownerList = [];
            })
            .addCase(getIOCTOwnerListThunk.pending, (state) => {
                state.ownerList = [];
            })
            .addCase(getIOCTOwnerListThunk.fulfilled, (state, { payload = [] }) => {
                state.ownerList = payload;
            })
            .addCase(getIOCTOwnerListThunk.rejected, (state) => {
                state.ownerList = [];
            })
            .addCase(getAircraftListThunk.pending, (state) => {
                state.aircraftOptions = [];
                state.regAircraftMapping = {};
            })
            .addCase(getAircraftListThunk.rejected, (state) => {
                if (state.aircraftOptions.length > 0) {
                    state.aircraftOptions = [];
                    state.regAircraftMapping = {};
                }
            })
            .addCase(getAircraftListThunk.fulfilled, (state, { payload }) => {
                if (payload) {
                    state.aircraftOptions = payload.map(({ aircraftType }) => {
                        return {
                            label: aircraftType,
                            value: aircraftType,
                        };
                    });
                    state.regAircraftMapping = payload.reduce((prev, next) => {
                        next.aircraftSubtypeList.reduce((subPrev, subNext) => {
                            subNext.registration.forEach((reg) => {
                                subPrev[reg] = next.aircraftType;
                            });
                            return subPrev;
                        }, prev);
                        return prev;
                    }, {});
                }
            })
            .addCase(getEventLabelListThunk.pending, (state) => {
                if (state.eventLabelList.length > 0) {
                    state.eventLabelList = [];
                }
            })
            .addCase(getEventLabelListThunk.rejected, (state) => {
                if (state.eventLabelList.length > 0) {
                    state.eventLabelList = [];
                }
            })
            .addCase(getEventLabelListThunk.fulfilled, (state, { payload }) => {
                const { [PropertiesTypes.AOGEvent]: labels } = payload;
                state.eventLabelList = labels?.map((item) => {
                    return {
                        label: item.description,
                        value: item.code,
                    };
                });
            })
            .addCase(generateEventsReportThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(generateEventsReportThunk.rejected, (state) => {
                state.isEventLoading = false;
            })
            .addCase(generateEventsReportThunk.fulfilled, (state, { payload, meta }) => {
                const { exportPdf } = meta.arg;
                if (exportPdf) {
                    const { blob, header } = payload;
                    const encodedFileName = header['content-disposition']?.split('attachment;filename=')[1];
                    const fileName = decodeURI(encodedFileName);
                    downloadBlob({
                        blob,
                        fileName,
                    });
                }
                state.isEventLoading = false;
            })
            .addCase(getEventMediaThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(getEventMediaThunk.rejected, (state) => {
                state.isEventLoading = false;
            })
            .addCase(getEventMediaThunk.fulfilled, (state, { payload }) => {
                state.eventMediaList = payload || [];
                state.isEventLoading = false;
            });
    },
});

const {
    selectEventId,
    deselectEvent,
    selectFlight,
    changeFlightFilter,
    initUpdateEtsDialog,
    initResolveEtsConflictDialog,
    completedEtsConflictUIUpdate,
    toggleCloseEventDialog,
    toggleReopenEventDialog,
    toggleCancelEventDialog,
    updateIocEventListFilters,
    resetIocEventListFilters,
    updateCreateEventFormStatus,
    toggleEventAssessmentDialog,
    updateCreateHandoverNoteStatus,
    updateCloseMcEventDialogStatus,
} = eventSlice.actions;

const selectEvent = (state: RootState) => state.event;

export {
    selectEventId,
    deselectEvent,
    selectEvent,
    selectFlight,
    changeFlightFilter,
    initUpdateEtsDialog,
    initResolveEtsConflictDialog,
    completedEtsConflictUIUpdate,
    toggleCloseEventDialog,
    toggleReopenEventDialog,
    toggleCancelEventDialog,
    updateIocEventListFilters,
    resetIocEventListFilters,
    updateCreateEventFormStatus,
    toggleEventAssessmentDialog,
    updateCreateHandoverNoteStatus,
    updateCloseMcEventDialogStatus,
};

export default eventSlice.reducer;
