import React, { forwardRef, ReactElement, useEffect, useState, memo, useCallback } from 'react';
import { Dialog, Slide, AppBar, Toolbar, IconButton, DialogActions, DialogContent, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/system';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useForm, FormProvider } from 'react-hook-form';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import StyledButton from './Button';
import { toggleFullScreenDialog, globalUI } from '../../slices/globalUISlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styledDialogStyles, { DialogSize, StyledDialogSxProps } from './StyledDialogStyles';
import colors from '../../style/color';

type StyledDialogProps = {
    title: string;
    isOpen: boolean;
    children: React.ReactNode;
    dialogSize?: DialogSize;
    defaultValues?: object;

    // custom styles
    sx?: StyledDialogSxProps;

    // header
    backAction?: () => void;
    headerFilterBar?: JSX.Element | null;

    // footer
    closeBtnLabel?: string;
    confirmBtnLabel: string;
    secondaryActionBtnLabel?: string;
    tertiaryActionBtnLabel?: string;
    isConfirmDisabled?: boolean;
    isSecondaryIgnoreError?: boolean;

    onClickCloseBtn: () => void;
    onClickConfirmBtn: (data) => void;
    onClickSecondaryActionBtn?: (data) => void;
    onClickTertiaryActionBtn?: () => void;

    hasStatusBar?: boolean;
    isRequired?: boolean;
};

const TransitionComponent = forwardRef(
    (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<ReactElement>) => {
        return <Slide direction='up' ref={ref} {...props} children={props.children} />;
    }
);

const StyledDialog = ({
    title,
    isOpen,
    children,
    dialogSize = 'fullScreen',
    sx,
    backAction,
    headerFilterBar,
    closeBtnLabel = 'Cancel',
    confirmBtnLabel,
    secondaryActionBtnLabel,
    isConfirmDisabled = false,
    onClickCloseBtn,
    onClickConfirmBtn,
    onClickSecondaryActionBtn,
    hasStatusBar = false,
    isRequired = false,
    tertiaryActionBtnLabel,
    onClickTertiaryActionBtn,
}: StyledDialogProps) => {
    const dispatch = useAppDispatch();
    const [dialogId] = useState<string>(uuidv4());

    const getClasses = () => {
        const cls = [dialogSize.toString()];

        if (hasStatusBar) {
            cls.push('hasStatusBar');
        }

        if (headerFilterBar) {
            cls.push('headerFilterBar');
        }

        return cls.join(' ');
    };

    useEffect(() => {
        if (dialogSize === 'fullScreen') {
            dispatch(toggleFullScreenDialog({ isOpen, dialogId }));
        }

        return () => {
            if (dialogSize === 'fullScreen') {
                dispatch(toggleFullScreenDialog({ isOpen: false, dialogId }));
            }
        };
    }, [isOpen]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClickCloseBtn}
            TransitionComponent={TransitionComponent}
            className={getClasses()}
            sx={{
                ...styledDialogStyles.container,
                ...sx?.container,
            }}
            fullScreen={dialogSize === 'fullScreen'}
        >
            {dialogSize === 'fullScreen' && (
                <AppBar
                    sx={{
                        ...styledDialogStyles.dialogHeader,
                        ...sx?.dialogHeader,
                    }}
                    className={'header-app-bar'}
                >
                    <Box sx={{ ...styledDialogStyles.decorationLine, ...sx?.decorationLine }} />
                    <Toolbar
                        disableGutters
                        variant='regular'
                        className={backAction ? 'back-icon header-toolbar' : 'header-toolbar'}
                    >
                        <Typography
                            variant='h6'
                            component='div'
                            sx={{
                                display: 'flex',
                                justifyContent: backAction ? 'flex-start' : 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            {backAction && (
                                <IconButton size='large' color='inherit' onClick={onClickCloseBtn} aria-label='close'>
                                    <ArrowBackIosNewIcon />
                                </IconButton>
                            )}
                            {title}
                            {!backAction && (
                                <IconButton
                                    size='large'
                                    color='inherit'
                                    onClick={onClickCloseBtn}
                                    aria-label='close'
                                    sx={{ left: '15px' }}
                                >
                                    <HighlightOffIcon />
                                </IconButton>
                            )}
                        </Typography>
                    </Toolbar>
                    {headerFilterBar && (
                        <Toolbar
                            disableGutters
                            variant='regular'
                            className={backAction ? 'back-icon headerFilterBar' : 'headerFilterBar'}
                        >
                            <Box sx={{ flex: 1 }}>{headerFilterBar}</Box>
                        </Toolbar>
                    )}
                </AppBar>
            )}
            <DialogContent
                sx={{ ...sx?.dialogContent }}
                className={clsx(dialogSize, {
                    'with-back-action': !!backAction,
                })}
            >
                {dialogSize !== 'fullScreen' && (
                    <div style={{ display: 'flex' }}>
                        <Typography variant='h2' component='div'>
                            {title}
                        </Typography>
                        {isRequired && (
                            <Typography variant='h2' component='div' sx={{ color: colors.borderRed }}>
                                &nbsp; *
                            </Typography>
                        )}
                    </div>
                )}
                {children}
            </DialogContent>
            <DialogActions className={dialogSize} sx={{ ...sx?.dialogFooter }}>
                <StyledButton label={closeBtnLabel} variant='secondary' onClick={onClickCloseBtn} />
                <Box>
                    {tertiaryActionBtnLabel && (
                        <StyledButton
                            label={tertiaryActionBtnLabel}
                            variant='outlined'
                            isSubmitBtn={true}
                            onClick={onClickTertiaryActionBtn}
                        />
                    )}
                    {secondaryActionBtnLabel && (
                        <StyledButton
                            label={secondaryActionBtnLabel}
                            variant='secondary'
                            isSubmitBtn={true}
                            onClick={onClickSecondaryActionBtn}
                        />
                    )}
                    <StyledButton
                        isDisabled={isConfirmDisabled}
                        label={confirmBtnLabel}
                        isSubmitBtn={true}
                        onClick={onClickConfirmBtn}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    );
};

const MemoizedStyledDialog = memo(StyledDialog);

const StyledFormDialog = ({
    onClickConfirmBtn,
    onClickSecondaryActionBtn,
    isSecondaryIgnoreError = false,
    isOpen,
    defaultValues = {},
    hasStatusBar,
    ...otherProps
}: StyledDialogProps) => {
    const { statusMessageList } = useAppSelector(globalUI);
    const methods = useForm({ defaultValues });
    const { handleSubmit, reset, clearErrors, getValues } = methods;
    const props = {
        ...otherProps,
        isOpen,
        onClickConfirmBtn: useCallback(
            () => handleSubmit((data) => onClickConfirmBtn(data))(),
            [onClickConfirmBtn, handleSubmit]
        ),
        onClickSecondaryActionBtn: useCallback(
            () =>
                handleSubmit(
                    (data) => onClickSecondaryActionBtn(data),
                    (errors) => {
                        if (isSecondaryIgnoreError) {
                            clearErrors();
                            onClickSecondaryActionBtn(getValues());
                        }
                    }
                )(),
            [onClickConfirmBtn, handleSubmit]
        ),
        hasStatusBar: hasStatusBar || statusMessageList?.length > 0,
    };

    useEffect(() => {
        reset();
    }, [isOpen]);

    return (
        <FormProvider {...methods}>
            <form>
                <MemoizedStyledDialog {...props} />
            </form>
        </FormProvider>
    );
};

export { StyledDialog as default, StyledFormDialog };
