import React, { useState, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import isEqual from 'lodash/isEqual';

import Content from './Content';
import SearchHeader from './SearchHeader';
import { DateFormat } from '../../helper/dateHelper';
import { useAppDispatch } from '../../app/hooks';
import { IFlightBar } from '../common/FlightBarInfo';
import { StyledFormDialog } from '../common/StyledDialog';
import { getAllFlightThunk, FlightParam, cleanFlightList } from '../../slices/flightSlice';

import { FlightBarSortItem } from '../../constants/constants';

interface FiltersParams extends Omit<FlightParam, 'startDate' | 'endDate'> {
    startDate: DateTime;
    endDate: DateTime;
}

interface FlightSearchMainContextProps {
    minDate?: DateTime;
    maxDate?: DateTime;
    filters?: FiltersParams;
    defaultKeySearch?: string;
    validDefaultFlightBars?: IFlightBar[];
    disableDefault?: boolean;
    multiple?: boolean;
    onSearch?: (params: Partial<FiltersParams>) => void;
    onFlightBarSelected?: (flightBars: IFlightBar[]) => void;
}

interface FlightSearchProps {
    title?: string;
    isOpen: boolean;
    defaultKeySearch?: string;
    defaultStartDate?: DateTime;
    defaultEndDate?: DateTime;
    minDate?: DateTime;
    maxDate?: DateTime;
    disableDefault?: boolean;
    multiple?: boolean;
    defaultFlightBars?: IFlightBar[];
    confirmBtnLabel?: string;
    closeBtnLabel?: string;
    onBack?: () => void;
    onClose?: () => void;
    onConfirm?: (flightBars: IFlightBar[]) => void;
}

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_PAGE = 1;

const FlightSearchMainContext = React.createContext<FlightSearchMainContextProps>({});

const FlightSearch = (props: FlightSearchProps) => {
    const {
        title = 'Select a flight',
        isOpen,
        defaultKeySearch,
        defaultFlightBars,
        defaultStartDate,
        defaultEndDate,
        minDate,
        maxDate,
        confirmBtnLabel = 'Add (<#>) affected flight(s)',
        closeBtnLabel = 'Back',
        disableDefault = false,
        multiple = false,
        onBack,
        onClose: onSelectionClose,
        onConfirm: onSelectionConfirm,
    } = props;

    const validDefaultFlightBars = defaultFlightBars?.filter((item) => Boolean(item));

    const defaultFilters: FiltersParams = useMemo(
        () => ({
            keySearch: defaultKeySearch || '',
            startDate: defaultStartDate || DateTime.now(),
            endDate: defaultEndDate || DateTime.now().plus({ days: 1 }),
            sort: FlightBarSortItem.Std,
            size: DEFAULT_PAGE_SIZE,
            page: DEFAULT_PAGE,
            airline: [],
        }),
        [defaultKeySearch, defaultStartDate, defaultEndDate]
    );

    const dispatch = useAppDispatch();

    const [selectedFlightCount, setSelectedFlightCount] = useState<number>(0);
    const [filters, setFilters] = useState<FiltersParams>(defaultFilters);

    const numberOfNewlyAddedFlights = Math.max(selectedFlightCount - (validDefaultFlightBars?.length || 0), 0);

    const onFlightBarSelected = (flightBars: IFlightBar[] = []) => {
        setSelectedFlightCount(flightBars.length);
    };

    const reset = () => {
        dispatch(cleanFlightList());
        setFilters(defaultFilters);
        setSelectedFlightCount(0);
    };

    const onSearch = (params: Partial<FiltersParams>) => {
        if (!params.page) {
            params.page = DEFAULT_PAGE;
        }

        setFilters((preFilters) => ({
            ...preFilters,
            ...params,
        }));
    };

    const onConfirm = (data: { selectedFlights: IFlightBar[] }) => {
        onSelectionConfirm?.(data?.selectedFlights || []);
        onClose();
    };

    const onClose = () => {
        onSelectionClose?.();
    };

    useEffect(() => {
        reset();
    }, [defaultFilters, isOpen]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        dispatch(
            getAllFlightThunk({
                ...filters,
                startDate: filters.startDate.toFormat(DateFormat.ServerQueryFormat),
                endDate: filters.endDate.toFormat(DateFormat.ServerQueryFormat),
            })
        );
    }, [filters, isOpen]);

    return (
        <FlightSearchMainContext.Provider
            value={{
                filters,
                validDefaultFlightBars,
                disableDefault,
                multiple,
                minDate,
                maxDate,
                onSearch,
                onFlightBarSelected,
            }}
        >
            <StyledFormDialog
                isOpen={isOpen}
                title={title}
                backAction={onBack}
                confirmBtnLabel={confirmBtnLabel.replace('<#>', numberOfNewlyAddedFlights.toString())}
                onClickConfirmBtn={onConfirm}
                closeBtnLabel={closeBtnLabel}
                onClickCloseBtn={onClose}
                headerFilterBar={<SearchHeader />}
            >
                <Content />
            </StyledFormDialog>
        </FlightSearchMainContext.Provider>
    );
};

const MemorizedFlightSearch = React.memo(FlightSearch, (pre, cur) => {
    return isEqual(pre.defaultFlightBars, cur.defaultFlightBars) && pre.isOpen === cur.isOpen;
});

export {
    FlightSearch as default,
    MemorizedFlightSearch,
    FlightSearchProps,
    FlightSearchMainContext,
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE,
};
