import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEventTaskHistoryLogs } from '../services/systemHistoryLogs';

import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { FilterItem } from '../components/common/Filter';
import { SortingItem } from '../components/common/Sort';
import UserInfo from '../interface/UserInfo';
import { ThumbnailItem } from './fileSlice';
import { ChangeType, SORT_FIELD, TaskType } from '../constants/constants';

import type { RelatedActivity } from './coeEventSlice';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';
import { Groups } from './userProfileSlice';
import { PartNumber } from '../interface/WorkRequest';
export interface HistorySlice {
    iocFilterOptions: FilterItem[];
    mcFilterOptions: FilterItem[];
    mcLogFilterOptions: FilterItem[];
    lmttFilterOptions: FilterItem[];
    lmttActionItemFilterOptions: FilterItem[];
    sortOptions: SortingItem[];
    isHistoryLoading: boolean;
    histories: EventTaskHistoryLog[];
    errorMessage?: string;
}

const initialState: HistorySlice = {
    iocFilterOptions: [
        {
            name: 'Event',
            value: TaskType.event,
        },
        {
            name: 'Event Assessment',
            value: TaskType.assessment,
        },
        {
            name: 'Log',
            value: TaskType.iocLogChat,
        },
        {
            name: 'IOC Alert',
            value: TaskType.iocAlert,
        },
    ],
    mcFilterOptions: [
        {
            name: 'Event',
            value: TaskType.event,
        },
        {
            name: 'Issue Summary',
            value: TaskType.issueSummary,
        },
        {
            name: 'DisMessage',
            value: TaskType.disMessage,
        },
        {
            name: 'Spares & Toolings',
            value: TaskType.spare,
        },
        {
            name: 'Status Report',
            value: TaskType.aogReport,
        },
    ],
    mcLogFilterOptions: [
        {
            name: 'Event',
            value: TaskType.event,
        },
        {
            name: 'DisMessage',
            value: TaskType.disMessage,
        },
        {
            name: 'Log',
            value: TaskType.issueSummary,
        },
        {
            name: 'Status Report',
            value: TaskType.aogReport,
        },
        {
            name: 'Work Request',
            value: TaskType.mcWrq,
        },
    ],
    lmttFilterOptions: [
        {
            name: 'Event',
            value: TaskType.event,
        },
    ],
    lmttActionItemFilterOptions: [
        {
            name: 'Tasks',
            value: TaskType.actionItem,
        },
    ],
    sortOptions: [
        {
            name: 'By the latest',
            value: SORT_FIELD.latest,
        },
        {
            name: 'By the earilest',
            value: SORT_FIELD.earliest,
        },
    ],
    isHistoryLoading: false,
    histories: [],
    errorMessage: null,
};

export interface DetailsElement {
    data: string;
    type: string;
}

interface DetailsArrayElement extends Omit<DetailsElement, 'data'> {
    data: string[];
}

interface DetailsObjectElement extends Omit<DetailsElement, 'data'> {
    data: Object;
}

interface DetailsBooleanElement extends Omit<DetailsElement, 'data'>{
    data: boolean;
}

export interface IssueSummaryDetailsData {
    ataCode?: DetailsElement;
    openDate?: DetailsElement;
    reporter?: DetailsElement;
    description?: DetailsElement;
    melCategory?: DetailsElement;
    deferDisplay?: DetailsElement;
    melReference?: DetailsElement;
    daysRemaining?: DetailsElement;
    addDescription?: DetailsElement;
    addRestriction?: DetailsElement;
}

export interface SpareDetailsData {
    airline?: DetailsElement;
    partNumber?: DetailsElement;
    description?: DetailsElement;
    transportMethod?: DetailsElement;
    comments?: DetailsElement;
    quantity?: DetailsElement;
    trackingNumber?: DetailsElement;
    flightNumber?: DetailsElement;
    flightDate?: DetailsElement;
}

export interface DisMessageDetailsData {
    body?: DetailsElement;
    group?: DetailsElement;
    header?: DetailsElement;
    result?: DetailsElement;
}

export interface MQNote {
    mqNote: string;
    createdBy: string;
    updatedBy: string;
    mqNoteUpdatedAt: string;
}

export interface EventDetailsData {
    owner?: DetailsElement | { data: Groups; type: string };
    description?: DetailsElement;
    eventLabels?: DetailsArrayElement;
    isHandoverReport?: DetailsBooleanElement;
    reporter?: DetailsElement;
    flightStatus?: DetailsElement;
    registration?: DetailsElement;
    affectedFlights?: DetailsArrayElement;
    impactedPorts?: DetailsArrayElement;
    routes?: DetailsArrayElement;
    eventTitle?: DetailsElement;
    aircraftStatus?: DetailsObjectElement;
    relatedActivities?: { data: RelatedActivity[]; type: string };
    attachment?: { data: number[]; type: string };
    ataChapter?: DetailsElement;
    nextFlightDept?: DetailsElement;
    techArea?: DetailsElement;
    partNumber?: DetailsArrayElement;
    commodity?: DetailsElement;
    mtceCode?: DetailsElement;
    aircraftType?: DetailsElement;
    mqNote?: { data: MQNote; type: string } | { mqNote: DetailsElement };
    flightNumber?: DetailsElement;
    port?: DetailsElement;
}

export interface EventAssessmentDetailsData {
    assessment?: DetailsElement;
}

export interface IOCAlertDetailsData {
    version?: DetailsElement;
}

export interface HandoverNoteDetailsData {
    aircraftType?: DetailsElement;
    carrier?: DetailsElement;
    description?: DetailsElement;
    eventTitle?: DetailsElement;
    flightNumber?: DetailsElement;
    isHandoverReport?: DetailsBooleanElement;
    owner?: DetailsElement;
    port?: DetailsElement;
    registration?: DetailsElement;
}

export interface WorkRequestDetailsData {
    port?: DetailsElement;
    isIMT?: DetailsBooleanElement;
    acType?: DetailsElement;
    ipcRef?: DetailsElement;
    carrier?: DetailsElement;
    subject?: DetailsElement;
    ataChapter?: DetailsElement;
    background?: DetailsElement;
    isADChecked?: DetailsBooleanElement;
    partNumbers: {
        data: PartNumber[];
        type: string;
    };
    supersedeOf?: DetailsElement;
    aircraftType: DetailsElement;
    flightNumber: DetailsElement;
    registration: DetailsElement;
    requestStart: DetailsElement;
    actionRequest: DetailsElement;
    backgroundHtml: DetailsElement;
    actionRequestHtml: DetailsElement;
    creationStartTime: DetailsElement;
    supersedeWorkRequest: DetailsElement;
}

export interface LogEntryDetailsData {
    recordTime?: DetailsElement;
    content?: DetailsElement;
    id?: DetailsElement;
    sender?: DetailsObjectElement;
}

export interface ActionItemDetailsData {
    type?: DetailsElement;
    owner?: DetailsElement;
    title?: DetailsElement;
    description?: DetailsElement;
}

export interface EventTaskHistoryLog {
    id: number;
    eventId: number;
    eventTaskId: number;
    taskType: string;
    changeType: string;
    userId: number;
    createdAt?: string;
    updatedAt?: string;
    createdBy?: UserInfo;
    uploadFiles?: ThumbnailItem[];
    details?: {
        id?: DetailsElement;
        ufi?: DetailsElement;
        active?: DetailsElement;
        status?: DetailsElement;
        eventId?: DetailsElement;
        taskType?: DetailsElement;
        eventType?: DetailsElement;
        eventSubtype?: DetailsElement;
        startTimeUTC?: DetailsElement;
        endTimeUTC?: DetailsElement;
        etsTimeUTC?: DetailsElement;
        atsTimeUTC?: DetailsElement;
        duration?: DetailsElement;
        createdAt?: DetailsElement;
        createdBy?: DetailsElement;
        updatedBy?: DetailsElement;
        subtypeStatus: DetailsElement;
        upload?: DetailsElement;
        recordTime?: DetailsElement; // Issue Log
        atsTime?: DetailsElement; // Issue Log
        etsTime?: DetailsElement; // Issue Log
        remarks?: DetailsElement; // Issue Log
        type: DetailsElement; // AOG Report
        version: DetailsElement; // AOG Report
        dueTime?: DetailsElement; // AOG Report
        updatedAt?: DetailsElement; // AOG Report
        details?:
        | IssueSummaryDetailsData
        | SpareDetailsData
        | DisMessageDetailsData
        | EventDetailsData
        | EventAssessmentDetailsData
        | IOCAlertDetailsData
        | HandoverNoteDetailsData
        | WorkRequestDetailsData;
        state?: DetailsElement; // Event History
        statusRemarks?: DetailsElement; // LMTT
        estEndTimeUTC?: DetailsElement;
        eventTitle?: DetailsElement; // LMTT
        owner?: DetailsElement; // LMTT
        registration?: DetailsElement;
    };
}

interface ReturnType {
    data: EventTaskHistoryLog[];
}

interface Input {
    eventId: string;
    taskTypes?: string[];
    eventTaskId?: string;
}

export const getEventTaskHistoryLogsThunk = createAsyncThunk<
ReturnType,
Input,
{ state: RootState; rejectValue: ApiErrorObj }
>('systemLogs/getEventTaskHistoryLogs', async ({ eventId, taskTypes }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/getEventTaskSystemLogs')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getEventTaskHistoryLogs({ eventId, taskTypes });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { data };
});

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEventTaskHistoryLogsThunk.pending, (state) => {
                state.isHistoryLoading = true;
            })
            .addCase(getEventTaskHistoryLogsThunk.fulfilled, (state, { payload }) => {
                state.isHistoryLoading = false;
                state.histories = payload.data.filter((item) => {
                    const { details } = item;
                    const { details: innerDetails } = details;

                    details &&
                        Object.entries(details).forEach(
                            ([detailsKey, detailsElem]) =>
                                detailsElem.hasOwnProperty('data') &&
                                (detailsElem['data'] =
                                    detailsElem['data'] || detailsKey === 'active'
                                        ? detailsElem['data']
                                        : detailsElem['type'] !== ChangeType.created
                                            ? '--'
                                            : '')
                        );
                    innerDetails &&
                        Object.entries(innerDetails).forEach(
                            ([, detailsElem]) =>
                                (detailsElem['data'] = detailsElem['data']
                                    ? detailsElem['data']
                                    : detailsElem['type'] !== ChangeType.created
                                        ? '--'
                                        : '')
                        );

                    // aircraftStatus is not captured to be shown in history logs on frontend side
                    // it appears because backend is needed when create event.
                    // since we won't pass aircraftStatus to backend when update event, it appears to always have changes.
                    if ((innerDetails as EventDetailsData)?.aircraftStatus) {
                        delete (details.details as EventDetailsData).aircraftStatus;
                    }

                    // remove update isHandoverReport history log
                    if ((innerDetails as EventDetailsData)?.isHandoverReport) {
                        delete (details.details as EventDetailsData).isHandoverReport;
                        if (Object.keys(details.details).length <= 0) return false;
                    }

                    return true;
                });
            })
            .addCase(getEventTaskHistoryLogsThunk.rejected, (state, { payload }) => {
                const { detail: errorMessage } = payload;

                state.isHistoryLoading = false;
                state.errorMessage = errorMessage;
            });
    },
});

const historySliceReducer = historySlice.reducer;

const selectHistory = (state: RootState) => state.history;

export { historySliceReducer as default, selectHistory };
