import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import {
    EVENT_STATUS,
    EventStatusLabelMap,
    DEFINTION_STATUS,
    DefinitionStatusLabelMap,
    FlightBarLabel,
    OBS_MESSAGE_STATUS,
    ObsMessageStatusLabelMap,
} from '../../constants/constants';

import COLORS from '../../style/color';

interface StatusLabelProps {
    status: string;
    sx?: SxProps;
    labelText?: string;
}

const StatusLabelMapping = {
    ...EventStatusLabelMap,
    ...DefinitionStatusLabelMap,
    ...ObsMessageStatusLabelMap,
};

const getStatusLabelTextColorMap = (status: string) => {
    switch (status) {
        case EVENT_STATUS.open:
        case DEFINTION_STATUS.future:
        case OBS_MESSAGE_STATUS.pending:
            return {
                backgroundColor: COLORS.lavenderLighten,
                textColor: COLORS.secondError,
            };
        case EVENT_STATUS.cancelled:
        case FlightBarLabel.cancelled:
            return {
                backgroundColor: COLORS.shadowGray,
                textColor: COLORS.white,
            };
        case EVENT_STATUS.closed:
        case DEFINTION_STATUS.expired:
            return {
                backgroundColor: COLORS.twoColorTable2Dark,
                textColor: COLORS.grayDark,
            };
        case DEFINTION_STATUS.active:
        case OBS_MESSAGE_STATUS.sent:
            return {
                backgroundColor: COLORS.brightGray,
                textColor: COLORS.cathayJade,
            };
        case OBS_MESSAGE_STATUS.failed:
            return {
                backgroundColor: COLORS.summaryBgRed,
                textColor: COLORS.error,
            };
        default:
            return {
                backgroundColor: COLORS.brightGray,
                textColor: COLORS.cathayJade,
            };
    }
};

const StatusLabel = (props: StatusLabelProps) => {
    const { status, sx, labelText } = props;
    const { backgroundColor, textColor } = getStatusLabelTextColorMap(status);

    return (
        <Typography
            variant='caption3'
            sx={{
                padding: '4px',
                borderRadius: '2px',
                background: backgroundColor,
                color: textColor,
                whiteSpace: 'nowrap',
                height: 'fit-content',
                ...sx,
            }}
        >
            {labelText || StatusLabelMapping[status]}
        </Typography>
    );
};

export default StatusLabel;
