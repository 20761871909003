import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import { getIssueLogs, updateMcLog, removeMcLog } from '../services/issueSummary';
import { declareAog, updateATS } from '../services/events';
import { LogDetails, LogSubmitContent } from '../interface/MCLog';
import { isDateStrAfterOther } from '../helper/dateHelper';
import { DetailedEvent } from '../slices/eventSlice';
import { DeclareAogInput } from '../interface/MCLog';

interface MCLogStateProps {
    logList: LogDetails[];
    selectedMcLog: LogDetails | null;
    isMcLogLoading: boolean;
    isMcSubmitLoading: boolean;
    dialogStatus: {
        isOpen: boolean;
        logId?: string | null;
    };
    declareAogDialogData: {
        isOpen: boolean;
        eventId?: string;
    };
    declareServiceableData: {
        isOpen: boolean;
        eventId?: string;
        startTime?: string;
    };
}

const initialState: MCLogStateProps = {
    selectedMcLog: null,
    logList: [],
    isMcLogLoading: false,
    isMcSubmitLoading: false,
    dialogStatus: {
        isOpen: false,
        logId: null,
    },
    declareAogDialogData:{
        isOpen:false,
    },
    declareServiceableData:{
        isOpen:false,
    },
};

export const getMcLogListThunk = createAsyncThunk<LogDetails[], string, { state: RootState; rejectValue: ApiErrorObj }>(
    'log/issueLogs',
    async (eventId, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/issueLogs', ResourceAction.GET)) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await getIssueLogs(eventId);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }
);

export const updateMcLogThunk = createAsyncThunk<
number,
LogSubmitContent,
{ state: RootState; rejectValue: ApiErrorObj }
>('log/updateMcLog', async (submitContent, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/issueLog', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await updateMcLog(submitContent);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data.id;
});

export const removeMcLogThunk = createAsyncThunk<LogDetails[], string, { state: RootState; rejectValue: ApiErrorObj }>(
    'log/removeMcLogThunk',
    async (id, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/issueLog/delete', ResourceAction.POST)) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await removeMcLog(id);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }
);

export const declareServiceableThunk = createAsyncThunk<
number,
LogSubmitContent,
{ state: RootState; rejectValue: ApiErrorObj }
>('log/decalreServiceable', async (submitContent, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/updateATS', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await updateATS(submitContent);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data.id;
});

export const declareAogThunk = createAsyncThunk<
{ event: DetailedEvent[] },
DeclareAogInput,
{ state: RootState; rejectValue: ApiErrorObj }
>('log/declareAogThunk', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/aog/declare', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    params.recordTime = params.recordTime || params.startTime;
    const [err, data] = await declareAog(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

const mcLogSlice = createSlice({
    name: 'mcLog',
    initialState,
    reducers: {
        selectLog: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            state.selectedMcLog = state.logList.find((item) => item.id === id) ?? null;
        },
        setDialogStatus: (state, action: PayloadAction<{ isOpen: boolean; logId: string }>) => {
            state.dialogStatus = action.payload;
        },
        setShowDeclareAogDialog: (state, action: PayloadAction<{isOpen: boolean; eventId?: string}>) => {
            state.declareAogDialogData = action.payload;
        },
        toggleDeclareServiceableDialog: (state, action: PayloadAction<{isOpen: boolean; eventId?: string;startTime?: string}>) => {
            state.declareServiceableData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMcLogListThunk.pending, (state) => {
                state.isMcLogLoading = true;
            })
            .addCase(getMcLogListThunk.fulfilled, (state, { payload }) => {
                state.isMcLogLoading = false;
                state.logList = payload?.sort((aTime, bTime) => {
                    return isDateStrAfterOther(aTime.recordTime, bTime.recordTime);
                });
            })
            .addCase(getMcLogListThunk.rejected, (state) => {
                state.isMcLogLoading = false;
            })
            .addCase(updateMcLogThunk.pending, (state) => {
                state.isMcSubmitLoading = true;
            })
            .addCase(updateMcLogThunk.fulfilled, (state) => {
                state.isMcSubmitLoading = false;
            })
            .addCase(updateMcLogThunk.rejected, (state) => {
                state.isMcSubmitLoading = false;
            })
            .addCase(declareServiceableThunk.pending, (state) => {
                state.isMcSubmitLoading = true;
            })
            .addCase(declareServiceableThunk.fulfilled, (state) => {
                state.isMcSubmitLoading = false;
            })
            .addCase(declareServiceableThunk.rejected, (state) => {
                state.isMcSubmitLoading = false;
            })
            .addCase(removeMcLogThunk.pending, (state) => {
                state.isMcLogLoading = true;
            })
            .addCase(removeMcLogThunk.fulfilled, (state) => {
                state.isMcLogLoading = false;
            })
            .addCase(removeMcLogThunk.rejected, (state) => {
                state.isMcLogLoading = false;
            })
            .addCase(declareAogThunk.pending, (state) => {
                state.isMcLogLoading = true;
            })
            .addCase(declareAogThunk.fulfilled, (state) => {
                state.isMcLogLoading = false;
            })
            .addCase(declareAogThunk.rejected, (state) => {
                state.isMcLogLoading = false;
            });
    },
});

export const selectMCLog = (state: RootState) => state.mcLog;
export const { selectLog, setDialogStatus, setShowDeclareAogDialog, toggleDeclareServiceableDialog } =
    mcLogSlice.actions;
export default mcLogSlice.reducer;
