import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { store } from '../app/store';
import { msalConfig, loginRequest } from '../config/authConfig';
import globalConfig from '../config/globalConfig';
import { updateAccessToken } from '../slices/authSlice';

export const msalInstance = new PublicClientApplication(msalConfig);

export const getNewAccessToken = (forceRefresh: boolean) => {
    const accounts = msalInstance.getAllAccounts();
    const authResult = msalInstance
        .acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
            forceRefresh,
        })
        .then((response: AuthenticationResult) => {
            return response;
        })
        .catch((error) => {
            console.error('acquireTokenSilentError', error);
            throw new Error(error);
        });
    return authResult;
};

const getAccessToken = async () => {
    const state = store.getState();
    const isLogin = state.auth.isLogin;

    if (isLogin) {
        const accessToken = state.auth.accessToken;
        if (accessToken) {
            return accessToken;
        }
    }
    const token = await getNewAccessToken(true);
    const { accessToken } = token || {};
    return accessToken;
};

export const logout = async () => {
    msalInstance.logoutRedirect({
        postLogoutRedirectUri: `https://login.microsoftonline.com/${globalConfig.azureTenantId}/oauth2/v2.0/logout`,
    });
};

const eventCallBack = (message: EventMessage) => {
    if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        const payload = message.payload as AuthenticationResult;
        const { accessToken: storedToken } = store.getState().auth;
        const { accessToken } = payload;
        if (accessToken !== storedToken) {
            store.dispatch(updateAccessToken(accessToken));
        }
    }
};

export { getAccessToken };
msalInstance.addEventCallback(eventCallBack);
