import { styled } from '@mui/material';

import { ReactComponent as ArrowDown } from '../../assets/icons/ArrowDown.svg';

const ExpandArrow = styled(ArrowDown, { shouldForwardProp: (props) => props !== 'expand' })(
    ({ expand }: { expand: boolean }) => ({
        transform: `rotate(${expand ? '0deg' : '-90deg'})`,
        width: '15px',
        height: '8px',
    })
);

export default ExpandArrow;
