import React, { forwardRef, ChangeEvent, FocusEvent, ComponentClass, ReactElement, KeyboardEvent } from 'react';
import { Box, TextField, Typography, InputAdornment } from '@mui/material';
import { SxProps } from '@mui/system';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import clsx from 'clsx';

import textFieldStyles, { getAdjustedTextFieldHeightStyle } from './InputFieldStyles';

type TextFieldProps = {
    label?: string;
    value?: string;
    prefixElem?: string | ReactElement;
    suffixElem?: string | ReactElement;
    textMask?: TextMask;
    onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (value: FocusEvent<HTMLInputElement>) => void;
    onClick?: () => void;
    onKeyDown?: (value: KeyboardEvent<HTMLInputElement>) => void;
    hasError?: boolean;
    showErrorMessage?: boolean;
    errorMessage?: string;
    defaultInputValue?: string | Date;
    isReadOnly?: boolean;
    isDisabled?: boolean;
    width?: string;
    sx?: SxProps;
    className?: string;
    isMultiline?: boolean;
    maxRows?: number;
    variant?: 'standard' | 'readOnlyNoBorder';
};

enum TextMask {
    None,
    Time, // 2359
    Duration, // 99:99
}

const MaskedInputField = NumberFormat as ComponentClass<TextFieldProps & NumberFormatProps>;

const getAdornment = (
    adornment: { prefixElem?: string | ReactElement; suffixElem?: string | ReactElement },
    className: string
) => {
    const { prefixElem, suffixElem } = adornment || {};

    if (!prefixElem && !suffixElem) {
        return null;
    }

    const position = prefixElem ? 'start' : 'end';
    const elem = prefixElem || suffixElem;

    return (
        <InputAdornment position={position} sx={{ ...textFieldStyles.inputPrefixIcon }} className={className || ''}>
            {elem}
        </InputAdornment>
    );
};

const CustomTextField = forwardRef((props: TextFieldProps, inputRef: any) => {
    const {
        value,
        className = '',
        prefixElem,
        suffixElem,
        hasError = false,
        isReadOnly = false,
        isDisabled = false,
        defaultInputValue,
        isMultiline = false,
        maxRows = 4,
        variant = 'standard',
        onChange,
        ...otherProps
    } = props;
    const isPrefixString = !prefixElem || (typeof prefixElem === 'string' && prefixElem.length > 0);
    const inputRows = isMultiline ? maxRows : 1;

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            {prefixElem && getAdornment({ prefixElem }, className)}
            <TextField
                className={clsx(className, 'MuiTextField-root', {
                    'no-border': isReadOnly && variant === 'readOnlyNoBorder',
                    'input-error': hasError,
                })}
                {...otherProps}
                defaultValue={defaultInputValue}
                value={value}
                onChange={onChange}
                sx={{
                    ...getAdjustedTextFieldHeightStyle(textFieldStyles.input, inputRows),
                }}
                inputRef={inputRef}
                variant='filled'
                InputLabelProps={{
                    shrink: value?.length > 0 ? true : undefined,
                    style: {
                        marginLeft: isPrefixString ? 0 : '27px',
                    },
                }}
                InputProps={{
                    disableUnderline: true,
                    sx: {
                        width: '100%',
                    },
                    endAdornment: <InputAdornment position='end'>{suffixElem}</InputAdornment>,
                    classes: {
                        adornedStart: isPrefixString ? '' : 'adorned-with-icon',
                    },
                    readOnly: isReadOnly,
                }}
                margin='dense'
                maxRows={inputRows}
                error={hasError}
                multiline={isMultiline}
                disabled={isDisabled}
            />
        </Box>
    );
});

const limit = (val: string, max: string): string => {
    let value = val;
    if (value.length === 1 && value[0] > max[0]) {
        value = '0' + value;
    }

    if (value.length === 2) {
        if (Number(value) === 0) {
            value = '00';
        } else if (value > max) {
            value = max;
        }
    }

    return value;
};

function getTimeMaskFormat(val: string = ''): string {
    const paddedValue = val.padEnd(4, '0');
    const hours = limit(paddedValue.substring(0, 2), '23');
    const minutes = limit(paddedValue.substring(2, 4), '59');
    return hours + (hours.length ? minutes : '');
}

function getDurationMaskFormat(val: string = ''): string {
    const hours = limit(val.substring(0, 2), '99');
    const minutes = limit(val.substring(2, 4), '99');
    return hours + (hours.length ? ':' + minutes : '');
}

const getTextMaskConfig = (
    textMask: TextMask
): { textMaskFunc: ((value: string) => string) | null; placeholder: string } => {
    switch (textMask) {
        case TextMask.Time: {
            return {
                textMaskFunc: getTimeMaskFormat,
                placeholder: 'HHmm',
            };
        }
        case TextMask.Duration: {
            return {
                textMaskFunc: getDurationMaskFormat,
                placeholder: 'HH:mm',
            };
        }
        default:
            return {
                textMaskFunc: null,
                placeholder: '',
            };
    }
};

const InputField = forwardRef((props: TextFieldProps, ref: any) => {
    const {
        textMask = TextMask.None,
        showErrorMessage = false,
        onBlur,
        sx,
        isDisabled,
        width = '100%',
        errorMessage = 'Invalid Input',
        ...propsWithoutWidth
    } = props;

    const { textMaskFunc, placeholder } = getTextMaskConfig(textMask);

    return (
        <Box
            sx={{ ...textFieldStyles.container, ...sx, width: width }}
            className={clsx({
                disabled: isDisabled,
                'input-container': true,
            })}
        >
            {textMask === TextMask.None ? (
                <CustomTextField isDisabled={isDisabled} onBlur={onBlur} {...propsWithoutWidth} ref={ref} />
            ) : (
                <MaskedInputField
                    {...propsWithoutWidth}
                    customInput={CustomTextField}
                    format={textMaskFunc}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    isDisabled={isDisabled}
                />
            )}
            {showErrorMessage && <Typography sx={{ ...textFieldStyles.errorMessage }}>{errorMessage}</Typography>}
        </Box>
    );
});

type InputAreaProps = {
    error?: boolean;
    label?: string;
    isMultiline?: boolean;
    minRows?: number;
    maxRows?: number;
    value?: string;
    isReadOnly?: boolean;
    variant?: 'standard' | 'readOnlyNoBorder';
    defaultValue?: string;
    sx?: SxProps;
    hiddenLabel?: boolean;
    placeholder?: string;
    helperText?: string;
    fullWidth?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const InputArea = forwardRef((props: InputAreaProps, inputRef: any) => {
    const {
        error = false,
        label = '',
        isMultiline = true,
        minRows = 1,
        isReadOnly = false,
        variant = 'standard',
        defaultValue,
        maxRows,
        onChange,
        fullWidth = true,
        value,
        sx,
        hiddenLabel = false,
        placeholder,
        helperText,
    } = props;

    return (
        <TextField
            error={error}
            className={clsx({
                'no-border': isReadOnly && variant === 'readOnlyNoBorder',
            })}
            sx={{ ...textFieldStyles.textAreaInput, ...sx }}
            InputProps={{
                disableUnderline: true,
                readOnly: isReadOnly,
            }}
            InputLabelProps={{
                shrink: value?.length > 0 ? true : undefined,
            }}
            ref={inputRef}
            defaultValue={defaultValue}
            fullWidth={fullWidth}
            label={label}
            multiline={isMultiline}
            minRows={minRows}
            maxRows={maxRows}
            value={value}
            variant='filled'
            hiddenLabel={hiddenLabel}
            onChange={onChange}
            placeholder={placeholder}
            helperText={helperText}
        />
    );
});

export { InputField as default, TextMask, InputArea };
