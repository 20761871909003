import { styled, Paper } from '@mui/material';
import colors from '../../../../style/color';

const PopperPaperContainer = styled(Paper)({
    border: `1px solid ${colors.grayNormal}`,
    boxShadow: colors.boxShadow,
    marginTop: '4px',
    marginBottom: '4px',
});

export default PopperPaperContainer;