import { TaskType } from '../constants/constants';
import {
    GANTT_PATH,
    MCEVENTLIST_PATH,
    CHAT_PATH,
    COE_EVENTLIST_PATH,
    LIVE_DASHBOARD_PATH,
    EVENTLIST_MC_PATH,
} from '../constants/paths';

enum ResourceType {
    API = 'api',
    VIEW = 'view',
    ACTION = 'action',
    CONFIG = 'config',
    MOBILETABLET = 'view-ipad',
}

enum ResourceAction {
    GET = 'GET',
    POST = 'POST',
    NULL = 'NULL',
}

enum MappingKey {
    ROUTER = 'router',
    FLIGHT_DETAILS = 'flightDetails',
    AIRCRAFT_DETAILS = 'aircraftDetails',
    MAINT_DETAILS = 'maintDetails',
    LMTT_MAINT_DETAILS = 'lmtt_maintDetails',
    SUBFLEET_DETAILS = 'subfleetDetails',
    TARGET_TASK_TYPE = 'targetTaskType',
    HOME_PAGE = 'homePage',
}

interface Permission {
    resourceName: string;
    resourceType: ResourceType;
    resourceAction?: ResourceAction;
}

interface DisplayItem {
    key: string;
    resourceName: string;
    resourceType: ResourceType;
}

const routerMapping: DisplayItem[] = [
    { key: 'schedule', resourceName: '/gantt', resourceType: ResourceType.VIEW },
    { key: 'eventListMc', resourceName: '/eventlist/mc', resourceType: ResourceType.VIEW }, // new mc event list
    { key: 'iocEventList', resourceName: '/ioceventlist', resourceType: ResourceType.VIEW },
    { key: 'coeEventList', resourceName: '/coeeventlist', resourceType: ResourceType.VIEW },
    { key: 'crdeventManagementList', resourceName: '/crdeventManagementList', resourceType: ResourceType.VIEW },
    { key: 'coeEventList', resourceName: '/coeeventlist', resourceType: ResourceType.MOBILETABLET },
    { key: 'mcEventList', resourceName: '/mceventlist', resourceType: ResourceType.VIEW },
    { key: 'opsBroadcast', resourceName: '/opsBroadcast', resourceType: ResourceType.VIEW },
    { key: 'checklistEvent', resourceName: '/checklistEvent', resourceType: ResourceType.VIEW },
    { key: 'checklistManagement', resourceName: '/checklistManagement', resourceType: ResourceType.VIEW },
    { key: 'checklistDashboard', resourceName: '/checklistDashboard', resourceType: ResourceType.VIEW },
    { key: 'chat', resourceName: '/chat', resourceType: ResourceType.VIEW },
    { key: 'liveDashboard', resourceName: '/dashboard/live', resourceType: ResourceType.VIEW },
    { key: 'dailyDashboard', resourceName: '/dashboard/daily', resourceType: ResourceType.VIEW },
    { key: 'directory', resourceName: '/dom', resourceType: ResourceType.VIEW },
    { key: 'priorityFlightList', resourceName: '/priorityFlight/list', resourceType: ResourceType.VIEW },
    { key: 'priorityFlightDefinitions', resourceName: '/priorityFlight/definitions', resourceType: ResourceType.VIEW },
    { key: 'maintenanceSearch', resourceName: '/maintenanceSearch', resourceType: ResourceType.MOBILETABLET },
    { key: 'alertCentre', resourceName: '/alertCentre', resourceType: ResourceType.VIEW },
];
const sidePanelFlightDetailsMapping: DisplayItem[] = [
    { key: 'schedule', resourceName: '/flightDetails/schedule', resourceType: ResourceType.VIEW },
    { key: 'summary', resourceName: '/flightDetails/summary', resourceType: ResourceType.VIEW },
    { key: 'pax', resourceName: '/flightDetails/pax', resourceType: ResourceType.VIEW },
    { key: 'cargo', resourceName: '/flightDetails/cargo', resourceType: ResourceType.VIEW },
    { key: 'crew', resourceName: '/flightDetails/crew', resourceType: ResourceType.VIEW },
    { key: 'flightPlanning', resourceName: '/flightDetails/flightPlanning', resourceType: ResourceType.VIEW },
    { key: 'airport', resourceName: '/flightDetails/airport', resourceType: ResourceType.VIEW },
    { key: 'cargopts', resourceName: '/flightDetails/cargopts', resourceType: ResourceType.VIEW },
    { key: 'aircraft', resourceName: '/flightDetails/aircraft', resourceType: ResourceType.VIEW },
    { key: 'chat', resourceName: '/chat', resourceType: ResourceType.VIEW },
];
const sidePanelAircraftDetailsMapping: DisplayItem[] = [
    { key: 'summary', resourceName: '/aircraftDetails/summary', resourceType: ResourceType.VIEW },
    { key: 'aircraft', resourceName: '/aircraftDetails/aircraft', resourceType: ResourceType.VIEW },
    { key: 'defects', resourceName: '/aircraftDetails/defects', resourceType: ResourceType.VIEW },
];
const sidePanelLmttMaintDetailsMapping: DisplayItem[] = [
    { key: 'schedule', resourceName: '/maintDetails/lmttSchedule', resourceType: ResourceType.VIEW },
    { key: 'event', resourceName: '/maintDetails/lmttEvent', resourceType: ResourceType.VIEW },
    { key: 'dueTolerance', resourceName: '/maintDetails/lmttDueTolerance', resourceType: ResourceType.VIEW },
    { key: 'packageTaskInfo', resourceName: '/maintDetails/lmttPackageTaskInfo', resourceType: ResourceType.VIEW },
    {
        key: 'allGroundTimeTasks',
        resourceName: '/maintDetails/lmttAllGroundTimeTasks',
        resourceType: ResourceType.VIEW,
    },
    { key: 'checkHistory', resourceName: '/maintDetails/lmttCheckHistory', resourceType: ResourceType.VIEW },
];
const sidePanelSubfleetDetailsMapping: DisplayItem[] = [
    { key: 'summary', resourceName: '/subfleetDetails/summary', resourceType: ResourceType.VIEW },
    {
        key: 'aircraftPerformance',
        resourceName: '/subfleetDetails/aircraftPerformance',
        resourceType: ResourceType.VIEW,
    },
];

const taskTypeMapping: DisplayItem[] = [
    { key: TaskType.event, resourceName: 'config/taskType/event', resourceType: ResourceType.CONFIG },
    { key: TaskType.iocLogChat, resourceName: 'config/taskType/iocLogChat', resourceType: ResourceType.CONFIG },
    { key: TaskType.iocLogManual, resourceName: 'config/taskType/iocLogManual', resourceType: ResourceType.CONFIG },
    { key: TaskType.issueSummary, resourceName: 'config/taskType/issueSummary', resourceType: ResourceType.CONFIG },
    { key: TaskType.issueLog, resourceName: 'config/taskType/issueLog', resourceType: ResourceType.CONFIG },
    { key: TaskType.spare, resourceName: 'config/taskType/spare', resourceType: ResourceType.CONFIG },
    { key: TaskType.tooling, resourceName: 'config/taskType/tooling', resourceType: ResourceType.CONFIG },
    { key: TaskType.manpower, resourceName: 'config/taskType/manpower', resourceType: ResourceType.CONFIG },
    { key: TaskType.disMessage, resourceName: 'config/taskType/disMessage', resourceType: ResourceType.CONFIG },
    { key: TaskType.aogReport, resourceName: 'config/taskType/aogReport', resourceType: ResourceType.CONFIG },
    { key: TaskType.actionItem, resourceName: 'config/taskType/actionItem', resourceType: ResourceType.CONFIG },
    { key: TaskType.assessment, resourceName: 'config/taskType/assessment', resourceType: ResourceType.CONFIG },
    { key: TaskType.eventNote, resourceName: 'config/taskType/eventNote', resourceType: ResourceType.CONFIG },
    { key: TaskType.actionItemNote, resourceName: 'config/taskType/eventTaskNote', resourceType: ResourceType.CONFIG },
    { key: TaskType.iocAlert, resourceName: 'config/taskType/IOCAlert', resourceType: ResourceType.CONFIG },
    { key: TaskType.mcWrq, resourceName: 'config/taskType/mcWrq', resourceType: ResourceType.CONFIG },
    { key: TaskType.mailService, resourceName: 'config/taskType/mailService', resourceType: ResourceType.CONFIG },
];

const homePageMapping: DisplayItem[] = [
    { key: GANTT_PATH, resourceName: 'config/homePage/gantt', resourceType: ResourceType.CONFIG },
    { key: CHAT_PATH, resourceName: 'config/homePage/chat', resourceType: ResourceType.CONFIG },
    { key: MCEVENTLIST_PATH, resourceName: 'config/homePage/mcEventList', resourceType: ResourceType.CONFIG },
    { key: EVENTLIST_MC_PATH, resourceName: 'config/homePage/eventListMC', resourceType: ResourceType.CONFIG }, // new mc event list
    { key: COE_EVENTLIST_PATH, resourceName: 'config/homePage/coeEventList', resourceType: ResourceType.CONFIG },
    { key: LIVE_DASHBOARD_PATH, resourceName: 'config/homePage/liveDashboard', resourceType: ResourceType.CONFIG },
];

const configResources = {
    mcLogPermissionControl: {
        resourceName: 'config/mcilog/issueLogPermissionControl',
        resourceType: ResourceType.CONFIG,
    },
};

const resourceCheck = (
    permissionList: Permission[] | undefined | null,
    resourceType: string,
    resourceName: string,
    resourceAction?: string
) => {
    const hasPermission = permissionList?.some((permission) => {
        return resourceAction
            ? permission.resourceType === resourceType &&
                  permission.resourceName === resourceName &&
                  resourceAction === permission.resourceAction
            : permission.resourceType === resourceType && permission.resourceName === resourceName;
    });
    // console.log('ResourceCheck: ' + resourceName + '----' + hasPermission);
    return hasPermission;
};

const isMobileOrTablet = () => {
    const isMobileOrTablet = /iPad|iPhone|iPod|Android/.test(navigator.userAgent);
    return isMobileOrTablet;
};

const getItemsFromResourceList = (
    permissionList: Permission[] | undefined | null,
    itemName: MappingKey,
    isMobileOrTablet?: boolean
): string[] => {
    if (!permissionList) {
        return [];
    }
    let mappingList: DisplayItem[];
    switch (itemName) {
        case MappingKey.ROUTER:
            mappingList = routerMapping;
            break;
        case MappingKey.FLIGHT_DETAILS:
            mappingList = sidePanelFlightDetailsMapping;
            break;
        case MappingKey.AIRCRAFT_DETAILS:
            mappingList = sidePanelAircraftDetailsMapping;
            break;
        case MappingKey.LMTT_MAINT_DETAILS:
            mappingList = sidePanelLmttMaintDetailsMapping;
            break;
        case MappingKey.SUBFLEET_DETAILS:
            mappingList = sidePanelSubfleetDetailsMapping;
            break;
        case MappingKey.TARGET_TASK_TYPE:
            mappingList = taskTypeMapping;
            break;
        case MappingKey.HOME_PAGE:
            mappingList = homePageMapping;
            break;
        default:
            mappingList = [];
            break;
    }

    if (isMobileOrTablet) {
        return mappingList
            .filter((item) => {
                return permissionList.some((permission) => {
                    return (
                        permission.resourceName === item.resourceName && ResourceType.MOBILETABLET === item.resourceType
                    );
                });
            })
            .map((item) => item.key);
    }

    return mappingList
        .filter((item) => {
            return permissionList.some((permission) => {
                return (
                    permission.resourceName === item.resourceName &&
                    permission.resourceType === item.resourceType &&
                    item.resourceType !== ResourceType.MOBILETABLET
                );
            });
        })
        .map((item) => item.key);
};

const isIncludeInMappingList = (resourceName: string, itemName: MappingKey): boolean => {
    if (!resourceName || !itemName) {
        return false;
    }

    let mappingList: DisplayItem[];
    switch (itemName) {
        case MappingKey.ROUTER:
            mappingList = routerMapping;
            break;
        case MappingKey.FLIGHT_DETAILS:
            mappingList = sidePanelFlightDetailsMapping;
            break;
        case MappingKey.AIRCRAFT_DETAILS:
            mappingList = sidePanelAircraftDetailsMapping;
            break;
        case MappingKey.SUBFLEET_DETAILS:
            mappingList = sidePanelSubfleetDetailsMapping;
            break;
        case MappingKey.TARGET_TASK_TYPE:
            mappingList = taskTypeMapping;
            break;
        case MappingKey.HOME_PAGE:
            mappingList = homePageMapping;
            break;
        default:
            mappingList = [];
            break;
    }

    return mappingList.some((item) => {
        return item.resourceName === resourceName;
    });
};

export {
    configResources,
    ResourceType,
    ResourceAction,
    Permission,
    MappingKey,
    resourceCheck,
    getItemsFromResourceList,
    isIncludeInMappingList,
    isMobileOrTablet,
};
