import './helper/wdyr';

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from '@azure/msal-react';

import { store } from './app/store';
import { loginRequest } from './config/authConfig';
import { msalInstance } from './services/auth';

const App = React.lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);

const RedirectToAzureLogin = () => {
    const { instance } = useMsal();
    useEffect(() => {
        instance.loginRedirect(loginRequest);
    }, []);
    return <></>;
};

root.render(
    <Provider store={store}>
        <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>
                <React.StrictMode>
                    <React.Suspense fallback={<>Loading...</>}>
                        <App />
                    </React.Suspense>
                </React.StrictMode>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <RedirectToAzureLogin />
            </UnauthenticatedTemplate>
        </MsalProvider>
    </Provider>
);
