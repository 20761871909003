import React, { useContext, useRef, useEffect } from 'react';
import { Stack, SxProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import MemoFlightBar from './MemoFlightBar';
import Pagination from './Pagination';
import EmptyResultView from '../common/EmptyResultView';
import { selectFlight } from '../../slices/flightSlice';
import { useAppSelector } from '../../app/hooks';
import { IFlightBar } from '../common/FlightBarInfo';
import { FlightSearchMainContext } from './FlightSearch';

interface FlightListProps {
    sx?: SxProps;
}

const emptyViewText = {
    warning: 'Your search did not match any results',
    suggestions: ['Check the spelling or try searching a different word', 'Be more specific'],
};

const FlightList = (props: FlightListProps) => {
    const { sx } = props;

    const flightListContainerRef = useRef(null);
    const { control, getValues } = useFormContext();
    const { flightBarList } = useAppSelector(selectFlight);
    const { onFlightBarSelected, validDefaultFlightBars, disableDefault, multiple } =
        useContext(FlightSearchMainContext);

    const selectTargetFlightBars = (flightBar: IFlightBar, formOnChange: (...event: any[]) => void) => {
        const selectedValues = getValues('selectedFlights');

        let finalFlightBarList: IFlightBar[];

        if (!multiple) {
            finalFlightBarList = [flightBar];
        } else {
            const isFlightBarSelected = selectedValues.some((item: IFlightBar) => item.ufi === flightBar.ufi);
            if (isFlightBarSelected) {
                finalFlightBarList = selectedValues.filter((item: IFlightBar) => item.ufi !== flightBar.ufi);
            } else {
                const combinedList = [...selectedValues].concat(flightBar);
                const uniqueFlightListMap = new Map(combinedList.map((flightBar) => [flightBar.ufi, flightBar]));
                finalFlightBarList = [...uniqueFlightListMap.values()];
            }
        }

        formOnChange(finalFlightBarList);
        onFlightBarSelected(finalFlightBarList);
    };

    useEffect(() => {
        flightListContainerRef?.current?.scrollTo(0, 0);
    }, [flightBarList]);

    return (
        <Stack sx={sx} ref={flightListContainerRef}>
            {flightBarList.length > 0 ? (
                <>
                    <Controller
                        control={control}
                        name='selectedFlights'
                        defaultValue={validDefaultFlightBars || []}
                        render={({ field: { value, onChange } }) => {
                            return (
                                <>
                                    {flightBarList.map((flightBar: IFlightBar, index) => {
                                        const selected = value?.some((item: IFlightBar) => {
                                            return item?.ufi === flightBar.ufi;
                                        });
                                        const isDefaultFlight = validDefaultFlightBars?.some(
                                            (item) => item?.ufi === flightBar.ufi
                                        );

                                        return (
                                            <MemoFlightBar
                                                key={flightBar.ufi}
                                                flightBar={flightBar}
                                                multiple={multiple}
                                                selectable={true}
                                                checked={selected}
                                                disabled={isDefaultFlight && disableDefault}
                                                onClick={(flight: IFlightBar) => {
                                                    selectTargetFlightBars(flight, onChange);
                                                }}
                                                showLabel={true}
                                            />
                                        );
                                    })}
                                </>
                            );
                        }}
                    />
                    <Pagination />
                </>
            ) : (
                <EmptyResultView warning={emptyViewText.warning} suggestions={emptyViewText.suggestions} />
            )}
        </Stack>
    );
};

export default FlightList;
