import React from 'react';
import clsx from 'clsx';
import { styled, SxProps } from '@mui/system';
import Button from '@mui/material/Button';

import buttonStyles, { simpleButtonStyles } from './ButtonStyles';

export type ButtonProps = {
    label: string;
    isDisabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
    isSubmitBtn?: boolean;
    buttonType?: 'button' | 'reset' | 'submit';
    variant?: 'primary' | 'secondary' | 'tertiary' | 'outlined';
    sx?: SxProps;
};

export const SimpleButton = styled(Button)(simpleButtonStyles);

const StyledButton = (props: ButtonProps) => {
    const {
        label,
        isDisabled = false,
        startIcon,
        endIcon,
        onClick,
        buttonType = 'button',
        variant = 'primary',
        sx,
    } = props;
    const Component = styled(Button)({ ...buttonStyles });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <Component
            sx={sx}
            disabled={isDisabled}
            onClick={handleClick}
            startIcon={startIcon}
            endIcon={endIcon}
            children={label}
            className={clsx('MuiButton-root', {
                'MuiButton-primary': variant === 'primary',
                'MuiButton-secondary': variant === 'secondary',
                'MuiButton-outlined': variant === 'outlined',
                'MuiButton-tertiary': variant === 'tertiary',
            })}
            type={buttonType}
        />
    );
};

export { StyledButton as default };
