import React, { forwardRef } from 'react';
import { IconButton as MuiIconButton, Tooltip, SxProps, styled } from '@mui/material';

import colors from '../../../../style/color';

interface IconButtonProps {
    isActive?: boolean;
    isSelected?: boolean;
    sx?: SxProps;
    iconContent: React.ReactNode;
    tooltipTitle?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledIconButton = styled(MuiIconButton)({
    borderRadius: '2px',
    padding: '4px',
    display: 'flex',
    alignSelf: 'center',
    height: '28px',
    width: '28px',
    justifyContent: 'center',
    path: { fill: colors.grayDark },
    rect: { fill: colors.grayDark },
    '&:hover': {
        borderRadius: '2px',
        cursor: 'pointer',
        background: colors.tableBorder,
        path: { fill: colors.grayDark },
        rect: { fill: colors.grayDark },
        color: colors.white,
    },
    '&.active': {
        borderRadius: '2px',
        background: colors.white,
        color: colors.grayDark,
        path: { fill: colors.grayDark },
        rect: { fill: colors.grayDark },
        border: `1px solid ${colors.textDark}`,
    },
    '&.selected': {
        borderRadius: '2px',
        background: colors.textDark,
        path: { fill: colors.white },
        rect: { fill: colors.white },
        color: colors.white,
    },
});

const IconButton = forwardRef((props: IconButtonProps, ref: any) => {
    const { isActive, sx, iconContent, tooltipTitle, onClick, isSelected } = props;
    return (
        <Tooltip disableFocusListener disableTouchListener title={tooltipTitle}>
            <StyledIconButton
                arial-name='iconButton'
                ref={ref}
                sx={sx}
                onClick={onClick}
                disableRipple={true}
                className={isActive ? 'active' : isSelected ? 'selected' : ''}
            >
                {iconContent}
            </StyledIconButton>
        </Tooltip>
    );
});

export default IconButton;
