import COLORS from '../../style/color';

const datePickerStyles = {
    display: 'inline-block',
    borderRadius: 4,
    '& .react-datepicker-wrapper': {
        width: '100%',
    },
    '& .react-datepicker-popper': {
        zIndex: 2147483647,
    },
    '& .react-datepicker__triangle': {
        display: 'none',
    },
    '& .react-datepicker__input-container': {
        display: 'inline-flex',
        '& input': {
            marginLeft: '27px',
        },
    },
    '& .react-datepicker__month-container': {
        width: '100%',
    },
    '& .react-datepicker__month': {
        margin: '0px',
    },
    '& .react-datepicker__header': {
        backgroundColor: 'unset',
        borderBottom: 'unset',
        padding: '8px 0px 0',
    },
    '& .react-datepicker': {
        width: '313px',
        border: '1px solid #C9CBCC',
        borderRadius: '4px',
    },
    '& .react-datepicker__current-month': {
        width: '100%',
        osition: 'absolute',
        marginTop: '33px',
        fontWeight: 500,
        fontSize: '18px',
        color: '#4C4C4C',
    },
    '& .react-datepicker__day, .react-datepicker__day-name': {
        width: '36.8px',
        height: '36.8px',
        fontSize: '14px',
        lineHeight: '36.8px',
    },
    '& .react-datepicker__day-names': {
        position: 'absolute',
        top: 0,
        margin: '0px 10px',
        borderBottom: '1px solid #EBEDEC',
        height: '35px',
    },
    '& .react-datepicker__day-name': {
        visibility: 'hidden',
        fontSize: '12px',
        color: '#4C4C4C',
    },
    '& .react-datepicker__day-name:first-letter': {
        visibility: 'visible',
    },
    '& .react-datepicker__day--selected': {
        backgroundColor: '#2D2D2D',
        border: '1px solid #2D2D2D',
    },
    '& .react-datepicker__day--keyboard-selected': {
        backgroundColor: COLORS.transparent,
        color: 'black',
        '&:hover': {
            borderRadius: '4.8px',
            backgroundColor: COLORS.ultraLightGray,
        },
    },
    '& .react-datepicker__day--today': {
        border: '1px solid #979797',
        borderRadius: '4px',
    },
    '& .react-datepicker__navigation': {
        top: '40px',
    },
    '& .react-datepicker__navigation--previous': {
        left: '12px',
    },
    '& .react-datepicker__navigation--next': {
        right: '12px',
    },
};

export default datePickerStyles;
