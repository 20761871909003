import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRecoveryPlanByEventIdAndType, saveRecoveryPlan } from '../services/recoveryPlan';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { IFlightBar, FlightBarType } from '../components/common/FlightBar';
import { EventTasks, SpareDetailType } from './eventSlice';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';

export interface SparesAndTooling extends EventTasks {
    details: SpareDetailType;
    flightBar?: IFlightBar;
    active?: boolean;
}

export interface RecoveryPlanSlice {
    resourceOptions: string[];
    statusList: { display: string; value: string }[];
    transportMethods: { display: string; value: string }[];
    airlines: { display: string; value: string }[];
    isPlanSaving: boolean;
    isGettingPlan: boolean;
    sparesAndToolingList: SparesAndTooling[];
}

export const airlines = [
    { display: 'CX/ LD', value: 'cx/ld' },
    { display: 'Others', value: 'others' },
];

const initialState: RecoveryPlanSlice = {
    isPlanSaving: false,
    isGettingPlan: false,
    statusList: [
        { display: 'Required', value: 'required' },
        { display: 'Optional', value: 'optional' },
        { display: 'No longer required', value: 'none' },
    ],
    resourceOptions: ['Spares & Tooling'],
    transportMethods: [
        { display: 'Shipping (AWS FSN SCN)', value: 'shipping' },
        { display: 'Hand Carry', value: 'hand carry' },
        { display: 'Local Transport', value: 'local' },
    ],
    airlines,
    sparesAndToolingList: [],
};

interface ReturnType {
    data: SparesAndTooling[];
}

interface Input {
    sparesAndTooling: SpareDetailType[];
}

export const getRecoveryPlanByEventIdAndTypeThunk = createAsyncThunk<
ReturnType,
{ eventId: string; types?: string[] },
{ state: RootState; rejectValue: ApiErrorObj }
>('events/getRecoveryPlanByEventIdAndType', async ({ eventId, types }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getRecoveryPlanByEventIdAndType({ eventId, types });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return { data: data } as ReturnType;
});

export const saveRecoveryPlanThunk = createAsyncThunk<
ReturnType,
Input,
{ state: RootState; rejectValue: ApiErrorObj }
>('recoveryPlan/saveChanges', async ({ sparesAndTooling }, thunkAPI) => {
    const { userProfile } = thunkAPI.getState().userProfile;
    const { userId, currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.GET)) {
        return thunkAPI.rejectWithValue(ForbiddenObj);
    }
    const { selectedEventId: eventId } = thunkAPI.getState().event;

    const [err, data] = await saveRecoveryPlan({ userId, eventId, details: sparesAndTooling });

    if (err) {
        return thunkAPI.rejectWithValue(err as ApiErrorObj);
    }

    return { data: data } as ReturnType;
});

export const recoveryPlanSlice = createSlice({
    name: 'recoveryPlan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveRecoveryPlanThunk.pending, (state) => {
                state.isPlanSaving = true;
            })
            .addCase(saveRecoveryPlanThunk.fulfilled, (state, { payload }) => {
                state.isPlanSaving = false;
                state.sparesAndToolingList = payload.data;
            })
            .addCase(saveRecoveryPlanThunk.rejected, (state) => {
                state.isPlanSaving = false;
            })
            .addCase(getRecoveryPlanByEventIdAndTypeThunk.pending, (state) => {
                state.isGettingPlan = true;
            })
            .addCase(getRecoveryPlanByEventIdAndTypeThunk.fulfilled, (state, { payload }) => {
                state.isGettingPlan = false;
                state.sparesAndToolingList = payload.data.map((item) => {
                    const { flight: flightItem, id, details, etsTime, endTime } = item;
                    const parsedEtsTime = etsTime;
                    const parsedEndTime = endTime;
                    if (!flightItem) {
                        return {
                            ...item,
                            etsTimeDisplay: etsTime,
                            endTimeDisplay: endTime,
                            etsTime: parsedEtsTime,
                            endTime: parsedEndTime,
                            details: { ...details, id },
                        };
                    }

                    return {
                        ...item,
                        etsTimeDisplay: etsTime,
                        endTimeDisplay: endTime,
                        etsTime: parsedEtsTime,
                        endTime: parsedEndTime,
                        details: {
                            ...details,
                            id,
                        } as SpareDetailType,
                        flightBar: {
                            flightNum: flightItem.callsign,
                            dpt: flightItem.scheduleDepartureAirport,
                            dptTimeStr: flightItem.displayDepartureTime,
                            arr: flightItem.scheduleArrivalAirport,
                            arrTimeStr: flightItem.displayArrivalTime,
                            type: FlightBarType.PAX_FLIGHT,
                            flightRegi: flightItem.aircraftRegistration,
                            subtype: flightItem.iataAircraftSubtype,
                            ufi: flightItem.ufi,
                            selectable: true,
                            airline: flightItem.airline,
                            STD: flightItem.STD,
                            ETD: flightItem.ETD,
                            ATD: flightItem.ATD,
                            STA: flightItem.STA,
                            ETA: flightItem.ETA,
                            ATA: flightItem.ATA,
                        } as IFlightBar,
                    };
                });
            })
            .addCase(getRecoveryPlanByEventIdAndTypeThunk.rejected, (state, { payload }) => {
                state.isGettingPlan = false;
                state.sparesAndToolingList = [];
            });
    },
});

const recoveryPlanSliceReducer = recoveryPlanSlice.reducer;

const selectRecoveryPlan = (state: RootState) => state.recoveryPlan;

export { recoveryPlanSliceReducer as default, selectRecoveryPlan };
