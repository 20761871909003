import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { RootState } from '../app/store';
import { getFlights, getDepartureFlights, getFlightPortInfo } from '../services/flights';
import { IFlightBar } from '../components/common/FlightBarInfo';
import { constructFlightBarObjects } from '../helper/flightBarHelper';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { ResourceAction, resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';
import { PortInfo } from '../interface/Flight';

type Filter = {
    type: string;
    value: string | DateTime | boolean;
};

type FlightFilter = {
    startDate: DateTime;
    endDate: DateTime;
    searchKey: string;
    searchTrigger: boolean;
};

interface DepartureFlightParam {
    startDate: string;
    endDate: string;
    subTypes: string[];
}

interface FlightParam {
    keySearch?: string;
    size?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    sort?: string;
    airline?: string[];
    regis?: string;
    ports?: string[];
    ufi?: string;
}

interface FlightObj {
    ufi: string;
    airline: string;
    flightNumber: string;
    originFlightDate: string;
    callsign: string;
    legState: string;
    scheduleDepartureAirport: string;
    scheduleArrivalAirport: string;
    iataAircraftType: string;
    iataAircraftSubtype: string;
    displayDepartureTime: string;
    displayArrivalTime: string;
    aircraftRegistration: string;
    multipleEvent: boolean;
    STD: string;
    ETD: string;
    ATD: string;
    STA: string;
    ETA: string;
    ATA: string;
}

interface DepartureFlightObj {
    ufi: string;
    registration: string;
    LTD: string;
    LTA: string;
    LTDLocal: string;
    LTALocal: string;
    flightNumber: string;
    serviceType: string;
    departure: string;
    arrived: string;
    STD: string;
    ETD: string;
    ATD: string;
}

interface FlightSlice {
    totalFlightBars: number;
    flightBarList: IFlightBar[];
    isFlightListLoading: boolean;
}

const initialState: FlightSlice = {
    isFlightListLoading: false,
    totalFlightBars: 0,
    flightBarList: [],
};

const getFlightPortInfoThunk = createAsyncThunk<
    { [key: string]: PortInfo },
    string[],
    { state: RootState; rejectValue: ApiErrorObj }
>('flight/portInfo', async (ufis, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile || {};

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/flight/portInfo', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getFlightPortInfo(ufis);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

const getDepartureFlightThunk = createAsyncThunk<
    DepartureFlightObj[],
    DepartureFlightParam,
    { state: RootState; rejectValue: ApiErrorObj }
>('flights/getDepartureFlights', async (param: DepartureFlightParam, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/flight/nextDepts')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getDepartureFlights(param);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

const fetchFlights = async (
    param: FlightParam,
    getState: () => RootState,
    rejectWithValue: (value: ApiErrorObj) => any
) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/flight')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getFlights(param);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
};

const getAllFlightThunk = createAsyncThunk<
    { flights: FlightObj[]; total: number },
    FlightParam,
    { state: RootState; rejectValue: ApiErrorObj }
>('flights/getFlights', async (param: FlightParam, { getState, rejectWithValue }) => {
    return fetchFlights(param, getState, rejectWithValue);
});

const getAllFlightNoLoadingThunk = createAsyncThunk<
    { flights: FlightObj[]; total: number },
    FlightParam,
    { state: RootState; rejectValue: ApiErrorObj }
>('flights/getAllFlightNoLoadingThunk', async (param: FlightParam, { getState, rejectWithValue }) => {
    return fetchFlights(param, getState, rejectWithValue);
});

export const flightSlice = createSlice({
    name: 'flights',
    initialState: initialState,
    reducers: {
        cleanFlightList: (state) => {
            state.flightBarList = [];
            state.totalFlightBars = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllFlightThunk.pending, (state) => {
                state.isFlightListLoading = true;
            })
            .addCase(getAllFlightThunk.fulfilled, (state, { payload }) => {
                const { flights, total } = payload;
                state.isFlightListLoading = false;
                state.totalFlightBars = total;
                state.flightBarList = constructFlightBarObjects(flights);
            })
            .addCase(getAllFlightThunk.rejected, (state) => {
                state.isFlightListLoading = false;
            })
            .addCase(getFlightPortInfoThunk.pending, (state) => {
                state.isFlightListLoading = true;
            })
            .addCase(getFlightPortInfoThunk.fulfilled, (state) => {
                state.isFlightListLoading = false;
            })
            .addCase(getFlightPortInfoThunk.rejected, (state) => {
                state.isFlightListLoading = false;
            });
    },
});

const selectFlight = (state: RootState) => state.flight;

const flightSliceReducer = flightSlice.reducer;

export const { cleanFlightList } = flightSlice.actions;
export {
    flightSliceReducer as default,
    getDepartureFlightThunk,
    getAllFlightThunk,
    getFlightPortInfoThunk,
    getAllFlightNoLoadingThunk,
    DepartureFlightObj,
    FlightObj,
    FlightParam,
    DepartureFlightParam,
    FlightFilter,
    Filter,
    selectFlight,
};
