import React from 'react';

import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { ReactComponent as ArrowDown } from '../../assets/icons/ArrowDown.svg';
import COLORS from '../../style/color';

interface StyledDropdownProps {
    title?: string;
    dataList: Array<DropdownData>;
    filterDataList?: Array<DropdownData>;
    width?: number | string;
    value?: string;
    isOptional?: boolean;
    hasError?: boolean;
    isReadOnly?: boolean;
    isDisabled?: boolean;
    hiddenLabel?: boolean;
    variant?: 'standard' | 'readOnlyNoBorder';
    callback?: (value: string) => void; // return the selected value to caller
    sx?: SxProps;
}

type DropdownData = { display: string; value: string };

const dropdownFieldHeight = '40px';
const labelOffset = '-6px';

const getBorder = ({
    hasError = false,
    isActive = false,
    isReadOnly,
    variant,
}: {
    hasError: boolean;
    isActive: boolean;
    isReadOnly: boolean;
    variant: string;
}) => {
    if (isReadOnly && variant === 'readOnlyNoBorder') {
        return 'none';
    }

    if (hasError) {
        return `1px solid ${COLORS.borderRed}`; // error red
    }

    if (isActive) {
        return `1px solid ${COLORS.textDark}`; // active state
    }

    return `1px solid ${COLORS.borderGray}`; // default
};

const Dropdown = ({
    title,
    value = '',
    dataList = [],
    isOptional = false,
    width = '100%',
    callback,
    hasError = false,
    isReadOnly = false,
    isDisabled = false,
    hiddenLabel = false,
    variant = 'standard',
    sx,
    filterDataList,
}: StyledDropdownProps) => {
    return (
        <FormControl
            className={clsx({
                'is-readonly': isReadOnly,
                'no-border': isReadOnly && variant === 'readOnlyNoBorder',
                'is-disabled': isDisabled,
            })}
            variant='filled'
            sx={{
                m: '4px',
                height: dropdownFieldHeight,
                width: width,
                overflow: 'hidden',
                '& .MuiFilledInput-root': {
                    // the selection box
                    height: dropdownFieldHeight,
                },
                '& .MuiFilledInput-root.Mui-focused': {
                    // the selection box
                    color: COLORS.grayNormal,
                    bgcolor: COLORS.white,
                    border: getBorder({ hasError, isActive: !isReadOnly && !isDisabled, isReadOnly, variant }), // black border on active
                },
                '& .MuiInputLabel-root': {
                    top: labelOffset,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                    // title label
                    color: COLORS.grayNormal,
                },
                '& .MuiSelect-select': {
                    color: COLORS.grayDark,
                    paddingTop: hiddenLabel ? '8px' : '20px',
                    paddingBottom: '8px',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    '&:focus': {
                        bgcolor: COLORS.transparent,
                    },
                },
                '& .MuiSelect-icon': {
                    top: 'calc(50% - 0.2em)',
                },
                '&.is-readonly': {
                    pointerEvents: 'none',
                    '&.no-border': {
                        '& .MuiInputLabel-root': {
                            marginLeft: '-10px',
                        },
                        '& .MuiSelect-select': {
                            marginLeft: '-10px',
                        },
                    },
                },
                '&.is-disabled': {
                    pointerEvents: 'none',
                    '& .MuiFilledInput-root': {
                        background: COLORS.grayLight,
                    },
                },
                ...sx,
            }}
        >
            {title && (
                <InputLabel
                    sx={{
                        color: COLORS.grayNormal,
                    }}
                >
                    {isOptional ? `${title} (optional)` : title}
                </InputLabel>
            )}
            <Select
                inputProps={{ readOnly: isReadOnly || isDisabled }}
                value={value}
                onChange={(event: SelectChangeEvent) => {
                    callback && callback(event.target.value as string);
                }}
                disableUnderline
                sx={{
                    // default select style
                    bgcolor: COLORS.white,
                    border: getBorder({ hasError, isActive: false, isReadOnly, variant }),
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '4px',
                    ':hover': {
                        bgcolor: COLORS.backgroundWhite,
                    },
                    ':focus': {
                        bgcolor: COLORS.white,
                    },
                }}
                MenuProps={{
                    sx: {
                        '& .MuiList-root': {
                            // Menu item list
                            border: `1px solid ${COLORS.grayNormal}`,
                        },
                        '&& .Mui-selected': {
                            // selected menu item
                            color: COLORS.white,
                            bgcolor: COLORS.textDark,
                            ':hover': {
                                bgcolor: COLORS.textDark,
                            },
                        },
                    },
                }}
                IconComponent={!isReadOnly && !isDisabled && ArrowDown ? ArrowDown : () => null}
            >
                {dataList &&
                    dataList.map(({ display, value }) => {
                        const shouldHideOption = filterDataList?.find((item) => item.value === value) !== undefined;
                        const hideOptionStyle = {
                            padding: '0px',
                            maxHeight: '0px',
                            overflow: 'hidden',
                        };

                        const optionStyle = {
                            color: COLORS.grayDark,
                            ':hover': {
                                bgcolor: COLORS.buttonBgWhite,
                            },
                        };

                        return (
                            <MenuItem
                                key={value}
                                value={value}
                                sx={shouldHideOption ? { ...hideOptionStyle, ...optionStyle } : optionStyle}
                            >
                                {display}
                            </MenuItem>
                        );
                    })}
            </Select>
        </FormControl>
    );
};

export { Dropdown as default, DropdownData };
