import ReportStyle from './ReportGenStyles';
import { GenPdfInfo } from '../../constants/constants';
import { IPdfType, IGenPdfIssueUpdate, IGenPdfSpare } from '../../interface/Report';
import { TDocumentDefinitions, PredefinedPageSize, Margins, PageOrientation } from 'pdfmake/interfaces';

import {
    getGenTime,
    getReportTitle,
    getReportDescription,
    getReportSubInfo,
    getPageNumber,
    getTableCaption,
} from './ReportGenInfo';
import getDetailsColData from './ReportGenDetail';
import getSparesAndToolings from './ReportGenSparesAndToolings';
import { detailLayout, issueUpdateLayout, sparesAndToolingsLayout } from './ReportGenLayout';
import { getIssueUpdateColLabel, getIssueUpdateColData } from './ReportGenIssueUpdate';

type IFilterIssueUpdateAndSpare = IGenPdfIssueUpdate | IGenPdfSpare;

const pageSize = 'A4' as PredefinedPageSize;
const pageOrientation = 'portrait' as PageOrientation;
const pageMargins = [24, 12, 24, 38] as Margins;
const defaultStyle = {
    font: 'CathaySans',
};

export const createPDF = (documentDefinition: TDocumentDefinitions) => {
    return new Promise((resolve, reject) => {
        import('pdfmake/build/pdfmake.min')
            .then((pdfMake) => {
                return import('./ReportGenFonts').then((pdfFonts) => {
                    pdfMake.default.vfs = pdfFonts.default;
                    pdfMake.default.fonts = {
                        CathaySans: {
                            normal: 'CathaySans-Regular.woff',
                            bold: 'CathaySans-Bold.woff',
                            italics: 'CathaySans-Light.woff',
                            bolditalics: 'CathaySans-Medium.woff',
                        },
                    };
                    return pdfMake.default;
                });
            })
            .then((pdfMake) => {
                pdfMake.createPdf(documentDefinition).getBlob((blob) => {
                    resolve(blob);
                });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getStatusReportBlob = (data?: IPdfType) => {
    return new Promise((resolve, reject) => {
        const { details, issueUpdateList = [], spares = [], hideIssueUpdateIds = [], hidePlanIds = [] } = data;
        import('pdfmake/build/pdfmake.min')
            .then((pdfMake) => {
                return import('./ReportGenFonts').then((pdfFonts) => {
                    pdfMake.default.vfs = pdfFonts.default;
                    pdfMake.default.fonts = {
                        CathaySans: {
                            normal: 'CathaySans-Regular.woff',
                            bold: 'CathaySans-Bold.woff',
                            italics: 'CathaySans-Light.woff',
                            bolditalics: 'CathaySans-Medium.woff',
                        },
                    };
                    return pdfMake.default;
                });
            })
            .then((pdfMake) => {
                const filteredIssueUpdateItems = filterIssueUpdateAndRecoveryPlanByIds(
                    issueUpdateList,
                    hideIssueUpdateIds
                ) as IGenPdfIssueUpdate[];
                const filteredSparesItems = filterIssueUpdateAndRecoveryPlanByIds(
                    spares,
                    hidePlanIds
                ) as IGenPdfSpare[];

                const tableIssueUpdate = {
                    style: 'tableUni',
                    layout: issueUpdateLayout,
                    table: {
                        headerRows: 2,
                        widths: ['10%', '50%', '20%', '20%'],
                        body: [
                            getTableCaption(GenPdfInfo.issueUpdateTitle, 4),
                            getIssueUpdateColLabel(),
                            ...getIssueUpdateColData(filteredIssueUpdateItems),
                        ],
                    },
                    margin: [0, 0, 0, 16],
                    columns: null,
                };
                const tableSparesAndToolings = {
                    style: 'tableUni',
                    layout: sparesAndToolingsLayout,
                    table: {
                        headerRows: 1,
                        widths: ['36%', '24%', '20%', '20%'],
                        body: [
                            getTableCaption(GenPdfInfo.SparesAndToolingsTitle, 4),
                            ...getSparesAndToolings(filteredSparesItems),
                        ],
                    },
                    margin: [0, 0, 0, 0],
                    columns: null,
                };

                const contentArray = [
                    getGenTime(),
                    getReportTitle(GenPdfInfo.title),
                    getReportSubInfo(details?.regNum, details?.location),
                    getReportDescription(details?.description, details?.isServiceable),
                    {
                        style: 'tableUni11',
                        layout: detailLayout,
                        table: {
                            headerRows: 1,
                            widths: ['25%', '25%', '25%', '25%'],
                            body: [getTableCaption(GenPdfInfo.detailTitle, 4), ...getDetailsColData(details)],
                        },
                        margin: [0, 0, 0, 16],
                    },
                ] as object[];
                issueUpdateList.length > 0 && contentArray.push(tableIssueUpdate);
                spares.length > 0 && contentArray.push(tableSparesAndToolings);

                const reportOptions = {
                    pageSize: pageSize,
                    styles: ReportStyle,
                    defaultStyle: defaultStyle,
                    footer: getPageNumber,
                    pageMargins: pageMargins,
                    pageOrientation: pageOrientation,
                    content: contentArray,
                } as TDocumentDefinitions;

                pdfMake.createPdf(reportOptions).getBlob((blob) => {
                    resolve(blob);
                });
            })
            .catch((error) => {
                console.log('create pdf error: ' + error);
                reject(error);
            });
    });
};

export const filterIssueUpdateAndRecoveryPlanByIds = (
    sourceData: IFilterIssueUpdateAndSpare[],
    filterIds: number[]
) => {
    return sourceData.filter((item) => {
        return !filterIds.includes(item.id);
    });
};
