import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import {
    getMaintBarHistory,
    getMaintBarEventsByLegNo,
    getAllGroundTimeTasks,
    getMaintenanceDueTolerance,
} from '../services/gantt';
import COLORS from '../style/color';
import { SimpleActionItem, FollowUp, LastStatusLog, RelatedActivity, AffectedDefect } from './coeEventSlice';
import { Groups } from './userProfileSlice';
import { ThumbnailItem } from './fileSlice';
import { Maintenance } from './maintenanceSlice';
import UserInfo from '../interface/UserInfo';
import { FlightObj } from './flightSlice';
import { SORT_FIELD, MaintenanceType } from '../constants/constants';
import { SortingItem } from '../components/common/Sort';
import { DateFormat, formatDefaultDisplay, timeStringToDateTime } from '../helper/dateHelper';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';

export const getSortedItems = ({ sort, list }: { sort: string; list: GroundTimeTask[] }) => {
    switch (sort) {
        case SORT_FIELD.completedStatus:
            return list;
        case SORT_FIELD.taskId: {
            return [...list].sort((a, b) => {
                if (a.cardNum === 'N/A') return 1;
                if (b.cardNum === 'N/A') return -1;

                return (
                    Number(/^[0-9]/.test(a.cardNum)) - Number(/^[0-9]/.test(b.cardNum)) ||
                    a.cardNum.localeCompare(b.cardNum)
                );
            });
        }
        case SORT_FIELD.latestDueDate: {
            return [...list].sort((a, b) => {
                const aEts = timeStringToDateTime(a.etsDueDate || '');
                const bEts = timeStringToDateTime(b.etsDueDate || '');

                if (aEts > bEts) return -1;
                if (aEts < bEts) return 1;

                if (a.cardNum === 'N/A') return 1;
                if (b.cardNum === 'N/A') return -1;

                return (
                    Number(/^[0-9]/.test(a.cardNum)) - Number(/^[0-9]/.test(b.cardNum)) ||
                    a.cardNum.localeCompare(b.cardNum)
                );
            });
        }
        case SORT_FIELD.earliestDueDate: {
            return [...list].sort((a, b) => {
                const aEts = timeStringToDateTime(a.etsDueDate || '');
                const bEts = timeStringToDateTime(b.etsDueDate || '');

                if (aEts > bEts) return 1;
                if (aEts < bEts) return -1;

                if (a.cardNum === 'N/A') return 1;
                if (b.cardNum === 'N/A') return -1;
                return (
                    Number(/^[0-9]/.test(a.cardNum)) - Number(/^[0-9]/.test(b.cardNum)) ||
                    a.cardNum.localeCompare(b.cardNum)
                );
            });
        }
        case SORT_FIELD.byPkgId: {
            return [...list].sort((a, b) => {
                if (!a.packageId || !b.packageId) {
                    if (a.cardNum === 'N/A') return 1;
                    if (b.cardNum === 'N/A') return -1;

                    return (
                        Number(/^[0-9]/.test(a.cardNum)) - Number(/^[0-9]/.test(b.cardNum)) ||
                        a.cardNum.localeCompare(b.cardNum)
                    );
                }
                if (a.packageId !== b.packageId) {
                    return (
                        Number(/^[0-9]/.test(a.packageId)) - Number(/^[0-9]/.test(b.packageId)) ||
                        a.packageId.localeCompare(b.packageId)
                    );
                }
                return 0;
            });
        }
        default:
            return list;
    }
};

export const aircraftStatusColorScheme = {
    green: {
        bg: COLORS.brightGray,
        color: COLORS.cathayJade,
        border: `1px solid ${COLORS.brightGray}`,
    },
    red: {
        bg: COLORS.summaryBgRed,
        color: COLORS.borderRed,
        border: `1px solid ${COLORS.summaryBgRed}`,
    },
    grey: {
        bg: COLORS.veryLightShadeGray,
        color: COLORS.grayDark,
        border: `1px solid ${COLORS.veryLightShadeGray}`,
    },
    amber: {
        bg: COLORS.lavenderLighten,
        color: COLORS.borderYellow,
        border: `1px solid ${COLORS.lavenderLighten}`,
    },
    na: {
        bg: COLORS.white,
        color: COLORS.grayDark,
        border: `1px solid ${COLORS.grayLight}`,
    },
};

export const domEventLogsStatusList = {
    OPEN: {
        color: COLORS.darkshadeYellow,
        bg: COLORS.white,
        context: 'Outstanding',
        border: `1px solid ${COLORS.grayLight}`,
    },
    COMP: {
        color: COLORS.cathayJade,
        bg: COLORS.brightGray,
        context: 'Completed',
        border: 'none',
    },
    WIP: {
        color: COLORS.borderYellow,
        bg: COLORS.lavenderLighten,
        context: 'WIP',
        border: 'none',
    },
    'O/S': {
        color: COLORS.darkshadeYellow,
        bg: COLORS.white,
        context: 'O/S',
        border: `1px solid ${COLORS.grayLight}`,
    },
    error: {
        bg: COLORS.white,
        border: `1px solid ${COLORS.white}`,
        context: '--',
        color: COLORS.grayDark,
    },
};

export const taskStatusList = {
    Completed: {
        color: COLORS.cathayJade,
        bg: COLORS.brightGray,
        border: 'none',
    },
    Deferred: {
        color: COLORS.grayDark,
        bg: COLORS.veryLightShadeGray,
        border: 'none',
    },
    Outstanding: {
        color: COLORS.darkshadeYellow,
        bg: COLORS.white,
        border: `1px solid ${COLORS.grayLight}`,
    },
    'Prev Complied': {
        color: COLORS.grayDark,
        bg: COLORS.veryLightShadeGray,
        border: 'none',
    },
    Replanned: {
        color: COLORS.grayDark,
        bg: COLORS.veryLightShadeGray,
        border: 'none',
    },
    Void: {
        color: COLORS.white,
        bg: COLORS.shadowGray,
        border: 'none',
    },
    WIP: {
        color: COLORS.borderYellow,
        bg: COLORS.white,
        border: 'none',
    },
    'ADD Raised/Remain': {
        color: COLORS.grayDark,
        bg: COLORS.veryLightShadeGray,
        border: 'none',
    },
    Withdrawn: {
        color: COLORS.white,
        bg: COLORS.shadowGray,
        context: 'Withdrawn',
        border: 'none',
    },
    error: {
        bg: COLORS.white,
        border: `1px solid ${COLORS.white}`,
        color: COLORS.grayDark,
        context: '--',
    },
};

export type TaskInfo = {
    acStatus?: {
        status: string;
        color: string;
    };
    packageStatus?: string;
    packageProgress?: number | string;
    packageId?: string | null;
    desc?: string;
    date?: string;
};

export type MaintenanceDetailsData = {
    name?: string;
    description?: string;
    remarks?: string;
    legNo?: string;
};

export type MaintenanceScheduleData = {
    legNo?: string;
    title?: string;
    type?: string;
    subtype?: string;
    reg?: string;
    stat?: string;
    maintStart?: string;
    maintEnd?: string;
    maintStartLocal?: string;
    maintEndLocal?: string;
    totalNumberOfMaint?: number;
    totalMaintDurationStart?: string;
    totalMaintDurationEnd?: string;
    totalMaintDurationStartLocal?: string;
    totalMaintDurationEndLocal?: string;
    maintDuration?: string;
    timeRemaining?: string;
    preGroundTime?: string;
    postGroundTime?: string;
    overlappMaintTime?: string;
    overlapTimeRange?: string;
};

export type MaintenancesData = {
    active: boolean;
    createdAt: string;
    createdBy: number;
    eventFrom: string;
    eventId: number;
    id: number;
    maintenance: Maintenance;
    relatedActivityId: number;
    relatedActivityType: string;
    relatedPackageId: string;
    state: string;
    ufi: string;
    updatedAt: string;
    updatedBy: number;
};

export type CheckReadinessData = {
    type: MaintenanceType | string;
    trafficLight: string;
    partNumber?: string;
    reason?: string;
};

export type GroundTimeTask = {
    aog?: boolean;
    cardNum: string;
    cardType?: string | null;
    defect?: {
        id: string;
        melReference: string;
        melCategory: string;
    } | null;
    domEventLogs?: DomEventLogObj[];
    externalWORef: string;
    finalBeginDate?: string | null;
    finalEndDate?: string | null;
    id?: string;
    manhoursAirframe?: string;
    manhoursAvionics?: string;
    etsDueDate?: string;
    package?: {
        id: string;
        earliestDueDate: string;
    } | null;
    packageId?: string | null;
    woStatus?: string;
    workContent?: string;
    woDisplayStatus?: string;
    eventIndicatorColor?: string;
    ets?: string;
    eta?: string;
    etd?: string;
    ata?: string | null;
    atd?: string | null;
    otp?: string | null;
    umWorkOrderId?: number;
    techArea?: string;
    relatedEvents?: any;
    checkReadiness?: CheckReadinessData[];
    crdTaskTrafficLight?: string;
    mtceCode?: string | null;
    updateCompTime?: string | null;
    crdIndicators?: string[];
    woNumberTitle: string;
};

export type DomEventLogObj = {
    category: string;
    comments: string;
    event: string;
    eventId: number;
    eventNum: string;
    externalWORef: string;
    id: string;
    status: string;
    partNum: string;
    quantity: string;
    createdDateTime: string;
};

export interface MaintBarEventsData {
    aogEvents: MaintBarEvents[];
    coeEvents: MaintBarEvents[];
    totalEvents: number;
}

export interface MaintBarEvents {
    ataChapter: string;
    atsTime: string;
    attachment: number[];
    craft: { aircraftType: string; subtype: string };
    createdAt: string;
    createdBy: UserInfo;
    defects: AffectedDefect[];
    description: string;
    endTime: string;
    etsTime: string;
    eventId: number;
    eventTasks: SimpleActionItem[];
    eventTitle: string;
    flights: FlightObj[];
    followUp: FollowUp[];
    groupId: number;
    id: string;
    initial: string;
    lastStatusLog: LastStatusLog[];
    liveNextFlight: any[];
    maintenances: MaintenancesData[];
    nextFlightDept: string;
    owner: Groups;
    procedures: any[];
    registration: string;
    relatedActivities: RelatedActivity[];
    startTime: string;
    state: string;
    subType: string;
    subTypeId: number;
    subtypeStatus: string;
    type: string;
    typeId: number;
    umPackages: any[];
    updatedAt: string;
    updatedBy: UserInfo;
    uploadFiles: ThumbnailItem[];
}

export type MaintBarHistoryData = {
    id: string;
    messageAction: string;
    legNo: number;
    whatIf: string;
    workPackage: string;
    airport: string;
    aircraft: string;
    name: string;
    seq: number;
    checkKind: string;
    start: string;
    end: string;
    carriedOut: string;
    checkComment: string;
    userId: string;
    timestamp: string;
    updateKey: string;
    updateNo: number;
    active: boolean;
    createdAt: string;
    updatedAt: string;
};

export type DueToleranceData = {
    maintFlightInfo: {
        remainingFlightInfo: {
            remainingMaintDaysUTC: string;
            remainingFlightHours: number;
            remainingFlightCycles: number;
        };
        dueFlightInfo: {
            dueMaintDateUTC: string;
            dueFlightHours: number;
            dueFlightCycles: number;
        };
    };
    totalFlightInfo: {
        totalFlightCycles: number;
        totalFlightHours: number;
    };
};

export type GroundTimeTaskObj = {
    domPackage: {
        endTimeUTC?: string | null;
        etsUTC?: string | null;
        startTimeUTC?: string | null;
        techArea: string | null;
    };
    maint: {
        id: string;
        messageAction: string;
        legNo: number;
        whatIf: string;
        workPackage: string | null;
        airport: string;
        aircraft: string;
        name: string;
        seq: number;
        checkKind: string;
        start: string;
        end: string;
        startLocal: string;
        endLocal: string;
        carriedOut: string;
        checkComment: string;
        userId: string;
        timestamp: string;
        updateKey: string;
        updateNo: number;
        updatedAt: string;
        createdAt: string;
    };
    techArea?: string | null;
    projectTasks: GroundTimeTask[];
    crdPackages: GroundTimeTask[];
    packageStatus: string;
    packageProgress: number;
    acStatus: {
        status: string;
        color: string;
    };
    etsUTC?: string | null;
    atsUTC?: string | null;
};

type FilterItem = {
    name: string;
    value: string;
};
interface MaintenanceTabsPanelSlice {
    isDetailLoading: boolean;
    maintenanceHistory: MaintBarHistoryData[] | null;
    maintenanceErrorObj: ApiErrorObj | null | undefined;
    maintenanceEventsErrorObj: ApiErrorObj | null | undefined;
    maintBarEvents: MaintBarEventsData | null;
    groundTimeTasksList: GroundTimeTask[];
    allProjectTasksList: GroundTimeTask[];
    groundTimeSortOptions: SortingItem[];
    taskInfo: TaskInfo;
    dueTolerance: DueToleranceData | null;
    taskCategoryFilterItems: FilterItem[];
    taskStatusFilterItems: FilterItem[];
}

const initialState: MaintenanceTabsPanelSlice = {
    isDetailLoading: false,
    maintenanceHistory: null,
    maintenanceErrorObj: null,
    maintenanceEventsErrorObj: null,
    maintBarEvents: null,
    groundTimeTasksList: [],
    allProjectTasksList: [],
    taskInfo: {},
    dueTolerance: null,
    groundTimeSortOptions: [
        {
            name: 'O/S to Completed status',
            value: SORT_FIELD.completedStatus,
            default: true,
        },
        // Hide sort function By the latest due date and By the earliest due date till we have due date info.
        // {
        //     name: 'By the latest due date',
        //     value: SORT_FIELD.latestDueDate,
        // },
        // {
        //     name: 'By the earliest due date',
        //     value: SORT_FIELD.earliestDueDate,
        // },
        {
            name: 'By PKG ID (A-Z)',
            value: SORT_FIELD.byPkgId,
        },
        {
            name: 'By Task ID (A-Z)',
            value: SORT_FIELD.taskId,
        },
    ],
    taskCategoryFilterItems: [],
    taskStatusFilterItems: [],
};

export const getAllGroundTimeTasksThunk = createAsyncThunk<
GroundTimeTaskObj,
{
    legNo: number;
    includeLogs: boolean;
    limitToPackage: boolean;
},
{ state: RootState; rejectValue: ApiErrorObj }
>(
    'maintenanceTabsPanel/getAllGroundTimeTasks',
    async ({ legNo, includeLogs, limitToPackage }, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/maintDetails/getAllGroundTimeTasks')) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await getAllGroundTimeTasks({
            legNo,
            includeLogs,
            limitToPackage,
        });
        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }
        return data as GroundTimeTaskObj;
    }
);

export const getMaintBarHistoryThunk = createAsyncThunk<
MaintBarHistoryData[],
{ legNo: number },
{ state: RootState; rejectValue: ApiErrorObj }
>('maintenanceTabsPanel/getMaintBarHistory', async ({ legNo }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/maintDetails/getMaintBarHistory')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [maintErr, data] = await getMaintBarHistory({ legNo });

    if (maintErr) {
        return rejectWithValue(maintErr as ApiErrorObj);
    }

    return data;
});

export const getMaintBarEventsByLegNoThunk = createAsyncThunk<
MaintBarEventsData,
{ legNo: number },
{ state: RootState; rejectValue: ApiErrorObj }
>('maintenanceTabsPanel/getMaintBarEventsByLegNo', async ({ legNo }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/maintDetails/getMaintBarEventsByLegNo')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [maintErr, data] = await getMaintBarEventsByLegNo({ legNo });

    if (maintErr) {
        return rejectWithValue(maintErr as ApiErrorObj);
    }

    return data;
});

export const getMaintenanceDueToleranceThunk = createAsyncThunk<
DueToleranceData,
{ legNo: number; registration: string },
{ state: RootState; rejectValue: ApiErrorObj }
>('maintenanceTabsPanel/getMaintenanceDueTolerance', async ({ legNo, registration }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/maintDetails/getMaintenanceDueTolerance')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [maintErr, data] = await getMaintenanceDueTolerance({ legNo, registration });

    if (maintErr) {
        return rejectWithValue(maintErr as ApiErrorObj);
    }
    return data as DueToleranceData;
});

export const maintenanceTabsPanelSlice = createSlice({
    name: 'maintenanceTabsPanel',
    initialState,
    reducers: {
        filterTaskList: (state, action) => {
            const filters = action.payload;
            const allProjectTasksList = state.allProjectTasksList;
            if (filters.length !== 0) {
                state.groundTimeTasksList = allProjectTasksList.reduce((arr: GroundTimeTask[], cur: GroundTimeTask) => {
                    const isCrdIndicators = cur.crdIndicators?.some((item) => filters.includes(item));
                    if (filters.includes(cur.cardType) || filters.includes(cur.woDisplayStatus) || isCrdIndicators) {
                        arr.push(cur);
                    }
                    return arr;
                }, []);
            } else {
                state.groundTimeTasksList = allProjectTasksList;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMaintBarHistoryThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.maintenanceErrorObj = null;
        });
        builder.addCase(getMaintBarHistoryThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.maintenanceErrorObj = payload;
            state.maintenanceHistory = null;
        });
        builder.addCase(getMaintBarHistoryThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.maintenanceErrorObj = null;
            state.maintenanceHistory = payload;
        });
        builder.addCase(getMaintBarEventsByLegNoThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.maintenanceEventsErrorObj = null;
        });
        builder.addCase(getMaintBarEventsByLegNoThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.maintBarEvents = payload;
            state.maintenanceEventsErrorObj = null;
        });
        builder.addCase(getMaintBarEventsByLegNoThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.maintenanceEventsErrorObj = payload;
        });
        builder.addCase(getMaintenanceDueToleranceThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.maintenanceEventsErrorObj = null;
        });
        builder.addCase(getMaintenanceDueToleranceThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.dueTolerance = payload;
            state.maintenanceEventsErrorObj = null;
        });
        builder.addCase(getMaintenanceDueToleranceThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.maintenanceEventsErrorObj = payload;
            state.dueTolerance = null;
        });
        builder.addCase(getAllGroundTimeTasksThunk.pending, (state) => {
            state.groundTimeTasksList = [];
            state.allProjectTasksList = [];
            state.taskInfo = {};
            state.isDetailLoading = true;
        });
        builder.addCase(getAllGroundTimeTasksThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.taskInfo = {};
            state.groundTimeTasksList = [];
            state.allProjectTasksList = [];
        });
        builder.addCase(getAllGroundTimeTasksThunk.fulfilled, (state, { payload, meta }) => {
            const { limitToPackage } = meta.arg || {};
            const hasDOMData = payload?.projectTasks?.length > 0;
            const useCRDPackagesData = !hasDOMData && limitToPackage;

            state.isDetailLoading = false;
            state.taskInfo = {
                packageProgress: payload.packageProgress,
                acStatus: payload.acStatus,
                packageStatus: payload.packageStatus ?? null,
                packageId: (payload.maint.workPackage as string) ?? null,
                date: payload.maint.updatedAt,
                desc: payload.maint.checkComment,
            };

            if (useCRDPackagesData) {
                state.groundTimeTasksList = payload?.crdPackages?.map((item) => {
                    const crdIndicators =
                        item?.checkReadiness?.map((type) => `${type.type}-${type.trafficLight}`) || [];
                    return {
                        ...item,
                        crdIndicators,
                        woNumberTitle: 'WO Number',
                    };
                });
            } else {
                state.groundTimeTasksList = payload?.projectTasks?.map((item) => {
                    const crdIndicators =
                        item?.checkReadiness?.map((type) => `${type.type}-${type.trafficLight}`) || [];
                    const domEventLogs = item?.domEventLogs
                        ?.sort((a, b) => a.id.localeCompare(b.id))
                        .map((domEventLog) => {
                            return {
                                ...domEventLog,
                                createdDateTime: formatDefaultDisplay(domEventLog?.createdDateTime),
                            };
                        });
                    const externalWORef = item?.externalWORef || '';
                    const woNumberTitle = externalWORef.indexOf('-') < 0 ? 'DOM Reference' : 'WO Number';
                    return {
                        ...item,
                        crdIndicators,
                        woNumberTitle,
                        etsDueDate: item?.package?.earliestDueDate || item?.ets,
                        package: {
                            ...item.package,
                            earliestDueDate: item?.package
                                ? formatDefaultDisplay(item?.package?.earliestDueDate, DateFormat.dateOnlyUTC)
                                : formatDefaultDisplay(item?.ets, DateFormat.dateOnlyUTC),
                        },
                        updateCompTime: item?.updateCompTime
                            ? formatDefaultDisplay(item?.updateCompTime, DateFormat.Hktformat)
                            : null,
                        domEventLogs,
                        checkReadiness: item?.checkReadiness,
                        crdTaskTrafficLight: item?.crdTaskTrafficLight,
                    } as GroundTimeTask;
                });
            }

            state.allProjectTasksList = state.groundTimeTasksList;

            const taskCategoryFilterItemsName: string[] = [];
            const taskStatusFilterItemsName: string[] = [];
            state.taskCategoryFilterItems = [];

            state.groundTimeTasksList &&
                state.groundTimeTasksList.forEach((item) => {
                    const { cardType } = item;
                    if (cardType && !taskCategoryFilterItemsName.includes(cardType)) {
                        state.taskCategoryFilterItems.push({
                            name: cardType,
                            value: cardType,
                        });
                        taskCategoryFilterItemsName.push(cardType);
                    }
                });

            state.taskStatusFilterItems =
                payload &&
                payload.projectTasks &&
                payload.projectTasks.reduce((arr: any, cur: GroundTimeTask) => {
                    if (cur.woDisplayStatus && !taskStatusFilterItemsName.includes(cur.woDisplayStatus)) {
                        arr.push({
                            name: cur.woDisplayStatus,
                            value: cur.woDisplayStatus,
                        });
                        taskStatusFilterItemsName.push(cur.woDisplayStatus);
                    }
                    return arr;
                }, []);
        });
    },
});

export const { filterTaskList } = maintenanceTabsPanelSlice.actions;

export const selectMaintenanceTabsPanel = (state: RootState) => state.maintenanceTabsPanel;

export default maintenanceTabsPanelSlice.reducer;
