import CathaySansRegular from '../assets/fonts/CathaySans-Regular.woff';
import CathaySansLight from '../assets/fonts/CathaySans-Light.woff';
import CathaySansBold from '../assets/fonts/CathaySans-Bold.woff';
import CathaySansMedium from '../assets/fonts/CathaySans-Medium.woff';

// mui theme style override does not support font face array:  https://github.com/mui-org/material-ui/issues/24966

const CATHAY_FONT_NAME = 'CathaySans';

const UNICODE_RANGE = 'U + 0000 - 00FF, U + 0131, U + 0152 - 0153, U + 02BB - 02BC, U + 02C6, U + 02DA, U + 02DC, U + 2000 - 206F, U + 2074, U + 20AC, U + 2122, U + 2191, U + 2193, U + 2212, U + 2215, U + FEFF';

const FONT_WEIGHT = {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700
};

export { CATHAY_FONT_NAME, FONT_WEIGHT, UNICODE_RANGE };

const lightFont = `{
    font-family: '${CATHAY_FONT_NAME}';
    font-style: 'normal';
    font-weight: ${FONT_WEIGHT.light};
    src: url(${CathaySansLight}) format('woff');
    unicodeRange: ${UNICODE_RANGE};
}`;

const regularFont = `{
    font-family: '${CATHAY_FONT_NAME}';
    font-style: 'normal';
    font-weight: ${FONT_WEIGHT.regular};
    src: url(${CathaySansRegular}) format('woff');
    unicodeRange: ${UNICODE_RANGE};
}`;

const mediumFont = `{
    font-family: '${CATHAY_FONT_NAME}';
    font-style: 'normal';
    font-weight: ${FONT_WEIGHT.medium};
    src: url(${CathaySansMedium}) format('woff');
    unicodeRange: ${UNICODE_RANGE};
}`;

const boldFont = `{
    font-family: '${CATHAY_FONT_NAME}';
    font-style: 'normal';
    font-weight: ${FONT_WEIGHT.bold};
    src: url(${CathaySansBold}) format('woff');
    unicodeRange: ${UNICODE_RANGE};
}`;

export { lightFont, regularFont, mediumFont, boldFont };
