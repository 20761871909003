import { Box } from '@mui/system';
import React from 'react';
import colors from '../../../../style/color';

type status = 'green' | 'yellow' | 'red';

export const statusColorMap = {
    green: colors.green,
    yellow: colors.amber,
    red: colors.error,
};

interface ColorDotProps {
    status?: status | string;
    color?: string;
}

const ColorDot = (props: ColorDotProps) => {
    const { status, color } = props;

    return (
        <Box
            sx={{
                height: '16px',
                width: '16px',
                borderRadius: '50%',
                bgcolor: statusColorMap[status?.toLocaleLowerCase()] || color || colors.grayLight,
            }}
        />
    );
};

export default ColorDot;
