import React, { createElement, forwardRef, useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SxProps, styled } from '@mui/system';
import { Box } from '@mui/material';

import InputField from './InputField';
import SearchButton from './SearchButon';
import { ReactComponent as Calendar } from '../../assets/icons/Calendar.svg';
import datePickerStyles from './DatePickerStyles';
import { DateFormat, getDateOnly } from '../../helper/dateHelper';

type DatePickerFieldProps = {
    label: string;
    value?: DateTime;
    hasError?: boolean;
    errorMessage?: string;
    minDate?: DateTime;
    inputClassName?: string;
    onChange?: (value: DateTime) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement> | DateTime) => void;
    maxDate?: DateTime;
    width?: string;
    sx?: SxProps;
    isReadOnly?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    onErrStateChange?: (valid: boolean) => void;
};

type DateRangePicker = {
    fromLabel?: string;
    toLabel?: string;
    minStartDate?: DateTime;
    maxEndDate?: DateTime;
    startDate?: DateTime;
    endDate?: DateTime;
    disableSearch?: boolean;
    onChangeStartDate?: (value: DateTime) => void;
    onChangeEndDate?: (value: DateTime) => void;
    searchClicked?: (startTime: DateTime, endTime: DateTime) => void;
    sx?: SxProps;
    isShowSearchButton?: boolean;
};

const ErrorTip = styled('p')({
    fontWeight: 400,
    color: '#D11F00',
    fontSize: '12px',
    paddingLeft: '4px',
    minWidth: '328px',
});

const getOffsetJSDate = (jsDate: Date, isAddOffset: boolean = true) => {
    if (!jsDate) {
        return null;
    }

    const offset = isAddOffset ? jsDate.getTimezoneOffset() : -jsDate.getTimezoneOffset();
    const offsetDate = new Date();
    offsetDate.setTime(jsDate.getTime() + offset * 60000);
    return offsetDate;
};

const DatePickerField = (props: DatePickerFieldProps) => {
    const {
        label,
        value = null,
        hasError = false,
        errorMessage = '',
        minDate = null,
        inputClassName = '',
        onChange,
        onBlur,
        maxDate = null,
        width,
        sx,
        isReadOnly = false,
        isDisabled = false,
        onErrStateChange,
        isClearable,
    } = props;

    const validateDate = (date: DateTime) => {
        return date === null || (date.isValid && dateMin <= date && date <= dateMax);
    };

    // obtain the timezone offset from value > minDate > maxDate, default 0
    const offset =
        value && value.isValid
            ? value.offset
            : minDate && minDate.isValid
                ? minDate.offset
                : maxDate && maxDate.isValid
                    ? maxDate.offset
                    : 0;
    const [selectedDate, setSelectedDate] = useState<DateTime | null>(value ? getDateOnly(value) : value);
    const [dateMin, setDateMin] = useState<DateTime | null>(getDateOnly(minDate || DateTime.fromISO('1990-09-09')));
    const [dateMax, setDateMax] = useState<DateTime | null>(getDateOnly(maxDate || DateTime.fromISO('9999-12-12')));
    const [dateError, setDateError] = useState(!validateDate(selectedDate));

    useEffect(() => {
        if (value) {
            setSelectedDate(value.isValid ? getDateOnly(value) : selectedDate);
        } else {
            setSelectedDate(null);
        }
    }, [value]);

    useEffect(() => {
        setDateError(!validateDate(selectedDate));
    }, [selectedDate]);

    useEffect(() => {
        if (minDate && minDate.isValid) {
            setDateMin(getDateOnly(minDate));
        }
    }, [minDate]);

    useEffect(() => {
        if (maxDate && maxDate.isValid) {
            setDateMax(getDateOnly(maxDate));
        }
    }, [maxDate]);

    useEffect(() => {
        onErrStateChange && onErrStateChange(dateError);
    }, [dateError]);

    const changeDateValue = (date: Date) => {
        const newSelectedDate = date
            ? getDateOnly(DateTime.fromJSDate(getOffsetJSDate(date, false)).toUTC(offset))
            : null;
        setSelectedDate(newSelectedDate);
        onChange && onChange(newSelectedDate);
        onBlur && onBlur(newSelectedDate);
        setDateError(!validateDate(newSelectedDate));
    };
    /**
     * ref is defined here to suppress the warning:
     * Warning: forwardRef render functions accept exactly two parameters: props and ref. Did you forget to use the ref parameter?
     */
    const CustomInput = ({ onChange, placeholder, value, onClick }, ref) => {
        const checkIsValidDate = (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
            const elem = e.target as HTMLInputElement;
            /**
             * DateTime parsed in local timezone, make sure DateTimes are validated in same timezone
             */
            const dateTime = DateTime.fromFormat(elem.value, DateFormat.DateDisplayFormatWithoutTime, {
                zone: selectedDate ? selectedDate.zoneName : 'utc',
            });
            const isWithinValidDateRange = dateTime <= dateMax && dateTime >= dateMin;
            if (selectedDate === null || (selectedDate.isValid && isWithinValidDateRange)) {
                onChange(e);
                setDateError(false);
            } else {
                // turn back to defaultValue if the typed text is not a date
                setDateError(true);
                setSelectedDate(
                    elem.defaultValue.length === 0
                        ? null
                        : DateTime.fromFormat(elem.defaultValue, DateFormat.DateDisplayFormatWithoutTime, {
                            zone: selectedDate ? selectedDate.zoneName : 'utc',
                        })
                );
                if (isClearable && elem.value === '') {
                    onChange(e);
                    setSelectedDate(null);
                    setDateError(false);
                }
            }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                checkIsValidDate(e);
            }
        };

        return (
            <InputField
                label={placeholder}
                defaultInputValue={value}
                onBlur={checkIsValidDate}
                onClick={onClick}
                onKeyDown={handleKeyDown}
                prefixElem={<Calendar />}
                hasError={dateError || hasError}
                onChange={() => {}}
                showErrorMessage={false}
                className={inputClassName}
                isReadOnly={isReadOnly}
                isDisabled={isDisabled}
            />
        );
    };

    return (
        <Box
            sx={{
                ...datePickerStyles,
                ...sx,
                width,
            }}
        >
            <DatePicker
                selected={selectedDate && selectedDate.isValid && selectedDate.toJSDate()}
                onChange={changeDateValue}
                placeholderText={label}
                customInput={createElement(forwardRef(CustomInput))}
                minDate={dateMin.toJSDate()}
                dateFormat='ddMMMyy'
                calendarStartDay={1}
                dateFormatCalendar='MMM yyyy'
                maxDate={dateMax.toJSDate()}
                readOnly={isReadOnly || isDisabled}
            />
            {hasError && errorMessage && (
                <ErrorTip className='error-warning' style={{}}>
                    {errorMessage}
                </ErrorTip>
            )}
        </Box>
    );
};

const DateRangePickerField = (props: DateRangePicker) => {
    const {
        fromLabel = 'Day (UTC)',
        toLabel = 'Day (UTC)',
        minStartDate,
        maxEndDate,
        startDate,
        endDate,
        disableSearch,
        onChangeStartDate,
        onChangeEndDate,
        searchClicked,
        sx,
        isShowSearchButton = true,
    } = props;

    const [dateRange, setDateRange] = useState({
        startDate: startDate,
        endDate: endDate,
    });

    const [startDateErr, setStartDateErr] = useState(false);
    const [endDateErr, setIsEndDateErr] = useState(false);

    useEffect(() => {
        if (!(startDate && startDate.isValid && endDate && endDate.isValid)) return;
        if (
            dateRange?.startDate?.valueOf() === startDate.valueOf() &&
            dateRange?.endDate?.valueOf() === endDate.valueOf()
        )
            return;
        setDateRange({
            startDate: startDate,
            endDate: endDate,
        });
    }, [startDate, endDate]);

    return (
        <Box
            sx={{
                display: 'flex',
                '& .btn-search': {
                    marginLeft: 0,
                },
                '& .input-container': {
                    marginRight: 0,
                },
                '& > div + div .input-container': {
                    marginLeft: 0,
                },
                '& > div + div .input-container .end-date-picker': {
                    marginLeft: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                },
                ...sx,
            }}
        >
            <DatePickerField
                label={fromLabel}
                value={startDate}
                minDate={minStartDate}
                maxDate={dateRange.endDate}
                inputClassName='start-date-picker'
                onChange={(dateTime: DateTime) => {
                    setDateRange({
                        ...dateRange,
                        startDate: dateTime,
                    });
                    onChangeStartDate && onChangeStartDate(dateTime);
                }}
                onErrStateChange={(state: boolean) => setStartDateErr(state)}
            />
            <DatePickerField
                label={toLabel}
                value={endDate}
                minDate={dateRange.startDate}
                maxDate={maxEndDate}
                inputClassName='end-date-picker'
                onChange={(dateTime: DateTime) => {
                    setDateRange({
                        ...dateRange,
                        endDate: dateTime,
                    });
                    onChangeEndDate && onChangeEndDate(dateTime);
                }}
                onErrStateChange={(state: boolean) => setIsEndDateErr(state)}
            />
            {isShowSearchButton && (
                <SearchButton
                    onClick={() => searchClicked(dateRange.startDate, dateRange.endDate)}
                    isDisabled={disableSearch || startDateErr || endDateErr}
                />
            )}
        </Box>
    );
};

export { DatePickerField as default, DateRangePickerField };
