import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSubfleetSummary, getSubfleetAircraftPerformance } from '../services/gantt';
import { RootState } from '../app/store';
import { ApiErrorObj } from '../services/ServerError';

export type SubfleetSummaryData = {
    reg?: string;
    totalActiveAircraft?: number;
    preferences?: SubfleetSummaryPreferences[];
};

export type SubfleetAircraftPerformanceData = {
    reg?: string;
    performances?: SubfleetSummaryPreformances[];
};

type PerformanceFactorList = {
    registration?: string;
    basicWeight?: number;
    perf?: number;
    payloadCapacity?: number;
};

type SubfleetSummaryPreferences = {
    fleetType?: string;
    lastUpdated?: string;
    displayUpdated?: string;
    performanceFactorList?: PerformanceFactorList[];
    aircraftInList?: number;
};

type SubfleetSummaryPreformances = {
    fleetType?: string;
    lastUpdated?: string;
    displayUpdated?: string;
    performanceFactorList?: PerformanceFactorList[];
    aircraftInList?: number;
};

interface SubfleetTabsPanelSlice {
    isDetailLoading: boolean;
    subfleetSummary: SubfleetSummaryData;
    subfleetAircraftPerformance: SubfleetAircraftPerformanceData;
    subfleetErrorObj: ApiErrorObj;
}

const initialState: SubfleetTabsPanelSlice = {
    isDetailLoading: false,
    subfleetSummary: null,
    subfleetAircraftPerformance: null,
    subfleetErrorObj: null,
};

export const getSubfleetSummaryThunk = createAsyncThunk<
SubfleetSummaryData,
{ reg: string },
{ rejectValue: ApiErrorObj }
>('subfleetTabsPanel/getSummary', async ({ reg }, { rejectWithValue }) => {
    const [err, data] = await getSubfleetSummary({
        reg,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { reg, ...data };
});

export const getSubfleetAircraftPerformanceThunk = createAsyncThunk<
SubfleetAircraftPerformanceData,
{ reg: string },
{ rejectValue: ApiErrorObj }
>('subfleetTabsPanel/getAircraftPerformance', async ({ reg }, { rejectWithValue }) => {
    const [err, data] = await getSubfleetAircraftPerformance({
        reg,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { reg, performances: data };
});

export const subfleetTabsPanelSlice = createSlice({
    name: 'subfleetTabsPanelSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSubfleetSummaryThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.subfleetErrorObj = null;
        });
        builder.addCase(getSubfleetSummaryThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.subfleetErrorObj = payload;
        });
        builder.addCase(getSubfleetSummaryThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.subfleetSummary = payload;
            state.subfleetErrorObj = null;
        });
        builder.addCase(getSubfleetAircraftPerformanceThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.subfleetErrorObj = null;
        });
        builder.addCase(getSubfleetAircraftPerformanceThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.subfleetErrorObj = payload;
        });
        builder.addCase(getSubfleetAircraftPerformanceThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.subfleetErrorObj = null;
            state.subfleetAircraftPerformance = payload;
        });
    },
});

export const selectSubfleetTabsPanel = (state: RootState) => state.subfleetTabsPanel;

export default subfleetTabsPanelSlice.reducer;
