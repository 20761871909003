import { TDocumentDefinitions, PredefinedPageSize, Margins, PageOrientation } from 'pdfmake/interfaces';
import { DateTime } from 'luxon';

import { createPDF } from './ReportGenBase';
import { CoePdfStyle } from './ReportGenStyles';
import { DateFormat, formatDefaultDisplay, Zone } from '../../helper/dateHelper';
import { CoePdf } from '../../helper/eventListHelper';
import COLORS from '../../style/color';
import { cathayLogo } from './constants';

const pageSize = 'A4' as PredefinedPageSize;
const pageOrientation = 'portrait' as PageOrientation;
const pageMargins = [24, 24, 24, 38] as Margins;
const defaultStyle = {
    font: 'CathaySans',
};

const getHeader = ({ eventId, exportDate }: { eventId: string; exportDate: string }) => {
    return {
        columns: [
            {
                text: `${eventId}`,
                style: 'header',
            },
            {
                svg: cathayLogo,
                alignment: 'center',
            },
            {
                text: `Export date: ${exportDate}`,
                alignment: 'right',
                style: 'header',
            },
        ],
    };
};

const pageDivider = ({ x1, x2, y1, y2 }: { x1: number; x2: number; y1: number; y2: number }) => {
    return { canvas: [{ type: 'line', x1, y1, x2, y2, lineWidth: 0.5 }] };
};

const row = ({ data, style }: { data: string[]; style?: any }) => {
    const final = data.map((item) => {
        return {
            text: `${item}`,
            style,
        };
    });
    return {
        columns: final,
    };
};

const getPageNumberWithDivider = (currentPage: any, pageCount: string | number) => {
    return {
        columns: [
            {
                fontSize: 8,
                text: [
                    {
                        text:
                            '_____________________________________________________________________________________________________________' +
                            '\n',
                        fontSize: 10,
                    },
                    {
                        text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                    },
                ],
                alignment: 'center',
            },
        ],
    };
};

const createCoeEventPDF = async (events: CoePdf[], withActionItems: boolean) => {
    const content = [];

    events.forEach((event, index) => {
        const {
            eventId,
            registration,
            startTimeDisplay,
            etsTimeDisplay,
            type,
            subType,
            nextFlightDept,
            eventTitle,
            eventStatusDisplay,
            statusRemarks,
            techArea,
            ataChapterDisplay,
            owner,
            partNumber,
            description,
            relatedActivityType,
            relatedActivityValue,
            additionalNotes,
            commodity,
            mtceCode,
            isAog,
            eventTasks,
        } = event;
        const exportDate =
            formatDefaultDisplay(DateTime.now().toUTC().toISO(), DateFormat.DateDisplayFormatWithoutTime, Zone.hkt) ||
            '--';

        if (index > 0) {
            content.push({ text: '', pageBreak: 'before' });
        }
        content.push(getHeader({ eventId, exportDate }));
        content.push({ text: '', style: 'topMargin' });
        content.push(pageDivider({ x1: 0, x2: 595 - 48, y1: 5, y2: 5 }));
        content.push('\n');
        content.push(
            row({
                data: ['Registration', 'Start Date & Time (UTC)', 'Est End Date & Time (UTC)'],
                style: 'title',
            })
        );
        content.push({
            columns: [
                { text: registration || '--', style: 'detailBold', color: isAog ? COLORS.borderRed : COLORS.grayDark },
                { text: startTimeDisplay || '--', style: 'detailBold' },
                { text: etsTimeDisplay || '--', style: 'detailBold' },
            ],
        });
        content.push(row({ data: ['Event Type', 'Event Subtype', 'Next Flight Dept (UTC)'], style: 'title' }));
        content.push(row({ data: [type || '--', subType || '--', nextFlightDept || '--'], style: 'detail' }));
        content.push(row({ data: [eventTitle || '--'], style: 'detailBold' }));
        content.push({
            text: ['Event Status ', { text: `${eventStatusDisplay}`, style: 'detailBold' }],
            style: 'title',
        });
        content.push(row({ data: [statusRemarks || '--'], style: 'detail' }));
        content.push(row({ data: ['Tech Area', 'ATA No.', 'Owner'], style: 'title' }));
        content.push(row({ data: [techArea || '--', ataChapterDisplay || '--', owner || '--'], style: 'detail' }));
        content.push(row({ data: ['PN / QTY', '', 'Commodity'], style: 'title' }));
        content.push(row({ data: [partNumber, '', commodity || '--'], style: 'detail' }));
        content.push(row({ data: ['Short Description'], style: 'title' }));
        content.push(row({ data: [description || '--'], style: 'detail' }));
        content.push(row({ data: [`Related activity: ${relatedActivityType}`], style: 'title' }));
        content.push(row({ data: [relatedActivityValue], style: 'detail' }));
        content.push(row({ data: ['MTCE Code'], style: 'title' }));
        content.push(row({ data: [mtceCode || '--'], style: 'detail' }));
        content.push(pageDivider({ x1: 175, x2: 175 + 165, y1: 5, y2: 5 }));
        content.push({ text: '', style: 'topMargin' });
        content.push(
            row({
                data: ['Event (Addition Notes)'],
                style: 'title',
            })
        );
        content.push({ text: '', style: 'topMargin' });
        if (additionalNotes?.length !== 0) {
            additionalNotes?.forEach((item) => {
                content.push(row({ data: [item.note], style: 'noteTitle' }));
                content.push(row({ data: [item.userInfo], style: 'noteUserInfo' }));
            });
        } else {
            content.push(row({ data: ['--'], style: 'noteTitle' }));
        }
        if (withActionItems && eventTasks?.length !== 0) {
            eventTasks.forEach((actionItem, index) => {
                const {
                    id,
                    details,
                    startTimeDisplay,
                    etsTimeDisplay,
                    status,
                    statusRemarks: actionItemStatusRemarks,
                    additionalNotes: actionItemsAdditionalNotes,
                } = actionItem;
                const { owner, title, type } = details;

                content.push(pageDivider({ x1: 0, x2: 595 - 48, y1: 5, y2: 5 }));
                content.push({ text: '', style: 'topMargin' });
                content.push(
                    row({
                        data: ['Action Item', '', 'Assignee'],
                        style: 'title',
                    })
                );
                content.push(row({ data: [`ID ${id}`, '', owner?.displayName || '--'], style: 'detail' }));
                content.push(
                    row({
                        data: ['Action Item Type', 'Start Date & Time (UTC)', 'Est End Date & Time (UTC)'],
                        style: 'title',
                    })
                );

                content.push({
                    columns: [
                        { text: `${type}`, style: 'detailBold' },
                        { text: startTimeDisplay || '--', style: 'detailBold' },
                        { text: etsTimeDisplay || '--', style: 'detailBold' },
                    ],
                });
                content.push({
                    columns: [{ text: `${title}`, style: 'detailBold' }],
                });

                content.push({
                    text: ['Action Item Status ', { text: `${status}`, style: 'detailBold' }],
                    style: 'title',
                });
                content.push(row({ data: [actionItemStatusRemarks || '--'], style: 'detail' }));
                content.push(pageDivider({ x1: 175, x2: 175 + 165, y1: 5, y2: 5 }));
                content.push({ text: '', style: 'topMargin' });
                content.push(
                    row({
                        data: ['Action Item (Addition Notes)'],
                        style: 'title',
                    })
                );
                content.push({ text: '', style: 'topMargin' });
                if (actionItemsAdditionalNotes?.length !== 0) {
                    actionItemsAdditionalNotes?.forEach((item) => {
                        content.push(row({ data: [item.note], style: 'noteTitle' }));
                        content.push(row({ data: [item.userInfo], style: 'noteUserInfo' }));
                    });
                } else {
                    content.push(row({ data: ['--'], style: 'noteTitle' }));
                }
            });
        }
    });

    const reportOptions = {
        pageSize,
        styles: CoePdfStyle,
        defaultStyle: { ...defaultStyle, columnGap: 5 },
        footer: getPageNumberWithDivider,
        pageMargins,
        pageOrientation,
        content,
    } as TDocumentDefinitions;

    return await createPDF(reportOptions);
};

export { createCoeEventPDF };
