import React from 'react';
import { Checkbox, Tooltip, FormControlLabel, TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';

import { ReactComponent as CxCheckbox } from '../../assets/icons/Checkbox.svg';
import { ReactComponent as CheckboxSelected } from '../../assets/icons/CheckboxSelected.svg';
import { ReactComponent as CheckboxIndeterminate } from '../../assets/icons/CheckboxIndeterminate.svg';
import { ReactComponent as DisabledCheckbox } from '../../assets/icons/DisabledCheckbox.svg';
import { ReactComponent as DisabledCheckboxSelected } from '../../assets/icons/DisabledCheckboxSelected.svg';
import theme from '../../style/theme';
import colors from '../../style/color';

type CheckBoxProps = {
    sx?: SxProps;
    size?: string;
    label?: string;
    checked: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type CheckBoxWithLabelProps = {
    label: string;
    checked?: boolean;
    disabled?: boolean;
    sx?: SxProps;
    typographyProps?: TypographyProps;
    onChange?: (checked: boolean) => void;
};

const StyledCheckbox = (props: CheckBoxProps) => {
    const { label, checked, disabled = false, sx, size, onChange, indeterminate } = props;
    const checkBoxSize = size || '21px';

    return (
        <Tooltip title={label ? label : ''}>
            <Checkbox
                onChange={onChange}
                sx={{
                    width: checkBoxSize,
                    height: checkBoxSize,
                    marginRight: '8px',
                    padding: '0px',
                    color: 'gray.light',
                    '&.Mui-checked': {
                        color: theme.palette.cathayJade.main,
                    },
                    ...sx,
                }}
                size='small'
                icon={
                    disabled ? (
                        <DisabledCheckbox height={checkBoxSize} width={checkBoxSize} />
                    ) : (
                        <CxCheckbox height={checkBoxSize} width={checkBoxSize} />
                    )
                }
                checkedIcon={
                    disabled ? (
                        <DisabledCheckboxSelected height={checkBoxSize} width={checkBoxSize} />
                    ) : (
                        <CheckboxSelected height={checkBoxSize} width={checkBoxSize} />
                    )
                }
                indeterminateIcon={<CheckboxIndeterminate />}
                checked={checked}
                disabled={disabled}
                indeterminate={indeterminate}
            />
        </Tooltip>
    );
};

export const StyledCheckboxWithLabel = ({
    label,
    checked,
    disabled = false,
    sx,
    typographyProps,
    onChange,
}: CheckBoxWithLabelProps) => {
    return (
        <FormControlLabel
            sx={{
                ml: '0px',
                '& .Mui-disabled': {
                    color: colors.shadowGray,
                },
                ...sx,
            }}
            checked={checked}
            control={
                <Checkbox
                    sx={{
                        width: '21px',
                        height: '21px',
                        marginRight: '8px',
                        padding: '0px',
                        color: 'gray.light',
                        '&.Mui-checked': {
                            color: theme.palette.cathayJade.main,
                        },
                    }}
                    size='small'
                    icon={disabled ? <DisabledCheckbox /> : <CxCheckbox />}
                    checkedIcon={disabled ? <DisabledCheckboxSelected /> : <CheckboxSelected />}
                    disabled={disabled}
                />
            }
            label={label}
            disabled={disabled}
            onChange={(event, checked) => {
                onChange && onChange(checked);
            }}
            componentsProps={{ typography: { variant: 'body7', ...typographyProps } }}
        />
    );
};

export default StyledCheckbox;
