import React, { useEffect, useState } from 'react';
import { SxProps } from '@mui/material';

import AutoCompleteDropdown, { AutoCompleteOption } from '../../AutoCompleteDropdown';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectEvent, getRegNoListThunk } from '../../../../slices/eventSlice';

interface RegistrationProps {
    label: string;
    defaultValue?: string;
    value?: string | AutoCompleteOption;
    sx?: SxProps;
    onChange?: (option: AutoCompleteOption) => void;
    isDisabled?: boolean;
}

const Registration = (props: RegistrationProps) => {
    const { label, defaultValue, sx, value, onChange, isDisabled } = props;

    const dispatch = useAppDispatch();
    const { regOptions } = useAppSelector(selectEvent);
    const [selectedValue, setSelectedValue] = useState<AutoCompleteOption>();

    const filterOptions = (
        options: AutoCompleteOption[],
        { inputValue }: { inputValue: any }
    ): AutoCompleteOption[] => {
        const searchKey = inputValue.toUpperCase().replace(/^(B|EI)-/, '$1');
        return options.filter((option: AutoCompleteOption, index: number, self: AutoCompleteOption[]) => {
            if (!option || self.indexOf(option) !== index) {
                return false;
            }
            return option.value.match(searchKey) !== null;
        });
    };

    useEffect(() => {
        if (Array.isArray(regOptions) && !selectedValue && defaultValue) {
            const defaultOption = regOptions.find((item) => item.value === defaultValue);
            setSelectedValue(defaultOption);
        }
    }, [regOptions]);

    useEffect(() => {
        if (!value) setSelectedValue(null);

        if (typeof value === 'string' && value !== selectedValue?.value)
            setSelectedValue(regOptions.find((item) => item.value === value) || null);

        if ((value as AutoCompleteOption)?.value !== selectedValue?.value)
            setSelectedValue(value as AutoCompleteOption);
    }, [value, regOptions]);

    useEffect(() => {
        if (!regOptions || regOptions.length <= 0) {
            dispatch(getRegNoListThunk());
        }
    }, []);

    return (
        <AutoCompleteDropdown
            label={label}
            options={regOptions}
            value={value}
            sx={sx}
            filterOptions={filterOptions}
            onChange={(option) => {
                if (option) {
                    setSelectedValue(option);
                    onChange?.(option);
                }
            }}
            isDisabled={isDisabled}
        />
    );
};

export default Registration;
