import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { getCrdRulesList, createTrigger, getTriggerById, getAircraftTypes } from '../services/crdRules';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import UserInfo from '../interface/UserInfo';

export const COE_TRIGGER_LIST_DEFAULT_PAGE = 1;
export const COE_TRIGGER_LIST_DEFAULT_PAGE_SIZE = 50;

export interface FilterAndSortOptions {
    keyWords?: string;
    acType?: string[];
    owner?: number[];
    mtceCode?: string;
    ataChapter?: string[];
    page: number;
    size: number;
}

export interface COECrdRuleData {
    id: number;
    active: boolean;
    acType: string;
    taskId: string;
    daysBeforePkgStart: number;
    ataChapter: string;
    title: string;
    description: string;
    commodity: string;
    mtceCode: string;
    partNumber: string[];
    owner: {
        id: number;
        group: string;
    };
    createdAt: string;
    updatedAt: string;
    updatedBy: UserInfo;
    createdBy: UserInfo;
}

interface COECrdRulesSlice {
    rulesList: { total: number; crdRules: COECrdRuleData[] } | null;
    isCrdRulesLoading: boolean;
    coeCrdRulesListFilters: FilterAndSortOptions;
    selectedTrigger?: COECrdRuleData | null;
    aircraftFilterOptions: string[];
}

interface AircraftType {
    acType: string;
}

export interface CreateTriggerObj {
    id?: number;
    acType: string;
    taskId: string;
    daysBeforePkgStart: number;
    ataChapter: string;
    mtceCode: string;
    title: string;
    description: string;
    commodity: string;
    partNumber: string[];
    active?: boolean; // <-- only set false when deleting crd rule
    owner: number;
}

export const defaultFilter = {
    keyWords: '',
    acType: [],
    owner: [],
    mtceCode: '',
    ataChapter: [],
    page: COE_TRIGGER_LIST_DEFAULT_PAGE,
    size: COE_TRIGGER_LIST_DEFAULT_PAGE_SIZE,
};

export const getTriggerByIdThunk = createAsyncThunk<
COECrdRuleData,
{
    ruleId: number;
},
{ state: RootState; rejectValue: ApiErrorObj }
>('/crd/rule', async ({ ruleId }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/crd/rule', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getTriggerById({ ruleId });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data as COECrdRuleData;
});

export const getCrdRulesListThunk = createAsyncThunk<
{ total: number; crdRules: COECrdRuleData[] },
FilterAndSortOptions,
{ state: RootState; rejectValue: ApiErrorObj }
>('/coeCrdRules/rules/filter', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/crd/rules/filter')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getCrdRulesList(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as { total: number; crdRules: COECrdRuleData[] };
});

export const getAircraftTypesThunk = createAsyncThunk<
AircraftType[],
void,
{ state: RootState; rejectValue: ApiErrorObj }
>('/crd/getAircraftTypes', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/crd/rules/filter')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getAircraftTypes();

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as AircraftType[];
});

export const saveTriggerThunk = createAsyncThunk<
{ crdRules: COECrdRuleData[] },
CreateTriggerObj,
{ state: RootState; rejectValue: ApiErrorObj }
>('/crd/createTrigger', async (param, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/crd/rules', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await createTrigger({ param });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

const initialState: COECrdRulesSlice = {
    rulesList: null,
    isCrdRulesLoading: false,
    coeCrdRulesListFilters: defaultFilter,
    selectedTrigger: null,
    aircraftFilterOptions: [],
};

export const coeCrdRulesSlice = createSlice({
    name: 'coeCrdRules',
    initialState,
    reducers: {
        updateCoeCrdRulesListFilters: (state, action: PayloadAction<Partial<FilterAndSortOptions>>) => {
            state.coeCrdRulesListFilters = { ...state.coeCrdRulesListFilters, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCrdRulesListThunk.pending, (state) => {
                state.isCrdRulesLoading = true;
            })
            .addCase(getCrdRulesListThunk.fulfilled, (state, { payload }) => {
                state.isCrdRulesLoading = false;
                if (JSON.stringify(state.rulesList) === JSON.stringify(payload)) {
                    return state;
                }
                if (state.coeCrdRulesListFilters.page === 1 || payload?.crdRules?.length !== 0) {
                    state.rulesList = payload;
                }
            })
            .addCase(getCrdRulesListThunk.rejected, (state) => {
                state.isCrdRulesLoading = false;
            })
            .addCase(saveTriggerThunk.pending, (state) => {
                state.isCrdRulesLoading = true;
            })
            .addCase(saveTriggerThunk.fulfilled, (state, { payload }) => {
                state.isCrdRulesLoading = false;
            })
            .addCase(saveTriggerThunk.rejected, (state, { payload }) => {
                state.isCrdRulesLoading = false;
            })
            .addCase(getTriggerByIdThunk.pending, (state) => {
                state.isCrdRulesLoading = true;
            })
            .addCase(getTriggerByIdThunk.fulfilled, (state, { payload }) => {
                state.isCrdRulesLoading = false;
                state.selectedTrigger = payload;
            })
            .addCase(getTriggerByIdThunk.rejected, (state, { payload }) => {
                state.isCrdRulesLoading = false;
            })
            .addCase(getAircraftTypesThunk.pending, (state) => {
                state.isCrdRulesLoading = true;
            })
            .addCase(getAircraftTypesThunk.fulfilled, (state, { payload }) => {
                state.isCrdRulesLoading = false;
                state.aircraftFilterOptions = payload?.map((i) => i.acType) || [];
            })
            .addCase(getAircraftTypesThunk.rejected, (state, { payload }) => {
                state.isCrdRulesLoading = false;
            });
    },
});

const selectCoeCrdRules = (state: RootState) => state.coecrdrules;

export default coeCrdRulesSlice.reducer;

const { updateCoeCrdRulesListFilters } = coeCrdRulesSlice.actions;

export { selectCoeCrdRules, updateCoeCrdRulesListFilters };
