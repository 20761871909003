import { sendHttpRequest } from './api';
import {
    HTTP_REQUEST_TYPE,
    HTTP_RESPONSE_TYPE,
    EVENTS_API,
    EVENT_TASK_API,
    ACTION_ITEM_DETAIL_API,
    REPORT_API,
} from '../constants/api';
import { Event, EventStatusUpdate, IocEventListFilters } from '../slices/eventSlice';
import { DeclareAogInput, LogSubmitContent } from '../interface/MCLog';
import { ActionItem, Event as COEEvent, FilterAndSortOptions } from '../slices/coeEventSlice';
import { TaskType } from '../constants/constants';
import { EventListFilters, EventListShiftViewRequestBody } from '../interface/MCEventList';
import { HandoverReportRequestParams } from '../interface/HandoverReport';
import { EventReportRequestParams } from '../interface/MCExportEvent';

const getEventSetting = async (headers?: any) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.setting, null, { headers });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const createEvent = async ({ param, headers }: { param: Event | COEEvent; headers?: any }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.event, param, {
        headers,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const reopenEvent = async ({ eventId }: { eventId: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.reopenEvent, {
        eventId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const cancelEvent = async ({ eventId }: { eventId: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.cancelEvent, {
        eventId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const closeEvent = async ({
    eventId,
    userId,
    dateTime,
    assessment,
    statusRemarks,
    isHandoverReport,
}: {
    eventId: string;
    userId: string;
    dateTime: string;
    assessment?: string;
    statusRemarks?: string;
    isHandoverReport?: boolean;
}) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.closeEvent, {
        eventId,
        userId,
        dateTime,
        ...(isHandoverReport !== undefined ? { isHandoverReport } : {}),
        ...(assessment ? { assessment } : {}),
        ...(statusRemarks ? { statusRemarks } : {}),
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

/**
 * @deprecated try to use getEventByIdV2
 * @param param0
 * @returns
 */
const getEventById = async ({ id, headers }: { id: string; headers?: any }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        EVENTS_API.event,
        {
            eventId: id,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getEventByIdV2 = async ({ id, headers }: { id: string; headers?: any }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        EVENTS_API.eventV2,
        {
            eventId: id,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getAllEvents = async ({
    eventStatus,
    startDate,
    endDate,
    eventPage,
    headers,
}: {
    eventStatus: string;
    startDate: string;
    endDate: string;
    eventPage?: string;
    headers?: any;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        EVENTS_API.getEvents,
        {
            status: eventStatus,
            startDate,
            endDate,
            eventPage,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getIocEvents = async (params: IocEventListFilters, headers?: any) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.getEventsByPost, params, {
        headers,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};
const getLMTTEventList = async (params: FilterAndSortOptions) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.getLMTTEvent, params);
    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getEventTasksById = async (eventId: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENT_TASK_API.eventTasks, {
        eventId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateEtsById = async (param: EventStatusUpdate) => {
    const { userId, eventId, dateTime, eventTaskType, remarks, status, subtypeStatus } = param;
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.updateEts, {
        userId,
        eventId,
        dateTime,
        status,
        remarks,
        eventTaskType,
        subtypeStatus,
    });

    if (err) {
        return [err];
    }

    const { status: httpStatus } = resp;
    if (httpStatus === HTTP_RESPONSE_TYPE.success) {
        return [null, param];
    }
};

const updateAtsById = async (param: EventStatusUpdate) => {
    const { userId, eventId, dateTime, eventTaskType, remarks, status, subtypeStatus } = param;
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.updateAts, {
        userId,
        eventId,
        dateTime,
        status,
        remarks,
        eventTaskType,
        subtypeStatus,
    });

    if (err) {
        return [err];
    }

    const { status: httpStatus, data: result } = resp;
    if (httpStatus === HTTP_RESPONSE_TYPE.success) {
        return [null, result];
    }
};

const getConflictEventTasks = async (eventId: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.getConflictEventTasks, {
        eventId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getRegNoList = async () => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.getRegList);
    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getAircraftList = async () => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.getAircraftType);
    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getProperties = async ({ types }: { types: string[] }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.getProperties, {
        types,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getAirportList = async (portName: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.getAirportList, {
        portName,
    });
    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getNextFlightDeparture = async ({
    registration,
    startDate,
    endDate,
    headers,
}: {
    registration: string;
    startDate: string;
    endDate: string;
    headers?: any;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        EVENTS_API.getNextFlightDeparture,
        {
            registration,
            startDate,
            endDate,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateFollowUpStatus = async ({
    id,
    eventId,
    userId,
    active,
    headers,
}: {
    id?: number;
    eventId: number;
    userId: number;
    active: boolean;
    headers?: any;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        EVENTS_API.updateFollowUpStatus,
        {
            id,
            eventId,
            userId,
            active,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateEventStatus = async ({
    eventId,
    userId,
    eventStatus,
    statusRemarks,
    headers,
}: {
    eventId: number;
    userId: number;
    eventStatus: string;
    statusRemarks: string;
    headers?: any;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        EVENTS_API.updateEventStatus,
        {
            eventId,
            userId,
            status: eventStatus,
            statusRemarks,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateEventTaskStatus = async ({
    eventTaskId,
    userId,
    eventTaskStatus,
    statusRemarks,
    headers,
}: {
    eventTaskId: number;
    userId: number;
    eventTaskStatus: string;
    statusRemarks: string;
    headers?: any;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        EVENTS_API.updateEventTaskStatus,
        {
            eventTaskId,
            userId,
            status: eventTaskStatus,
            statusRemarks,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const saveEventNote = async ({
    userId,
    eventId,
    note,
    groupId,
    headers,
}: {
    eventId: number;
    userId: number;
    note: string;
    groupId: number;
    headers?: any;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        EVENTS_API.saveEventNote,
        {
            eventId,
            userId,
            note,
            groupId,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};
const saveEventTaskNote = async ({
    userId,
    eventId,
    eventTaskId,
    note,
    groupId,
    headers,
}: {
    eventId: number;
    eventTaskId: number;
    userId: number;
    note: string;
    groupId: number;
    headers?: any;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        EVENTS_API.saveEventTaskNote,
        {
            eventId,
            userId,
            note,
            groupId,
            eventTaskId,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getOwnerList = async ({ category, headers }: { category: string; headers?: any }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        EVENTS_API.getOwnerList,
        {
            category,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateEventDetails = async ({ eventId, details }: { eventId: string; details: object }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.updateEventDetails, {
        eventId,
        details,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateActionItems = async ({
    userId,
    eventId,
    actionItemDetails,
}: {
    userId: number;
    eventId: number;
    actionItemDetails: ActionItem[];
}) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENT_TASK_API.eventTasks, {
        userId,
        eventId,
        details: actionItemDetails,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getActionItems = async ({ eventId, taskTypes }: { eventId: number; taskTypes?: TaskType[] }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENT_TASK_API.eventTasks, {
        eventId,
        types: taskTypes,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};
const getActionItem = async ({ eventTaskId }: { eventTaskId: number }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, ACTION_ITEM_DETAIL_API.eventTasks, {
        eventTaskId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getEventReportBlob = async (zipSource) => {
    const [err, resp, header] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.getEventReport, zipSource, {
        responseType: 'blob',
    });

    if (err) {
        return [err];
    }

    return [null, resp, header];
};

const getHandoverNoteBlob = async (eventId: string) => {
    const [err, resp, header] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        EVENTS_API.getHandoverNotePDF,
        { eventId },
        {
            responseType: 'blob',
        }
    );

    if (err) {
        return [err];
    }

    return [null, resp, header];
};

const getActionItemTypeList = async () => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.getActionItemType);
    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const declareAog = async (params: DeclareAogInput) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.declareAog, params);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getMcilogEvents = async (params: EventListFilters) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.getMCiLogEvents, params);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getMcilogShiftViewEvents = async (params: EventListShiftViewRequestBody) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.getMcilogShiftViewEvents, params);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const generateHandoverReport = async (params: HandoverReportRequestParams) => {
    const [err, resp, header] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        REPORT_API.generateHandoverReport,
        params,
        {
            responseType: 'blob',
        }
    );

    if (err) {
        return [err];
    }

    return [null, resp, header];
};

const generateEventsReport = async (params: EventReportRequestParams) => {
    const [err, resp, header] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, REPORT_API.generateEventsReport, params, {
        responseType: 'blob',
    });

    if (err) {
        return [err];
    }

    return [null, resp, header];
};

const updateATS = async (param: LogSubmitContent) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.updateAts, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getEventMedia = async (eventId: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.getEventMedia, { eventId });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export {
    getEventSetting,
    createEvent,
    reopenEvent,
    cancelEvent,
    getEventById,
    getEventByIdV2,
    getAllEvents,
    getIocEvents,
    getEventTasksById,
    updateEtsById,
    updateAtsById,
    getConflictEventTasks,
    getRegNoList,
    getAirportList,
    closeEvent,
    getNextFlightDeparture,
    updateFollowUpStatus,
    updateEventStatus,
    updateEventTaskStatus,
    updateEventDetails,
    getOwnerList,
    updateActionItems,
    getActionItems,
    getActionItem,
    getLMTTEventList,
    saveEventNote,
    saveEventTaskNote,
    getEventReportBlob,
    getHandoverNoteBlob,
    getAircraftList,
    getActionItemTypeList,
    getProperties,
    declareAog,
    getMcilogEvents,
    getMcilogShiftViewEvents,
    generateHandoverReport,
    generateEventsReport,
    updateATS,
    getEventMedia,
};
