import { IconButton, styled } from '@mui/material';

import colors from '../../../../style/color';

const OutlinedIconButton = styled(IconButton)({
    width: '28px',
    height: '28px',
    borderRadius: '2px',
    background: colors.white,
    border: `1px solid ${colors.grayLight}`,
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    padding: '6px',
});

export default OutlinedIconButton;
