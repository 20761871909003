import React, { PropsWithChildren } from 'react';
import { Typography, Stack } from '@mui/material';
import { FIELD_OFFSET } from '../constants';

interface TitledSectionProps {
    title: string;
}

const TitledSection = (props: PropsWithChildren<TitledSectionProps>) => {
    const { title, children } = props;

    return (
        <Stack>
            <Typography variant='body7' sx={{ ml: FIELD_OFFSET }}>
                {title}
            </Typography>
            <Stack rowGap='8px'>{children}</Stack>
        </Stack>
    );
};

export default TitledSection;
