import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Box, FormHelperText, styled, SxProps, InputAdornment } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { TextInput } from '../../input';
import { DateFormat, Zone, getDateOnly } from '../../../../helper/dateHelper';
import { ReactComponent as Calendar } from '../../../../assets/icons/CalendarIcon.svg';
import { popperContainerStyles, calendarContainerStyles } from './DatePickerStyles';
interface DatePickerProps {
    maxDate?: string;
    minDate?: string;
    isClearable?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    label: string;
    sx?: SxProps;
    inputSx?: SxProps;
    defaultValue?: string | DateTime;
    value?: string | DateTime;
    onChange?: (dateStr: string, dateTimeObj: DateTime | null) => void;
    yearDropdownItemNumber?: number;
}

const CustomInput = forwardRef<
    HTMLInputElement,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
        error: boolean;
        isDisabled: boolean;
        label: string;
        sx?: SxProps;
    }
>(({ onChange, onClick, onKeyDown, value, isDisabled, error, label, sx }, ref) => {
    return (
        <TextInput
            sx={sx}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <Calendar />
                    </InputAdornment>
                ),
            }}
            inputRef={ref}
            onKeyDown={onKeyDown}
            label={label}
            error={error}
            disabled={isDisabled}
            onClick={onClick}
            onChange={onChange}
            value={value}
        />
    );
});

const convertValueToJSDate = (value: string | DateTime): Date => {
    if (!value) return null;

    const dateTimeObj = typeof value === 'string' ? DateTime.fromISO(value, { setZone: true }) : value;

    if (!dateTimeObj.isValid) {
        console.error('Invalid Date');
        return null;
    }

    return dateTimeObj.set({ hour: 0, minute: 0, second: 0 }).toJSDate();
};

const DatePicker = (props: DatePickerProps) => {
    const {
        maxDate,
        minDate,
        isClearable = false,
        error,
        helperText,
        disabled,
        label,
        sx,
        inputSx,
        defaultValue,
        value,
        onChange,
        yearDropdownItemNumber = 3,
    } = props;
    const maxDateInJsDate = maxDate ? getDateOnly(DateTime.fromISO(maxDate, { setZone: true })).toJSDate() : undefined;
    const minDateInJsDate = minDate ? getDateOnly(DateTime.fromISO(minDate, { setZone: true })).toJSDate() : undefined;

    const refCustomInput = useRef();
    const [selectedDate, setSelectedDate] = useState<Date | null>(() =>
        convertValueToJSDate(defaultValue || value || null)
    );

    useEffect(() => {
        setSelectedDate(convertValueToJSDate(value));
    }, [value]);

    return (
        <Box sx={{ width: '100%', ...sx }}>
            <ReactDatePicker
                strictParsing
                calendarStartDay={1}
                disabledKeyboardNavigation
                readOnly={disabled}
                formatWeekDay={(nameOfDay: string) => nameOfDay.substring(0, 1)}
                dateFormatCalendar='MMM yyyy'
                dateFormat='ddMMMyy'
                popperContainer={({ children }) => <StyledPopperContainer>{children}</StyledPopperContainer>}
                calendarContainer={({ children }) => <StyledCalendarContainer>{children}</StyledCalendarContainer>}
                customInput={
                    <CustomInput label={label} sx={inputSx} ref={refCustomInput} error={error} isDisabled={disabled} />
                }
                showMonthDropdown
                showYearDropdown
                showTwoColumnMonthYearPicker
                useShortMonthInDropdown
                yearDropdownItemNumber={yearDropdownItemNumber}
                maxDate={maxDateInJsDate}
                minDate={minDateInJsDate}
                selected={selectedDate}
                onChange={(date) => {
                    if (!date && !isClearable) return;
                    setSelectedDate(date);

                    if (onChange) {
                        if (!date) {
                            onChange('', null);
                        } else {
                            const dateTimeObj = DateTime.fromJSDate(date);
                            const returnString = dateTimeObj.toFormat(DateFormat.ServerQueryFormat);
                            // 'date' has local time specific. Reconstruct DateObject to make sure it's in UTC.
                            onChange(
                                returnString,
                                DateTime.fromFormat(returnString, DateFormat.ServerQueryFormat, { zone: Zone.utc })
                            );
                        }
                    }
                }}
            />
            {
                // Cannot use the helperText in <TextInput /> because the datepicker popper pop from
                // the bottom of the custom input box. Adding helper text will move the popper downwards.
            }
            {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : false}
        </Box>
    );
};

const StyledPopperContainer = styled(Box)({ ...popperContainerStyles, userSelect: 'none' });

const StyledCalendarContainer = styled(Box)(calendarContainerStyles);

export { DatePicker as default, DatePickerProps };
