import globalConfig from '../config/globalConfig';
import { HTTP_REQUEST_TYPE, CHAT_API, HTTP_RESPONSE_TYPE } from '../constants/api';
import { sendHttpRequest } from './api';
import { v4 as uuidv4 } from 'uuid';

const { chatServerApiUrl } = globalConfig;

const onChatWebSocketConnected = (ws: WebSocket, accessToken: string, audiences: string[], currentAdGroup: string) => {
    const { chatAppId } = globalConfig;
    if (!ws || audiences.length === 0) {
        return;
    }
    const body = {
        type: 'request',
        auth: accessToken,
        resource: 'channel',
        method: 'subscribeMany',
        requestId: uuidv4(),
        filter: {
            appId: chatAppId,
            audiences,
            tags: [currentAdGroup],
        },
    };
    ws.send(JSON.stringify(body));
};

const getAndReadChatMessages = async (param: {
    userId: string;
    appId: string;
    globalChannelName: string;
    audiences: string[];
    lastUpdated: string;
}) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, CHAT_API.message, param, {
        baseURL: chatServerApiUrl,
    });

    if (err) {
        return [err];
    }

    return [null, resp];
};

const postChatMessage = async (param: FormData) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHAT_API.message, param, {
        baseURL: chatServerApiUrl,
        headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (err) {
        return [err];
    }

    return [null, resp];
};

const getChatAttachedImage = async (param: { messageId: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, CHAT_API.messageAttachment, param, {
        baseURL: chatServerApiUrl,
        responseType: 'blob',
    });

    if (err) {
        return [err];
    }

    return [null, resp];
};

const getChatMetaDataByAudience = async (param: {
    lastUpdated: string;
    audiences: string[];
    tags: string[];
    offset: number;
    limit: number;
}) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHAT_API.getMetaDataByAudience, param, {
        baseURL: chatServerApiUrl,
    });

    if (err) {
        return [err];
    }

    return [null, resp];
};

const getFlightByChatUfis = async (param: { chatUfis: string[] }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHAT_API.getFlightByChatUfis, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const markAllChatRead = async (param: { [key: string]: string[] }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        CHAT_API.markAllChatRead,
        { audiencesByGlobalChannelName: param },
        {
            baseURL: chatServerApiUrl,
        }
    );

    if (err) {
        return [err];
    }

    return [null, resp];
};

export {
    getAndReadChatMessages,
    postChatMessage,
    getChatAttachedImage,
    getChatMetaDataByAudience,
    onChatWebSocketConnected,
    getFlightByChatUfis,
    markAllChatRead,
};
