import React from 'react';
import { FormControlLabel, FormControlLabelProps, SxProps } from '@mui/material';

import StyledCheckbox, { StyledCheckboxProps } from './StyledCheckbox';

interface LabelledCheckboxProps extends Omit<StyledCheckboxProps, 'sx'> {
    value?: string;
    label?: string;
    sx?: SxProps;
    checkboxSx?: SxProps;
    labelSx?: SxProps;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
}

const LabelledCheckbox = (props: LabelledCheckboxProps) => {
    const { value, label, sx, labelSx, labelPlacement = 'end', checkboxSx, ...checkboxProps } = props;
    return (
        <FormControlLabel
            sx={{ m: '0px', ...sx }}
            componentsProps={{
                typography: {
                    variant: 'regularGrayDark14',
                    sx: {
                        ml: '8px',
                        ...labelSx,
                    },
                },
            }}
            value={value}
            label={label}
            labelPlacement={labelPlacement}
            control={<StyledCheckbox sx={checkboxSx} size='20px' {...checkboxProps} />}
        />
    );
};

export default LabelledCheckbox;
