import { Margins, TableCell } from 'pdfmake/interfaces';
import { IGenPdfSpare, ISpareSouce } from '../../interface/Report';
import { SparesAndToolings, RecoveryPlanItemsIdPrefixMap } from '../../constants/constants';

enum spareOrToolingTitle {
    Spare = 'Spare',
    Tooling = 'Tooling',
}

const getSpareTitle = (title: string) => {
    return spareOrToolingTitle[title] as string;
};

const getColMargin = (marginIndex: number = 4) => {
    const margin = {
        0: [0, 0],
        2: [10, 0],
        3: [10, 0],
        4: [0, 0],
    };
    return margin[marginIndex] as Margins;
};

/* because each line has 4 elements so it's used to handle feedLine for array with uncertain amounts of element */
const feedLineHandle = (list) => {
    if (!Array.isArray(list)) return [{}, {}, {}, {}];

    /* supplement {} to make sure array's length is four otherwise pdfmake will crash because of wrong format */
    const emptyObjectSupplement = (ary) => {
        const rest = 4 - ary.length;
        for (let i = 0; i < rest; i++) {
            ary.push({});
        }
        return ary;
    };

    const noEmptyArray = list.filter((item) => JSON.stringify(item) !== '{}');

    if (noEmptyArray.length <= 4) return [emptyObjectSupplement(noEmptyArray)];

    const arrayChunk = [];
    const count = noEmptyArray.length;
    for (let i = 0; i < count; i += 4) {
        const slice = noEmptyArray.slice(i, i + 4);
        arrayChunk.push(emptyObjectSupplement(slice));
    }
    return arrayChunk;
};

const getSparesAndToolings = (spares: IGenPdfSpare[]) => {
    const spareAndToolingSourceData = spares.map(
        ({
            actualArrival = '--',
            comments = '--',
            description = '--',
            estimatedArrival = '--',
            airline = '--',
            flightDate = '--',
            flightNumber = '--',
            id = '--',
            partNumber = '--',
            quantity = '--',
            status = '--',
            title = '--',
            trackingNumber = '--',
            transportMethod = '--',
        }) => {
            return [
                [
                    { text: `${getSpareTitle(title)} ${RecoveryPlanItemsIdPrefixMap[title]}${id}`, colSpan: 4 },
                    {},
                    {},
                    {},
                ],
                [
                    { label: SparesAndToolings.partNumber, text: partNumber, colSpan: 2 },
                    {},
                    { label: SparesAndToolings.quantity, text: quantity },
                    { label: SparesAndToolings.status, text: status },
                ],
                [
                    {
                        label: SparesAndToolings.description,
                        text: description,
                        colSpan: 4,
                    },
                    {},
                    {},
                    {},
                ],
                [
                    { label: SparesAndToolings.transportMethod, text: transportMethod },
                    { label: SparesAndToolings.trackingNumber, text: trackingNumber },
                    {},
                    {},
                ],
                ...feedLineHandle([
                    /* certain fields will be hidden based on the selection of transport method */
                    !!airline && airline !== '--' ? { label: SparesAndToolings.airline, text: airline } : {},
                    !!flightNumber && flightNumber !== '--'
                        ? { label: SparesAndToolings.flightNumber, text: flightNumber }
                        : {},
                    !!flightDate && flightDate !== '--'
                        ? { label: SparesAndToolings.flightDate, text: flightDate }
                        : {},
                ]),
                [
                    { label: SparesAndToolings.estimatedArrival, text: estimatedArrival },
                    { label: SparesAndToolings.actualArrival, text: actualArrival, colSpan: 3 },
                    {},
                    {},
                ],
                [{ label: SparesAndToolings.comments, text: comments, colSpan: 4, hasBottomBorder: true }, {}, {}, {}],
            ] as ISpareSouce[][];
        }
    ) as ISpareSouce[][][];

    const convertedSpareAndToolingData = (spare: ISpareSouce[][]) => {
        return spare.map((spareItem: ISpareSouce[]) => {
            return spareItem.map((spareChildItem: ISpareSouce, spareChildIndex: number) => {
                const { label = '--', text = '--', colSpan = 0, hasBottomBorder = false } = spareChildItem;
                // if empty cell
                if (label === '--' && text === '--') return {};

                const spareCellItem = {
                    colSpan: colSpan,
                    margin: getColMargin(spareChildIndex),
                };
                // if this is title
                if (label === '--') {
                    spareCellItem['text'] = text;
                    spareCellItem['style'] = 'sparesTableCaption';
                    return spareCellItem;
                }

                // has bottom border like commet
                if (hasBottomBorder) {
                    spareCellItem['border'] = [true, false, true, true];
                }

                // if cell has content, then display as a stack in cell.
                spareCellItem['stack'] = [
                    { text: label, style: 'sparesTableLabel' },
                    { text: text, style: 'sparesTableText' },
                ];
                return spareCellItem as TableCell;
            });
        });
    };

    const spareConvertedArray = [];
    spareAndToolingSourceData.forEach((spare: ISpareSouce[][]) => {
        convertedSpareAndToolingData(spare).forEach((item: ISpareSouce[]) => {
            spareConvertedArray.push(item);
        });
    });
    return spareConvertedArray;
};

export default getSparesAndToolings;
