import { IssueSummarySaveObj } from '../slices/issueSummarySlice';
import { IssueUpdate } from '../slices/eventSlice';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, ISSUE_SUMMARY_API, EVENT_TASK_API } from '../constants/api';
import { sendHttpRequest } from './api';
import { TaskType } from '../constants/constants';
import { LogSubmitContent } from '../interface/MCLog';

const getAtaCodes = async (headers?: any) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, ISSUE_SUMMARY_API.getAtaCodes, null, {
        headers,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getIssueHistory = async (eventId: string, type: TaskType) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENT_TASK_API.eventTasks, {
        eventId,
        type,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;

    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getIssueLogs = async (eventId: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, ISSUE_SUMMARY_API.getIssueLogs, {
        eventId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;

    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const removeMcLog = async (id: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, ISSUE_SUMMARY_API.removeMcLog, {
        id: Number(id),
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const saveIssue = async (param: IssueSummarySaveObj) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENT_TASK_API.eventTasks, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateIssueLog = async (param: IssueUpdate) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, ISSUE_SUMMARY_API.updateIssueLog, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateMcLog = async (param: LogSubmitContent) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, ISSUE_SUMMARY_API.updateIssueLog, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getAtaCodes, getIssueHistory, saveIssue, updateIssueLog, getIssueLogs, updateMcLog, removeMcLog };
