import COLORS from '../../style/color';
import { StyleDictionary, Style } from 'pdfmake/interfaces';

const issueUpdateText = {
    color: COLORS.grayDark,
    fontSize: 12,
    bold: true,
    italics: true,
} as Style;

const detailTableLabel = {
    color: COLORS.grayDark,
    fontSize: 10,
    bold: false,
    margin: [10, 0, 10, 5],
} as Style;

const detailTableText = {
    color: COLORS.grayDark,
    fontSize: 12,
    bold: true,
    italics: true,
    margin: [10, 0, 10, 0],
} as Style;

export const CoePdfStyle = {
    topMargin: {
        margin: [0, 10, 0, 0]
    },
    header: {
        fontSize: 8,
        color: COLORS.black,
        margin: [0, 16, 0, 0],
    },
    title: {
        color: COLORS.grayNormal,
        fontSize: 14,
        margin: [0, 0, 0, 2],
    },
    detail: {
        color: COLORS.grayDark,
        fontSize: 14,
        margin: [0, 0, 0, 14],
    },
    detailBold: {
        color: COLORS.grayDark,
        fontSize: 14,
        bold: true,
        margin: [0, 0, 0, 14],
    },
    eventTitle: {
        color: COLORS.grayDark,
        fontSize: 16,
        bolditalics: true,
        margin: [0, 0, 0, 14],
    },
    note: {
        color: COLORS.grayDark,
        fontSize: 14,
        margin: [0, 0, 0, 2],
    },
    noteUserInfo: {
        color: COLORS.grayNormal,
        fontSize: 14,
        margin: [0, 0, 0, 14],
    }
} as StyleDictionary;

const ReportStyle = {
    tableUni: {
        color: COLORS.grayDark,
        margin: [0, 0, 0, 16],
        fontSize: 12,
    },
    tableCaption: {
        fillColor: COLORS.cathayJade,
        color: COLORS.white,
        fontSize: 12,
    },
    detailTableLabel: detailTableLabel,
    detailTableFirstColLabel: {
        ...detailTableLabel,
        margin: [-1, 0, 10, 5],
    },
    detailTableText: detailTableText,
    detailTableFirstColText: {
        ...detailTableText,
        margin: [-1, 0, 10, 0],
    },
    issueUpdateLabel: {
        color: COLORS.grayDark,
        fontSize: 10,
    },
    issueUpdateText: issueUpdateText,
    issueUpdateTextServiceable: {
        ...issueUpdateText,
        color: COLORS.brightJade,
    },
    pagination: {
        margin: [0, 0, 0, 0],
        color: COLORS.grayDark,
    },
    sparesTableCaption: {
        color: COLORS.cathayJade,
        fontSize: 12,
        bold: true,
        italics: true,
    },
    sparesTableLabel: {
        color: COLORS.grayDark,
        fontSize: 10,
        bold: false,
        margin: [0, 0, 0, 2],
    },
    sparesTableText: {
        color: COLORS.grayDark,
        fontSize: 12,
        bold: true,
        italics: true,
    },
    tableHeader: [COLORS.cathayJade, COLORS.cathayJade, COLORS.cathayJade, COLORS.cathayJade],
} as StyleDictionary;

export default ReportStyle;
