import { styled } from '@mui/system';

import { ReactComponent as Arrow } from '../../assets/icons/ArrowDown.svg';
import { ReactComponent as FilledArrow } from '../../assets/icons/TableSortArrow.svg';

const ArrowLeft = styled(Arrow)({
    transform: 'rotate(90deg)',
});

const ArrowRight = styled(Arrow)({
    transform: 'rotate(-90deg)',
});

const ArrowUp = styled(Arrow)({
    transform: 'rotate(180deg)',
});

const ArrowDown = styled(Arrow)({
    transform: 'rotate(0deg)',
});

const FilledArrowUp = styled(FilledArrow)({
    transform: 'rotate(0deg)',
});

const FilledArrowRight = styled(FilledArrow)({
    transform: 'rotate(90deg)',
});

const FilledArrowDown = styled(FilledArrow)({
    transform: 'rotate(180deg)',
});

const FilledArrowLeft = styled(FilledArrow)({
    transform: 'rotate(-90deg)',
});

export { ArrowLeft, ArrowRight, ArrowUp, ArrowDown, FilledArrowUp, FilledArrowRight, FilledArrowDown, FilledArrowLeft };
