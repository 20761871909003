import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { SxProps, styled } from '@mui/system';
import clsx from 'clsx';
import autoCompleteStyles from './AutoCompleteDropdownStyles';
import { ReactComponent as ArrowDown } from '../../assets/icons/ArrowDown.svg';
import COLORS from '../../style/color';

export type AutoCompleteOption = {
    label: string;
    value: string;
};

type AutoCompleteProps = {
    options: AutoCompleteOption[];
    onChange: (selectedOption: AutoCompleteOption) => void;
    value?: AutoCompleteOption | string;
    label?: string;
    sx?: SxProps;
    filterOptions?: (options, { inputValue }) => AutoCompleteOption[];
    onInputChange?: (inputText: string) => void;
    open?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    loading?: boolean;
    isShowEndAdornment?: boolean;
    onBlur?: () => void;
    isDisabled?: boolean;
    disableClearable?: boolean;
};

const ErrorMsg = styled('div')({
    '&': {
        fontFamily: 'CathaySans',
        fontSize: '14px',
        color: COLORS.error,
    },
});

export default function AutoCompleteDropdown({
    label,
    options,
    onChange,
    sx,
    value,
    filterOptions,
    onInputChange,
    open,
    hasError,
    errorMessage,
    loading,
    isShowEndAdornment = true,
    onBlur,
    isDisabled = false,
    disableClearable = true,
}: AutoCompleteProps) {
    const [selectedValue, setSelectedValue] = useState<AutoCompleteOption | null>(null);
    const [inputValue, setInputValue] = useState('');
    const { classes } = autoCompleteStyles();

    useEffect(() => {
        if (options && value) {
            if (typeof value === 'string') {
                setSelectedValue(options?.find((option) => option.value === value) || null);
            } else {
                setSelectedValue(value);
            }
        } else {
            setSelectedValue(null);
        }
    }, [value, options]);

    return (
        <>
            <Autocomplete
                sx={sx}
                classes={{
                    root: classes.root,
                    paper: classes.paper,
                    option: classes.option,
                    endAdornment: isShowEndAdornment ? classes.endAdornment : classes.hideEndAdornment,
                }}
                className={clsx({
                    'has-error': hasError,
                    'is-disabled': isDisabled,
                })}
                value={selectedValue}
                onChange={(event: any, newValue: string | AutoCompleteOption) => {
                    if (typeof newValue !== 'string') {
                        setSelectedValue(newValue as AutoCompleteOption);
                        onChange && onChange(newValue as AutoCompleteOption);
                    }
                }}
                disableClearable={disableClearable}
                inputValue={inputValue}
                onInputChange={(event: React.ChangeEvent, newInputValue: string) => {
                    if (event && event.type !== 'click') {
                        onInputChange && onInputChange(newInputValue);
                    }
                    setInputValue(newInputValue);
                }}
                options={[selectedValue, ...options] || []} // Currently selected value must be preset to not trigger the warning
                filterOptions={filterOptions}
                renderInput={(params) => (
                    <TextField
                        classes={{ root: classes.inputRoot }}
                        {...params}
                        label={label}
                        variant='filled'
                        size='small'
                        InputProps={{ ...params.InputProps, disableUnderline: true }}
                    />
                )}
                open={open}
                loading={loading}
                popupIcon={<ArrowDown />}
                isOptionEqualToValue={(option: AutoCompleteOption, value: AutoCompleteOption) =>
                    option?.value === value?.value
                }
                onBlur={onBlur}
                disabled={isDisabled}
            />
            {hasError && errorMessage && <ErrorMsg children={errorMessage} />}
        </>
    );
}
