import React, { useState, useRef } from 'react';
import { Link, ListItemText, MenuItem, Popover, Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
    FilterProps,
    FilterMenuItemProps,
    CollapsableFilterProps,
    FilterClearItemProps,
} from '../../../../interface/CollapsibleFilter';
import StyledCheckbox from '../../CheckBox';
import ExpandArrow from '../../ExpandArrow';
import { IconButton as StyledIconButton } from '../../buttons';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/Filter.svg';
import filterStyles from '../../FilterStyles';

import colors from '../../../../style/color';

const CRD_TASK_INDICATORS = 'CRD Task Indicators';

const popoverPaperStyles = makeStyles()({
    paper: {
        boxShadow: colors.boxShadow,
        border: `1px solid ${colors.grayNormal}`,
        width: '240px',
        padding: '16px 0 8px 0',
    },
});

const FilterMenuItem = (props: FilterMenuItemProps) => {
    const { handleChange, availableItems, selectedValue, sx } = props;
    return (
        <Stack sx={sx}>
            {availableItems.map((item) => {
                const { value, name } = item;
                const isChecked = selectedValue.includes(value);
                return (
                    <MenuItem
                        key={value}
                        onClick={() => handleChange(value)}
                        value={value}
                        disableRipple
                        sx={{ ...filterStyles.menuItem, padding: '8px 16px' }}
                    >
                        <StyledCheckbox checked={isChecked} />
                        <ListItemText primary={name} sx={filterStyles.listItemText} disableTypography></ListItemText>
                    </MenuItem>
                );
            })}
        </Stack>
    );
};

const CollapsibleMenu = (props: CollapsableFilterProps) => {
    const { title, children } = props;
    const [shouldShowMenu, setShouldShowMenu] = useState<boolean>(true);
    return (
        <>
            <Stack
                sx={{
                    justifyContent: 'space-between',
                    borderBottom: 'none',
                    padding: '12px 0px',
                    flexDirection: 'row',
                }}
            >
                <Typography variant='body7'>{title}</Typography>
                <IconButton
                    aria-label='expand row'
                    size='small'
                    sx={{ height: '20px' }}
                    onClick={() => setShouldShowMenu((preState) => !preState)}
                >
                    <ExpandArrow expand={shouldShowMenu} />
                </IconButton>
            </Stack>
            <Collapse in={shouldShowMenu} timeout='auto' unmountOnExit>
                {children}
            </Collapse>
        </>
    );
};

const FilterClearItem = (props: FilterClearItemProps) => {
    const { clearText, handleChange, sx } = props;
    return (
        <MenuItem onClick={() => handleChange('clear')} value='clear' disableRipple sx={sx}>
            <Link component='button' underline='always' sx={filterStyles.resetButton}>
                {clearText}
            </Link>
        </MenuItem>
    );
};

// v2 for FilterTask
const CollapsibleFilter = (props: FilterProps) => {
    const { clearText = 'Clear all filters', availableItems, onChange, value = [], tooltipTitle, sx } = props;
    const [open, setOpen] = useState(false);
    const iconButtonRef = useRef();
    const { classes: paperStyles } = popoverPaperStyles();

    const onClose = () => {
        setOpen(false);
    };
    const handleChange = (val: string) => {
        if (val === 'clear') {
            onChange && onChange([]);
        } else {
            const hasItem = value.find((item) => item === val);
            let updatedList: string[] = [];
            if (hasItem) {
                updatedList = value.filter((item) => item !== val);
            } else {
                updatedList = value.concat([val]);
            }
            onChange && onChange(updatedList);
        }
    };

    return (
        <Box sx={sx}>
            <StyledIconButton
                ref={iconButtonRef}
                tooltipTitle={tooltipTitle}
                iconContent={<FilterIcon />}
                isActive={open}
                isSelected={value?.length !== 0}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setOpen(true);
                }}
            />
            <Popover
                classes={{ paper: paperStyles.paper }}
                open={open}
                onClose={onClose}
                anchorEl={iconButtonRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {Object.keys(availableItems).map((item, index) => {
                    const category = Object.values(availableItems?.[item]);
                    const filterItems = category
                        ?.map((option, optionIndex) => {
                            const title = Object.keys(option)?.[0];
                            const filterOptions = Object.values(option)?.[0];
                            if (filterOptions?.length === 0) return null;

                            return (
                                <Stack key={title}>
                                    {item === CRD_TASK_INDICATORS ? (
                                        <Stack>
                                            <Typography variant='body7' sx={{ padding: '12px 0' }}>
                                                {title}
                                            </Typography>
                                            <FilterMenuItem
                                                selectedValue={value}
                                                availableItems={filterOptions}
                                                handleChange={handleChange}
                                            />
                                        </Stack>
                                    ) : (
                                        <CollapsibleMenu title={title}>
                                            <FilterMenuItem
                                                selectedValue={value}
                                                availableItems={filterOptions}
                                                handleChange={handleChange}
                                            />
                                        </CollapsibleMenu>
                                    )}
                                </Stack>
                            );
                        })
                        .filter((filterItem) => filterItem !== null);

                    const hasFilterItems = filterItems?.length !== 0;

                    return (
                        <Stack key={item} sx={{ padding: hasFilterItems ? '0 20px 8px' : 0 }}>
                            {hasFilterItems && <Typography variant='boldGrayDark14'>{item}</Typography>}
                            {filterItems}
                        </Stack>
                    );
                })}
                <FilterClearItem
                    sx={{ p: '8px 16px', top: '-8px' }}
                    clearText={clearText}
                    handleChange={handleChange}
                />
            </Popover>
        </Box>
    );
};

export default CollapsibleFilter;
