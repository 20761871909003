import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAircraftInfo, getTimeOffsetByPort } from '../services/common';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';
import { getProperties } from '../services/events';
import { PropertiesDetails } from '../interface/event.interface';
import { AircraftInfo } from '../interface/Flight';

const getTimeOffsetByPortThunk = createAsyncThunk<
{ [key: string]: number },
string[],
{ state: RootState; rejectValue: ApiErrorObj }
>('common/getTimeOffsetByPort', async (ports, { getState, rejectWithValue }) => {
    const [err, data] = await getTimeOffsetByPort(ports);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export interface Properties {
    label: string;
    display: string;
    value: string;
    name: string;
    defaultChecked?: boolean;
}

interface CommonSlice {
    portTimeOffsets: {
        [key: string]: number;
    };
    propertiesDetails: {
        [key: string]: Properties[];
    };
    isAircraftInfoLoading: boolean;
}
const initialState: CommonSlice = {
    portTimeOffsets: {},
    propertiesDetails: {},
    isAircraftInfoLoading: false,
};

export const getPropertiesTypesListThunk = createAsyncThunk<
PropertiesDetails,
{
    types: string[];
},
{ state: RootState; rejectValue: ApiErrorObj }
>('events/getPropertiesTypesListThunk', async ({ types }, { getState, rejectWithValue }) => {
    // If there is already a list of types in propertiesDetails, there is no need to call the api again.
    const { propertiesDetails } = getState().common;
    const acquireTypes = types.filter((key: string) => {
        return !propertiesDetails[key] || propertiesDetails[key]?.length === 0;
    });
    if (acquireTypes.length === 0) {
        return {};
    }
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/properties')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getProperties({ types: acquireTypes });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as PropertiesDetails;
});

export const getAircraftInfoThunk = createAsyncThunk<
AircraftInfo[],
{ registrations: string[]; aircraftTypes: string[]; needLoading?: boolean },
{ rejectValue: ApiErrorObj }
>('getAircraftInfoDetails', async ({ registrations, aircraftTypes }, { rejectWithValue }) => {
    const [err, data] = await getAircraftInfo({ registrations, aircraftTypes });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTimeOffsetByPortThunk.fulfilled, (state, { payload }) => {
                state.portTimeOffsets = { ...state.portTimeOffsets, ...payload };
            })
            .addCase(getPropertiesTypesListThunk.fulfilled, (state, { payload }) => {
                const resultTypes = Object.keys(payload) || [];
                const propertiesDetails = state.propertiesDetails;
                resultTypes.length !== 0 &&
                    resultTypes.forEach((key) => {
                        propertiesDetails[key] = payload[key].map((item) => {
                            return {
                                display: item.description,
                                label: item.description,
                                name: item.description,
                                value: item.code,
                            };
                        });
                    });
                state.propertiesDetails = propertiesDetails;
            })
            .addCase(getAircraftInfoThunk.pending, (state, { meta: { arg } }) => {
                if (arg.needLoading) {
                    state.isAircraftInfoLoading = true;
                }
            })
            .addCase(getAircraftInfoThunk.fulfilled, (state, { meta: { arg } }) => {
                if (arg.needLoading) {
                    state.isAircraftInfoLoading = false;
                }
            })
            .addCase(getAircraftInfoThunk.rejected, (state, { meta: { arg } }) => {
                if (arg.needLoading) {
                    state.isAircraftInfoLoading = false;
                }
            });
    },
});

const selectCommon = (state: RootState) => state.common;
const commonSliceReducer = commonSlice.reducer;
export { commonSliceReducer as default, selectCommon, getTimeOffsetByPortThunk };
