import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, SxProps } from '@mui/material';
import { useFormContext, UseFormRegister, FieldValues as TFieldValues } from 'react-hook-form';
import cloneDeep from 'lodash/fp/cloneDeep';
import { DateTime } from 'luxon';

import DatePickerField from './DatePicker';
import ErrorMsg from '../common/ErrorMsg';
import InputField, { TextMask } from './InputField';
import { TimeZoneToggle } from './buttons';
import { getDateOnly, getMinOrMaxDateTime, Zone } from '../../helper/dateHelper';
import { ReactComponent as Asterisks } from '../../assets/icons/Asterisks.svg';
import COLORS from '../../style/color';

const Header = ({ title, subTitle, isOptional }: { title: string; subTitle?: string; isOptional: boolean }) => {
    return (
        <>
            {title ? (
                <Box
                    sx={{
                        display: 'flex',
                        marginLeft: '4px',
                        marginTop: '15px',
                    }}
                >
                    <Typography
                        className='title'
                        component='div'
                        sx={{
                            color: `${COLORS.grayDark}`,
                            fontSize: '14px',
                            paddingRight: '4px',
                        }}
                    >
                        {title}
                    </Typography>
                    {isOptional ? <></> : <Asterisks />}
                </Box>
            ) : (
                <></>
            )}
            {subTitle ? (
                <Box
                    sx={{
                        display: 'flex',
                        marginLeft: '4px',
                        marginTop: '4px',
                    }}
                >
                    <Typography
                        className='title'
                        component='div'
                        sx={{
                            color: `${COLORS.grayNormal}`,
                            fontSize: '12px',
                            paddingRight: '4px',
                        }}
                    >
                        {subTitle}
                    </Typography>
                </Box>
            ) : (
                <></>
            )}
        </>
    );
};

type ChildComponentWidth = {
    dateTime: string;
    duration: string;
};

type DateTimeDurationProps = {
    name: string;
    dateLabel: string;
    timeLabel: string;
    defaultDateTime: DateTime;
    shouldShowDuration: boolean;
    minDate?: DateTime;
    maxDate?: DateTime;
    duration?: string;
    register: UseFormRegister<TFieldValues>;
    onDateTimeChange: (date: DateTime, time: string) => void;
    onBlur?: (date: DateTime) => void; // it's better to pass the component value in function
    childWidth: ChildComponentWidth;
    isReadOnly?: boolean;
    isDisabled?: boolean;
    errorMessage?: string;
    hasError?: boolean;
    isClearable?: boolean;
    sx?: SxProps;
    showToggleZone?: boolean;
    zone?: Zone;
    handleZoneChange?: (zone: Zone) => void;
};

const convertMinutesToDuration = (minutes: number) => {
    const posMinutes = Math.abs(minutes);
    const prefixSigns = minutes >= 0 ? '' : '-';
    const days = Math.floor(posMinutes / 60 / 24)
        .toString()
        .padStart(2, '0');
    const hours = Math.floor((posMinutes / 60) % 24)
        .toString()
        .padStart(2, '0');
    const remainder = (posMinutes % 60).toString().padStart(2, '0');
    return days === '00' ? `${prefixSigns}${hours}:${remainder}` : `${prefixSigns}${days}:${hours}:${remainder}`;
};

const getDuration = (startDateTime: DateTime, endDateTime: DateTime) => {
    if (!startDateTime || !endDateTime) {
        return 'DD:HH:MM';
    }
    const durationMinutes = endDateTime.diff(startDateTime, ['minutes']).toObject().minutes;
    return convertMinutesToDuration(durationMinutes);
};

const getDateTime = (dateTime: DateTime = DateTime.now(), timeStr: string) => {
    const targetTime = timeStr ?? '0';
    const targetDateTime = dateTime.set({
        hour: parseInt(targetTime.substring(0, 2).padStart(1, '0')),
        minute: parseInt(targetTime.substring(2, 4).padStart(1, '0')),
    });
    return targetDateTime;
};

const getChildComponentWidth = (durationWidthRatio: number): ChildComponentWidth => {
    return {
        dateTime: `${(100 * (1 - durationWidthRatio)) / 2}%`,
        duration: `${100 * durationWidthRatio}%`,
    };
};

const getInitialDateTimeState = (dateTime: DateTime, zone: Zone = Zone.utc): DateTimeState => {
    if (!dateTime) {
        return {
            date: null,
            time: null,
            dateTime: null,
        };
    }

    const newDateTime = dateTime.setZone(zone);
    return {
        date: getDateOnly(newDateTime),
        time: newDateTime.toFormat('HHmm'),
        dateTime: newDateTime.set({ second: 0, millisecond: 0 }),
    };
};

const DateTimeDuration = ({
    name,
    dateLabel,
    timeLabel,
    shouldShowDuration,
    defaultDateTime,
    minDate,
    maxDate,
    duration,
    register,
    onDateTimeChange,
    hasError = false,
    errorMessage,
    onBlur,
    childWidth,
    isReadOnly,
    isDisabled,
    sx,
    isClearable,
    showToggleZone,
    zone,
    handleZoneChange,
}: DateTimeDurationProps) => {
    return (
        <Stack sx={{ flexDirection: 'column' }}>
            <Box sx={{ display: 'flex' }}>
                <DatePickerField
                    label={dateLabel}
                    onChange={(dt: DateTime) => {
                        onDateTimeChange(dt, null);
                    }}
                    value={defaultDateTime}
                    minDate={minDate}
                    maxDate={maxDate}
                    width={childWidth.dateTime}
                    sx={sx}
                    isReadOnly={isReadOnly}
                    isDisabled={isDisabled}
                    onBlur={(newDate: DateTime) => {
                        onBlur(
                            newDate &&
                                newDate.set({ hour: defaultDateTime?.hour || 0, minute: defaultDateTime?.minute || 0 })
                        );
                    }}
                    hasError={hasError}
                    isClearable={isClearable}
                />
                <InputField
                    label={timeLabel}
                    onChange={(event) => {
                        onDateTimeChange(null, event.target.value);
                    }}
                    value={defaultDateTime ? defaultDateTime.toFormat('HHmm') : ''}
                    textMask={TextMask.Time}
                    width={childWidth.dateTime}
                    isReadOnly={isReadOnly}
                    isDisabled={isDisabled}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        // adjustment for father component to get a string in the format of DateTimeWithSec in onBlur event
                        const hour = Number(e.target.value.substring(0, 2));
                        const minute = Number(e.target.value.substring(2, 4));
                        onBlur(defaultDateTime && defaultDateTime.set({ hour, minute }));
                    }}
                />
                {name && (
                    <input
                        type='hidden'
                        {...register(`${name}`)}
                        value={(defaultDateTime && defaultDateTime.toISO()) || ''}
                    />
                )}
                {shouldShowDuration ? (
                    <InputField
                        label='Duration (DD:HH:MM)'
                        isDisabled={true}
                        value={duration}
                        width={childWidth.duration}
                    />
                ) : showToggleZone ? (
                    <Box sx={{ width: childWidth.duration, m: '4px' }}>
                        <TimeZoneToggle selectedZone={zone} onChange={handleZoneChange} />
                    </Box>
                ) : (
                    <Box sx={{ width: childWidth.duration }} />
                )}
            </Box>
            {hasError && errorMessage && <ErrorMsg sx={{ ml: '4px' }} children={errorMessage} />}
        </Stack>
    );
};

type DateTimeDurationBlockProps = {
    startName?: string;
    startTitle?: string;
    startSubTitle?: string;
    startDateLabel?: string;
    startTimeLabel?: string;
    startDateLabelHkt?: string;
    startTimeLabelHkt?: string;
    isStartOptional?: boolean;
    defaultStartDateTime?: DateTime;
    minStartDate?: DateTime;
    maxStartDate?: DateTime;

    endName?: string;
    endTitle?: string;
    endSubTitle?: string;
    endDateLabel?: string;
    endTimeLabel?: string;
    endDateLabelHkt?: string;
    endTimeLabelHkt?: string;
    isEndOptional?: boolean;
    defaultEndDateTime?: DateTime;

    shouldShowDuration?: boolean;
    containerWidth?: string;
    durationWidthRatio?: number; // dateTime, duration
    onChange?: (dateTime: string, type?: 'start' | 'end') => void;
    onBlur?: (type?: 'start' | 'end', e?: DateTime) => void; // it's better to pass the component value in function
    isReadOnly?: boolean;
    isStartDisabled?: boolean;
    isEndDisabled?: boolean;
    isEditMode?: boolean;
    sx?: SxProps;
    isClearable?: boolean;
    showToggleZone?: boolean;
    zoneValue?: Zone;
    hasError?: boolean;
    errorMessage?: {
        start?: string;
        end?: string;
    };
};

type DateTimeState = {
    date: DateTime;
    time: string;
    dateTime: DateTime;
};

interface DatetimeObject {
    start: DateTimeState;
    end: DateTimeState;
}
const defaultDatetime: DatetimeObject = {
    start: {
        date: null,
        time: null,
        dateTime: null,
    },
    end: {
        date: null,
        time: null,
        dateTime: null,
    },
};

const DateTimeDurationBlock = ({
    startName,
    startTitle,
    startSubTitle,
    startDateLabel,
    startTimeLabel,
    startDateLabelHkt,
    startTimeLabelHkt,
    isStartOptional = true,
    defaultStartDateTime,
    minStartDate,
    maxStartDate,
    endName,
    endTitle,
    endSubTitle,
    endDateLabel,
    endTimeLabel,
    endDateLabelHkt,
    endTimeLabelHkt,
    isEndOptional = true,
    defaultEndDateTime,
    shouldShowDuration,
    containerWidth = '100%',
    durationWidthRatio = shouldShowDuration ? 0.33 : 0,
    onChange,
    onBlur,
    isReadOnly,
    isStartDisabled,
    isEndDisabled,
    isEditMode = false,
    sx,
    isClearable = false,
    showToggleZone = false,
    zoneValue = Zone.utc,
    hasError = false,
    errorMessage,
}: DateTimeDurationBlockProps) => {
    const { register, setValue, getValues, setError, clearErrors } = useFormContext();
    const childWidth = getChildComponentWidth(durationWidthRatio);
    const [zone, setZone] = useState<Zone>(zoneValue);
    const [dateTime, setDateTime] = useState({
        start: getInitialDateTimeState(defaultStartDateTime),
        end: getInitialDateTimeState(defaultEndDateTime),
    });

    const [duration, setDuration] = useState(getDuration(dateTime.start.dateTime, dateTime.end.dateTime));
    const [hasDateTimeError, setHasDateTimeError] = useState(false);

    useEffect(() => {
        setHasDateTimeError(hasError);
    }, [hasError]);

    useEffect(() => {
        return () => {
            setHasDateTimeError(false);
            setDateTime(defaultDatetime);
            setDuration('');
        };
    }, []);

    const onDateTimeFieldChange = (fieldName: string, type: 'start' | 'end', date: DateTime, time: string) => {
        const result: { start: DateTimeState; end: DateTimeState } = isClearable
            ? cloneDeep(defaultDatetime)
            : cloneDeep(dateTime);

        if (date) result[type].date = date;
        else if (time) result[type].time = time;

        if (isClearable) {
            if (result[type].time) {
                result[type].date = dateTime[type].dateTime;
            }

            // clear time field should clear date field, vice versa
            if ((time === '' || !time) && !date) {
                onChange && onChange(null, type);
                setDateTime(defaultDatetime);
            }
        }

        if (result[type].date) {
            const targetDateTime = getDateTime(result[type].date, result[type].time);
            result[type].dateTime = targetDateTime;

            setDateTime(result);
            onChange && onChange(targetDateTime.toISO(), type);
        }
    };

    useEffect(() => {
        const newStartDateTimeState = getInitialDateTimeState(defaultStartDateTime, zone);
        const newEndDateTimeState = getInitialDateTimeState(defaultEndDateTime, zone);

        if (
            JSON.stringify(newStartDateTimeState) !== JSON.stringify(dateTime.start) ||
            JSON.stringify(newEndDateTimeState) !== JSON.stringify(dateTime.end)
        ) {
            setDateTime({
                start: newStartDateTimeState,
                end: newEndDateTimeState,
            });
        }

        if (startName && getValues(startName) !== defaultStartDateTime?.toString()) {
            if (!isEditMode) {
                setValue(startName, defaultStartDateTime?.toISO(), { shouldDirty: true });
            }
        }

        if (endName && getValues(endName) !== defaultEndDateTime?.toString()) {
            if (!isEditMode) {
                setValue(endName, defaultEndDateTime?.toISO(), { shouldDirty: true });
            }
        }
    }, [defaultStartDateTime, defaultEndDateTime]);

    useEffect(() => {
        if (dateTime.start.dateTime && dateTime.end.dateTime) {
            setDuration(getDuration(dateTime.start.dateTime, dateTime.end.dateTime));
        }

        if (dateTime.end.dateTime && endName) {
            const isError = dateTime.end.dateTime < dateTime.start.dateTime;
            setHasDateTimeError(isError);

            if (isError) {
                setError(endName, { type: 'invalidTime' });
            } else {
                clearErrors(endName);
            }
        }
    }, [dateTime]);

    const handleZone = (newZone: Zone | null) => {
        if (newZone !== null) {
            setZone(newZone);
            if (dateTime?.start?.dateTime || dateTime?.end?.dateTime) {
                setDateTime(() => {
                    const newStartDateTime = dateTime?.start?.date
                        ? getDateTime(dateTime.start.date, dateTime.start.time)
                        : null;
                    const newEndDateTime = dateTime?.end?.date
                        ? getDateTime(dateTime.end.date, dateTime.end.time)
                        : null;
                    return {
                        start: newStartDateTime ? getInitialDateTimeState(newStartDateTime, newZone) : dateTime.start,
                        end: newEndDateTime ? getInitialDateTimeState(newEndDateTime, newZone) : dateTime.end,
                    };
                });
            }
        }
    };

    useEffect(() => {
        handleZone(zoneValue);
    }, [zoneValue]);

    const isUTC = zone === Zone.utc;

    return (
        <Box sx={{ flexDirection: 'column', width: containerWidth }}>
            <Header title={startTitle} isOptional={isStartOptional} subTitle={startSubTitle} />
            <DateTimeDuration
                isReadOnly={isReadOnly}
                isDisabled={isStartDisabled}
                register={register}
                name={startName}
                dateLabel={isUTC ? startDateLabel : startDateLabelHkt || startDateLabel}
                timeLabel={isUTC ? startTimeLabel : startTimeLabelHkt || startTimeLabel}
                shouldShowDuration={false}
                defaultDateTime={dateTime.start.dateTime}
                minDate={minStartDate && getDateOnly(minStartDate)}
                maxDate={getMinOrMaxDateTime('min', [dateTime.end?.dateTime, maxStartDate])}
                onDateTimeChange={(date: DateTime, time: string) => {
                    onDateTimeFieldChange(startName, 'start', date, time);
                }}
                hasError={hasDateTimeError}
                errorMessage={errorMessage?.start}
                onBlur={(startTime) => {
                    onBlur && onBlur('start', startTime);
                    if (startName) {
                        setValue(startName, startTime?.setZone(Zone.utc)?.toISO(), {
                            shouldDirty: (startTime && !defaultStartDateTime?.equals(startTime)) || true,
                        });
                    }
                }}
                childWidth={childWidth}
                sx={sx}
                isClearable={isClearable}
                showToggleZone={showToggleZone}
                zone={zone}
                handleZoneChange={handleZone}
            />
            {endName && (
                <>
                    <Header title={endTitle} isOptional={isEndOptional} subTitle={endSubTitle} />
                    <DateTimeDuration
                        isReadOnly={isReadOnly}
                        isDisabled={isEndDisabled}
                        register={register}
                        name={endName}
                        dateLabel={isUTC ? endDateLabel : endDateLabelHkt || endDateLabel}
                        timeLabel={isUTC ? endTimeLabel : endTimeLabelHkt || endTimeLabel}
                        shouldShowDuration={shouldShowDuration}
                        duration={duration}
                        defaultDateTime={dateTime.end.dateTime}
                        minDate={dateTime.start.date}
                        onDateTimeChange={(date: DateTime, time: string) => {
                            onDateTimeFieldChange(endName, 'end', date, time);
                        }}
                        hasError={hasDateTimeError}
                        errorMessage={`*${endTitle} date/time cannot be before start date/time`}
                        childWidth={childWidth}
                        onBlur={(endTime) => {
                            onBlur && onBlur('end', endTime);
                            setValue(endName, endTime?.setZone(Zone.utc)?.toISO(), {
                                shouldDirty: !defaultEndDateTime?.equals(endTime) || true,
                            });
                        }}
                        sx={sx}
                    />
                </>
            )}
        </Box>
    );
};

export default DateTimeDurationBlock;
