import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import { getChecklistStatistics } from '../services/checklistDashboard';
import { IStatisticsResponse, IStatisticsRequest } from '../interface/ChecklistDashboard';

interface ChecklistDashboardStateProps {
    // loading
    isChecklistDashboardLoading: boolean;
    statistics: IStatisticsResponse | null;
}

export const getChecklistStatisticsThunk = createAsyncThunk<
    IStatisticsResponse,
    IStatisticsRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('getChecklistStatistics', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/dashboard/statistic', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = (await getChecklistStatistics(params)) || [];

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data;
});

const initialState: ChecklistDashboardStateProps = {
    isChecklistDashboardLoading: false,
    statistics: null,
};

const checklistDashboardSlice = createSlice({
    name: 'checklistDashboard',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isChecklistDashboardLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getChecklistStatisticsThunk.fulfilled, (state, action) => {
            state.statistics = action.payload;
        });
    },
});

export const selectChecklistDashboard = (state: RootState) => state.checklistDashboard;
export const { setLoading } = checklistDashboardSlice.actions;
export default checklistDashboardSlice.reducer;
