import React from 'react';
import { Box, Stack } from '@mui/material';

import ErrorState from '../../components/common/ErrorState';
import StyledButton from '../../components/common/Button';

import ErrorPageFooter from './ErrorPageFooter';
import { logout } from '../../services/auth';
import { useRoutes } from '../../app/hooks';


const ErrorPage403 = () => {
    const { redirectToDefaultHomePage } = useRoutes();
    const title = '403 Permission Denied';
    const subtitle = 'Try going to the Homepage or log out and log back in';
    const actionButtons = (
        <Stack direction='row'>
            <StyledButton variant='secondary' label='Logout' onClick={() => logout()} />
            <StyledButton label='Go to Homepage' onClick={redirectToDefaultHomePage} />
        </Stack>
    );

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ErrorState
                title={title}
                subtitle={subtitle}
                subTitleSx={{
                    fontSize: '16px',
                }}
                actionButtons={actionButtons}
            />
            <ErrorPageFooter />
        </Box>
    );
};

export default ErrorPage403;
