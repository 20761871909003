import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, DASHBOARD_API } from '../constants/api';
import { DailyDashboardRequestBody } from '../interface/Dashboard';


const getDailyDashboard = async (param: DailyDashboardRequestBody) => {
    const { airline, date } = param;
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, DASHBOARD_API.getDailyDashboard, {
        airline,
        date,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getDailyDashboard };
