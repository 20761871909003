// checklist colors
const Colors = {
    Text: {
        grey600: '#747474',
        grey700: '#474747',
        grey900: '#242424',
        placeholder: '#999B9F',
        errorHelperText: '#d32f2f',
        checkBoxDisabledText: '#959595',
    },
    Common: {
        white: '#fff',
        black: '#000',
        grey100: '#FBFBFB',
        grey200: '#F9F9F9',
        grey300: '#E9E9E9',
        grey400: '#D4D4D4',
        grey500: '#B8B8B8',
        grey600: '#747474',
        grey65073: '#78788073',
        grey65029: '#78788029',
        grey700: '#4C4C4C',
        grey800: '#2d2d2d',
        grey900: '#242424',
        grey252: '#252525',
        grey9001A: '#2424241A',
        blue100: '#E5F2FF',
        blue200: '#86CBFD',
        blue500: '#1274A1',
        blue800: '#0D5A7D',
        yellow200: '#FCE195',
        red100: '#F2B4AB',
        transparent: 'transparent',
        // success banner
        successBannerBorder: '#8CD0C6',
        successBannerBg: '#D8F7EE',
        successBannerIcon: '#357772',
        error: '#D11F00',
        // warning banner
        warningBannerBorder: '#E9C733',
        warningBannerBg: '#FFF6E1',
        warningBannerIcon: '#887000',
        normalBorderColor: '#C9CBCC',
    },
    Status: {
        inProgress: '#FCE195',
        blocked: '#C0E1FE',
        completed: '#B6E7DC',
        notStart: '#D4D4D4',
    },
};
export default Colors;
