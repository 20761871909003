import React, { useState, useEffect, useCallback, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import {
    Autocomplete,
    Box,
    InputAdornment,
    styled,
    Link,
    Stack,
    Typography,
    IconButton,
    FilterOptionsState,
} from '@mui/material';
import { TextInput } from '../../input';
import { LabelledCheckbox } from '..';
import { ArrowDown } from '../../Arrow';
import { ReactComponent as Cross } from '../../../../assets/icons/Cross.svg';
import { FilterItem } from '../index';
import PopperPaperContainer from '../../view/v1/PopperPaperContainer';
import StyledChip from '../../buttons/v1/StyledChip';

import colors from '../../../../style/color';

const CHIP_SECTION_VALUE = 'chipContent';
const RESET_BTN_VALUE = 'reset';

interface AutoCompleteFilterProps {
    value?: string[];
    label?: string;
    dataList: FilterItem[] | ((inputValue: string) => Promise<FilterItem[]>);
    popperWidth?: string;
    minSearchChars?: number;
    renderChipLabel?: (item: FilterItem) => string;
    onChange?: (value: string[]) => void;
}

const constructDataList = (data: FilterItem[]) => [
    { label: '', value: CHIP_SECTION_VALUE },
    ...data,
    { label: '', value: RESET_BTN_VALUE },
];

const filterOptions = (options: FilterItem[], state: FilterOptionsState<FilterItem>): FilterItem[] => {
    return options.filter(
        (option) =>
            [CHIP_SECTION_VALUE, RESET_BTN_VALUE].includes(option.value) ||
            option.label.toLowerCase().includes(state.inputValue.toLowerCase())
    );
};

const AutoCompleteFilter = (props: AutoCompleteFilterProps) => {
    const { label, minSearchChars = 0, value, dataList, popperWidth, renderChipLabel, onChange } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState<FilterItem[]>(() => constructDataList([]));
    const [selectedValues, setSelectedValues] = useState<FilterItem[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>();

    const refreshDataList = useCallback(
        typeof dataList === 'function'
            ? debounce(async (inputValue: string) => {
                  const results = await dataList(inputValue);
                  setOptions(constructDataList(results || []));
                  setLoading(false);
              }, 750)
            : null,
        [setOptions, dataList]
    );

    const onChipDelete = (value: string) => {
        setSelectedValues((pre) => pre.filter((selectedItem) => selectedItem.value !== value));
    };

    const onTextInput = (value: string) => {
        setInputValue(value);

        if (typeof dataList === 'function') {
            if (value.length >= minSearchChars) {
                setIsOpen(true);
                setLoading(true);
                refreshDataList(value);
            } else {
                const isEmptyOptions =
                    options.filter((item) => item.value !== CHIP_SECTION_VALUE && item.value !== RESET_BTN_VALUE)
                        .length <= 0;
                if (!isEmptyOptions) setOptions(constructDataList([]));
            }
        }
    };

    useEffect(() => {
        const selectedStringValues = selectedValues.map((item) => item.value);
        if (isEqual(selectedStringValues, value)) return;

        if (value.length <= 0) {
            setSelectedValues([]);
            return;
        }

        if (Array.isArray(dataList)) {
            const optionValues: FilterItem[] = value.reduce((acc, cur) => {
                const itemInList = dataList.find((datum) => datum.value === cur);
                if (itemInList) return [...acc, itemInList];
                return acc;
            }, []);

            if (!isEqual(selectedValues, optionValues)) setSelectedValues(optionValues);
        } else {
            const optionValues = value.map((item) => {
                return { label: item, value: item }; // now the label is same as value, if need to show the different label and value, need further handling
            });
            setSelectedValues(optionValues);
        }
    }, [value, dataList]);

    const onIconButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (!focus) {
            inputRef.current.focus();
            setIsOpen(true);
        } else if (inputValue) {
            onTextInput('');
        } else {
            inputRef.current.blur();
            setIsOpen(false);
        }
    };

    useEffect(() => {
        onChange?.(selectedValues.map((item) => item.value));
    }, [selectedValues]);

    useEffect(() => {
        if (Array.isArray(dataList) && !isEqual(dataList, options)) {
            setOptions(constructDataList(dataList));
        }
    }, [dataList]);

    return (
        <Autocomplete
            freeSolo={true}
            open={isOpen}
            renderTags={() => null}
            options={options}
            multiple={true}
            disableCloseOnSelect={true}
            forcePopupIcon={false}
            value={selectedValues}
            inputValue={inputValue}
            loading={loading}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            PaperComponent={PopperPaperContainer}
            disableClearable={true}
            onOpen={() => setIsOpen(true)}
            onInputChange={(event, value, reason) => reason !== 'reset' && onTextInput(value)}
            onFocus={() => setFocus(true)}
            onBlur={() => {
                setIsOpen(false);
                setFocus(false);
                onTextInput('');
            }}
            filterOptions={filterOptions}
            componentsProps={{
                paper: {
                    sx: {
                        ...(popperWidth && { minWidth: popperWidth }),
                    },
                },
            }}
            renderInput={(props) => (
                <TextInput
                    {...props}
                    label={isOpen || selectedValues.length <= 0 ? label : `${label} (${selectedValues.length})`}
                    inputRef={inputRef}
                    onKeyDown={(event) => {
                        if (event.key === 'Backspace') {
                            event.stopPropagation();
                        }
                    }}
                    InputLabelProps={{
                        ...(!isOpen &&
                            selectedValues.length > 0 && {
                                sx: {
                                    '&.MuiInputLabel-root': {
                                        color: colors.grayDark,
                                    },
                                },
                            }),
                    }}
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton onClick={onIconButtonClick}>
                                    <EndIcon focus={focus} inputValue={inputValue} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option, { selected }) => {
                if (option.value === CHIP_SECTION_VALUE) {
                    return (
                        <Box key={option.value} sx={{ p: '12px 16px' }}>
                            <Typography variant='regularGrayNormal14'>
                                {selectedValues.length > 0 ? 'Selected filters' : 'No filters selected'}
                            </Typography>
                            {selectedValues.length > 0 ? (
                                <Stack direction='row' gap='4px' flexWrap='wrap' marginTop='8px'>
                                    {selectedValues.map((item) => (
                                        <StyledChip
                                            key={item.value}
                                            label={renderChipLabel?.(item) || item.value}
                                            onDelete={() => onChipDelete(item.value)}
                                            onClick={() => onChipDelete(item.value)}
                                            active={true}
                                            size='small'
                                        />
                                    ))}
                                </Stack>
                            ) : (
                                false
                            )}
                        </Box>
                    );
                }

                if (option.value === RESET_BTN_VALUE) {
                    return (
                        <Link
                            key={option.value}
                            component='button'
                            underline='always'
                            sx={{
                                p: '16px 16px 8px',
                                fontSize: '14px',
                                color: colors.grayNormal,
                                textDecorationColor: 'inherit',
                            }}
                            onClick={() => setSelectedValues([])}
                        >
                            Clear all filters
                        </Link>
                    );
                }

                return (
                    <StyledOptionItem
                        component='li'
                        {...props}
                        onClick={(e) => {
                            // The native MUI autocomplete item does not work well with native FormControlLabel
                            // because FormControlLabel propergate the effect to the control, i.e. Checkbox here,
                            // which conflict with the native autocomplete li item click.
                            // This line stop the propergation of FormControlLabel to the checkbox.
                            // The checkbox state is controlled by the autocomplete selected item.
                            e.preventDefault();
                            e.stopPropagation();
                            props.onClick(e);
                        }}
                    >
                        <MemoizedLabelledCheckBox
                            value={option.value.toString()}
                            checked={selected}
                            label={option.label}
                        />
                    </StyledOptionItem>
                );
            }}
            onChange={(event, value) => {
                if (typeof value === 'string') return;
                setSelectedValues(value as FilterItem[]);
            }}
        />
    );
};

interface EndIconProps {
    focus: boolean;
    inputValue: string;
}

const EndIcon = (props: EndIconProps) => {
    const { focus, inputValue } = props;

    if (!focus) return <ArrowDown />;

    if (inputValue) return <Cross fill={colors.grayDark} />;

    return <ArrowDown sx={{ transform: 'rotate(180deg)' }} />;
};

const MemoizedLabelledCheckBox = React.memo(
    LabelledCheckbox,
    (preProps, curProps) => preProps.checked === curProps.checked
);

const StyledOptionItem = styled(Box)({
    '&:hover, &.Mui-focused': {
        backgroundColor: `${colors.buttonBgWhite} !important`,
    },
    '&.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: 'transparent',
    },
}) as typeof Box;

export default AutoCompleteFilter;
