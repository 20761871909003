import React, { PropsWithChildren } from 'react';
import { Box, Typography, Divider, Stack, SxProps } from '@mui/material';

import {
    sectionHeaderStyle,
    sectionHeaderValueStyle,
    dividerStyle,
    contentStyle,
} from '../../../aogStatusReport/AOGStatusReportStyles';

interface ReportFrameProps {
    title: string;
    contentSx?: SxProps;
    sx?: SxProps;
}

const ReportSession = (props: PropsWithChildren<ReportFrameProps>) => {
    const { title, contentSx, children, sx } = props;
    return (
        <Box sx={sx}>
            <Box sx={sectionHeaderStyle}>
                <Typography sx={sectionHeaderValueStyle}>{title}</Typography>
            </Box>
            <Stack sx={{ ...contentStyle, ...contentSx }} divider={<Divider sx={{ ...dividerStyle, my: '16px' }} />}>
                {children}
            </Stack>
        </Box>
    );
};

export default ReportSession;
