import axios, { AxiosRequestConfig } from 'axios';
import { store } from '../app/store';
import history from '../app/history';
import { sendHttpRequestByAxiosInstance } from './sharedApi';
import globalConfig from '../config/globalConfig';
import { getNewAccessToken, getAccessToken } from './auth';
import { ERROR_401_PATH } from '../constants/paths';

const MAX_AUTH_RETRY_COUNT = 3;

const instance = axios.create({
    baseURL: globalConfig.baseURL,
});

const getAuthHeader = () => {
    const headers: any = {
        'Content-Type': 'application/json',
    };
    const state = store.getState();
    const accessToken = state.auth.accessToken;
    if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
    }

    const { userProfile } = state.userProfile || {};
    const { currentGroupId } = userProfile || {};

    if (currentGroupId) {
        headers['X-GROUP-ID'] = currentGroupId;
    }

    return { headers };
};

const sendHttpRequest = (method: string, path: string, payload?: object, requestConfig?: AxiosRequestConfig) => {
    return sendHttpRequestByAxiosInstance(instance, method, path, payload, requestConfig, getAuthHeader());
};

instance.interceptors.request.use(async (config) => {
    const accessToken = await getAccessToken();

    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
        delete config.headers.Authorization;
    }
    return config;
});

instance.interceptors.response.use(
    async (response) => {
        return response;
    },
    async (err) => {
        //   refresh token and retry flow
        const errorStatusCode = err?.response?.status;
        if (err.response) {
            switch (errorStatusCode) {
                case 401:
                    try {
                        const config = err.config;
                        config.retry = MAX_AUTH_RETRY_COUNT;
                        config.__retryCount = config.__retryCount || 0;

                        if (config.__retryCount >= config.retry) {
                            history.push(ERROR_401_PATH);
                            return;
                        }
                        config.__retryCount += 1;

                        const authResult = await getNewAccessToken(true);
                        const requestObj = config?.data && !(config.data instanceof FormData) && JSON.parse(config.data);
                        const { accessToken } = authResult || {};

                        if (requestObj && typeof requestObj === 'object') {
                            config.data = requestObj;
                        }
                        const retryResp = await instance.request({
                            ...config,
                            headers: {
                                ...err.config.headers,
                                Authorization: `Bearer ${accessToken}`,
                            },
                            isRetry: true,
                        });

                        return retryResp;
                    } catch (error) {
                        console.error('refreshAndretryRequestFailed', error);
                        history.push(ERROR_401_PATH);
                    }
                    break;
                default:
                    // logout();
            }
        }
        return Promise.reject(err);
    }
);

export { sendHttpRequest };
