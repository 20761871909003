import { FlightObj } from '../slices/flightSlice';
import { IFlightBar, FlightBarType } from '../components/common/FlightBarInfo';
import { isDateStrAfter } from './dateHelper';
import { FlightBarSortItem } from '../constants/constants';

const getFlightBarSortFunc = (selectedSortItem: string): ((a: IFlightBar, b: IFlightBar) => number) => {
    switch (selectedSortItem) {
        case FlightBarSortItem.Std:
            return (a: IFlightBar, b: IFlightBar) => isDateStrAfter(a.STD, b.STD);
        case FlightBarSortItem.EtdAndAtd:
            return (a: IFlightBar, b: IFlightBar) =>
                a.ETD === '--' ? isDateStrAfter(a.ETD, b.ETD) : isDateStrAfter(a.ATD, b.ATD);
        case FlightBarSortItem.Sta:
            return (a: IFlightBar, b: IFlightBar) => isDateStrAfter(a.STA, b.STA);
        case FlightBarSortItem.Eta:
            return (a: IFlightBar, b: IFlightBar) => isDateStrAfter(a.ETA, b.ETA);
        default:
            return (a: IFlightBar, b: IFlightBar) => 0;
    }
};

const constructFlightBarObjects = (flightList: FlightObj[]): IFlightBar[] => {
    return [...flightList].map((flight) => ({
        iataAircraftType: flight.iataAircraftType,
        flightNum: flight.callsign,
        dpt: flight.scheduleDepartureAirport,
        dptTimeStr: flight.displayDepartureTime,
        arr: flight.scheduleArrivalAirport,
        arrTimeStr: flight.displayArrivalTime,
        type: FlightBarType.PAX_FLIGHT,
        state: flight.legState,
        flightRegi: flight.aircraftRegistration,
        subtype: flight.iataAircraftSubtype,
        ufi: flight.ufi,
        multipleEvent: flight.multipleEvent,
        selectable: true,
        airline: flight.airline,
        STD: flight.STD,
        ETD: flight.ETD,
        ATD: flight.ATD,
        STA: flight.STA,
        ETA: flight.ETA,
        ATA: flight.ATA,
    }));
};

const isIFlightBar = (item: IFlightBar | FlightObj): item is IFlightBar => {
    if (!item) return false;
    return (item as FlightObj).callsign === undefined;
};

export { FlightBarSortItem, getFlightBarSortFunc, constructFlightBarObjects, isIFlightBar };
