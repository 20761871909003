import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { getAdds } from '../services/add';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';

enum AddStatus {
    OPEN = 'open',
    CLOSE = 'close',
}

type AddParam = {
    tail: string;
    page?: number;
    size?: number;
    // startDate?: string;
    // endDate?: string;
    addStatus: AddStatus.OPEN | AddStatus.CLOSE;
};

type AddSlice = {
    addList: AddObj[];
    totalAdds: number;
    isAddLoading: boolean;
};

type AddObj = {
    addId?: string;
    deferDisplay: string;
    melReference: string;
    melCategory: string;
    daysRemaining: string;
    openDate: string;
    description: string;
    operationRestriction: string;
    aircraft?: string;
    subtype?: string;
};

type AddResponse = { adds: AddObj[]; total: number };

const initialState: AddSlice = {
    addList: [],
    totalAdds: 0,
    isAddLoading: false,
};

const getAllAddThunk = createAsyncThunk<AddResponse, AddParam, { state: RootState; rejectValue: ApiErrorObj }>(
    'add/getAdds',
    async (param: AddParam, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/getDefects')) {
            return rejectWithValue(ForbiddenObj);
        }

        const [err, data] = await getAdds(param);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        const returnDataList: AddObj[] = data.adds?.map((add) => {
            const {
                id,
                deferId,
                deferType,
                melReference,
                melCategory,
                daysRemaining,
                openDate,
                description,
                operationRestriction,
                aircraft,
                subtype,
            } = add;

            return {
                addId: id,
                deferDisplay: deferType + deferId,
                melReference,
                melCategory,
                daysRemaining,
                openDate,
                description,
                operationRestriction,
                aircraft,
                subtype,
            };
        });

        return { adds: returnDataList, total: data.total };
    }
);

export const addSlice = createSlice({
    name: 'adds',
    initialState,
    reducers: {
        cleanAddList: (state) => {
            state.addList = [];
            state.totalAdds = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllAddThunk.pending, (state) => {
                state.isAddLoading = true;
            })
            .addCase(getAllAddThunk.fulfilled, (state, { payload }) => {
                const { adds, total } = payload;
                state.isAddLoading = false;
                state.addList = adds;
                state.totalAdds = total;
            })
            .addCase(getAllAddThunk.rejected, (state, { payload }) => {
                state.isAddLoading = false;
            });
    },
});

const selectAdd = (state: RootState) => state.add;

export const { cleanAddList } = addSlice.actions;
export { getAllAddThunk, AddParam, selectAdd, AddStatus, AddObj };

export default addSlice.reducer;
