const statusBarHeight = 44;

const statusBarStyles = {
    '&': {
        '&.persist': {
            '&::after': {
                content: '" "',
                display: 'block',
                height: `${statusBarHeight}px`,
                minHeight: `${statusBarHeight}px`,
            },
        },
    },
    '& .MuiAlert-message': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
};

export { statusBarStyles as default, statusBarHeight };