import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AXIOS_ERR_CODE_TIMEOUT } from '../constants/api';
import { HTTP_REQUEST_TYPE } from '../constants/api';
import ServerError, { ApiErrorObj } from './ServerError';

const getHttpRequestResp = async (
    instance: AxiosInstance,
    method: string,
    path: string,
    payload?: object,
    requestConfig?: AxiosRequestConfig,
    authHeader?: object
) => {
    let response;
    const { headers, ...rest } = requestConfig || {};
    let config = authHeader;

    if (headers) {
        config = {
            headers: {
                ...(config['headers'] || {}),
                ...headers,
            },
        };
    }

    if (rest) {
        config = { ...config, ...rest };
    }

    try {
        if (method === HTTP_REQUEST_TYPE.get) {
            response = await instance.get(path, {
                ...config,
                params: payload || {},
            });
        } else if (method === HTTP_REQUEST_TYPE.post) {
            response = await instance.post(path, payload, config);
        }
    } catch (err) {
        const isJsonBlob = err.response?.data instanceof Blob && err.response?.data?.type === "application/json";
        const statusCode = err?.response?.status;
        if (err.code === AXIOS_ERR_CODE_TIMEOUT) {
            response = {
                error: {
                    status: 0,
                    title: 'TIME OUT',
                },
            };
        } else if (statusCode === 401) {
            const errObj: ApiErrorObj = {
                statusCode,
                title: err.response.statusText,
                detail: undefined,
                caseId: 401001, // refrfeshAndretryRequestFailed - 001
            };

            throw new ServerError({
                error: errObj,
                errors: errObj,
                status: statusCode,
            });
        } else if (isJsonBlob) {
            // Checking with Blob error, we need to show error msg in banner
            const errBlobMsg = JSON.parse(await err.response?.data.text());
            const errObj: ApiErrorObj = {
                statusCode,
                title: err.response.statusText,
                detail: errBlobMsg?.errors?.detail,
                caseId: errBlobMsg?.errors?.caseId,
            };

            throw new ServerError({
                error: errObj,
                errors: errObj,
                status: statusCode,
            });
        } else {
            response = err.response;
        }
    }

    const { status: httpStatus, data: result, headers: respHeaders } = response || {};

    if (httpStatus === 200) {
        return { result, respHeaders };
    }

    throw new ServerError(result);
};

const sendHttpRequestByAxiosInstance = (
    instance: AxiosInstance,
    method: string,
    path: string,
    payload?: object,
    requestConfig?: AxiosRequestConfig,
    authHeader?: object,
) => {
    const promise = getHttpRequestResp(instance, method, path, payload, requestConfig, authHeader);
    return promise
        .then((data) => {
            const { result, respHeaders } = data;
            return [null, result, respHeaders];
        })
        .catch((err) => {
            if (err instanceof ServerError) {
                return [err.toJson()];
            }
            return [err];
        });
};

export { sendHttpRequestByAxiosInstance };
