import { EventStatusType } from '../../constants/constants';
import { SortingItem } from '../common/Sort';
import { SortDirection } from '../common/sort/index';

const MIN_SEARCH_CHAR = 2;
const DEFAULT_FILTER_PAGE = 1;
const DEFAULT_FILTER_SIZE = 50;
const DEFAULT_SEARCHVIEW_GROUP_BY_STATUS = false;
const DEFAULT_SHIFTVIEW_GROUP_BY_STATUS = true;

enum CreateEventButtonDropdownItems {
    createHandoverNote = 'Create handover note',
}
enum EventType {
    event = 'Event',
    note = 'Note',
}
enum EventSubtype {
    aog = 'AOG',
    nonAog = 'non AOG',
}
enum DateType {
    event = 'Event',
    ets = 'ETS',
}

enum SortBy {
    reg = 'reg',
    date = 'date',
}

enum SortFiltersName {
    commonSort = 'commonSort',
    closedSort = 'closedSort',
    openedSort = 'openedSort',
    aogSort = 'aogSort',
    nonAogSort = 'nonAogSort',
    allyNonAogSort = 'allyNonAogSort',
}

const defaultRegSortDirection = SortDirection.ASC;
const defaultDateSortDirection = SortDirection.DESC;

const eventTypeOptions = [
    { name: 'Event', value: EventType.event },
    { name: 'Note', value: EventType.note },
];

const dateSearchOptions = [
    { display: 'Event', value: DateType.event },
    { display: 'ETS', value: DateType.ets },
];

const eventSubtypeOptions = [
    { name: 'AOG', value: EventSubtype.aog },
    { name: 'Non AOG', value: EventSubtype.nonAog },
];

const eventStatusOptions = [
    { name: 'In Progress', value: EventStatusType.open },
    { name: 'Closed', value: EventStatusType.closed },
];

enum TabName {
    shiftView = 'Shift view',
    searchView = 'Search view',
}
enum TabNameMapIndex {
    'Shift view' = 0,
    'Search view',
}

const shiftViewGroupOptions: SortingItem[] = [
    {
        name: 'Group by Open Status',
        value: EventStatusType.open,
        default: true,
    },
    {
        name: 'Group by Closed Status',
        value: EventStatusType.closed,
    },
];

const tabWidth = '157px';

export {
    MIN_SEARCH_CHAR,
    DEFAULT_FILTER_PAGE,
    DEFAULT_FILTER_SIZE,
    DEFAULT_SEARCHVIEW_GROUP_BY_STATUS,
    DEFAULT_SHIFTVIEW_GROUP_BY_STATUS,
    CreateEventButtonDropdownItems,
    eventTypeOptions,
    dateSearchOptions,
    eventSubtypeOptions,
    eventStatusOptions,
    shiftViewGroupOptions,
    DateType,
    tabWidth,
    SortBy,
    defaultRegSortDirection,
    defaultDateSortDirection,
    TabName,
    TabNameMapIndex,
    SortFiltersName,
};
