import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { styled, SxProps, Box } from '@mui/system';

import StyledCheckbox from './CheckBox';
import StyledRadioButton from './RadioButton';
import { ReactComponent as DepToArr } from '../../assets/icons/DepToArr.svg';
import { ReactComponent as MainToMain } from '../../assets/icons/MainToMain.svg';
import { ReactComponent as Airport } from '../../assets/icons/Airport.svg';
import { ReactComponent as Risk } from '../../assets/icons/Risk.svg';
import { ReactComponent as TrashBin } from '../../assets/icons/Trash.svg';
import { ReactComponent as WebChat } from '../../assets/icons/WebChat.svg';
import { FlightBarLabel, LegStateType } from '../../constants/constants';
import StatusLabel from './StatusLabel';
import theme from '../../style/theme';
import colors from '../../style/color';

import customStyles from './FlightBarStyles';

const Component = styled('div')({ ...customStyles });

enum ButtonTypes {
    RUBBISH_BIN = 'rubbishBin',
    CHAT = 'chat',
}

interface IFlightBarProps {
    sx?: SxProps;
    flightBar: IFlightBar;
    checked?: boolean;
    selectable?: boolean;
    displayButtons?: {
        button: ButtonTypes;
        onClickAction: (ufi: string) => void;
    }[];
    disabled?: boolean;
    multiple?: boolean;
    onClick?: (flight: IFlightBar) => void;
    leadingIcon?: JSX.Element;
    onLeadingIconClick?: () => void;
    selectableStyle?: 'default' | 'coloured';
    iconVariant?: 'simplified' | 'full';
    showLabel?: boolean;
}

interface IFlightBar {
    flightNum: string;
    dpt: string;
    dptTimeStr: string;
    arr: string;
    arrTimeStr: string;
    flightRegi: string;
    subtype: string;
    type: FlightBarType;
    state?: string;
    isRisk?: boolean;
    ufi?: string;
    selectable?: boolean;
    airline?: string;
    STD?: string;
    ETD?: string;
    ATD?: string;
    STA?: string;
    ETA?: string;
    ATA?: string;
    multipleEvent?: boolean;
    iataAircraftType?: string;
}

enum FlightBarType {
    PAX_FLIGHT = 'pax_flight',
    FREIGHT_FLIGHT = 'freight_flight',
    OTHER_FLIGHT = 'other_flight',
    MTCE = 'mtce',
}

const getFlightBarTypeStyle = (type: string, state: string) => {
    if (state === LegStateType.cancelled) {
        return {
            backgroundColor: theme.flightBar.disabledFlightBarColor,
            iconColor: theme.flightBar.cancelledIcon,
        };
    }
    switch (type) {
        case FlightBarType.PAX_FLIGHT:
            return {
                backgroundColor: theme.flightBar.pax,
                iconColor: theme.flightBar.paxIcon,
            };
        case FlightBarType.FREIGHT_FLIGHT:
            return {
                backgroundColor: theme.flightBar.freight,
                iconColor: theme.flightBar.freightIcon,
            };
        case FlightBarType.OTHER_FLIGHT:
            return {
                backgroundColor: theme.flightBar.other,
                iconColor: theme.flightBar.otherIcon,
            };
        case FlightBarType.MTCE:
            return {
                backgroundColor: theme.flightBar.maintentence,
                iconColor: theme.flightBar.maintenanceIcon,
            };
        default:
            return {
                backgroundColor: theme.flightBar.pax,
                iconColor: theme.flightBar.paxIcon,
            };
    }
};

const FlightBar = (props: IFlightBarProps) => {
    const {
        flightBar,
        checked = false,
        selectable = false,
        displayButtons = [],
        disabled = false,
        onClick,
        multiple = true,
        leadingIcon,
        sx,
        selectableStyle = 'default',
        iconVariant,
        showLabel = false,
    } = props;

    const [mflightBar, setMFlightBar] = useState(flightBar);
    const {
        flightNum,
        dpt,
        dptTimeStr,
        arr,
        arrTimeStr,
        flightRegi,
        subtype,
        state,
        type,
        isRisk,
        ufi = '',
    } = mflightBar || {};

    const { backgroundColor, iconColor } = getFlightBarTypeStyle(type, state);

    const canSelectStyle: SxProps = {
        backgroundColor: selectableStyle === 'default' ? `${theme.common.boxColor} !important` : backgroundColor,
        cursor: 'pointer',
    };

    const disabledStyle: SxProps = {
        backgroundColor: `${theme.flightBar.disabledFlightBarColor} !important`,
        height: '100%',
        width: '100%',
        opacity: '50%',
        position: 'absolute',
        zIndex: 1,
        cursor: 'auto',
    };

    useEffect(() => {
        setMFlightBar(flightBar);
    }, [flightBar]);

    return (
        <Component
            sx={{
                color: state === LegStateType.cancelled ? colors.grayDark : '',
                ...sx,
            }}
            className='common-flight-bar'
            onClick={() => (!disabled && selectable ? onClick(flightBar) : {})}
        >
            <Grid container className='container' columns={16} sx={selectable ? canSelectStyle : { backgroundColor }}>
                {disabled && <Box sx={disabledStyle} />}
                <Grid item className='item-svg'>
                    {isRisk && <Risk />}
                    {leadingIcon}
                </Grid>
                <Grid item xs={2} className='item' arial-name='relatedActivityValue'>
                    {flightNum}
                </Grid>
                <Grid container item xs={10} className='flight-info-with-icon'>
                    <Grid item xs={4} className='item' arial-name='relatedActivityValue'>
                        <span className='span-item'>{dpt}</span>
                        {dptTimeStr}
                    </Grid>
                    <Grid item className='item-svg'>
                        {type === FlightBarType.MTCE ? (
                            <MainToMain fill={iconColor} stroke={iconColor} />
                        ) : iconVariant === 'simplified' ? (
                            <Airport fill={iconColor} stroke={iconColor} />
                        ) : (
                            <DepToArr fill={iconColor} stroke={iconColor} />
                        )}
                    </Grid>
                    <Grid item xs={4} className='item' arial-name='relatedActivityValue'>
                        <span className='span-item'>{arr}</span>
                        {arrTimeStr}
                    </Grid>
                </Grid>
                <Grid container item xs={3} className='flight-bar-tail-section' spacing={2}>
                    {showLabel && state === LegStateType.cancelled && (
                        <Grid>
                            <StatusLabel
                                sx={{ padding: '6px 3px', marginRight: '12px' }}
                                status={FlightBarLabel.cancelled}
                            ></StatusLabel>
                        </Grid>
                    )}
                    <Grid className='item' arial-name='relatedActivityValue'>
                        {flightRegi}
                    </Grid>
                    <Grid className='item' arial-name='relatedActivityValue'>
                        {subtype}
                    </Grid>

                    {displayButtons && displayButtons.length > 0 && (
                        <Grid className='flight-button-group'>
                            {displayButtons.map((type) => (
                                <Grid item className='item-svg' key={type.button}>
                                    <div className='svg-button' onClick={() => type.onClickAction(ufi)}>
                                        <FlightBarIcon props={type.button} />
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    )}

                    {selectable && (
                        <Grid className='flight-button-group'>
                            {multiple && <StyledCheckbox checked={checked} label='Select' disabled={disabled} />}
                            {!multiple && (
                                <StyledRadioButton
                                    value={ufi}
                                    selectedValue={checked ? ufi : ''}
                                    label='Select'
                                    name=''
                                />
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Component>
    );
};

const FlightBarIcon = ({ props }: { props: ButtonTypes }) => {
    switch (props) {
        case ButtonTypes.RUBBISH_BIN:
            return <TrashBin />;
        case ButtonTypes.CHAT:
            return <WebChat width='17px' height='15px' fill={colors.grayDark} />;
        default:
            return <></>;
    }
};

export { FlightBar as default, IFlightBar, FlightBarType, ButtonTypes, IFlightBarProps };
