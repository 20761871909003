import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg';
import theme from '../../style/theme';

type ComponentProps = {
    onClick?: () => void;
    isDisabled?: boolean;
};

// this is for the icon stick with an <InputField />
const SearchButton = (props: ComponentProps) => {
    const { isDisabled = false } = props;

    const onBtnClick = () => {
        if ( !isDisabled && props.onClick ) {
            props.onClick();
        }
    };

    return (
        <Box onClick={onBtnClick}
            className={'btn-search'}
            sx={{
                background: isDisabled ? theme.common.backGroundColor : theme.palette.cathayJade.main,
                border: `1px solid ${isDisabled ? theme.common.borderGray : theme.palette.cathayJade.main}`,
                borderRadius: '0px 4px 4px 0px',
                width: '40px',
                margin: '4px 0px 4px -16px',
                zIndex: '99',
                display: 'flex',
                cursor: isDisabled ? 'auto' : 'pointer',
            }}
        >
            <SearchIcon style={{ margin: 'auto' }} fill={isDisabled ? theme.common.iconGray : 'white'} />
        </Box>
    );
};

export default SearchButton;
