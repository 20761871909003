import COLORS from '../../style/color';

const customStyles = {
    '&': {
        display: 'inline-block',
    },
    '&.common-flight-bar': {
        width: '100%',
        margin: '3px 0px',
    },
    '& .container': {
        width: `calc(100% - 2px)`, // make room for outline
        margin: `0 auto`, // align item to the center
        height: '40px',
        position: 'relative',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        '&:hover': {
            outline: `1px solid ${COLORS.grayLight}`,
        },
        textAlign: 'center',
        alignContent: 'center',
        '& .item': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
        },
        '& .item-svg': {
            overflow: 'initial',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0px 8px !important',
            '& .svg-button': {
                background: COLORS.white,
                border: `1px solid ${COLORS.grayLight}`,
                boxSizing: 'border-box',
                borderRadius: '2px',
                width: '28px',
                height: '28px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            },
        },
        '& .flight-info-with-icon': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .span-item': {
                margin: '0px 10px',
            },
        },
        '& .flight-button-group': {
            display: 'flex',
            justifyContent: 'flex-end',
            '& .MuiBox-root': {
                marginRight: '0px',
            },
        },
        '& .flight-bar-tail-section': {
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexFlow: 'row nowrap',
            margin: '0 auto',
            '& > *': {
                paddingLeft: '4px',
                textAlign: 'left',
            },
            '& .item': {
                width: '65px',
            },
        },
    },
};

export default customStyles;
