import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { RootState } from '../app/store';
import { getDailyDashboard } from '../services/dailyDashboard';
import { AirlineType } from '../constants/constants';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';
import { DailyDashboardDetailType, DailyDashboardRequestBody, DailyDashboardSlice } from '../interface/Dashboard';

export const getDailyDashboardThunk = createAsyncThunk<
DailyDashboardDetailType,
DailyDashboardRequestBody,
{ state: RootState; rejectValue: ApiErrorObj }
>('dashboard/getDailyDashboard', async (DailyDashboardRequestBody, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/performance/daily')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getDailyDashboard(DailyDashboardRequestBody);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data as DailyDashboardDetailType;
});

const initialState: DailyDashboardSlice = {
    blocksToDisplay: {
        [AirlineType.CX]: {},
        [AirlineType.LD]: {},
    },
    isDashboardLoading: false,
};

export const dailyDashboardSlice = createSlice({
    name: 'dailyDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDailyDashboardThunk.pending, (state) => {
                state.isDashboardLoading = true;
            })
            .addCase(getDailyDashboardThunk.fulfilled, (state, action) => {
                const carrier = action.meta?.arg?.airline;
                state.blocksToDisplay[carrier] = Object.assign(
                    state.blocksToDisplay[carrier] || {},
                    action.payload || {}
                );
                state.isDashboardLoading = false;
            })
            .addCase(getDailyDashboardThunk.rejected, (state) => {
                state.isDashboardLoading = false;
            });
    },
});

export const selectDailyDashboard = (state: RootState) => state.dailyDashboard;
export default dailyDashboardSlice.reducer;
