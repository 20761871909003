import { sendHttpRequest } from './api';
import {
    HTTP_REQUEST_TYPE,
    HTTP_RESPONSE_TYPE,
    COE_CRD_API_URL,
} from '../constants/api';
import { FilterAndSortOptions as CrdFilterAndSortOptions, CreateTriggerObj } from '../slices/coeEventManagementSlice';

const getCrdRulesList = async (params: CrdFilterAndSortOptions) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, COE_CRD_API_URL.getCrdRulesList, params);
    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const createTrigger  = async ({ param, headers }: { param: CreateTriggerObj; headers?: any }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, COE_CRD_API_URL.createTrigger, param, {
        headers,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getTriggerById = async ({ ruleId, headers }: { ruleId: number; headers?: any }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        COE_CRD_API_URL.getCrdRule,
        {
            ruleId,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getAircraftTypes = async () => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, COE_CRD_API_URL.getAircraftTypes);
    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export {
    getCrdRulesList,
    createTrigger,
    getTriggerById,
    getAircraftTypes,
};
