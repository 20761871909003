import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import InputField from '../../common/InputField';
import { getTotalPaxCount } from '../../../helper/iocAlertHelper';

import { FIELD_WIDTH_SMALL } from '../constants';

interface TotalPaxCountProps {
    registerNamePrefix: string;
}

const TotalPaxCount = (props: TotalPaxCountProps) => {
    const { registerNamePrefix } = props;

    const { setValue } = useFormContext();
    const paxCounts = useWatch({
        name: [
            `${registerNamePrefix}.F`,
            `${registerNamePrefix}.J`,
            `${registerNamePrefix}.W`,
            `${registerNamePrefix}.Y`,
        ],
    });

    const totalPaxCount = getTotalPaxCount(paxCounts);

    useEffect(() => {
        setValue(`${registerNamePrefix}.total`, totalPaxCount);
    }, [totalPaxCount]);

    return (
        <InputField
            label='Total'
            isDisabled={true}
            width={FIELD_WIDTH_SMALL}
            value={totalPaxCount?.toString() || '--'}
        />
    );
};

export default TotalPaxCount;
