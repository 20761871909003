import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';
import { getAircraftType, getDomCrews } from '../services/dom';
import { AircraftTypeRes, DomCrewRes } from '../interface/Dom';
import { DateFormat, getOffsetDay } from '../helper/dateHelper';

export const getAircraftTypeThunk = createAsyncThunk<
AircraftTypeRes[],
null,
{ state: RootState; rejectValue: ApiErrorObj }
>('dom/getAllAircraftType', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/dom/getAllAircraftType')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getAircraftType();

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as AircraftTypeRes[];
});

export const getTopCardThunk = createAsyncThunk<DomCrewRes[], null, { state: RootState; rejectValue: ApiErrorObj }>(
    'dom/getTopCard',
    async (params, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/dom/getDomCrews')) {
            return rejectWithValue(ForbiddenObj);
        }

        const [err, data] = await getDomCrews({
            start: getOffsetDay('start', -1, 'day', DateFormat.DateTimeWithSec),
            end: getOffsetDay('end', 1, 'day', DateFormat.DateTimeWithSec),
            duplicate: true,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as DomCrewRes[];
    }
);

export const getDomCrewsThunk = createAsyncThunk<DomCrewRes[], null, { state: RootState; rejectValue: ApiErrorObj }>(
    'dom/getDomCrews',
    async (params, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/dom/getDomCrews')) {
            return rejectWithValue(ForbiddenObj);
        }

        const [err, data] = await getDomCrews({
            start: getOffsetDay('start', undefined, 'month'),
            end: getOffsetDay('end', undefined, 'month'),
            duplicate: false,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as DomCrewRes[];
    }
);

export interface DomSlice {
    isDomLoading: boolean;
    aircraftTypeList: AircraftTypeRes[];
    topCardList: DomCrewRes[];
    domCrewList: DomCrewRes[];
}

const initialState: DomSlice = {
    isDomLoading: false,
    aircraftTypeList: [],
    topCardList: [],
    domCrewList: [],
};

export const domSlice = createSlice({
    name: 'dom',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAircraftTypeThunk.pending, (state) => {
                state.isDomLoading = true;
            })
            .addCase(getAircraftTypeThunk.fulfilled, (state, { payload }) => {
                state.isDomLoading = false;
                state.aircraftTypeList = payload;
            })
            .addCase(getAircraftTypeThunk.rejected, (state) => {
                state.isDomLoading = false;
            })
            .addCase(getTopCardThunk.pending, (state) => {
                state.isDomLoading = true;
            })
            .addCase(getTopCardThunk.fulfilled, (state, { payload }) => {
                state.isDomLoading = false;
                state.topCardList = payload;
            })
            .addCase(getTopCardThunk.rejected, (state, { payload }) => {
                state.isDomLoading = false;
            })
            .addCase(getDomCrewsThunk.pending, (state) => {
                state.isDomLoading = true;
            })
            .addCase(getDomCrewsThunk.fulfilled, (state, { payload }) => {
                state.isDomLoading = false;
                state.domCrewList = payload;
            })
            .addCase(getDomCrewsThunk.rejected, (state, { payload }) => {
                state.isDomLoading = false;
            });
    },
});

export const selectDom = (state: RootState) => state.dom;
export default domSlice.reducer;
