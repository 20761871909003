import { IAddNewGenericChecklistRequest, IChecklistEventFormData } from '../../interface/Checklist';
import Colors from '../../style/checklistColor';
import { getDefaultMeetingTime } from '../../helper/dateHelper';

enum EventType {
    IROPS_TYPHOON = 'IROPS_TYPHOON',
    DISRUPTIONS = 'DISRUPTIONS',
    IROPS_OTHERS = 'IROPS_OTHERS',
    TYPHOON = 'TYPHOON',
    GENERIC = 'GENERIC',
    BOMB_THREAT = 'BOMB_THREAT',
}

const EventTypeIdMap = {
    // TODO: update the id when backend finished
    [EventType.GENERIC]: '669a1a52069c4df5f1fb8da2',
    [EventType.BOMB_THREAT]: '669a1b1b069c4df5f1fb8da4',
    [EventType.TYPHOON]: '662a267935575802ee51821c',
    [EventType.DISRUPTIONS]: '6653df42cd81882c2b952c23',
    [EventType.IROPS_TYPHOON]: '664fff7e896e87a0b184d3fd',
    [EventType.IROPS_OTHERS]: '6653df0ccd81882c2b952c21',
};

const DetailPageTitleMapping = {
    [EventType.GENERIC]: 'Generic',
    [EventType.BOMB_THREAT]: 'Bomb Threat',
    [EventType.TYPHOON]: 'Typhoon',
    [EventType.DISRUPTIONS]: 'Disruptions',
    [EventType.IROPS_TYPHOON]: 'IROPs / Typhoon',
    [EventType.IROPS_OTHERS]: 'IROPs / Others',
};

enum ChecklistTemplateIds {
    IROPS = '664ffef5896e87a0b184d3fa',
}

enum ChannelType {
    teams = 'Teams',
    whatsapp = 'Whatsapp',
    sms = 'Sms',
    email = 'Email',
    teamsGroup = 'TeamsGroupChat',
}

enum TaskType {
    OBS_MESSAGE = 'OBS_MESSAGE',
    OBS_TEAMS = 'OBS_TEAMS',
    MANUAL = 'MANUAL',
}
enum TaskStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    BLOCKED = 'BLOCKED',
    COMPLETED = 'COMPLETED',
}

enum CharacteristicsType {
    itSystem = 'IT_SYSTEM',
    injuries = 'INJURIES',
    weather = 'WEATHER',
    airspace = 'AIRSPACE',
    technical = 'TECHNICAL',
    regulatory = 'REGULATORY',
    political = 'POLITICAL',
    other = 'OTHER',
}

enum SeverityType {
    gold = 'GOLD',
    silver = 'SILVER',
    bronze = 'BRONZE',
}

const createEventTypeList = [
    // {
    //     value: EventType.TYPHOON,
    //     menuLevel: 0,
    //     label: 'Typhoon',
    // },
    // {
    //     value: EventType.DISRUPTIONS,
    //     menuLevel: 0,
    //     label: 'Disruptions',
    // },
    // {
    //     value: EventType.GENERIC,
    //     menuLevel: 0,
    //     label: 'Generic',
    // },
    {
        value: EventType.BOMB_THREAT,
        menuLevel: 0,
        label: 'Bomb Threat',
    },
    // {
    //     label: 'IROPs',
    //     menuLevel: 0,
    //     value: 'IROPSMenu',
    //     nestedOptions: [
    //         {
    //             value: EventType.IROPS_TYPHOON,
    //             label: 'Typhoon',
    //             menuLevel: 1,
    //         },
    //         {
    //             value: EventType.IROPS_OTHERS,
    //             label: 'Others',
    //             menuLevel: 1,
    //         },
    //     ],
    // },
];

const characteristicsList = [
    { label: 'IT System', value: CharacteristicsType.itSystem },
    { label: 'Injuries', value: CharacteristicsType.injuries },
    { label: 'Weather', value: CharacteristicsType.weather },
    { label: 'Airspace', value: CharacteristicsType.airspace },
    { label: 'Technical', value: CharacteristicsType.technical },
    { label: 'Regulatory', value: CharacteristicsType.regulatory },
    { label: 'Political', value: CharacteristicsType.political },
    { label: 'Other', value: CharacteristicsType.other },
];

const severityTypeList = [
    { label: 'Gold', value: SeverityType.gold },
    { label: 'Silver', value: SeverityType.silver },
    { label: 'Bronze', value: SeverityType.bronze },
];

const checklists = [
    { label: 'Typhoon Preparation', value: '6645922053c2a14571ca47af' },
    { label: 'Typhoon Escalation', value: '664ac993d87c5d25882c8d97' },
    { label: 'Typhoon Disruption Recovery', value: '664aca21d87c5d25882c8d9a' },
];

const statusList = [
    { display: 'In progress', value: TaskStatus.IN_PROGRESS },
    { display: 'Blocked', value: TaskStatus.BLOCKED },
    { display: 'Completed', value: TaskStatus.COMPLETED },
    { display: 'Not started', value: TaskStatus.NOT_STARTED },
];

const statusBgColorMapping = {
    [TaskStatus.IN_PROGRESS]: Colors.Status.inProgress,
    [TaskStatus.BLOCKED]: Colors.Status.blocked,
    [TaskStatus.COMPLETED]: Colors.Status.completed,
    [TaskStatus.NOT_STARTED]: Colors.Status.notStart,
};

const FORM_WIDTH = '500px';
const CREATE_FORM_WIDTH = '564px';

const openStateChecklistIdPrefix = 'checklist:';
const openStateTaskIdPrefix = 'task:';

enum eventFormFieldName {
    eventTypeId = 'eventTypeId',
    eventType = 'eventType',
    eventTitle = 'eventTitle',
    eventDescription = 'eventDescription',
    checklistTemplateIds = 'checklistTemplateIds',
}

enum IROPSFieldName {
    networkImpact = 'eventDetails.networkImpact',
    characteristic = 'eventDetails.characteristic',
    location = 'eventDetails.location',
    severity = 'eventDetails.severity',
    attachments = 'eventDetails.attachments',
    actionLogs = 'actionLogs',
    start = 'meeting.start',
    end = 'meeting.end',
    recipients = 'meeting.recipients',
    timezone = 'meeting.eventOption.timezone',
    allowNewTimeProposals = 'meeting.eventOption.allowNewTimeProposals',
    isReminderOn = 'meeting.eventOption.isReminderOn',
    isAllDay = 'meeting.eventOption.isAllDay',
    displayName = 'meeting.eventOption.location.displayName',
    subject = 'meeting.subject',
    content = 'meeting.content',
    channels = 'meeting.channels',
    props = 'meeting.props',
    selectedChecklistTemplateId = 'meeting.selectedChecklistTemplateId',
}

enum BombThreatFieldName {
    flightOrPort = 'eventDetails.flightOrPort',
    attachments = 'eventDetails.attachments',
}

enum TaskFormFieldName {
    taskName = 'taskName',
    assignTo = 'assignTo',
    dueDatetime = 'dueDatetime',
    taskDescription = 'taskDescription',
    subject = 'taskFields.subject',
    recipients = 'taskFields.recipients',
    content = 'taskFields.content',
    details = 'taskFields.details',
    end = 'taskFields.end',
    start = 'taskFields.start',
    mediums = 'taskFields.mediums',
}

enum ChecklistFormFieldName {
    selectedChecklistTemplateId = 'selectedChecklistTemplateId',
    checklistName = 'checklistName',
    checklistDescription = 'checklistDescription',
    selectedTaskTemplateIds = 'selectedTaskTemplateIds',
}

enum EmailType {
    group = 'group',
    member = 'member',
    other = 'other',
}

const updateTaskBannerText = 'Task successfully saved';
const sendMeetingBannerText = 'Teams meeting successfully sent';
const sendMessageBannerText = 'Message successfully sent';

const checklistFormBaseDefaultValues: IAddNewGenericChecklistRequest = {
    eventId: '',
};

const eventFormBaseDefaultValues: IChecklistEventFormData = {
    eventType: '',
    eventTitle: '',
};

const eventFormGenericDefaultValues = (eventType: EventType) => {
    const defaultValues: IChecklistEventFormData = {
        eventTypeId: EventTypeIdMap[eventType] || '',
        eventType: eventType || '',
        eventTitle: '',
        eventDescription: '',
        checklistTemplateIds: [],
    };
    return defaultValues;
};

const eventFormIROPSDefaultValues = (eventType: EventType, recipients: string[]) => {
    const { start, end } = getDefaultMeetingTime();
    const defaultValues: IChecklistEventFormData = {
        eventTypeId: EventTypeIdMap[eventType] || '',
        eventType,
        eventTitle: '',
        eventDescription: '',
        eventDetails: {
            networkImpact: '',
            characteristic: '',
            location: '',
            severity: eventType === EventType.IROPS_TYPHOON ? SeverityType.silver : '',
            attachments: [],
        },
        checklistTemplateIds: [ChecklistTemplateIds.IROPS],
        meeting: {
            subject: 'Meeting 1',
            recipients: recipients.map((recipient) => ({ value: recipient, name: recipient })),
            content: {
                textContent: '',
                htmlContent: '',
            },
            eventOption: {
                timezone: 'HKT',
                allowNewTimeProposals: true,
                isReminderOn: true,
                isAllDay: false,
                location: {
                    displayName: '',
                },
            },
            end,
            start,
            channels: [ChannelType.teams],
            props: null,
            selectedChecklistTemplateId: '',
        },
        actionLogs: [],
    };
    return defaultValues;
};
const defaultTeamMeetingGroup = 'DL_CX_IOC_BW_Group_CX@cathaypacific.com';

const teamMeetingAndChatDefaultValues = () => {
    const { start, end } = getDefaultMeetingTime();
    return {
        subject: '',
        recipients: [{ name: defaultTeamMeetingGroup, email: defaultTeamMeetingGroup }],
        content: '',
        end,
        start,
        eventOption: {
            timezone: 'HKT',
        },
        channels: [ChannelType.teamsGroup],
        props: null,
    };
};

const eventFormBombThreatDefaultValues = (eventType: EventType) => {
    const defaultValues: IChecklistEventFormData = {
        eventTypeId: EventTypeIdMap[eventType] || '',
        eventType,
        eventTitle: '',
        eventDescription: '',
        eventDetails: {
            ufi: '',
            flightOrPort: {},
            arrivalPort: '',
            departurePort: '',
            attachments: [],
        },
    };
    return defaultValues;
};

const teamMeetingDefaultValues = (isNewMeeting: boolean) => {
    const { start, end } = getDefaultMeetingTime();
    const defaultValues: IChecklistEventFormData = {
        meeting: {
            subject: isNewMeeting ? '' : 'Meeting 1',
            recipients: [],
            content: {
                htmlContent: '',
                textContent: '',
            },
            end,
            start,
            selectedChecklistTemplateId: '',
            ...(isNewMeeting
                ? {
                      eventOption: {
                          timezone: 'HKT',
                          allowNewTimeProposals: true,
                          isReminderOn: true,
                          isAllDay: false,
                          location: {
                              displayName: '',
                          },
                      },
                  }
                : {}),
        },
    };
    return defaultValues;
};

enum CommentsItemMenuText {
    editComment = 'Edit comment',
    deleteComment = 'Delete comment',
}

enum SequenceType {
    checklist = 'checklist',
    task = 'task',
}

enum isRequireMeeting {
    YES = 'YES',
    NO = 'NO',
}
enum isSelectSetupMeeting {
    YES = 'YES',
    NO = 'NO',
}
enum threatAssessmentLevel {
    RED = 'RED',
    AMBER = 'AMBER',
    GREEN = 'GREEN',
}
enum isChinaFlight {
    YES = 'YES',
    NO = 'NO',
}
enum noMeetingThreatAssessmentLevel {
    GREEN = 'GREEN',
    NONE = 'NONE',
}

const isRequireMeetingMapping = {
    [isRequireMeeting.YES]: 'Yes, BTAG meeting is required',
    [isRequireMeeting.NO]: 'No, BTAG meeting is not required',
};
const isSelectSetupMeetingMapping = {
    [isSelectSetupMeeting.YES]: 'yes',
    [isSelectSetupMeeting.NO]: 'no',
};

const threatAssessmentLevelMapping = {
    [threatAssessmentLevel.RED]: 'Red',
    [threatAssessmentLevel.AMBER]: 'Amber',
    [threatAssessmentLevel.GREEN]: 'Green',
};

const isChinaFlightMapping = {
    [isChinaFlight.YES]: 'Yes',
    [isChinaFlight.NO]: 'No',
};

const noMeetingThreatAssessmentLevelMapping = {
    [noMeetingThreatAssessmentLevel.GREEN]: 'Green',
    [noMeetingThreatAssessmentLevel.NONE]: 'None',
};

const bombThreatTemplateIds = {
    teamsChatMeeting: '66c31b0b1c6056a430fb8dd0',
    teamsChatMeetingRedLevel: '66c31b0b1c6056a430fb8dd1',
    teamsChatMeetingAmberLevel: '66c31b0b1c6056a430fb8dd2',
    teamsChatMeetingGreenChinaFlightLevel: '66c31b0b1c6056a430fb8dd3',
    teamsChatMeetingGreenNotChinaFlightLevel: '66c31b0b1c6056a430fb8dd4',
    greenChinaFlightLevel: '66c31b0b1c6056a430fb8dd5',
    greenNotChinaFlightLevel: '66c31b0b1c6056a430fb8dd6',
    noneLevel: '66c31b0b1c6056a430fb8dd7',
};

const ChinaFlightStatus = {
    YES: 1,
    NO: 0,
    UNDEFINED: -1,
};

const removeBombThreatLevelTemplateList = [
    bombThreatTemplateIds.teamsChatMeetingRedLevel,
    bombThreatTemplateIds.teamsChatMeetingAmberLevel,
    bombThreatTemplateIds.teamsChatMeetingGreenChinaFlightLevel,
    bombThreatTemplateIds.teamsChatMeetingGreenNotChinaFlightLevel,
    bombThreatTemplateIds.greenChinaFlightLevel,
    bombThreatTemplateIds.greenNotChinaFlightLevel,
    bombThreatTemplateIds.noneLevel,
];

const bombThreatQuestionObject = {
    isRequireMeeting: [isRequireMeetingMapping.YES, isRequireMeetingMapping.NO],
    threatAssessmentLevel: [
        threatAssessmentLevelMapping.RED,
        threatAssessmentLevelMapping.AMBER,
        threatAssessmentLevelMapping.GREEN,
    ],
    isChinaFlight: [isChinaFlightMapping.YES, isChinaFlightMapping.NO],
    noMeetingThreatAssessmentLevel: [
        noMeetingThreatAssessmentLevelMapping.GREEN,
        noMeetingThreatAssessmentLevelMapping.NONE,
    ],
};

export {
    openStateTaskIdPrefix,
    openStateChecklistIdPrefix,
    EventType,
    TaskStatus,
    TaskType,
    ChecklistTemplateIds,
    ChannelType,
    IROPSFieldName,
    BombThreatFieldName,
    FORM_WIDTH,
    CREATE_FORM_WIDTH,
    checklists,
    statusList,
    statusBgColorMapping,
    characteristicsList,
    severityTypeList,
    CharacteristicsType,
    SeverityType,
    DetailPageTitleMapping,
    TaskFormFieldName,
    updateTaskBannerText,
    sendMeetingBannerText,
    sendMessageBannerText,
    EmailType,
    eventFormFieldName,
    createEventTypeList,
    eventFormBaseDefaultValues,
    eventFormGenericDefaultValues,
    eventFormIROPSDefaultValues,
    eventFormBombThreatDefaultValues,
    teamMeetingDefaultValues,
    ChecklistFormFieldName,
    checklistFormBaseDefaultValues,
    CommentsItemMenuText,
    SequenceType,
    teamMeetingAndChatDefaultValues,
    isRequireMeetingMapping,
    isSelectSetupMeetingMapping,
    threatAssessmentLevelMapping,
    isChinaFlightMapping,
    noMeetingThreatAssessmentLevelMapping,
    bombThreatTemplateIds,
    defaultTeamMeetingGroup,
    ChinaFlightStatus,
    removeBombThreatLevelTemplateList,
    bombThreatQuestionObject,
};
