import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import isEqual from 'lodash/isEqual';

import { StyledChip } from '../../view';

interface ChipLabelGroupProps {
    labelsList?: { label: string; value: string }[];
    value?: string[];
    onChange?: (labels: string[]) => void;
    isDisabled?: boolean;
    sx?: SxProps;
}

const ChipLabelGroup = (props: ChipLabelGroupProps) => {
    const { labelsList = [], value = [], isDisabled, onChange, sx } = props;
    const [selectedLabels, setSelectedLabels] = useState<string[]>(value);

    useEffect(() => {
        if (!isEqual(value, selectedLabels)) {
            setSelectedLabels(value);
        }
    }, [value]);

    const change = (item: { label: string; value: string }) => {
        let temp = selectedLabels.includes(item.value)
            ? selectedLabels.filter((selectedLabel) => selectedLabel !== item.value)
            : [...selectedLabels, item.value];
        temp = temp.sort();
        setSelectedLabels(temp);
        onChange?.(temp);
    };

    return (
        <Stack sx={sx} direction='row' flexWrap='wrap'>
            {labelsList.map((item) => {
                return (
                    <StyledChip
                        key={item.value}
                        sx={{ mt: '8px', mr: '8px' }}
                        label={item.label}
                        clickable={!isDisabled}
                        variant='outlined'
                        disabled={isDisabled}
                        selected={selectedLabels.includes(item.value)}
                        onClick={() => {
                            change(item);
                        }}
                    />
                );
            })}
        </Stack>
    );
};

export default ChipLabelGroup;
