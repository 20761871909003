import React from 'react';
import { Stack } from '@mui/system';

import Filters from './Filters';
import FlightList from './FlightList';
import NumberOfFlight from './NumberOfFlight';

const Content = () => {
    return (
        <Stack sx={{ height: '100%' }}>
            <NumberOfFlight />
            <Filters />
            <FlightList sx={{ overflowY: 'auto', mb: '8px' }} />
        </Stack>
    );
};

export default Content;
