import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    RadioGroup,
    Popper,
    Paper,
    PopperPlacementType,
    ClickAwayListener,
    Select,
    Typography,
    Popover,
} from '@mui/material';
import { SxProps } from '@mui/system';
import colors from '../../style/color';

import StyledRadioButton from './RadioButton';
import sortingStyles, { menuProps } from './SortStyles';
import { ReactComponent as ArrowDown } from '../../assets/icons/ArrowDown.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/Sorting.svg';
import { IconButton as StyledIconButton } from './buttons';

export type SortingItem = {
    name: string;
    value: string;
    default?: boolean;
};

type SortingProps = {
    prefix?: string;
    availableItems: SortingItem[];
    onChange?: (selected: string) => void;
    sx?: SxProps;
    disablePrefix?: boolean;
    variant?: 'standard' | 'standardWithBorder' | 'iconOnly';
    icon?: JSX.Element;
    value?: string;
    tooltipTitle?: string;
};

const Sort = ({
    prefix = 'Sort By',
    availableItems,
    onChange,
    sx,
    disablePrefix = false,
    variant = 'standard',
    icon = <SortIcon />,
    value,
    tooltipTitle,
}: SortingProps) => {
    const [sortBy, setSortBy] = useState<string>(
        value || availableItems.find((item) => item.default === true)?.value || ''
    );
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [, setPlacement] = React.useState<PopperPlacementType>();

    const selectedItem = availableItems.find((item) => item.value === sortBy);

    const togglePopper = (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
        setPlacement(newPlacement);
    };

    const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
        const eventEle = event.target as HTMLInputElement;
        if (eventEle.type === 'radio') {
            setSortBy(eventEle.value);
            onChange && onChange(eventEle.value);
            setOpen(false);
        }
    };

    useEffect(() => {
        if (value) {
            setSortBy(value);
        } else {
            setSortBy(availableItems.find((item) => item.default === true)?.value || '');
        }
    }, [value]);

    return (
        <>
            {variant === 'standard' && (
                <Box
                    sx={{
                        ...sortingStyles.containerBox,
                        ...sx,
                    }}
                >
                    {!disablePrefix && (
                        <Typography arial-name='sortName' sx={{ color: colors.grayDark }}>{`${prefix} `}</Typography>
                    )}
                    <Button
                        onClick={togglePopper('bottom')}
                        disableRipple
                        endIcon={<ArrowDown className={clsx({ 'Mui-PopOpen': open })} />}
                        sx={sortingStyles.sortingButton}
                        arial-name='sortSelectName'
                    >
                        {selectedItem?.name}
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        style={{ zIndex: 9999 }}
                        onClose={() => setOpen(false)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Paper sx={sortingStyles.paper}>
                            <RadioGroup>
                                {availableItems.map((item) => (
                                    <StyledRadioButton
                                        key={item.value}
                                        name={item.name}
                                        value={item.value}
                                        selectedValue={sortBy}
                                        handleOptionClick={handleOptionClick}
                                        sx={sx}
                                    />
                                ))}
                            </RadioGroup>
                        </Paper>
                    </Popover>
                </Box>
            )}
            {variant === 'standardWithBorder' && (
                <Select
                    sx={{
                        ...sx,
                        ...sortingStyles.containerBox,
                    }}
                    IconComponent={ArrowDown}
                    displayEmpty
                    MenuProps={menuProps}
                    renderValue={() => {
                        return (
                            <Typography variant='body7'>
                                {!disablePrefix ? `${prefix} ${selectedItem?.name || ''}` : selectedItem?.name || ''}
                            </Typography>
                        );
                    }}
                >
                    <RadioGroup>
                        {availableItems.map((item) => {
                            return (
                                <StyledRadioButton
                                    key={item.value}
                                    name={item.name}
                                    value={item.value}
                                    selectedValue={sortBy}
                                    handleOptionClick={handleOptionClick}
                                />
                            );
                        })}
                    </RadioGroup>
                </Select>
            )}
            {variant === 'iconOnly' && (
                <ClickAwayListener
                    onClickAway={() => {
                        setOpen(false);
                    }}
                >
                    <Box sx={sx}>
                        <StyledIconButton
                            iconContent={icon}
                            isActive={open}
                            isSelected={false}
                            onClick={togglePopper('bottom')}
                            tooltipTitle={tooltipTitle}
                        />
                        <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 9999 }}>
                            <Paper sx={sortingStyles.paper}>
                                <RadioGroup>
                                    {availableItems.map((item) => (
                                        <StyledRadioButton
                                            key={item.value}
                                            name={item.name}
                                            value={item.value}
                                            selectedValue={sortBy}
                                            handleOptionClick={handleOptionClick}
                                            sx={sx}
                                        />
                                    ))}
                                </RadioGroup>
                            </Paper>
                        </Popper>
                    </Box>
                </ClickAwayListener>
            )}
        </>
    );
};

export default Sort;
