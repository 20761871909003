import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import { getTemplates, saveTaskTemplate } from '../services/checklistManagement';
import {
    IChecklistTemplatesRequest,
    IChecklistTemplatesResponse,
    ICreateChecklistTemplateResponse,
    ICreateChecklistTemplateRequest,
    ICreateChecklistTemplatesForm,
    ISaveTaskTemplateRequest,
    ITemplateDeleteRequest,
    ITemplateTasks,
} from '../interface/ChecklistManagement';
import { createChecklistTemplate, deleteIRopsChecklistAndTaskTemplate } from '../services/checklistManagement';
import { checklistCreationFormDefaultValues, ChecklistType } from '../components/checklistManagement/constants';

interface ChecklistManagementStateProps {
    // loading
    isChecklistManagementLoading: boolean;
    checklistTemplatesList?: IChecklistTemplatesResponse[];

    // modal
    createChecklistTemplatesForm: {
        isOpen: boolean;
        isEdit?: boolean;
        values?: ICreateChecklistTemplatesForm;
    };
    saveTaskTemplateModal: {
        isOpen: boolean;
        values?: ISaveTaskTemplateRequest;
    };
    templateDeleteDialog: {
        isOpen: boolean;
        model?: string;
        taskTemplateIds?: string[];
        checklistTemplateIds?: string[];
    };
    adhocTasks: {
        IROPS: ITemplateTasks[];
        generic: ITemplateTasks[];
    };
}

const initialState: ChecklistManagementStateProps = {
    isChecklistManagementLoading: false,
    checklistTemplatesList: [],

    createChecklistTemplatesForm: {
        isOpen: false,
        isEdit: false,
        values: checklistCreationFormDefaultValues,
    },
    saveTaskTemplateModal: {
        isOpen: false,
    },
    templateDeleteDialog: {
        isOpen: false,
        model: '',
        taskTemplateIds: [],
        checklistTemplateIds: [],
    },
    adhocTasks: {
        IROPS: [],
        generic: [],
    },
};

export const getChecklistTemplateThunk = createAsyncThunk<
    IChecklistTemplatesResponse[],
    IChecklistTemplatesRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('getChecklistTemplate', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/template', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getTemplates(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data as IChecklistTemplatesResponse[];
});

export const createChecklistTemplateThunk = createAsyncThunk<
    ICreateChecklistTemplateResponse,
    ICreateChecklistTemplateRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('createChecklistTemplate', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (
        !resourceCheck(
            currentPermissionList,
            ResourceType.API,
            '/checklist/template/saveChecklistTemplate',
            ResourceAction.POST
        )
    ) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await createChecklistTemplate(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const saveTaskTemplateThunk = createAsyncThunk<
    { taskTemplateId: string },
    ISaveTaskTemplateRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('saveTaskTemplate', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (
        !resourceCheck(
            currentPermissionList,
            ResourceType.API,
            '/checklist/template/saveTaskTemplate',
            ResourceAction.POST
        )
    ) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await saveTaskTemplate(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const deleteChecklistAndTaskTemplateThunk = createAsyncThunk<
    {},
    ITemplateDeleteRequest,
    { state: RootState; rejectValue: ApiErrorObj }
>('deleteTemplate', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/checklist/template/delete', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await deleteIRopsChecklistAndTaskTemplate(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

const checklistManagementSlice = createSlice({
    name: 'checklistManagement',
    initialState,
    reducers: {
        updateChecklistTemplatesForm: (
            state,
            action: PayloadAction<{
                isOpen: boolean;
                isEdit?: boolean;
                values?: ICreateChecklistTemplatesForm;
            }>
        ) => {
            state.createChecklistTemplatesForm = action.payload;
        },
        updateSaveTaskTemplateModal: (
            state,
            action: PayloadAction<{ isOpen: boolean; values?: ISaveTaskTemplateRequest }>
        ) => {
            state.saveTaskTemplateModal = action.payload;
        },
        updateTemplateDeleteDialog: (
            state,
            action: PayloadAction<{
                isOpen: boolean;
                model?: string;
                taskTemplateIds?: string[];
                checklistTemplateIds?: string[];
            }>
        ) => {
            state.templateDeleteDialog = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getChecklistTemplateThunk.pending, (state) => {
                state.isChecklistManagementLoading = true;
            })
            .addCase(getChecklistTemplateThunk.fulfilled, (state, { payload, meta }) => {
                const { checklistType } = meta.arg;
                // Save tasks for irops & generic
                if (checklistType === ChecklistType.IROPS) {
                    state.adhocTasks.IROPS = payload[0]?.templateTasks || [];
                }
                if (checklistType === ChecklistType.generic) {
                    state.adhocTasks.generic = payload[0]?.templateTasks || [];
                }
                state.checklistTemplatesList = payload;
                state.isChecklistManagementLoading = false;
            })
            .addCase(getChecklistTemplateThunk.rejected, (state) => {
                state.isChecklistManagementLoading = false;
            })
            .addCase(createChecklistTemplateThunk.pending, (state) => {
                state.isChecklistManagementLoading = true;
            })
            .addCase(createChecklistTemplateThunk.fulfilled, (state) => {
                state.isChecklistManagementLoading = false;
            })
            .addCase(createChecklistTemplateThunk.rejected, (state) => {
                state.isChecklistManagementLoading = false;
            })
            .addCase(saveTaskTemplateThunk.pending, (state) => {
                state.isChecklistManagementLoading = true;
            })
            .addCase(saveTaskTemplateThunk.fulfilled, (state) => {
                state.isChecklistManagementLoading = false;
            })
            .addCase(saveTaskTemplateThunk.rejected, (state) => {
                state.isChecklistManagementLoading = false;
            })
            .addCase(deleteChecklistAndTaskTemplateThunk.pending, (state) => {
                state.isChecklistManagementLoading = true;
            })
            .addCase(deleteChecklistAndTaskTemplateThunk.fulfilled, (state, action) => {
                state.isChecklistManagementLoading = false;
            })
            .addCase(deleteChecklistAndTaskTemplateThunk.rejected, (state) => {
                state.isChecklistManagementLoading = false;
            });
    },
});

export const selectChecklistManagement = (state: RootState) => state.checklistManagement;
export const { updateChecklistTemplatesForm, updateSaveTaskTemplateModal, updateTemplateDeleteDialog } =
    checklistManagementSlice.actions;
export default checklistManagementSlice.reducer;
