import { BASIC_API } from '../constants/api';
import globalConfig from './globalConfig';

export const msalConfig = {
    auth: {
        clientId: globalConfig.azureClientId,
        authority: `https://login.microsoftonline.com/${globalConfig.azureTenantId}/`,
        redirectUri: BASIC_API.authRedirectUrlInReact,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['openid', 'profile', 'email', 'offline_access', `api://${globalConfig.azureClientId}/API`],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
