import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Typography, Box, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';

import WarningSecondary from './WarningSeconday';
import COLORS from '../../style/color';

interface EmptyResultProps {
    warning: string;
    suggestions?: string[];
    sx?: SxProps;
    warningSecondarySx?: SxProps;
}

const EmptyResultView = (props: EmptyResultProps) => {
    const { warning, suggestions, sx, warningSecondarySx } = props;

    return (
        <Box sx={sx}>
            <WarningSecondary displayText={warning} sx={warningSecondarySx} />
            {suggestions && suggestions.length > 0 && (
                <Box
                    sx={{
                        mt: '16px',
                        border: `1px solid ${COLORS.tableBorder}`,
                        padding: '24px',
                        backgroundColor: COLORS.white,
                    }}
                >
                    <Typography variant='body5'>Suggestions:</Typography>
                    {suggestions.map((suggestion, index) => {
                        return (
                            <ListItemText
                                key={uuidv4()}
                                primaryTypographyProps={{ variant: 'body7', mt: '16px' }}
                                primary={`${index + 1}. ${suggestion}`}
                            />
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default EmptyResultView;
