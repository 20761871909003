import React, { useEffect, useState, useCallback } from 'react';
import { SxProps } from '@mui/material';
import debounce from 'lodash.debounce';

import AutoCompleteDropdown, { AutoCompleteOption } from '../../AutoCompleteDropdown';
import { useAppDispatch } from '../../../../app/hooks';
import { getAirportListThunk } from '../../../../slices/eventSlice';

const MIN_PORT_SEARCH_CHARS = 2;

interface PortProps {
    label: string;
    value?: string;
    sx?: SxProps;
    onChange?: (option: AutoCompleteOption) => void;
    isDisabled?: boolean;
    errorMessage?: string;
}

const Port = (props: PortProps) => {
    const { label, sx, value, onChange, isDisabled, errorMessage } = props;

    const dispatch = useAppDispatch();
    const [port, setPort] = useState<AutoCompleteOption | null>(value ? { label: value, value: value } : null);
    const [portInputValue, setPortInputValue] = useState<string | null>(null);
    const [airportOptions, setAirportOptions] = useState<AutoCompleteOption[] | null>(null);

    const updateSearchKey = useCallback(
        debounce(async (inputValue: string) => {
            const filteredAirportList = await dispatch(getAirportListThunk(inputValue)).unwrap();
            setAirportOptions(
                filteredAirportList?.map((iataCode: string) => ({
                    label: iataCode,
                    value: iataCode,
                })) || []
            );
        }, 500),
        []
    );

    useEffect(() => {
        setPort(value ? { label: value, value: value } : null);
    }, [value]);

    return (
        <AutoCompleteDropdown
            label={label}
            options={airportOptions || []}
            value={port || ''}
            filterOptions={() => airportOptions || []}
            onChange={(option) => {
                setPortInputValue(null);
                onChange?.(option);
            }}
            onInputChange={(newInputValue) => {
                setPortInputValue(newInputValue || null);
                if (newInputValue?.length >= MIN_PORT_SEARCH_CHARS) {
                    updateSearchKey(newInputValue);
                }
            }}
            isDisabled={isDisabled}
            isShowEndAdornment={false}
            open={portInputValue?.length >= MIN_PORT_SEARCH_CHARS}
            loading={airportOptions === null}
            onBlur={() => {
                setPortInputValue(null);
                setTimeout(() => setAirportOptions(null), 100);
            }}
            disableClearable={false}
            sx={sx}
            hasError={!!errorMessage}
            errorMessage={errorMessage}
        />
    );
};

export default Port;
