import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { sendDisMessage, getDisMessage } from '../services/disMessage';
import { TaskType, DisMessageStatus } from '../constants/constants';
import UserInfo from '../interface/UserInfo';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';

export interface DisMessageObj {
    // TODO temporarily. Will remove after MCiLog is released
    group?: string;
    groups?: string[];
    header: string;
    body: string;
    userId?: string;
    eventId?: string;
}

interface DisMessageSlice {
    isDisMessageLoading: boolean;
    sentMessages: DisMessageRes[];
    latestMessage: DisMessageRes;
    eventErrorObject: ApiErrorObj;
}

interface DisMessageDetailsResult {
    to: string[];
    from: string;
    text: string;
    status: DisMessageStatus;
    subject: string;
}

interface DisMessageDetails {
    body: string;
    group: string;
    header: string;
    result: DisMessageDetailsResult;
    taskType: TaskType;
}

export interface DisMessageRes {
    id: string;
    eventId: string;
    taskType: TaskType;
    status: DisMessageStatus;
    details: DisMessageDetails;
    startTime: string;
    etsTime: string;
    endTime: string;
    updatedBy: UserInfo;
    createdBy: UserInfo;
    updatedAt: string;
    createdAt: string;
    active: boolean;
}

export const getDisMessageThunk = createAsyncThunk<
DisMessageRes[],
null,
{ state: RootState; rejectValue: ApiErrorObj }
>('disMessage/getDisMessage', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const { selectedEventId } = getState().event;
    const [err, data] = await getDisMessage(selectedEventId);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as DisMessageRes[];
});

export const sendDisMessageThunk = createAsyncThunk<{}, DisMessageObj, { state: RootState; rejectValue: ApiErrorObj }>(
    'disMessage/sendDisMessage',
    async (params: DisMessageObj, { getState, rejectWithValue }) => {
        const { selectedEventId } = getState().event;
        const { userProfile } = getState().userProfile;

        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/sendDisMessage')) {
            return rejectWithValue(ForbiddenObj);
        }

        const { userId } = userProfile;

        const disMessageObj: DisMessageObj = {
            ...params,
            group: params.groups[0],
            groups: params.groups,
            eventId: selectedEventId,
            userId: userId,
        };
        const [err, data] = await sendDisMessage(disMessageObj);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }
);

const initialState: DisMessageSlice = {
    isDisMessageLoading: false,
    sentMessages: [],
    latestMessage: null,
    eventErrorObject: null,
};

export const disMessageSlice = createSlice({
    name: 'disMessage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDisMessageThunk.pending, (state, { payload }) => {
            state.isDisMessageLoading = true;
            state.eventErrorObject = null;
        });
        builder.addCase(getDisMessageThunk.rejected, (state, { payload }) => {
            state.isDisMessageLoading = false;
        });
        builder.addCase(getDisMessageThunk.fulfilled, (state, { payload }) => {
            state.isDisMessageLoading = false;
            const latestMessage = [...payload].pop();
            state.sentMessages = payload;
            state.latestMessage = latestMessage;
        });
        builder.addCase(sendDisMessageThunk.pending, (state, { payload }) => {
            state.isDisMessageLoading = true;
            state.eventErrorObject = null;
        });
        builder.addCase(sendDisMessageThunk.fulfilled, (state, { payload }) => {
            state.isDisMessageLoading = false;
        });
        builder.addCase(sendDisMessageThunk.rejected, (state, { payload }) => {
            state.isDisMessageLoading = false;
        });
    },
});

export const selectDisMessage = (state: RootState) => state.disMessage;

export default disMessageSlice.reducer;
