import React from 'react';
import { Stack, Typography, TypographyProps } from '@mui/material';

import { SortDirection } from '../constants';
import SortControl, { SortControlProps } from './SortControl';

interface HeaderWithSortControlProps extends Partial<Pick<SortControlProps, 'direction'>> {
    sortControl?: boolean;
    sortFieldKey?: string;
    title: string;
    onSortChange?: (sortField: string, direction: SortDirection) => void;
    typographyProps?: TypographyProps;
}

const HeaderWithSortControl = (props: HeaderWithSortControlProps) => {
    const { sortControl = false, typographyProps, title, direction, sortFieldKey, onSortChange } = props;
    const onSortingChange = (direction: SortDirection | null) => {
        onSortChange?.(sortFieldKey || title, direction || SortDirection.DESC);
    };

    return (
        <Stack flexDirection='row' flexWrap='nowrap' alignItems='center' columnGap={1}>
            <Typography variant='boldGrayDark12' {...typographyProps}>
                {title}
            </Typography>
            {sortControl ? <SortControl direction={direction} onChange={onSortingChange} /> : false}
        </Stack>
    );
};

export default HeaderWithSortControl;
