import COLORS from '../../style/color';

const filterStyles = {
    select: {
        height: 40,
        color: COLORS.grayDark,
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${COLORS.borderGray}`,
        },

        '& .MuiSelect-icon': {
            top: 'calc(50% - .25em)',
        },
        '& legend': {
            width: '0px !important'
        }
    },
    menuItem: {
        '&.Mui-focusVisible, &.Mui-selected': {
            background: 'transparent',
        },
        '&:hover': {
            backgroundColor: `${COLORS.buttonBgWhite} !important`,
        },
    },
    checkbox: {
        width: '21px',
        height: '21px',
        marginRight: '8px',
        padding: '0px',
        color: 'gray.light',
        '&.Mui-checked': {
            color: COLORS.cathayJade,
        },
    },
    listItemText: {
        color: COLORS.grayDark,
    },
    resetButton: {
        fontSize: '14px',
        height: '1.5em',
        color: COLORS.grayNormal,
        textDecorationColor: 'inherit',
    },
    menuPaper: {
        boxShadow: '0 0 8px rgba(0,0,0,.36)',
        border: `1px solid ${COLORS.grayNormal}`,
    },
};

export const menuProps = {
    PaperProps: {
        style: filterStyles.menuPaper,
    },
};

export default filterStyles;
