import COLORS from '../../style/color';
import { SxProps } from '@mui/system';
import { statusBarHeight } from './StatusBarStyles';

type DialogSize = 'small' | 'middle' | 'fullScreen' | 'custom';

const getAdjustedDialogHeaderHeight = (hasFilterBar, hasStatusBar) => {
    return {
        headerHeight: `${94 + (hasFilterBar ? 66 : 0) + (hasStatusBar ? statusBarHeight : 0)}px`,
        headerPaddingTop: `${hasStatusBar ? statusBarHeight : 0}px`,
    };
};

export const headerHeightDefault = `94px`;
const headerHeightWithStatusBar = `${60 + statusBarHeight}px`;
const headerHeightWithFilterAndStatusBar = `${60 + 66 + statusBarHeight}px`;

export interface StyledDialogSxProps {
    container?: SxProps;
    dialogHeader?: SxProps;
    dialogContent?: SxProps;
    dialogFooter?: SxProps;
    decorationLine?: SxProps;
    form?: SxProps;
}

const styledDialogStyles = {
    container: {
        justifyContent: 'flex-end',
        '& .MuiDialog-container > .MuiPaper-root': {
            overflow: 'hidden',
        },
        '&.small .MuiDialog-container > .MuiPaper-root': {
            width: '32%',
            maxWidth: '32%',
            minWidth: '614px',
        },
        '&.middle .MuiDialog-container > .MuiPaper-root': {
            width: '65%',
            maxWidth: '65%',
            minWidth: '1243px',
        },
    },
    dialogToolbar: {
        '& .MuiToolbar-root': {
            color: COLORS.cathayJade,
            width: '75%',
            margin: '0 auto',
            flex: 1,
            '& .MuiTypography-root': {
                flex: 1,
            },
            '& .IconButton': {
                color: COLORS.hoverGreen,
            },
        },
    },
    dialogHeader: {
        backgroundColor: COLORS.white,
        boxShadow: `0px 2px ${COLORS.grayWhite}`,
        paddingRight: '0px !important',
        height: headerHeightDefault,
        '.hasStatusBar &': {
            height: headerHeightWithStatusBar,
        },
        '.hasStatusBar & .header-toolbar': {
            marginTop: `${statusBarHeight}px`,
        },
        '.headerFilterBar &': {
            height: 'auto !important',
            position: 'initial',
        },
        '.headerFilterBar.hasStatusBar &': {
            height: headerHeightWithFilterAndStatusBar,
        },
    },
    dialogContent: {
        display: 'column',
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '0px',
        paddingRight: '0px',
        borderColor: COLORS.grayWhite,
        overflow: 'hidden auto',
        '&.small': {
            width: '100%',
            height: '255px',
            padding: '36px 40px 0px 40px',
            marginTop: '0px',
        },
    },
    dialogFooter: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        height: '96px',
        width: '80%',
        margin: 'auto',
        justifyContent: 'space-between',
        padding: '24px 0px 40px 0px',
        transform: 'translateZ(0px)', // avoid boxShadow flashing when scrolling the dialog list above
        '&.small': {
            height: '104px',
        },
        '& button:first-of-type': {
            marginLeft: '0!important',
        },
        '& button:last-child': {
            marginRight: '0!important',
        },
    },
    decorationLine: {
        width: '100%',
        height: '8px',
        minHeight: '8px',
        // marginBottom: '25px',
        backgroundColor: COLORS.cathayJade,
    },
    form: {
        overflow: 'auto',
        '& > div': {
            overflow: 'hidden',
        },
    },
};

export {
    styledDialogStyles as default,
    // type
    DialogSize,
    // func
    getAdjustedDialogHeaderHeight,
};
