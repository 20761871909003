import React from 'react';
import { Stack } from '@mui/material';

import TitledSection from './TitledSection';
import IndividualPaxCount from './IndividualPaxCount';
import TotalPaxCount from './TotalPaxCount';

interface PaxCountProps {
    registerNamePrefix: string;
}

const PaxCount = (props: PaxCountProps) => {
    const { registerNamePrefix } = props;

    return (
        <TitledSection title='Pax Count'>
            <Stack direction='row'>
                <IndividualPaxCount registerName={`${registerNamePrefix}.F`} label='F' />
                <IndividualPaxCount registerName={`${registerNamePrefix}.J`} label='J' />
                <IndividualPaxCount registerName={`${registerNamePrefix}.W`} label='W' />
                <IndividualPaxCount registerName={`${registerNamePrefix}.Y`} label='Y' />
                <TotalPaxCount registerNamePrefix={registerNamePrefix} />
            </Stack>
        </TitledSection>
    );
};

export default PaxCount;
