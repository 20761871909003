import COLORS from '../../style/color';
import { CustomTableLayout } from 'pdfmake/interfaces';

const detailLayout = {
    hLineWidth: (rowIndex: number, node: any) => {
        //  caption has no bottom line
        return rowIndex === 0 || rowIndex === node.table.widths.length + 1 ? 1 : 0;
    },
    vLineWidth: (colIndex: number, node: any) => {
        // only  leftmost and rightmost have vertical line
        return colIndex === 0 || colIndex === node.table.widths.length ? 1 : 0;
    },
    hLineColor: (rowIndex: number, node: any) => {
        // only the last row have horization line
        return COLORS.tableBorder;
    },
    vLineColor: (colIndex: number, node: any) => {
        return colIndex === 0 || colIndex === node.table.widths.length ? COLORS.tableBorder : COLORS.white;
    },
    paddingLeft: (colIndex: number, node: any) => {
        return colIndex === 0 ? 18 : 0;
    },
    paddingRight: (colIndex: number, node: any) => {
        return 0;
    },
    paddingTop: (rowIndex: number, node: any) => {
        return rowIndex === 0 ? 6 : 4;
    },
    paddingBottom: (rowIndex: number, node: any) => {
        return rowIndex === 0 ? 8 : 2;
    },
} as CustomTableLayout;

const issueUpdateLayout = {
    ...detailLayout,
    hLineWidth: (rowIndex: number, node: any) => {
        return rowIndex === 0 || rowIndex === 1 || rowIndex === 2 ? 0 : 1;
    },
    paddingTop: (rowIndex: number, node: any) => {
        return rowIndex === 0 ? 4 : 8;
    },
    paddingBottom: (rowIndex: number, node: any) => {
        return rowIndex === 0 ? 8 : 16;
    },
} as CustomTableLayout;

const sparesAndToolingsLayout = {
    ...detailLayout,
    hLineWidth: (rowIndex: number, node: any) => {
        const row = node.table.body[rowIndex - 1];
        // topest row dont have
        if (!row) {
            return 0;
        }
        // commet row has undeline
        if (row[0]['border'] || rowIndex === node.table.body.length) {
            return 1;
        }
        return 0;
    },
    paddingTop: (rowIndex: number, node: any) => {
        return rowIndex === 0 ? 4 : 8;
    },
    paddingBottom: (rowIndex: number, node: any) => {
        const row = node.table.body[rowIndex];
        if (rowIndex === 0) {
            return 8;
        }
        if (!row) {
            return 0;
        }
        if (row[0]['border']) {
            return 16;
        }
        return 4;
    },
} as CustomTableLayout;

export { detailLayout, issueUpdateLayout, sparesAndToolingsLayout };
