const GANTT_PATH = '/gantt';
const DOM_PATH = '/dom';
const MCEVENTLIST_PATH = '/mceventlist';
const EVENTLIST_MC_PATH = '/eventlist/mc'; // new mc event list
const MCLOG_PATH = '/mclog';
const IOC_EVENTLIST_PATH = '/ioceventlist';
const IOC_REPORT_PATH = '/ioc/report';
const COE_EVENTLIST_PATH = '/coeeventlist';
const ISSUESUMMARYUPDATE_PATH = '/issuesummaryupdate';
const DISMESSAGE_PATH = '/dismessage';
const DISMESSAGE_MC_PATH = '/mc/dismessage';
const REPORT_MC_PATH = '/mc/statusreport';
const EVENT_EXPORT_MC_PATH = '/mc/event/export';
const RECOVERYPLAN_PATH = '/recoveryplan';
const REPORTDOWNLOAD_PATH = '/index';
const REPORTVIEW_PATH = '/report';
const AOGSUMMARY_PATH = '/aogsummary';
const ISSUE_SUMMARY_PATH = '/issueSummary';
const CHAT_PATH = '/chat';
const IOC_LOG_PATH = '/ioclog';
const CREATE_EVENT_PATH = '/createevent';
const LIVE_DASHBOARD_PATH = '/dashboard/live';
const DAILY_DASHBOARD_PATH = '/dashboard/daily';
const PRIORITY_FLIGHT_LIST_PATH = '/priorityFlight/list';
const PRIORITY_FLIGHT_DEFINITIONS_PATH = '/priorityFlight/definitions';
const ERROR_401_PATH = '/Error401';
const COE_EVENT_SIDE_PANEL_NEW_TAB_PATH = '/coeSidePanelNewTab';
const COE_EVENT_MANAGEMENT_PATH = '/crdeventManagementList';
const MAINTENANCE_SEARCH = '/maintenanceSearch';
const MAINTENANCE_SIDE_PANEL = '/maintenanceSidePanel';
const ALERT_MODULE_PATH = '/alertCentre';
const OPS_BROADCAST_PATH = '/opsBroadcast';
const CHECKLIST_EVENT_PATH = '/checklistEvent';
const CHECKLIST_MEETING_DETAIL_PATH = '/checklistMeetingDetail';
const CHECKLIST_MANAGEMENT_PATH = '/checklistManagement';
const CHECKLIST_DASHBOARD_PATH = '/checklistDashboard';

export {
    MCLOG_PATH,
    DOM_PATH,
    GANTT_PATH,
    IOC_EVENTLIST_PATH,
    IOC_REPORT_PATH,
    MCEVENTLIST_PATH,
    COE_EVENTLIST_PATH,
    AOGSUMMARY_PATH,
    ISSUESUMMARYUPDATE_PATH,
    RECOVERYPLAN_PATH,
    REPORTDOWNLOAD_PATH,
    REPORTVIEW_PATH,
    DISMESSAGE_PATH,
    DISMESSAGE_MC_PATH,
    CHAT_PATH,
    IOC_LOG_PATH,
    CREATE_EVENT_PATH,
    ISSUE_SUMMARY_PATH,
    LIVE_DASHBOARD_PATH,
    DAILY_DASHBOARD_PATH,
    PRIORITY_FLIGHT_LIST_PATH,
    PRIORITY_FLIGHT_DEFINITIONS_PATH,
    ERROR_401_PATH,
    COE_EVENT_SIDE_PANEL_NEW_TAB_PATH,
    COE_EVENT_MANAGEMENT_PATH,
    EVENTLIST_MC_PATH,
    MAINTENANCE_SEARCH,
    MAINTENANCE_SIDE_PANEL,
    REPORT_MC_PATH,
    EVENT_EXPORT_MC_PATH,
    ALERT_MODULE_PATH,
    OPS_BROADCAST_PATH,
    CHECKLIST_EVENT_PATH,
    CHECKLIST_MEETING_DETAIL_PATH,
    CHECKLIST_MANAGEMENT_PATH,
    CHECKLIST_DASHBOARD_PATH,
};
