import React, { useEffect, useState } from 'react';

import CreateAlertForm from './CreateAlertForm';
import PreviewAlertForm from './preview';
import AlertDialog from '../common/AlertDialog';
import { StyledFormDialog } from '../common/StyledDialog';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
    saveIOCAlertThunk,
    selectAlert,
    updateAlertFormStatus,
    deleteAlertDraftThunk,
    sendAlertThunk,
} from '../../slices/iocAlertSlice';
import { removeStatusMessages } from '../../slices/globalUISlice';
import { getEventByIdThunk, selectEvent } from '../../slices/eventSlice';
import { IFlightBar } from '../common/FlightBarInfo';
import { StatusBarTypes } from '../common/StatusBar';
import { generateSubject } from '../../helper/iocAlertHelper';

import { IOCAlertType } from '../../interface/IocAlert';
import { IOCAlertStatus } from './constants';

interface IOCAlertProps {
    primaryAffectedFlights?: IFlightBar[];
    secondaryAffectedFlights?: IFlightBar[];
}

const IOCAlert = (props: IOCAlertProps) => {
    const { primaryAffectedFlights, secondaryAffectedFlights } = props;
    const dispatch = useAppDispatch();
    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
    const [isShowLeaveGuard, setIsShowLeaveGuard] = useState<boolean>(false);
    const [formData, setFormData] = useState<IOCAlertType | null>(null);
    const {
        alertFormStatus: { isOpen, alertId, alertStatus, version },
    } = useAppSelector(selectAlert);
    const { selectedEvent } = useAppSelector(selectEvent);
    const { id: eventId } = selectedEvent || {};
    const isDraft = alertStatus === IOCAlertStatus.draft;

    const closeAlertForm = () => {
        dispatch(updateAlertFormStatus({ isOpen: false }));
    };

    const previewAlert = (data) => {
        if (!data.alertMessage.emailSubject) {
            data.alertMessage.emailSubject = generateSubject(data.primaryAffectedFlights, version);
        }

        setFormData(data);
        setIsPreviewOpen(true);
    };

    const saveAlertForm = (data) => {
        if (!data.alertMessage.emailSubject) {
            data.alertMessage.emailSubject = generateSubject(data.primaryAffectedFlights, version);
        }

        dispatch(saveIOCAlertThunk({ details: data, alertId: isDraft ? `${alertId}` : undefined }))
            .unwrap()
            .then(() => {
                dispatch(getEventByIdThunk({ id: eventId }));
                dispatch(updateAlertFormStatus({ isOpen: false }));
            });
    };

    const backToAlertForm = () => {
        setIsPreviewOpen(false);
    };

    const sendAlert = () => {
        setIsPreviewOpen(false);
        const versionText = isNaN(version) ? 'v1' : `v${version + 1}`;
        const alertIdToServer = isDraft ? `${alertId}` : undefined;
        dispatch(sendAlertThunk({ details: { ...formData, version: versionText }, alertId: alertIdToServer }))
            .unwrap()
            .then(() => {
                dispatch(getEventByIdThunk({ id: eventId }));
                dispatch(updateAlertFormStatus({ isOpen: false }));
            })
            .catch((error) => {});
    };

    const deleteDraft = () => {
        setIsShowLeaveGuard(true);
    };

    const confirmDeleteDraft = () => {
        if (!alertId) return;
        setIsPreviewOpen(false);
        setIsShowLeaveGuard(false);
        dispatch(deleteAlertDraftThunk(`${alertId}`))
            .unwrap()
            .then(() => {
                dispatch(getEventByIdThunk({ id: selectedEvent.id ?? '' }));
                dispatch(updateAlertFormStatus({ isOpen: false }));
            });
    };

    const backToDeleteDraft = () => {
        setIsShowLeaveGuard(false);
    };
    useEffect(() => {
        if (!isOpen) {
            dispatch(removeStatusMessages({ statusBarType: StatusBarTypes.Error, statusMsgCategory: 'iocAlert' }));
        }
    }, [isOpen]);

    return (
        <>
            <StyledFormDialog
                defaultValues={{}}
                title='Create IOC Alert'
                isOpen={isOpen}
                confirmBtnLabel='Preview IOC alert'
                secondaryActionBtnLabel='Save'
                onClickCloseBtn={closeAlertForm}
                onClickConfirmBtn={previewAlert}
                onClickSecondaryActionBtn={saveAlertForm}
                {...(isDraft && {
                    tertiaryActionBtnLabel: 'Delete',
                    onClickTertiaryActionBtn: deleteDraft,
                })}
            >
                <CreateAlertForm
                    primaryFlightBars={primaryAffectedFlights}
                    secondaryFlightBars={secondaryAffectedFlights}
                />
            </StyledFormDialog>

            {isDraft && (
                <AlertDialog
                    title='Please confirm you want to delete this entry'
                    showWarningIcon={true}
                    content={<>This action cannot be undone.</>}
                    isDialogVisible={isShowLeaveGuard}
                    closeBtnLabel='Back'
                    confirmBtnLabel='Confirm and delete entry'
                    handleClose={backToDeleteDraft}
                    handleConfirmRoute={confirmDeleteDraft}
                    variant='small'
                />
            )}
            <StyledFormDialog
                title='Preview IOC Alert'
                isOpen={isPreviewOpen}
                confirmBtnLabel='Confirm and Send IOC Alert'
                closeBtnLabel='Back'
                sx={{
                    dialogContent: {
                        '&.with-back-action': {
                            paddingLeft: '0px',
                        },
                    },
                }}
                backAction={backToAlertForm}
                onClickCloseBtn={backToAlertForm}
                onClickConfirmBtn={sendAlert}
            >
                <PreviewAlertForm formData={formData} />
            </StyledFormDialog>
        </>
    );
};

export default IOCAlert;
