import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { saveIssue, getAtaCodes, getIssueHistory, updateIssueLog } from '../services/issueSummary';
import { RootState } from '../app/store';
import { DropdownData } from '../components/common/Dropdown';
import { SortingItem } from '../components/common/Sort';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { TaskType, SORT_FIELD as SortOption, StatusList } from '../constants/constants';
import { getEventTasksByIdThunk, IssueUpdate, EventTasks, IssueSummaryDetailType } from './eventSlice';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';

export interface AtaCode {
    id: number;
    code: string;
    description: string;
}

export interface IssueSummarySaveObj {
    details: IssueSummaryDetailType[];
    userId: number;
    eventId: number;
}
export interface IssueSummarySlice {
    isIssueSummaryPageLoading: boolean;
    ataCodes: AtaCode[];
    issueSummaryInfo: EventTasks;
    reportOptions: DropdownData[];
    issueUpdates: IssueUpdate[];
    latestIssueUpdate: IssueUpdate;
    fieldsEditableArr: number[];
    sortOptions: SortingItem[];
    isAircraftServiceable: boolean;
}

const initialState: IssueSummarySlice = {
    isIssueSummaryPageLoading: false,
    ataCodes: [],
    issueSummaryInfo: null,
    issueUpdates: [],
    fieldsEditableArr: [],
    latestIssueUpdate: null,
    isAircraftServiceable: false,
    sortOptions: [
        { name: 'earliest', value: SortOption.earliest, default: true },
        { name: 'latest', value: SortOption.latest },
    ],
    reportOptions: [
        { display: 'System Alert', value: 'System Alert' },
        { display: 'MRO', value: 'MRO' },
        { display: 'Crew', value: 'Crew' },
        { display: 'IOC', value: 'IOC' },
        { display: 'OEM', value: 'OEM' },
        { display: 'Tech Services', value: 'Tech Services' },
    ],
};

export const getAtaCodesThunk = createAsyncThunk<AtaCode[], void, { state: RootState; rejectValue: ApiErrorObj }>(
    'issueSummary/getAtaCodes',
    async (_, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/getAtaCodes')) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await getAtaCodes();

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as AtaCode[];
    }
);

export const getIssueHistoryThunk = createAsyncThunk<
EventTasks[],
null,
{ state: RootState; rejectValue: ApiErrorObj }
>('issueSummary/getissueHistory', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const { selectedEventId } = getState().event;
    const [err, data] = await getIssueHistory(selectedEventId, TaskType.issueSummary);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const saveIssueSummaryThunk = createAsyncThunk<
EventTasks[],
IssueSummaryDetailType,
{ state: RootState; rejectValue: ApiErrorObj }
>('issueSummary/createIssue', async (params: IssueSummaryDetailType, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const { selectedEventId } = getState().event;
    const { issueSummaryInfo } = getState().issueSummary;
    const { id: issueSummaryId } = issueSummaryInfo || {};

    const issueDetailsObject = {
        details: [{ ...params, taskType: TaskType.issueSummary, id: issueSummaryId }],
        userId: parseInt(userId),
        eventId: parseInt(selectedEventId),
    };

    const [err, data] = await saveIssue(issueDetailsObject);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const updateIssueThunk = createAsyncThunk<{}, IssueUpdate, { state: RootState; rejectValue: ApiErrorObj }>(
    'issueSummary/updateIssue',
    async (params: IssueUpdate, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { userId, currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/issueLog')) {
            return rejectWithValue(ForbiddenObj);
        }

        const issueDetailsObject = {
            ...params,
            taskType: TaskType.issueSummary,
            userId: parseInt(userId),
        };

        const [err, data] = await updateIssueLog(issueDetailsObject);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data;
    }
);

export const issueSummarySlice = createSlice({
    name: 'issueSummary',
    initialState,
    reducers: {
        clearIssueSummaryState: (state) => {
            state.issueUpdates = initialState.issueUpdates;
            state.issueSummaryInfo = initialState.issueSummaryInfo;
        },
        addEditing: (state, action: PayloadAction<number>) => {
            const hasItem = state.fieldsEditableArr.find((item) => item === action.payload);
            if (!hasItem) {
                state.fieldsEditableArr.push(action.payload);
            }
        },
        removeEditing: (state, action: PayloadAction<number>) => {
            const hasItem = state.fieldsEditableArr.find((item) => item === action.payload);
            if (hasItem) {
                state.fieldsEditableArr = state.fieldsEditableArr.filter((item) => item !== action.payload);
            }
        },
        removeAllEditing: (state) => {
            state.fieldsEditableArr = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAtaCodesThunk.pending, (state) => {
                state.isIssueSummaryPageLoading = true;
            })
            .addCase(getAtaCodesThunk.fulfilled, (state, { payload }) => {
                state.isIssueSummaryPageLoading = false;
                state.ataCodes = payload;
            })
            .addCase(getAtaCodesThunk.rejected, (state) => {
                state.isIssueSummaryPageLoading = false;
            })
            .addCase(saveIssueSummaryThunk.pending, (state) => {
                state.isIssueSummaryPageLoading = true;
            })
            .addCase(saveIssueSummaryThunk.fulfilled, (state) => {
                state.isIssueSummaryPageLoading = false;
            })
            .addCase(saveIssueSummaryThunk.rejected, (state) => {
                state.isIssueSummaryPageLoading = false;
            })
            .addCase(updateIssueThunk.pending, (state) => {
                state.isIssueSummaryPageLoading = true;
            })
            .addCase(updateIssueThunk.fulfilled, (state) => {
                state.isIssueSummaryPageLoading = false;
            })
            .addCase(updateIssueThunk.rejected, (state) => {
                state.isIssueSummaryPageLoading = false;
            })
            .addCase(getIssueHistoryThunk.pending, (state) => {
                state.isIssueSummaryPageLoading = true;
            })
            .addCase(getIssueHistoryThunk.fulfilled, (state, { payload }) => {
                const issueSummaryInfo = payload?.filter(({ taskType }) => taskType === TaskType.issueSummary)?.[0];
                const issueUpdates = issueSummaryInfo?.issueUpdates;
                const latestIssueUpdate = issueUpdates?.length > 0 ? [...issueSummaryInfo.issueUpdates].pop() : null;

                state.issueSummaryInfo = issueSummaryInfo;
                state.issueUpdates = issueUpdates;
                state.latestIssueUpdate = latestIssueUpdate;
                state.isAircraftServiceable = latestIssueUpdate?.status === StatusList.aircraftServiceable;
                state.isIssueSummaryPageLoading = false;
            })
            .addCase(getIssueHistoryThunk.rejected, (state) => {
                state.issueSummaryInfo = initialState.issueSummaryInfo;
                state.issueUpdates = initialState.issueUpdates;
                state.latestIssueUpdate = initialState.latestIssueUpdate;
                state.isAircraftServiceable = initialState.isAircraftServiceable;
                state.isIssueSummaryPageLoading = false;
            })
            .addCase(getEventTasksByIdThunk.pending, (state) => {
                state.isIssueSummaryPageLoading = true;
                state.issueSummaryInfo = initialState.issueSummaryInfo;
                state.issueUpdates = initialState.issueUpdates;
                state.latestIssueUpdate = initialState.latestIssueUpdate;
                state.isAircraftServiceable = initialState.isAircraftServiceable;
            })
            .addCase(getEventTasksByIdThunk.fulfilled, (state, { payload }) => {
                const issueSummaryInfo =
                    payload.filter(({ taskType }) => taskType === TaskType.issueSummary)?.[0] || {};
                const issueUpdates = issueSummaryInfo?.issueUpdates;
                const latestIssueUpdate = issueUpdates?.length > 0 ? [...issueSummaryInfo.issueUpdates].pop() : null;

                state.issueSummaryInfo = issueSummaryInfo;
                state.issueUpdates = issueUpdates;
                state.latestIssueUpdate = latestIssueUpdate;
                state.isAircraftServiceable = latestIssueUpdate?.status === StatusList.aircraftServiceable;
                state.isIssueSummaryPageLoading = false;
            })
            .addCase(getEventTasksByIdThunk.rejected, (state) => {
                state.issueSummaryInfo = initialState.issueSummaryInfo;
                state.issueUpdates = initialState.issueUpdates;
                state.latestIssueUpdate = initialState.latestIssueUpdate;
                state.isAircraftServiceable = initialState.isAircraftServiceable;
                state.isIssueSummaryPageLoading = false;
            });
    },
});

export const selectIssueSummary = (state: RootState) => state.issueSummary;
export const { clearIssueSummaryState, addEditing, removeEditing, removeAllEditing } = issueSummarySlice.actions;

export default issueSummarySlice.reducer;
