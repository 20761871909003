import { combineReducers, configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authReducer from '../slices/authSlice';
import userProfileReducer from '../slices/userProfileSlice';
import eventReducer from '../slices/eventSlice';
import flightReducer from '../slices/flightSlice';
import addSliceReducer from '../slices/addSlice';
import issueSummaryReducer from '../slices/issueSummarySlice';
import fileReducer from '../slices/fileSlice';
import recoveryPlanReducer from '../slices/recoveryPlanSlice';
import reportReducer from '../slices/reportSlice';
import globalUIReducer from '../slices/globalUISlice';
import disMessageReducer from '../slices/disMessageSlice';
import historyReducer from '../slices/historySlice';
import chatReducer from '../slices/chatSlice';
import logReducer from '../slices/iocLogSlice';
import ganttReducer from '../slices/ganttSlice';
import coeEventReducer from '../slices/coeEventSlice';
import coeCrdRulesReducer from '../slices/coeEventManagementSlice';
import alertModuleReducer from '../slices/alertModuleSlice';
import liveDashboardReducer from '../slices/liveDashboardSlice';
import dailyDashboardReducer from '../slices/dailyDashboardSlice';
import maintenanceReducer from '../slices/maintenanceSlice';
import priorityFlightReducer from '../slices/priorityFlightSlice';
import aircraftTabsPanelReducer from '../slices/aircraftTabsPanelSlice';
import maintenanceTabsPanelReducer from '../slices/maintenanceTabsPanelSlice';
import flightTabsPanelReducer from '../slices/flightTabsPanelSlice';
import subfleetTabsPanelReducer from '../slices/subfleetTabsPanelSlice';
import packageIdReducer from '../slices/packageIdSlice';
import domReducer from '../slices/domSlices';
import alertReducer from '../slices/iocAlertSlice';
import commonReducer from '../slices/commonSlice';
import mcLogReducer from '../slices/mcLogSlice';
import mcEventListReducer from '../slices/mcEventListSlice';
import mcWrqReducer from '../slices/mcWrqSlice';
import opsBroadcastReducer from '../slices/opsBroadcastSlice';
import checklistReducer from '../slices/checklistSlice';
import checklistManagementReducer from '../slices/checklistManagementSlice';
import checklistDashboardReducer from '../slices/checklistDashboardSlice';

const reducers = combineReducers({
    auth: authReducer,
    userProfile: userProfileReducer,
    event: eventReducer,
    flight: flightReducer,
    add: addSliceReducer,
    issueSummary: issueSummaryReducer,
    file: fileReducer,
    recoveryPlan: recoveryPlanReducer,
    report: reportReducer,
    globalUI: globalUIReducer,
    disMessage: disMessageReducer,
    history: historyReducer,
    chat: chatReducer,
    log: logReducer,
    gantt: ganttReducer,
    coeevent: coeEventReducer,
    coecrdrules: coeCrdRulesReducer,
    alerts: alertModuleReducer,
    liveDashboard: liveDashboardReducer,
    dailyDashboard: dailyDashboardReducer,
    maintenance: maintenanceReducer,
    priorityFlight: priorityFlightReducer,
    aircraftTabsPanel: aircraftTabsPanelReducer,
    maintenanceTabsPanel: maintenanceTabsPanelReducer,
    flightTabsPanel: flightTabsPanelReducer,
    subfleetTabsPanel: subfleetTabsPanelReducer,
    packageId: packageIdReducer,
    dom: domReducer,
    alert: alertReducer,
    common: commonReducer,
    mcLog: mcLogReducer,
    mcEventList: mcEventListReducer,
    mcWorkRequest: mcWrqReducer,
    opsBroadcast: opsBroadcastReducer,
    checklist: checklistReducer,
    checklistManagement: checklistManagementReducer,
    checklistDashboard: checklistDashboardReducer,
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActionPaths: [
                    'payload.value',
                    'payload.blob',
                    'payload.eventDateAndTime.startDate',
                    'payload.eventDateAndTime.endDate',
                    'payload.startDate',
                    'payload.endDate',
                    'meta.arg.file',
                    'meta.arg.openDate',
                    'meta.arg.lastUpdated',
                    'meta.arg.uploadFiles',
                    'meta.arg.details.nextFlightDept',
                ],
                ignoredPaths: [
                    'event.selectedFlightFilter.startDate',
                    'event.selectedFlightFilter.endDate',
                    'maintenance.maintenanceObjList',
                    'coeevent.coeEventListFilters.startDate',
                    'coeevent.coeEventListFilters.endDate',
                ],
            },
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
