import React from 'react';
import { Stack } from '@mui/material';

import { SubtitleDisplay } from '../../common/view/';
import { getDisplayText } from '../../../helper/stringHelper';

import { PREVIEW_COLUMN_WIDTH } from '../constants';

interface PreviewFlightBlockProps {
    flightNumber: string;
    iataAircraftType: string;
    iataAircraftSubtype: string;
    aircraftRegistration: string;
}

const PreviewFlightBlock = (props: PreviewFlightBlockProps) => {
    const { flightNumber, iataAircraftType, iataAircraftSubtype, aircraftRegistration } = props;
    return (
        <Stack direction='row'>
            <SubtitleDisplay
                label='Flight No.'
                value={getDisplayText(flightNumber)}
                containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
            />
            <SubtitleDisplay
                label='Aircraft Type'
                value={getDisplayText(iataAircraftType)}
                containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
            />
            <SubtitleDisplay
                label='Subtype'
                value={getDisplayText(iataAircraftSubtype)}
                containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
            />
            <SubtitleDisplay
                label='Registration'
                value={getDisplayText(aircraftRegistration)}
                containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
            />
        </Stack>
    );
};

export default PreviewFlightBlock;
