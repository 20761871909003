import { styled } from '@mui/material';
import colors from '../../style/color';

const ErrorMsg = styled('div')({
    fontFamily: 'CathaySans',
    fontSize: '12px',
    color: colors.borderRed,
});

export default ErrorMsg;
