import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import debounce from 'lodash.debounce';

import { FilterSearchBar } from '../common/input';
import { DateRangePickerField } from '../common/DatePicker';
import { FlightSearchMainContext } from './FlightSearch';

const SearchHeader = () => {
    const {
        onSearch,
        minDate,
        maxDate,
        filters: { keySearch, startDate: filterStartDate, endDate: filterEndDate },
    } = useContext(FlightSearchMainContext);

    return (
        <Stack flexDirection='row'>
            <FilterSearchBar
                value={keySearch}
                sx={{ flexGrow: 1, mr: '16px', mt:'4px', height:'40px' }}
                placeholder='Search for port, aircraft type, tail and flight number'
                onClearClick={() => {
                    onSearch({
                        keySearch: '',
                    });
                }}
                onChange={debounce((word: string) => {
                    onSearch({
                        keySearch: word,
                    });
                }, 1000)}
            />
            <DateRangePickerField
                fromLabel='From (UTC)'
                toLabel='To (UTC)'
                minStartDate={minDate}
                maxEndDate={maxDate}
                startDate={filterStartDate}
                endDate={filterEndDate}
                disableSearch={false}
                searchClicked={(startDate, endDate) =>
                    onSearch({
                        startDate: startDate,
                        endDate: endDate,
                    })
                }
            />
        </Stack>
    );
};

export default SearchHeader;
