import React from 'react';
import NumberInput from 'react-number-format';
import TextInput, { TextInputProps } from './TextInput';

interface TimeInputProps extends Partial<Omit<TextInputProps, 'ref' | 'type' | 'onChange'>> {
    value?: string | number;
    defaultValue?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, hour: string, min: string) => void;
}

const format = (val: string = ''): string => {
    const paddedValue = val.padEnd(4, '0');
    const hourPortion = paddedValue.substring(0, 2);
    const minPortion = paddedValue.substring(2, 4);
    const hour = parseInt(hourPortion) > 23 ? '23' : hourPortion;
    const min = parseInt(minPortion) > 59 ? '59' : minPortion;
    return `${hour}${min}`;
};

const TimeInput = (props: TimeInputProps) => {
    const { onChange, ...otherProps } = props;
    return (
        <NumberInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                const hour = value.substring(0, 2);
                const min = value.substring(2, 4);
                onChange(e, hour, min);
            }}
            {...otherProps}
            customInput={TextInput}
            format={format}
        />
    );
};

export default TimeInput;
