import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, EVENT_TASK_API } from '../constants/api';
import { sendHttpRequest } from './api';

const getEventTasksByEventIdAndType = async (params) => {
    const { eventId, types } = params;
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENT_TASK_API.eventTasks, {
        eventId,
        types,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateEventTasksByEventId = async (params) => {
    const { eventId, userId, details } = params;
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENT_TASK_API.eventTasks, {
        eventId,
        userId,
        details: [details],
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getEventTasksByEventIdAndType, updateEventTasksByEventId };
