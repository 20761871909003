import React from 'react';
import { Divider } from '@mui/material';

const FormBlockDivider = () => {
    return (
        <Divider
            sx={{
                mt: '32px',
                mb: '24px',
            }}
            flexItem
        />
    );
};

export default FormBlockDivider;
