import React from 'react';
import { Stack, Typography, SxProps } from '@mui/material';

import Dropdown, { DropdownData } from '../../Dropdown';

interface DropdownWithLabelProps {
    label: string;
    labelPosition?: 'left' | 'right' | 'top' | 'bottom';
    labelSx?: SxProps;
    dataList?: Array<DropdownData>;
    value?: string;
    onChange?: (value: string) => void;
    dropdownWidth?: string;
}

const getDirection = (position: 'left' | 'right' | 'top' | 'bottom') => {
    switch (position) {
        case 'left':
            return 'row';
        case 'right':
            return 'row-reverse';
        case 'top':
            return 'column';
        case 'bottom':
            return 'column-reverse';
        default:
            return 'row';
    }
};

const DropdownWithLabel = (props: DropdownWithLabelProps) => {
    const { label, labelPosition = 'left', labelSx, dataList = [], value, onChange, dropdownWidth } = props;
    return (
        <Stack direction={getDirection(labelPosition)} alignItems={'center'} spacing={'4px'}>
            <Typography variant='body8' sx={labelSx} noWrap>
                {label}
            </Typography>
            <Dropdown
                width={dropdownWidth || '72px'}
                hiddenLabel
                dataList={dataList}
                callback={(value: string) => {
                    onChange && onChange(value);
                }}
                value={value}
            />
        </Stack>
    );
};

export default DropdownWithLabel;
