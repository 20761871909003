import React from 'react';
import { Controller } from 'react-hook-form';

import InputField from '../../common/InputField';
import { FIELD_WIDTH_SMALL } from '../constants';

interface IndividualPaxCountProps {
    registerName: string;
    label: string;
}

const IndividualPaxCount = (props: IndividualPaxCountProps) => {
    const { registerName, label } = props;
    return (
        <Controller
            name={registerName}
            defaultValue={null}
            render={({ field: { value, onChange } }) => (
                <InputField
                    label={label}
                    value={value ?? '--'}
                    width={FIELD_WIDTH_SMALL}
                    onClick={() => {
                        if (value === null) {
                            onChange('');
                        }
                    }}
                    onBlur={(e) => {
                        if (e.target.value === '') {
                            onChange(null);
                        }
                    }}
                    onChange={(e) => {
                        const numberValue = e.target.value.replace(/[^0-9]/g, '');
                        onChange(numberValue === '' ? numberValue : parseInt(numberValue));
                    }}
                />
            )}
        />
    );
};

export default IndividualPaxCount;
