import { DateTime } from 'luxon';
import COLORS from '../../style/color';
import { DateFormat } from '../../helper/dateHelper';

enum SortType {
    DESC = 'DESC',
    ASC = 'ASC',
}

enum AlertDisplay {
    POPOVER = 'popover',
    STANDALONE = 'standalone',
}

enum Severity {
    RED = 'red',
    AMBER = 'amber',
    RESOLVED = 'resolved',
}

const severityColor = {
    [Severity.AMBER]: COLORS.amber,
    [Severity.RED]: COLORS.borderRed,
    [Severity.RESOLVED]: COLORS.grayLight,
};

const defaultFilter = {
    orderedBy: 'createdAt', // 'severity' | 'nextFlightEtd' | 'createdAt'
    order: SortType.DESC, // 'ASC' | 'DESC'
    severity: [Severity.RED, Severity.AMBER],
    acType: [],
    acSubtype: [],
    registration: [],
    alertType: [],
    keywords: '',
    triggerFrom: '',
    triggerTo: '',
};

const pageDefaultFilter = {
    orderedBy: 'createdAt',
    order: SortType.DESC,
    severity: [],
    acType: [],
    acSubtype: [],
    registration: [],
    alertType: [],
    keywords: '',
    triggerFrom: DateTime.now().toUTC().minus({ days: 30 }).startOf('day').toFormat(DateFormat.isoFormat),
    triggerTo: DateTime.now().toUTC().endOf('day').toFormat(DateFormat.isoFormat),
    page: 1,
    size: 50,
};

const alertType = {
    pkgInComp: 'Package incomplete',
    aircraftETS: 'Aircraft ETS',
    maintStatus: 'Maint Status <80%',
};

export { SortType, Severity, AlertDisplay, severityColor, defaultFilter, pageDefaultFilter, alertType };
