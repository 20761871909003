import { FONT_WEIGHT } from '../../style/font';
import COLORS from '../../style/color';

const sortingButtonFontSize = '12px';
const sortingButtonFontColor = COLORS.grayDark;

const sortingStyles = {
    containerBox: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        fontSize: sortingButtonFontSize,
        color: sortingButtonFontColor,
        fontWeight: FONT_WEIGHT.medium,
        '& .MuiInputBase-root': {
            border: `none`,
        },
        '& .MuiSelect-icon': {
            top: 'calc(50% - .25em)',
        },
        '& legend': {
            width: '0px !important',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${COLORS.borderGray}`,
        },
    },
    sortingButton: {
        textTransform: 'none' as const,
        fontSize: sortingButtonFontSize,
        color: sortingButtonFontColor,
        fontWeight: FONT_WEIGHT.regular,
        justifyContent: 'space-between',
        '&>span': {
            marginRight: '0px',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& .Mui-PopOpen': {
            transform: 'rotate(180deg)',
        },
    },
    iconButton: {
        width: '28px',
        height: '28px',
        borderRadius: '2px',
        padding: '4px',
        '&:hover': {
            backgroundColor: COLORS.buttonBgWhite,
        },
    },
    paper: {
        boxShadow: '0 0 8px rgba(0,0,0,.36)',
        border: `1px solid ${COLORS.grayNormal}`,
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    menuPaper: {
        boxShadow: '0 0 8px rgba(0,0,0,.36)',
        border: `1px solid ${COLORS.grayNormal}`,
    },
};

export const menuProps = {
    PaperProps: {
        style: sortingStyles.menuPaper,
    },
};
export default sortingStyles;
