import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, OBS_API } from '../constants/api';
import { ObsMessage, RequestObsMessage } from '../interface/Obs';

const sendObsMessage = async (params: ObsMessage) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, OBS_API.sendMessage, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getObsMessageList = async (params: RequestObsMessage) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, OBS_API.getMessages, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getObsRecipients = async (params: { keywords: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, OBS_API.getObsRecipients, params);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { sendObsMessage, getObsRecipients, getObsMessageList };
