import React, { PropsWithChildren } from 'react';

import { ReportSession } from '../../../components/common/view';

import { PREVIEW_CONTAINER_WIDTH } from '../constants';

interface BlockContainerProps {
    title: string;
}

const BlockContainer = (props: PropsWithChildren<BlockContainerProps>) => {
    const { title, children } = props;
    return (
        <ReportSession title={title} contentSx={{ p: '16px 24px', width: PREVIEW_CONTAINER_WIDTH }}>
            {children}
        </ReportSession>
    );
};

export default BlockContainer;
