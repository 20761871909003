import { DateTime } from 'luxon';

import COLORS from '../../style/color';
import ReportStyle from './ReportGenStyles';
import { Content } from 'pdfmake/interfaces';
import { GenPdfInfo, Position, AircraftDetails } from '../../constants/constants';
import { DateFormat } from '../../helper/dateHelper';

const createdDate = () => DateTime.now().toUTC().toFormat(DateFormat.Utcformat) || '';

const getGenTime = () => {
    return {
        text: createdDate(),
        alignment: Position.right,
        fontSize: 10,
    } as Content;
};

const getReportTitle = (title: string = '--') => {
    return {
        text: title,
        bold: true,
        fontSize: 16,
        margin: [0, 4, 0, 0],
        alignment: Position.center,
    } as Content;
};

const getReportDescription = (description: string = '--', isServiceable: boolean) => {
    return {
        fontSize: 14,
        alignment: Position.left,
        margin: [0, 0, 0, 25],
        text: `${GenPdfInfo.reason}: ${description}`,
        color: isServiceable ? COLORS.brightJade : COLORS.error,
    } as Content;
};

const getReportSubInfo = (regNum: string = '--', station: string = '--') => {
    return {
        columns: [
            {
                fontSize: 14,
                bold: false,
                margin: [50, 0],
                alignment: Position.right,
                text: `${AircraftDetails.regNum}: ${regNum}`,
                color: COLORS.grayDark,
            },
            {
                fontSize: 14,
                margin: [50, 0],
                alignment: Position.left,
                text: `${AircraftDetails.station}: ${station}`,
                color: COLORS.grayDark,
            },
        ],
        margin: [0, 8, 0, 8],
    } as Content;
};

const getTableCaption = (title: string, columns: number) => {
    const captionArray = Array(3).fill({});
    captionArray.unshift({
        text: title,
        colSpan: columns,
        style: 'tableCaption',
        borderColor: ReportStyle.tableHeader,
    });
    return captionArray as Content;
};

const getPageNumber = (currentPage: any, pageCount: string | number) => {
    return {
        text: currentPage.toString() + ' of ' + pageCount,
        alignment: Position.center,
        style: 'pagination',
    } as Content;
};

export {
    getGenTime,
    getReportTitle,
    getReportDescription,
    getReportSubInfo,
    getTableCaption,
    getPageNumber,
    createdDate,
};
