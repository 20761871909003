import React, { ChangeEvent, useContext } from 'react';

import CustomPagination from '../common/CustomPagination';
import { selectFlight } from '../../slices/flightSlice';
import { useAppSelector } from '../../app/hooks';
import { FlightSearchMainContext, DEFAULT_PAGE_SIZE } from './FlightSearch';

const Pagination = () => {
    const {
        onSearch,
        filters: { page },
    } = useContext(FlightSearchMainContext);
    const { totalFlightBars } = useAppSelector(selectFlight);

    const onPageChange = (event: ChangeEvent, page: number) => {
        onSearch({ page });
    };

    return (
        <>
            {totalFlightBars > 0 ? (
                <CustomPagination
                    page={page}
                    count={Math.ceil(totalFlightBars / DEFAULT_PAGE_SIZE)}
                    onChange={onPageChange}
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default Pagination;
