import { sendHttpRequest } from './api';
import { FlightParam, DepartureFlightParam } from '../slices/flightSlice';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, FLIGHTS_API } from '../constants/api';

const getFlights = async (param: FlightParam) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, FLIGHTS_API.getFlights, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getDepartureFlights = async (param: DepartureFlightParam) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, FLIGHTS_API.getDepartureFlights, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightPortInfo = async (ufis: string[]) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, FLIGHTS_API.getFlightPortInfo, { ufis });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getFlights, getDepartureFlights, getFlightPortInfo };
