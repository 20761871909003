import React from 'react';
import Grid from '@mui/material/Grid';
import { styled, SxProps, Box } from '@mui/system';

import StyledCheckbox from './CheckBox';
import { ReactComponent as DepToArr } from '../../assets/icons/DepToArr.svg';
import { ReactComponent as MainToMain } from '../../assets/icons/MainToMain.svg';
import { ReactComponent as Risk } from '../../assets/icons/Risk.svg';
import { ReactComponent as TrashBin } from '../../assets/icons/Trash.svg';
import theme from '../../style/theme';

import customStyles from './FlightBarStyles';

const Component = styled('div')({ ...customStyles });

enum ButtonTypes {
    RUBBISH_BIN = 'rubbishBin'
}

interface IFlightBarProps {
    flightBar: IFlightBar;
    checked?: boolean;
    selectable?: boolean;
    displayButtons?: {
        button: ButtonTypes;
        onClickAction: (ufi: string) => void;
    }[];
    disabled?: boolean;
}

interface IFlightBar {
    flightNum: string;
    dpt: string;
    dptTimeStr: string;
    arr: string;
    arrTimeStr: string;
    flightRegi: string;
    subtype: string;
    type: FlightBarType;
    isRisk?: boolean;
    ufi?: string;
    selectable?: boolean;
    airline?: string;
    onClick?: (flight: IFlightBar) => void;
    STD?: string;
    ETD?: string;
    ATD?: string;
    STA?: string;
    ETA?: string;
    ATA?: string;
}

enum FlightBarType {
    PAX_FLIGHT = 'pax_flight',
    FREIGHT_FLIGHT = 'freight_flight',
    OTHER_FLIGHT = 'other_flight',
    MTCE = 'mtce',
}

const getFlightBarTypeStyle = (type: string) => {
    switch (type) {
        case FlightBarType.PAX_FLIGHT:
            return {
                backgroundColor: theme.flightBar.pax,
                iconColor: theme.flightBar.paxIcon
            };
        case FlightBarType.FREIGHT_FLIGHT:
            return {
                backgroundColor: theme.flightBar.freight,
                iconColor: theme.flightBar.freightIcon,
            };
        case FlightBarType.OTHER_FLIGHT:
            return {
                backgroundColor: theme.flightBar.other,
                iconColor: theme.flightBar.otherIcon,
            };
        case FlightBarType.MTCE:
            return {
                backgroundColor: theme.flightBar.maintentence,
                iconColor: theme.flightBar.maintenanceIcon,
            };
        default:
            return {
                backgroundColor: theme.flightBar.pax,
                iconColor: theme.flightBar.paxIcon
            };
    }
};

const FlightBar = (props: IFlightBarProps) => {
    const { flightBar, checked = false, selectable = false, displayButtons = [], disabled = false } = props;
    const {
        flightNum,
        dpt,
        dptTimeStr,
        arr,
        arrTimeStr,
        flightRegi,
        subtype,
        type,
        isRisk,
        ufi = '',
    } = flightBar;

    const { backgroundColor, iconColor } = getFlightBarTypeStyle(type);

    const canSelectStyle: SxProps = {
        backgroundColor: 'transparent !important',
        cursor: 'pointer'
    };
    const disabledStyle: SxProps = {
        backgroundColor: `${theme.flightBar.disabledFlightBarColor} !important`,
        height: '100%',
        width: '100%',
        opacity: '50%',
        position: 'absolute',
        zIndex: 1,
        cursor: 'auto'
    };

    return (
        <Component className='common-flight-bar' onClick={() => !disabled && selectable ? flightBar.onClick(flightBar) : {}}>
            <Grid
                container
                className='container'
                columns={16}
                sx={selectable ? canSelectStyle : { backgroundColor }}
            >
                {disabled && <Box sx={disabledStyle} />}
                <Grid item className='item-svg'>
                    {isRisk && <Risk />}
                </Grid>
                <Grid item xs={2} className='item'>
                    {flightNum}
                </Grid>
                <Grid container item xs={10} className='flight-info-with-icon'>
                    <Grid item xs={4} className='item'>
                        <span className='span-item'>{dpt}</span>
                        {dptTimeStr}
                    </Grid>
                    <Grid item className='item-svg'>
                        {
                            type === FlightBarType.MTCE ?
                                <MainToMain fill={iconColor} stroke={iconColor} /> :
                                <DepToArr fill={iconColor} stroke={iconColor} />
                        }
                    </Grid>
                    <Grid item xs={4} className='item'>
                        <span className='span-item'>{arr}</span>
                        {arrTimeStr}
                    </Grid>
                </Grid>
                <Grid item xs={1} className='item'>
                    {flightRegi}
                </Grid>
                <Grid item xs={1} className='item'>
                    {subtype}
                </Grid>
                {displayButtons && displayButtons.length > 0 &&
                    <Grid item className='flight-button-group'>
                        {
                            displayButtons.map((type) => (
                                <Grid item className='item-svg'>
                                    <div className='svg-button' onClick={() => type.onClickAction(ufi)}>
                                        <FlightBarIcon props={type.button} />
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                }
                <Grid item className='flight-button-group' sx={{ marginLeft: 'auto' }}>
                    {selectable && <StyledCheckbox checked={checked} label='Select' disabled={disabled} />}
                </Grid>
            </Grid>
        </Component>
    );
};

const FlightBarIcon = ({ props }: { props: ButtonTypes }) => {
    switch (props) {
        case ButtonTypes.RUBBISH_BIN:
            return <TrashBin />;
        default:
            return <></>;
    }
};

export { FlightBar as default, IFlightBar, FlightBarType, ButtonTypes };