import React, { useState, useEffect, useCallback } from 'react';
import { Stack } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import debounce from 'lodash/debounce';

import { useLocalTime } from '../../../app/hooks';
import InputField from '../../common/InputField';
import DateTimeRow from './DateTimeRow';
import StyledButton from '../../common/Button';

import { FIELD_WIDTH, timeOptionsETAATA, timeOptionsETDATD, DEBOUNCE_INTERVAL } from '../constants';
import { TimeTypes } from '../../../constants/constants';

interface IndividualStopInfoProps {
    onDelete?: (index: number) => void;
    index: number;
    stop: Object; // To be updated when response is confirmed.
    registerNamePrefix: string;
    canDelete: boolean;
}

const IndividualStopInfo = (props: IndividualStopInfoProps) => {
    const { convertLocalTime, portTimeOffsets } = useLocalTime();
    const { setValue, getValues } = useFormContext();
    const { onDelete, index, registerNamePrefix, canDelete = false } = props;

    const portRegisterName = `${registerNamePrefix}.port`;
    const [dptDateTime, setDptDateTime] = useState({ type: '', dateTime: '' });
    const [arrDateTime, setArrDateTime] = useState({ type: '', dateTime: '' });

    const [inputETD, inputATD, inputETA, inputATA] = useWatch({
        name: [
            `${registerNamePrefix}.ETD`,
            `${registerNamePrefix}.ATD`,
            `${registerNamePrefix}.ETA`,
            `${registerNamePrefix}.ATA`,
        ],
    });

    const updateLocalTime = useCallback(
        debounce(async (registerName: string, UTCDateTime: string) => {
            const localRegisterName = `${registerName}Local`;
            if (!UTCDateTime) {
                setValue(localRegisterName, '');
                return;
            }

            const port = getValues(`${registerNamePrefix}.port`);
            convertLocalTime(port, [UTCDateTime]).then(([localTime]) => {
                setValue(localRegisterName, localTime);
            });
        }, DEBOUNCE_INTERVAL),
        [portTimeOffsets]
    );

    const handlePortChange = useCallback(
        debounce(async (port: string) => {
            convertLocalTime(port, [dptDateTime.dateTime, arrDateTime.dateTime]).then(
                ([dptLocalDateTime, arrLocalDateTime]) => {
                    setValue(`${registerNamePrefix}.${dptDateTime.type}Local`, dptLocalDateTime);
                    setValue(`${registerNamePrefix}.${arrDateTime.type}Local`, arrLocalDateTime);
                }
            );
        }, DEBOUNCE_INTERVAL),
        [dptDateTime.dateTime, arrDateTime.dateTime, portTimeOffsets]
    );

    useEffect(() => {
        if (inputATD) {
            setDptDateTime({
                type: TimeTypes.ATD,
                dateTime: inputATD,
            });
            return;
        }

        if (inputETD) {
            setDptDateTime({
                type: TimeTypes.ETD,
                dateTime: inputETD,
            });
            return;
        }

        const timeValues = getValues(registerNamePrefix);
        const shouldClear = ['ETD', 'ATD'].every((key) => !timeValues.hasOwnProperty(key));
        if (shouldClear) {
            setDptDateTime({ type: TimeTypes.ETD, dateTime: '' });
        }
    }, [inputETD, inputATD]);

    useEffect(() => {
        if (inputATA) {
            setArrDateTime({
                type: TimeTypes.ATA,
                dateTime: inputATA,
            });
            return;
        }

        if (inputETA) {
            setArrDateTime({
                type: TimeTypes.ETA,
                dateTime: inputETA,
            });
            return;
        }

        const timeValues = getValues(registerNamePrefix);
        const shouldClear = ['ETA', 'ATA'].every((key) => !timeValues.hasOwnProperty(key));
        if (shouldClear) {
            setArrDateTime({ type: TimeTypes.ETA, dateTime: '' });
        }
    }, [inputETA, inputATA]);

    return (
        <>
            <Stack direction='row' alignItems='center'>
                <Controller
                    name={portRegisterName}
                    defaultValue=''
                    render={({ field: { value, onChange } }) => (
                        <InputField
                            label={`Stop ${index + 1}`}
                            width={FIELD_WIDTH}
                            value={value}
                            onChange={(e) => {
                                const input = e.target.value.replace(/[^a-zA-Z]/g, '').toUpperCase();
                                onChange(input);
                                handlePortChange(input);
                            }}
                        />
                    )}
                />
                <StyledButton
                    isDisabled={!canDelete}
                    label='Remove Stop'
                    variant='outlined'
                    onClick={() => onDelete?.(index)}
                />
            </Stack>
            <DateTimeRow
                dropdownLabel='Arrival'
                registerNamePrefix={registerNamePrefix}
                timeType={TimeTypes[arrDateTime.type]}
                dateTime={arrDateTime.dateTime}
                dropwDownList={timeOptionsETAATA}
                onChange={async (type, dateTime) => {
                    setValue(`${registerNamePrefix}.${type}`, dateTime);
                    if (arrDateTime?.type && arrDateTime.type !== type) {
                        setValue(`${registerNamePrefix}.${arrDateTime.type}`, '');
                        setValue(`${registerNamePrefix}.${dptDateTime.type}Local`, '');
                    }

                    setArrDateTime({ type, dateTime });
                    updateLocalTime(`${registerNamePrefix}.${type}`, dateTime);
                }}
            />
            <DateTimeRow
                dropdownLabel='Departure'
                registerNamePrefix={registerNamePrefix}
                timeType={TimeTypes[dptDateTime.type]}
                dateTime={dptDateTime.dateTime}
                dropwDownList={timeOptionsETDATD}
                onChange={async (type, dateTime) => {
                    setValue(`${registerNamePrefix}.${type}`, dateTime);
                    if (dptDateTime?.type && dptDateTime.type !== type) {
                        setValue(`${registerNamePrefix}.${dptDateTime.type}`, '');
                        setValue(`${registerNamePrefix}.${dptDateTime.type}Local`, '');
                    }
                    setDptDateTime({ type, dateTime });
                    updateLocalTime(`${registerNamePrefix}.${type}`, dateTime);
                }}
            />
        </>
    );
};

export default IndividualStopInfo;
