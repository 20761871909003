import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import FormBlockDivider from '../common/FormBlockDivider';
import AffectedFlights from './affectedFlight';
import AlertMessage from './AlertMessage';
import Dropdown from '../common/Dropdown';
import CreateEventFormBlockLayout from '../createEventFormBlock/CreateEventFormBlockLayout';
import { IFlightBar } from '../common/FlightBarInfo';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getIOCAlertByIdThunk, selectAlert } from '../../slices/iocAlertSlice';
import { getFlightPortInfoThunk } from '../../slices/flightSlice';
import { getFormFlightInfo } from '../../helper/iocAlertHelper';

import {
    PRIMARY_REGISTER_NAME,
    IOCAlertTypes,
    alertTypeOptions,
    ROW_MAX_WIDTH,
    SECTION_SEPARATION,
    SECONDARY_REGISTER_NAME,
} from './constants';

interface CreateAlertFormProps {
    primaryFlightBars?: IFlightBar[];
    secondaryFlightBars?: IFlightBar[];
}

const CreateAlertForm = (props: CreateAlertFormProps) => {
    const { primaryFlightBars, secondaryFlightBars } = props;
    const [numberOfPrimaryFlights, setNumberOfPrimaryFlights] = useState(0);

    const { reset, setValue } = useFormContext();
    const dispatch = useAppDispatch();
    const {
        alertFormStatus: { alertId, version },
    } = useAppSelector(selectAlert);

    const setDefaultFlights = (primaryFlights: IFlightBar[], secondaryFlights: IFlightBar[]) => {
        if (primaryFlights.length < 1 && secondaryFlights.length < 1) return;
        const newUfis = primaryFlights.concat(secondaryFlights).map((flight) => flight.ufi);
        dispatch(getFlightPortInfoThunk(newUfis))
            .unwrap()
            .then((portInfos) => {
                const primaryNewFlightInfos = primaryFlights.map((flightBar) =>
                    getFormFlightInfo(portInfos, flightBar)
                );

                const secondaryNewFlightInfos = secondaryFlights.map((flightBar) =>
                    getFormFlightInfo(portInfos, flightBar)
                );

                if (primaryNewFlightInfos.length > 0) {
                    setValue(PRIMARY_REGISTER_NAME, primaryNewFlightInfos);
                }

                if (secondaryNewFlightInfos.length > 0) {
                    setValue(SECONDARY_REGISTER_NAME, secondaryNewFlightInfos);
                }
            });
    };

    useEffect(() => {
        if (alertId) {
            dispatch(getIOCAlertByIdThunk(`${alertId}`))
                .unwrap()
                .then((iocAlert) => {
                    reset(iocAlert.details);
                });
        } else {
            setDefaultFlights(primaryFlightBars, secondaryFlightBars);
        }

        return () => {
            reset({});
        };
    }, []);

    return (
        <Stack>
            <AffectedFlights
                flightSearchTitle='Add primary affected flight(s) to the IOC alert'
                sectionLabel='Primary Affected Flight(s)'
                addFlightBtnLabel='Primary Affected flight(s)'
                registerNamePrefix={PRIMARY_REGISTER_NAME}
                onFlightChange={(flights) => {
                    setNumberOfPrimaryFlights(flights.length);
                }}
            />

            {numberOfPrimaryFlights < 1 ? (
                <CreateEventFormBlockLayout
                    title='Alert Type'
                    isRequired={true}
                    contentWidth='100%'
                    sx={{ my: SECTION_SEPARATION }}
                >
                    <Controller
                        defaultValue={IOCAlertTypes.generalInfo}
                        name='alertMessage.alertType'
                        shouldUnregister={true}
                        render={({ field: { value, onChange } }) => (
                            <Dropdown
                                value={value}
                                title='Select alert type'
                                dataList={alertTypeOptions}
                                sx={{ maxWidth: ROW_MAX_WIDTH }}
                                callback={(value) => {
                                    onChange(value);
                                }}
                            />
                        )}
                    />
                </CreateEventFormBlockLayout>
            ) : (
                <FormBlockDivider />
            )}

            <AlertMessage registerNamePrefix='alertMessage' version={version} watchNamePrefix={PRIMARY_REGISTER_NAME} />

            <FormBlockDivider />

            <AffectedFlights
                flightSearchTitle='Add additional affected flight(s) to the IOC alert'
                sectionLabel='Additional Impacted Flight(s)'
                addFlightBtnLabel='Additional Impacted flight(s)'
                registerNamePrefix={SECONDARY_REGISTER_NAME}
            />
        </Stack>
    );
};

export default CreateAlertForm;
