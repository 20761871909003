import React, { ReactNode } from 'react';
import { Tabs, TabsProps } from '@mui/material';
import { StyledTab } from '..';
import COLORS from '../../../../style/color';

interface TabsNewProps extends TabsProps {
    items: {
        label: ReactNode;
        children: ReactNode;
    }[];
}

const StyledTabs = (props: TabsNewProps) => {
    const { items, value, ...other } = props;
    return (
        <>
            <Tabs
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: COLORS.cathayJade,
                        height: '4px',
                        bottom: 0,
                    },
                }}
                value={value}
                {...other}
            >
                {items.map((item, index) => {
                    const { label } = item;
                    return (
                        <StyledTab
                            sx={{ borderBottom: `4px solid ${COLORS.backgroundGray}` }}
                            label={label}
                            key={index}
                        />
                    );
                })}
            </Tabs>
            {items.map((item, index) => {
                const { children } = item;
                return (
                    <div style={{ overflow: 'auto', height: '100%' }} key={index} hidden={value !== index}>
                        {children}
                    </div>
                );
            })}
        </>
    );
};

export default StyledTabs;
