import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { RootState } from '../app/store';
import {
    createEvent,
    getAllEvents,
    getNextFlightDeparture,
    updateFollowUpStatus,
    updateEventStatus,
    updateEventTaskStatus,
    getOwnerList,
    updateActionItems,
    getActionItems,
    getEventSetting,
    getEventByIdV2,
    getActionItem,
    getLMTTEventList,
    saveEventNote,
    saveEventTaskNote,
    getActionItemTypeList,
} from '../services/events';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import UserInfo from '../interface/UserInfo';
import { AtaCode } from './issueSummarySlice';
import { getAtaCodes } from '../services/issueSummary';
import { EventSetting } from './eventSlice';
import { AddObj } from './addSlice';
import { TaskIdObj, PackageIdObj } from './packageIdSlice';
import { MQNote } from './historySlice';
import { EventTaskHistoryLog } from './historySlice';
import { IFlightBar } from '../components/common/FlightBar';
import { MaintenanceObj } from './maintenanceSlice';
import { Groups } from './userProfileSlice';
import { ThumbnailItem } from './fileSlice';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import { getCommodityText } from '../helper/stringHelper';
import { EventStatusType, TaskType, mqNoteTitle } from '../constants/constants';
import { getAircraftList } from '../services/events';
import { FilterItem } from '../components/common/Filter';
import { AircraftFilter } from '../interface/event.interface';

export const LMTTHeader = { 'x-client': 'LMTT' };

export enum ActionItemStatusType {
    open = 'open',
    outstanding = 'outstanding',
    inProgress = 'inProgress',
    onHold = 'onHold',
    resolved = 'resolved',
    approved = 'approved',
    rejected = 'rejected',
    closed = 'closed',
    cancelled = 'cancelled',
}

export const ActionItemStatusDisplayName = {
    [ActionItemStatusType.open]: 'Outstanding',
    [ActionItemStatusType.outstanding]: 'Outstanding',
    [ActionItemStatusType.inProgress]: 'In Progress',
    [ActionItemStatusType.onHold]: 'On Hold',
    [ActionItemStatusType.resolved]: 'Resolved',
    [ActionItemStatusType.approved]: 'Approved',
    [ActionItemStatusType.rejected]: 'Rejected',
    [ActionItemStatusType.closed]: 'Closed',
    [ActionItemStatusType.cancelled]: 'Cancelled',
};

export enum ActionItemType {
    operationSchedule = 'Operation Schedule',
    instructionOrDocuments = 'Instruction/Documents',
    spares = 'Spares',
    equipmentOrTools = 'Equipment/Tools',
    technical = 'Technical',
    mroArrangements = 'MRO Arrangements',
    rampArrangements = 'Ramp Arrangements',
    taskReplan = 'Task replan',
    others = 'Others',
}

export enum AvailableItems {
    latest = 'Latest Creation Date',
    earliest = 'Earliest Creation Date',
}

export const defaultEventStatusFilter = [
    EventStatusType.outstanding,
    EventStatusType.inProgress,
    EventStatusType.onHold,
    EventStatusType.resolved,
    EventStatusType.approved,
    EventStatusType.rejected,
    EventStatusType.hxArranged,
    EventStatusType.hxNoticed,
    EventStatusType.hxReceived,
];

export interface DisplayValueMap {
    display: string;
    value: string;
}

export interface Event {
    userId: string;
    groupId: number;
    details: EventDetails;
}

export interface EventDetails {
    actionItems?: ActionItem[];
    typeId: number;
    subTypeId: number;
    registration: string;
    startTime: string;
    etsTime: string;
    attachment: number[] | ThumbnailItem[] | null;
    description: string;
    eventTitle: string;
    owner: string | Groups | number;
    relatedActivities: RelatedActivity[];
    ataChapter: string;
    partNumber?: string[];
    commodity?: string;
}

export interface EventFieldDetails {
    submitName: string;
    submitFormat: string;
    isRequired: boolean;
    isEditable: boolean;
    onWatch: boolean;
}

export interface CoeEventListFilters {
    startDate: DateTime;
    endDate: DateTime;
    eventStatus: string[];
    actionItemStatus: string[];
    eventType: string[];
    eventSubtype: string[];
    registration: string[];
    followUpAction: ('true' | 'false')[];
    fleetSubtype: string[];
    owner: string[];
    order: string;
    orderedBy: string;
    page: number | null;
    size: number | null;
    aircraftType: string;
}

export type RelatedActivity = {
    relatedActivityId: number;
    relatedActivityType: string;
    ufi: string;
    details: AddObj | IFlightBar | MaintenanceObj | TaskIdObj | PackageIdObj | null;
    relatedPackageId?: string;
    freeTextId?: string;
};

export type AffectedDefect = {
    id: number;
    eventId: number;
    relatedActivityType: string;
    relatedActivityId: number;
    ufi: string;
    active: boolean;
    state: string;
    defect: Defect;
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string;
};

export type Defect = {
    id: string;
    aircraft: string;
    ufi: string;
    aircraftRegistration: string;
    callSign: string;
    deferType: string;
    deferId: string;
    melReference: string;
    openDate: string;
    closeDate: string;
    description: string;
    workOrderId: string;
    packageId: string;
    statusCode: string;
    carrier: string;
    flightNumber: string;
    scheduleDepartureDate: string;
    departLocation: string;
    arriveLocation: string;
    sectorNumber: string;
    daysDue: number;
    daysRemaining: string;
    cyclesDue: number;
    cyclesRemaining: string;
    flyingHoursDue: number;
    flyingHoursRemaining: string;
    closed: boolean;
    melCategory: string;
    operationRestriction: string;
    workOrder: WorkOrder;
    createdAt: string;
    updatedAt: string;
};

export type WorkOrder = {
    id: string;
    sector: {
        carrier: string;
        flightNumber: string;
        sectorNumber: string;
        arriveLocation: string;
        departLocation: string;
        scheduleDepartureDate: string;
    };
    packageId: string;
    statusCode: string;
    closeDateTime: string;
};

export type LastStatusLog = {
    id: number;
    title?: string | null;
    eventId: number;
    eventTaskId: number;
    logType: string;
    status: string;
    remarks?: string | null;
    userId?: number | null;
    createdAt: string;
    updatedAt: string;
    createdBy: UserInfo;
};

export type ActionItem = {
    id?: number | null; // Provide action item id if it's an update call
    taskType: TaskType;
    type: string;
    status?: string;
    title: string;
    description: string;
    owner: string | Groups | number;
    etsTime: string;
    startTime: string;
    upload?: number[] | ThumbnailItem[];
    actionItemId?: number;
    active?: boolean;
};

// This is EventTask from event object
export interface SimpleActionItem {
    id: number;
    eventId: number;
    taskType: string;
    status: string;
    statusRemarks: string | null;
    startTimeUTC: string | null;
    endTimeUTC: string | null;
    etsTimeUTC: string | null;
    details: {
        type: string;
        owner: Groups;
        title: string;
        description: string;
    };
    ufi: string | null;
    upload: number[];
    active: boolean;
    createdBy: UserInfo;
    updatedBy: UserInfo;
    createdAt: string;
    updatedAt: string;
    additionalNotes: LastStatusLog[];
}

// This is EventTask from eventTask api
export type DetailedActionItem = {
    id: number;
    eventId: number;
    status: string;
    active: boolean;
    taskType: string;
    details: {
        title: string;
        description: string;
        owner: Groups;
        type: string;
    };
    uploadFiles: ThumbnailItem[];
    startTime: string;
    etsTime: string;
    endTime: string;
    updatedBy: UserInfo;
    createdBy: UserInfo;
    updatedAt: string;
    createdAt: string;
    lastStatusLog?: LastStatusLog[];
    additionalNotes?: LastStatusLog[];
};

export interface COEDetailedEvent {
    id: string;
    eventId: string;
    eventTaskId?: number;
    eventTitle: string;
    groupId: number;
    typeId: number;
    subTypeId: number;
    type: string;
    subType: string;
    state: string;
    flightStatus: string;
    initial: string;
    registration: string;
    owner: Groups;
    description: string;
    startTime: string;
    startTimeHkt: string;
    etsTime: string;
    etsTimeHkt: string;
    relatedActivities: RelatedActivity[];
    upload: number[];
    updatedBy: UserInfo;
    createdBy: UserInfo;
    updatedAt: string;
    createdAt: string;
    defects: AffectedDefect[];
    followUp: FollowUp[];
    isAog: boolean;
    techArea?: string;
    ataChapter?: string;
    nextFlightDept?: string;
    uploadFiles?: ThumbnailItem[];
    craft: Aircraft;
    lastStatusLog: LastStatusLog[];
    eventTasks: SimpleActionItem[];
    partNumber?: string[];
    commodity?: string;
    commodityDisplay?: string;
    mtceCode?: string;
    bayObject?: {
        bayNum: string;
    };
    liveNextFlight: {
        ltdUTC: string;
    };
    additionalNotes?: LastStatusLog[];
    statusRemarks: string;
    mqNote?: MQNote;
    taskDueDate?: string;
}

export type Aircraft = {
    subtype: string;
    aircraftType: string;
};

export type NextFlightDept = {
    ufi: string;
    callsign: string;
    flightNumber: string;
    serviceType: string;
    ltdUTC: string;
};

export type FollowUp = {
    id: number;
    groupId: number;
    active: boolean;
    eventId: number;
    createdAt: string;
    updatedAt: string;
    createdBy: number;
    updatedBy: number;
};

export const getEventSettingThunk = createAsyncThunk<
{ eventSetting: EventSetting[] },
void,
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getEventSetting', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/setting')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getEventSetting();

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as { eventSetting: EventSetting[] };
});

export const createEventThunk = createAsyncThunk<
{ event: COEDetailedEvent[] },
Event,
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/createEvent', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentGroupId: groupId, currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    if (!userId || !groupId) {
        return rejectWithValue(ForbiddenObj);
    }

    const { details } = params;
    const eventData = {
        userId,
        groupId,
        details,
    };

    const [err, data] = await createEvent({ param: eventData, headers: LMTTHeader });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as { event: COEDetailedEvent[] };
});

interface AllEventsInput {
    eventStatus: string;
    startDate: string;
    endDate: string;
}

export interface ActionItemSetting {
    type: string;
}

export interface FilterAndSortOptions {
    eventType?: number[];
    eventSubtype?: number[];
    registration?: string[];
    owner?: number[];
    fleetSubtype?: string[];
    followUpAction?: boolean[];
    actionItemStatus?: string[];
    eventStatus?: string[];
    startDate: string;
    endDate: string;
    order?: string;
    orderedBy?: string;
    page?: number;
    size?: number;
    aircraftType?: string[];
    ataChapter?: string[];
    actionItemType?: string[];
    keyWords?: string;
    actionItemFilter?: boolean;
    commodity?: string[];
}

export const getAllEventsThunk = createAsyncThunk<
COEDetailedEvent[],
AllEventsInput,
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getAllEvents', async ({ eventStatus, startDate, endDate }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/getEvents')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getAllEvents({
        eventStatus,
        startDate,
        endDate,
        eventPage: '/coeeventlist',
        headers: LMTTHeader,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as COEDetailedEvent[];
});

export const getEventListThunk = createAsyncThunk<
{ total: number; events: COEDetailedEvent[] },
FilterAndSortOptions,
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getEvents', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/getEvents')) {
        return rejectWithValue(ForbiddenObj);
    }

    const { eventStatus, actionItemStatus } = params || {};
    const updatedEventStatus =
        eventStatus && eventStatus.length > 0 && eventStatus.find((item) => item === EventStatusType.outstanding)
            ? eventStatus.concat(EventStatusType.open)
            : eventStatus;

    const updatedActionItemStatus =
        actionItemStatus &&
        actionItemStatus.length > 0 &&
        actionItemStatus.find((item) => item === ActionItemStatusType.outstanding)
            ? actionItemStatus.concat(ActionItemStatusType.open)
            : actionItemStatus;
    const updateParams = {
        ...params,
        eventStatus: updatedEventStatus || [],
        actionItemStatus: updatedActionItemStatus || [],
    };

    const [err, data] = await getLMTTEventList({ ...updateParams });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    const payloadEvents = (data as { total: number; events: COEDetailedEvent[] }).events.map((item) => {
        const { commodity } = item;
        return {
            ...item,
            commodityDisplay: getCommodityText(commodity),
        };
    });

    return { ...data, events: payloadEvents } as { total: number; events: COEDetailedEvent[] };
});

export const getEventByIdThunk = createAsyncThunk<
COEDetailedEvent,
{
    id: string;
},
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getEventById', async ({ id }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getEventByIdV2({ id, headers: LMTTHeader });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data;
});

export const editEventThunk = createAsyncThunk<
{ event: COEDetailedEvent[] },
Event,
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/editEvent', async (params, { getState, rejectWithValue }) => {
    const { selectedEventId } = getState().coeevent;
    const { userProfile } = getState().userProfile;
    const { userId, currentGroupId: groupId, currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    if (!userId || !groupId || !selectedEventId) {
        return rejectWithValue(ForbiddenObj);
    }

    const { details } = params;
    const eventData = {
        userId,
        groupId,
        id: parseInt(selectedEventId),
        details,
    };

    const [err, data] = await createEvent({ param: eventData, headers: LMTTHeader });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as { event: COEDetailedEvent[] };
});

export const getAtaCodesThunk = createAsyncThunk<AtaCode[], void, { state: RootState; rejectValue: ApiErrorObj }>(
    'coeevents/getAtaCodes',
    async (_, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/getAtaCodes')) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await getAtaCodes(LMTTHeader);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as AtaCode[];
    }
);

export const getNextFlightDepartureThunk = createAsyncThunk<
NextFlightDept,
{
    registration: string;
    startDate: string;
    endDate: string;
},
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getNextFlightDeparture', async ({ registration, startDate, endDate }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/getNextDeptFlight')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getNextFlightDeparture({ registration, startDate, endDate, headers: LMTTHeader });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as NextFlightDept;
});

export const updateFollowUpThunk = createAsyncThunk<
FollowUp,
{
    id?: number;
    eventId: number;
    active: boolean;
},
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/updateFollowUpStatus', async ({ id, eventId, active }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId } = userProfile;

    const [err, data] = await updateFollowUpStatus({
        id,
        eventId,
        userId: parseInt(userId),
        active,
        headers: LMTTHeader,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as FollowUp;
});

export const updateEventStatusThunk = createAsyncThunk<
COEDetailedEvent,
{
    eventId: number;
    eventStatus: string;
    statusRemarks: string;
},
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/updateEventStatus', async ({ eventId, eventStatus, statusRemarks }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/updateEvent')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await updateEventStatus({
        eventId,
        userId: parseInt(userId),
        eventStatus,
        statusRemarks,
        headers: LMTTHeader,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as COEDetailedEvent;
});

export const saveEventNoteThunk = createAsyncThunk<
COEDetailedEvent,
{
    eventId: number;
    note: string;
},
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/saveEventNote', async ({ eventId, note }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentPermissionList, currentGroupId } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/saveEventNote')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await saveEventNote({
        eventId,
        userId: parseInt(userId),
        note,
        groupId: currentGroupId as number,
        headers: LMTTHeader,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as COEDetailedEvent;
});

export const saveEventTaskNoteThunk = createAsyncThunk<
DetailedActionItem,
{
    eventId: number;
    eventTaskId: number;
    note: string;
},
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/saveEventTaskNote', async ({ eventId, note, eventTaskId }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentPermissionList, currentGroupId } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/saveEventTaskNote')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await saveEventTaskNote({
        eventId,
        eventTaskId,
        userId: parseInt(userId),
        note,
        groupId: currentGroupId as number,
        headers: LMTTHeader,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as DetailedActionItem;
});

export const updateEventTaskStatusThunk = createAsyncThunk<
COEDetailedEvent,
{
    eventTaskId: number;
    eventTaskStatus: string;
    statusRemarks: string;
},
{ state: RootState; rejectValue: ApiErrorObj }
>(
    'coeevents/updateEventTaskStatus',
    async ({ eventTaskId, eventTaskStatus, statusRemarks }, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { userId, currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/updateEventTaskStatus')) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await updateEventTaskStatus({
            eventTaskId,
            userId: parseInt(userId),
            eventTaskStatus,
            statusRemarks,
            headers: LMTTHeader,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as COEDetailedEvent;
    }
);

export const getOwnerListThunk = createAsyncThunk<
Groups[],
{ category: string },
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getOwnerList', async ({ category }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/getCategoryGroups')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getOwnerList({
        category,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as Groups[];
});

export const updateActionItemsThunk = createAsyncThunk<
DetailedActionItem[],
{ eventId: number; details: ActionItem[] },
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/updateActionItems', async ({ eventId, details }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { userId, currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await updateActionItems({
        userId: parseInt(userId),
        eventId,
        actionItemDetails: details,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as DetailedActionItem[];
});

export const getActionItemsThunk = createAsyncThunk<
DetailedActionItem[],
{ eventId: number },
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getActionItems', async ({ eventId }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getActionItems({
        eventId,
        taskTypes: [TaskType.actionItem],
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as DetailedActionItem[];
});

export const getActionItemDetailThunk = createAsyncThunk<
DetailedActionItem,
{ eventTaskId: number },
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getActionItem', async ({ eventTaskId }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTask')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getActionItem({
        eventTaskId,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as DetailedActionItem;
});

export const getAircraftFilterOptionsThunk = createAsyncThunk<
AircraftFilter[],
void,
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getAircraftFilterOptionsThunk', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/aircraftList')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getAircraftList();
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data as AircraftFilter[];
});

export const getActionItemTypeThunk = createAsyncThunk<
ActionItemSetting[],
void,
{ state: RootState; rejectValue: ApiErrorObj }
>('coeevents/getActionItemTypeThunk', async (_, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/actionItem/setting')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getActionItemTypeList();
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return (data?.actionItemSetting as ActionItemSetting[]) || [];
});

export interface ActionItemUpLoadFiles {
    active: boolean;
    bucket: string;
    createdAt: string;
    createdBy: number;
    fileName: string;
    fileType: string;
    id: number;
    key: string;
    owner: string;
    ownerId: number;
    thumbnail: string;
    updatedAt: string;
    updatedBy: number;
}
interface COENewItemCount {
    eventCount: number;
    actionItemCount: number;
}

export const COE_EVENT_LIST_DEFAULT_PAGE = 1;
export const COE_EVENT_LIST_DEFAULT_PAGE_SIZE = 50;

export interface COEEventSlice {
    isEventLoading: boolean;
    isActionItemLoading: boolean;
    isAtaCodesLoading: boolean;
    isEventStatusUpdating: boolean;
    isGettingAllEvents: boolean;
    eventSetting: EventSetting[];
    eventTypesList: { display: string; value: string; accessType: string }[];
    ownerList: Groups[];
    ataCodesDisplayList: DisplayValueMap[];
    actionItemTypes: DisplayValueMap[];
    eventErrorObject?: ApiErrorObj | null;
    createdEvent?: COEDetailedEvent | null;
    selectedEventId?: string | null;
    selectedEvent?: COEDetailedEvent | null;
    selectedActionItemId?: number | null;
    selectedActionItem?: SimpleActionItem | DetailedActionItem | null;
    actionHistory?: EventTaskHistoryLog[];
    allEvents?: COEDetailedEvent[];
    eventListData: { total: number; events: COEDetailedEvent[] } | null;
    nextFlightDept?: NextFlightDept | null;
    rejectedFollowUp?: FollowUp | null;
    defaultFormValues: {
        typeId: string;
        subTypeId: string;
        eventTitle: string;
        description: string;
        ataChapter: string;
        owner: string;
        registration: string;
        relatedActivities: RelatedActivity[];
        startTime: string;
        etsTime: string;
        nextFlightDept: string;
        attachment: number[];
        actionItems: any[];
        partNumber?: string[];
    };
    coeEventListFilters: FilterAndSortOptions;
    actionItems: DetailedActionItem[];
    coeEventRegFilterOptions: FilterItem[];
    coeEventAircraftFilterOptions: FilterItem[];
    coeNewItemCount: COENewItemCount;
    aircraftListData: {
        [key: string]: {
            [key: string]: string[];
        };
    };
    eventFilterOptionsList: {
        name: string;
        value: number;
        subtypes: FilterItem[];
    }[];
}

export type RelatedActivityList = {
    default: DisplayValueMap[];
    manualAndPackage: DisplayValueMap[];
    manualAndTask: DisplayValueMap[];
    task: DisplayValueMap[];
    manual: DisplayValueMap[];
};

const initialState: COEEventSlice = {
    aircraftListData: {},
    eventFilterOptionsList: [],
    isEventLoading: false,
    isActionItemLoading: false,
    isAtaCodesLoading: false,
    isGettingAllEvents: false,
    isEventStatusUpdating: false,
    defaultFormValues: {
        typeId: '',
        subTypeId: '',
        eventTitle: '',
        description: '',
        ataChapter: '',
        owner: '',
        registration: '',
        relatedActivities: [{ relatedActivityId: 0, relatedActivityType: '', ufi: '', details: null }],
        startTime: '',
        etsTime: '',
        nextFlightDept: '',
        attachment: [],
        actionItems: [],
        partNumber: [],
    },
    eventSetting: [],
    eventTypesList: [],
    createdEvent: null,
    nextFlightDept: null,
    rejectedFollowUp: null,
    ownerList: [],
    ataCodesDisplayList: [],
    actionItemTypes: [],

    eventErrorObject: null,
    coeEventListFilters: {
        startDate: DateTime.now().toUTC().toString(),
        endDate: DateTime.now().toUTC().toString(),
    },
    eventListData: null,
    actionItems: [],
    coeEventRegFilterOptions: [],
    coeEventAircraftFilterOptions: [],
    coeNewItemCount: {
        eventCount: 0,
        actionItemCount: 0,
    },
};

export const coeEventSlice = createSlice({
    name: 'coeevents',
    initialState,
    reducers: {
        incrementCoeNewItemCountByNotification: (state, action: PayloadAction<COENewItemCount>) => {
            const { eventCount = 0, actionItemCount = 0 } = action.payload;
            const { eventCount: preEventCount, actionItemCount: preActionItemCount } = state.coeNewItemCount;

            state.coeNewItemCount = {
                eventCount: preEventCount + eventCount,
                actionItemCount: preActionItemCount + actionItemCount,
            };
        },
        resetCoeNewItemCount: (state) => {
            state.coeNewItemCount = {
                eventCount: 0,
                actionItemCount: 0,
            };
        },
        selectEventId: (state, action: PayloadAction<string>) => {
            state.selectedEventId = action.payload;
            state.selectedActionItem = null;
            state.selectedActionItemId = null;
        },
        deselectEvent: (state, action: PayloadAction<number>) => {
            state.selectedEvent = null;
            state.selectedEventId = null;
            state.selectedActionItem = null;
            state.selectedActionItemId = null;
        },
        selectActionItemId: (state, action: PayloadAction<number>) => {
            state.selectedActionItemId = action.payload;
            const [actionItem] = state.selectedEvent?.eventTasks?.filter((item) => item.id === action.payload) || [];
            state.selectedActionItem = actionItem || null;
        },
        deselectActionItem: (state) => {
            state.selectedActionItem = null;
            state.selectedActionItemId = null;
        },
        resetNextFlightDept: (state) => {
            state.nextFlightDept = null;
        },
        updateCoeEventListFilters: (state, action: PayloadAction<Partial<FilterAndSortOptions>>) => {
            state.coeEventListFilters = { ...state.coeEventListFilters, ...action.payload };
        },
        updateFollowUpStateInList: (state, action: PayloadAction<FollowUp>) => {
            const events = state.eventListData?.events.map((item) => {
                const { id } = item;
                if (parseInt(id) === action.payload.eventId) {
                    return { ...item, followUp: [action.payload] };
                }
                return item;
            });
            state.eventListData = {
                total: state.eventListData?.total || 0,
                events: events || [],
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEventSettingThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(getEventSettingThunk.fulfilled, (state, { payload }) => {
                const { eventSetting } = payload;
                state.eventSetting = eventSetting.map((es) => {
                    const { subType } = es;
                    const updatedSubType = subType.map((st) => {
                        const { eventFields } = st;
                        const updatedEventFields = eventFields.map((ef) => {
                            const { details } = ef;
                            details.sort((a, b) => (a.seq > b.seq ? 1 : -1));
                            return { ...ef, details };
                        });
                        return { ...st, eventFields: updatedEventFields };
                    });
                    return { ...es, subType: updatedSubType };
                });

                state.eventTypesList = eventSetting.map((item) => ({
                    display: item.type,
                    value: item.id.toString(),
                    accessType: item.accessType,
                }));

                state.isEventLoading = false;
            })
            .addCase(getEventSettingThunk.rejected, (state) => {
                state.isEventLoading = false;
            })
            .addCase(createEventThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(createEventThunk.fulfilled, (state, { payload }) => {
                const { event } = payload;
                state.isEventLoading = false;
                state.createdEvent = event?.[0];
            })
            .addCase(createEventThunk.rejected, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(getAllEventsThunk.pending, (state) => {
                state.isEventLoading = true;
                state.isGettingAllEvents = true;
            })
            .addCase(getAllEventsThunk.fulfilled, (state, { payload }) => {
                state.isEventLoading = false;
                state.isGettingAllEvents = false;
                state.allEvents = payload;
            })
            .addCase(getAllEventsThunk.rejected, (state) => {
                state.isEventLoading = false;
                state.isGettingAllEvents = false;
            })
            .addCase(getEventListThunk.pending, (state) => {
                state.isGettingAllEvents = true;
            })
            .addCase(getEventListThunk.fulfilled, (state, { payload }) => {
                state.isGettingAllEvents = false;

                if (JSON.stringify(state.eventListData) === JSON.stringify(payload)) {
                    return state;
                }
                if (state.coeEventListFilters?.page === 1 || payload?.events?.length !== 0) {
                    state.eventListData = payload;
                }
            })
            .addCase(getEventListThunk.rejected, (state) => {
                state.isGettingAllEvents = false;
            })
            .addCase(getEventByIdThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(getEventByIdThunk.fulfilled, (state, { payload }) => {
                state.isEventLoading = false;
                const { id, mqNote, additionalNotes = [], commodity } = payload || {};
                state.selectedEventId = id;
                state.selectedEvent = {
                    ...payload,
                    commodityDisplay: getCommodityText(commodity),
                };
                // if mqNote exists, add to the additional notes array
                if (mqNote?.mqNote) {
                    const note: LastStatusLog = {
                        id: 0,
                        title: mqNoteTitle,
                        eventId: Number(payload.id),
                        eventTaskId: payload?.eventTaskId || 0,
                        status: '',
                        logType: '',
                        remarks: mqNote?.mqNote,
                        userId: null,
                        createdBy: {
                            id: 0,
                            userName: mqNote?.createdBy,
                            group: mqNote?.createdBy,
                        },
                        createdAt: mqNote?.mqNoteUpdatedAt,
                        updatedAt: mqNote?.mqNoteUpdatedAt,
                    };

                    state.selectedEvent = {
                        ...payload,
                        commodityDisplay: getCommodityText(commodity),
                        additionalNotes: [note, ...additionalNotes],
                    };
                }
            })
            .addCase(getEventByIdThunk.rejected, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(editEventThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(editEventThunk.fulfilled, (state, { payload }) => {
                const { event } = payload;
                const updateEvent = event?.[0];
                state.isEventLoading = false;
                state.selectedEvent = updateEvent;
            })
            .addCase(editEventThunk.rejected, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(getAtaCodesThunk.pending, (state) => {
                state.isAtaCodesLoading = true;
            })
            .addCase(getAtaCodesThunk.fulfilled, (state, { payload }) => {
                state.isAtaCodesLoading = false;
                state.ataCodesDisplayList = payload.map((code) => {
                    return {
                        display: code.code === 'na' ? `${code.description}` : `${code.code} ${code.description}`,
                        value: code.code,
                    };
                });
            })
            .addCase(getAtaCodesThunk.rejected, (state, { payload }) => {
                state.isAtaCodesLoading = false;
                state.eventErrorObject = payload;
            })
            .addCase(getNextFlightDepartureThunk.fulfilled, (state, { payload }) => {
                state.nextFlightDept = payload || null;
            })
            .addCase(getNextFlightDepartureThunk.rejected, (state, { payload }) => {
                state.eventErrorObject = payload;
            })
            .addCase(updateFollowUpThunk.fulfilled, (state, { payload }) => {
                state.rejectedFollowUp = null;
            })
            .addCase(updateFollowUpThunk.rejected, (state, { payload }) => {
                const { detail } = payload || {};

                if (payload && detail && detail.includes('currentStatus')) {
                    const [message, currentStatus] = detail.split(', currentStatus:');
                    const detailObj = JSON.parse(currentStatus);
                    state.rejectedFollowUp = detailObj;
                    state.eventErrorObject = { ...payload, detail: message || '' };
                } else {
                    state.eventErrorObject = payload;
                }
            })
            .addCase(updateEventStatusThunk.pending, (state) => {
                state.isEventStatusUpdating = true;
            })
            .addCase(updateEventStatusThunk.fulfilled, (state, { payload }) => {
                state.isEventStatusUpdating = false;
            })
            .addCase(updateEventStatusThunk.rejected, (state, { payload }) => {
                state.eventErrorObject = payload;
                state.isEventStatusUpdating = false;
            })
            .addCase(saveEventNoteThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(saveEventNoteThunk.fulfilled, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(saveEventNoteThunk.rejected, (state, { payload }) => {
                state.eventErrorObject = payload;
                state.isEventLoading = false;
            })
            .addCase(saveEventTaskNoteThunk.pending, (state) => {
                state.isEventLoading = true;
            })
            .addCase(saveEventTaskNoteThunk.fulfilled, (state, { payload }) => {
                state.isEventLoading = false;
            })
            .addCase(saveEventTaskNoteThunk.rejected, (state, { payload }) => {
                state.eventErrorObject = payload;
                state.isEventLoading = false;
            })
            .addCase(updateEventTaskStatusThunk.pending, (state) => {
                state.isEventStatusUpdating = true;
            })
            .addCase(updateEventTaskStatusThunk.fulfilled, (state, { payload }) => {
                state.isEventStatusUpdating = false;
            })
            .addCase(updateEventTaskStatusThunk.rejected, (state, { payload }) => {
                state.eventErrorObject = payload;
                state.isEventStatusUpdating = false;
            })
            .addCase(getOwnerListThunk.pending, (state) => {
                state.ownerList = [];
            })
            .addCase(getOwnerListThunk.fulfilled, (state, { payload = [] }) => {
                state.ownerList = payload;
            })
            .addCase(getOwnerListThunk.rejected, (state) => {
                state.ownerList = [];
            })
            .addCase(updateActionItemsThunk.pending, (state) => {
                state.isActionItemLoading = true;
                state.actionItems = [];
            })
            .addCase(updateActionItemsThunk.fulfilled, (state, { payload = [] }) => {
                state.isActionItemLoading = false;
                state.actionItems = payload;
            })
            .addCase(updateActionItemsThunk.rejected, (state) => {
                state.isActionItemLoading = false;
                state.actionItems = [];
            })
            .addCase(getActionItemsThunk.pending, (state) => {
                state.actionItems = [];
            })
            .addCase(getActionItemsThunk.fulfilled, (state, { payload = [] }) => {
                state.actionItems = payload;
            })
            .addCase(getActionItemsThunk.rejected, (state) => {
                state.actionItems = [];
            })
            .addCase(getActionItemDetailThunk.pending, (state) => {
                state.isActionItemLoading = true;
            })
            .addCase(getActionItemDetailThunk.fulfilled, (state, { payload }) => {
                state.isActionItemLoading = false;
                state.selectedActionItemId = payload.id;
                state.selectedActionItem = payload;
            })
            .addCase(getActionItemDetailThunk.rejected, (state) => {
                state.isActionItemLoading = false;
                state.selectedActionItemId = null;
                state.selectedActionItem = null;
            })
            .addCase(getAircraftFilterOptionsThunk.pending, (state) => {
                state.coeEventAircraftFilterOptions = [];
            })
            .addCase(getAircraftFilterOptionsThunk.fulfilled, (state, { payload }) => {
                const result = Object.fromEntries(
                    payload.map(({ aircraftType, aircraftSubtypeList }) => {
                        return [
                            aircraftType,
                            Object.fromEntries(
                                aircraftSubtypeList.map(({ aircraftSubtype, registration }) => [
                                    aircraftSubtype,
                                    registration,
                                ])
                            ),
                        ];
                    })
                );
                state.aircraftListData = result;
                state.coeEventAircraftFilterOptions = Object.keys(result).map((item) => {
                    return {
                        name: item,
                        value: item,
                    };
                });
            })
            .addCase(getActionItemTypeThunk.fulfilled, (state, { payload }) => {
                state.actionItemTypes = payload.map((i: ActionItemSetting) => {
                    return {
                        display: i.type,
                        value: i.type,
                    };
                });
            });
    },
});

const {
    selectActionItemId,
    resetNextFlightDept,
    updateCoeEventListFilters,
    updateFollowUpStateInList,
    deselectEvent,
    selectEventId,
    incrementCoeNewItemCountByNotification,
    resetCoeNewItemCount,
} = coeEventSlice.actions;

const selectCoeEvent = (state: RootState) => state.coeevent;

export default coeEventSlice.reducer;

export {
    selectActionItemId,
    selectCoeEvent,
    resetNextFlightDept,
    updateCoeEventListFilters,
    updateFollowUpStateInList,
    deselectEvent,
    selectEventId,
    incrementCoeNewItemCountByNotification,
    resetCoeNewItemCount,
};
