/* eslint-disable no-unused-vars */
import * as React from 'react';

import { createTheme } from '@mui/material/styles';
import COLORS from './color';
import Colors from './checklistColor';
import { boldFont, CATHAY_FONT_NAME, FONT_WEIGHT, lightFont, mediumFont, regularFont } from './font';
import StyledDialogStyles from '../components/common/StyledDialogStyles';
import { isMobileOrTablet } from '../helper/resourceVerifyHelper';

const defaultSize = {
    toolbarHeight: '52px',
};
const backIconWidth = '48px';
const statusBarHeight = '40px';

const isMobile = isMobileOrTablet();

const headerFilterBarStyles = {
    ...StyledDialogStyles.dialogToolbar['& .MuiToolbar-root'],
    width: isMobile ? '84%' : '80%',
    margin: '0 auto 20px auto',
    minHeight: 'auto !important',
};

const headerToolbar = {
    ...StyledDialogStyles.dialogToolbar['& .MuiToolbar-root'],
    width: isMobile ? '95%' : '80%',
};

declare module '@mui/material/styles' {
    interface Theme {
        alertStatus: {
            error: React.CSSProperties['color'];
            warning: React.CSSProperties['color'];
            success: React.CSSProperties['color'];
        };
        flightBar: {
            pax: React.CSSProperties['color'];
            paxIcon: React.CSSProperties['color'];
            freight: React.CSSProperties['color'];
            freightIcon: React.CSSProperties['color'];
            other: React.CSSProperties['color'];
            otherIcon: React.CSSProperties['color'];
            maintentence: React.CSSProperties['color'];
            maintenanceIcon: React.CSSProperties['color'];
            disabledFlightBarColor: React.CSSProperties['color'];
            cargoBg: React.CSSProperties['color'];
            cargoIndex: React.CSSProperties['color'];
            paxBg: React.CSSProperties['color'];
            paxIndex: React.CSSProperties['color'];
            cancelledIcon: React.CSSProperties['color'];
        };
        common: {
            backGroundColor: React.CSSProperties['color'];
            backgroundGrayColor: React.CSSProperties['color'];
            borderGray: React.CSSProperties['color'];
            iconGray: React.CSSProperties['color'];
            boxColor: React.CSSProperties['color'];
            boxBorder: React.CSSProperties['color'];
            bodyTextColor: React.CSSProperties['color'];
            bodySubTextColor: React.CSSProperties['color'];
            borderGoldColor: React.CSSProperties['color'];
            borderRedColor: React.CSSProperties['color'];
            borderYellowColor: React.CSSProperties['color'];
            grayTextColor: React.CSSProperties['color'];
        };
        etsColour: {
            HIGH: React.CSSProperties['color'];
            MEDIUM: React.CSSProperties['color'];
            DEFAULT: React.CSSProperties['color'];
        };
        eventColor: {
            AOG: React.CSSProperties['color'];
            DEFAULT: React.CSSProperties['color'];
        };
        eventSummaryColor: {
            boxBgColor: React.CSSProperties['color'];
            boxBgBrightGrayColor: React.CSSProperties['color'];
        };
        statusTagColor: {
            completedColor: React.CSSProperties['color'];
            completedBgColor: React.CSSProperties['color'];
            inProgressColor: React.CSSProperties['color'];
            inProgressBgColor: React.CSSProperties['color'];
            closedColor: React.CSSProperties['color'];
            closedBgColor: React.CSSProperties['color'];
            cancelledColor: React.CSSProperties['color'];
            cancelledBgColor: React.CSSProperties['color'];
        };
    }
    interface ThemeOptions {
        alertStatus?: {
            error: React.CSSProperties['color'];
            warning: React.CSSProperties['color'];
            success: React.CSSProperties['color'];
        };
        flightBar?: {
            pax: React.CSSProperties['color'];
            paxIcon: React.CSSProperties['color'];
            freight: React.CSSProperties['color'];
            freightIcon: React.CSSProperties['color'];
            other: React.CSSProperties['color'];
            otherIcon: React.CSSProperties['color'];
            maintentence: React.CSSProperties['color'];
            maintenanceIcon: React.CSSProperties['color'];
            disabledFlightBarColor: React.CSSProperties['color'];
            cargoBg: React.CSSProperties['color'];
            cargoIndex: React.CSSProperties['color'];
            paxBg: React.CSSProperties['color'];
            paxIndex: React.CSSProperties['color'];
            cancelledIcon: React.CSSProperties['color'];
        };
        common?: {
            backGroundColor: React.CSSProperties['color'];
            backgroundGrayColor: React.CSSProperties['color'];
            borderGray: React.CSSProperties['color'];
            iconGray: React.CSSProperties['color'];
            boxColor: React.CSSProperties['color'];
            boxBorder: React.CSSProperties['color'];
            bodyTextColor: React.CSSProperties['color'];
            bodySubTextColor: React.CSSProperties['color'];
            borderGoldColor: React.CSSProperties['color'];
            borderYellowColor: React.CSSProperties['color'];
            borderRedColor: React.CSSProperties['color'];
            grayTextColor: React.CSSProperties['color'];
        };
        etsColour?: {
            HIGH: React.CSSProperties['color'];
            MEDIUM: React.CSSProperties['color'];
            DEFAULT: React.CSSProperties['color'];
        };
        eventColor?: {
            AOG: React.CSSProperties['color'];
            DEFAULT: React.CSSProperties['color'];
        };
        eventSummaryColor?: {
            boxBgColor: {
                HIGH: React.CSSProperties['color'];
                MEDIUM: React.CSSProperties['color'];
                DEFAULT: React.CSSProperties['color'];
            };
            boxBgBrightGrayColor: React.CSSProperties['color'];
        };
        statusTagColor?: {
            completedColor: React.CSSProperties['color'];
            completedBgColor: React.CSSProperties['color'];
            inProgressColor: React.CSSProperties['color'];
            inProgressBgColor: React.CSSProperties['color'];
            closedColor: React.CSSProperties['color'];
            closedBgColor: React.CSSProperties['color'];
            cancelledColor: React.CSSProperties['color'];
            cancelledBgColor: React.CSSProperties['color'];
        };
    }
    interface Palette {
        cathayJade: Palette['primary'];
        ldYellow: Palette['primary'];
        gray: Palette['secondary'];
    }
    interface PaletteOptions {
        cathayJade?: PaletteOptions['primary'];
        ldYellow?: PaletteOptions['primary'];
        gray?: PaletteOptions['secondary'];
    }

    interface TypographyVariants {
        subtitle3: React.CSSProperties;
        body3: React.CSSProperties;
        body4: React.CSSProperties;
        body5: React.CSSProperties;
        body6: React.CSSProperties;
        body7: React.CSSProperties;
        body8: React.CSSProperties;
        caption: React.CSSProperties;
        caption2: React.CSSProperties;
        caption3: React.CSSProperties;
        caption4: React.CSSProperties;
        dashboardBold: React.CSSProperties;
        dashboardRegular: React.CSSProperties;
        dashboardLight: React.CSSProperties;
        caption2Medium: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        subtitle3?: React.CSSProperties;
        body3?: React.CSSProperties;
        body4?: React.CSSProperties;
        body5?: React.CSSProperties;
        body6?: React.CSSProperties;
        body7?: React.CSSProperties;
        body8?: React.CSSProperties;
        caption?: React.CSSProperties;
        caption2?: React.CSSProperties;
        caption3?: React.CSSProperties;
        caption4?: React.CSSProperties;
        dashboardBold?: React.CSSProperties;
        dashboardRegular?: React.CSSProperties;
        dashboardLight?: React.CSSProperties;
        dashboardLightSmall?: React.CSSProperties;
        mediumGrayDark24?: React.CSSProperties;
        mediumGrayDark14?: React.CSSProperties;
        boldGrayDark14?: React.CSSProperties;
        boldGrayDark36?: React.CSSProperties;
        regularCathayJade16?: React.CSSProperties;
        regularGrayDark14?: React.CSSProperties;
        bold14?: React.CSSProperties;
        blackBold44?: React.CSSProperties;
        blackLight14?: React.CSSProperties;
        blackMedium18?: React.CSSProperties;
        blackRegular14?: React.CSSProperties;
        tableTip16?: React.CSSProperties;
        regular14?: React.CSSProperties;
        primaryRegular14?: React.CSSProperties;
        regularGrayDark20?: React.CSSProperties;
        mediumCathayJade14?: React.CSSProperties;
        mediumCathayJade18?: React.CSSProperties;
        mediumCathayJade20?: React.CSSProperties;
        mediumGrayNormal20?: React.CSSProperties;
        mediumHoverGreen20?: React.CSSProperties;
        mediumGrayNormal12?: React.CSSProperties;
        boldGrayDark12?: React.CSSProperties;
        boldGrayDark18?: React.CSSProperties;
        mediumGrayDark18?: React.CSSProperties;
        regularGrayDark18?: React.CSSProperties;
        mediumLightGray14?: React.CSSProperties;
        boldLightGray14?: React.CSSProperties;
        regularGrayNormal14?: React.CSSProperties;
        caption2Medium?: React.CSSProperties;
        regularShadowGray12?: React.CSSProperties;
        regularShadowGray14?: React.CSSProperties;
        boldGrayDark16?: React.CSSProperties;
        boldHoverGreen14?: React.CSSProperties;
        subtitle?: React.CSSProperties;
        regularGrayDark12?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        subtitle3: true;
        body3: true;
        body4: true;
        body5: true;
        body6: true;
        body7: true;
        body8: true;
        caption: true;
        caption2: true;
        caption3: true;
        caption4: true;
        dashboardBold: true;
        dashboardRegular: true;
        dashboardLight: true;
        dashboardLightSmall: true;
        mediumGrayDark24: true;
        mediumGrayDark14: true;
        boldGrayDark14: true;
        boldGrayDark36: true;
        regularGrayDark14: true;
        regular14: true;
        primaryRegular14: true;
        bold14: true;
        blackBold44: true;
        blackLight14: true;
        blackMedium18: true;
        blackRegular14: true;
        tableTip16: true;
        regularGrayDark20: true;
        regularCathayJade16: true;
        mediumCathayJade14: true;
        mediumCathayJade18: true;
        mediumCathayJade20: true;
        mediumGrayNormal20: true;
        mediumHoverGreen20: true;
        mediumGrayNormal12: true;
        boldGrayDark12: true;
        boldGrayDark18: true;
        mediumGrayDark18: true;
        regularGrayDark18: true;
        mediumLightGray14: true;
        boldLightGray14: true;
        regularGrayNormal14: true;
        caption2Medium: true;
        regularShadowGray12: true;
        regularShadowGray14: true;
        boldGrayDark16: true;
        boldHoverGreen14: true;
        subtitle: true;
        regularGrayDark12: true;
    }
}

declare module '@mui/system/createTheme/createBreakpoints' {
    interface BreakpointOverrides {
        md: true;
    }
}

// Update the Button's color options to include a gray option
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        gray: true;
    }
}
const defaultTheme = createTheme();

const theme = createTheme({
    typography: {
        fontWeightLight: FONT_WEIGHT.light,
        fontWeightRegular: FONT_WEIGHT.regular,
        fontWeightBold: FONT_WEIGHT.bold,
        fontWeightMedium: FONT_WEIGHT.medium,
        fontFamily: CATHAY_FONT_NAME,
        // semi bold title
        h1: {
            color: COLORS.cathayJade,
            fontSize: '36px',
            fontWeight: FONT_WEIGHT.medium,
        },
        // subtitle
        h2: {
            color: COLORS.cathayJade,
            fontSize: '24px',
            fontWeight: FONT_WEIGHT.regular,
        },
        // bold title
        h3: {
            color: COLORS.cathayJade,
            fontSize: '24px',
            fontWeight: FONT_WEIGHT.bold,
        },
        subtitle1: {
            color: COLORS.grayDark,
            fontSize: '18px',
            fontWeight: FONT_WEIGHT.regular,
        },
        subtitle2: {
            color: COLORS.cathayJade,
            fontSize: '18px',
            fontWeight: FONT_WEIGHT.regular,
        },
        subtitle3: {
            color: COLORS.textDark,
            fontSize: '18px',
            fontWeight: FONT_WEIGHT.medium,
        },
        // 14px, cathay Jade color, Typography deafult this if no variant
        body1: {
            color: COLORS.cathayJade,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
        },
        // 10px, cathay Jade color
        body2: {
            color: COLORS.cathayJade,
            fontSize: '10px',
            fontWeight: FONT_WEIGHT.light,
        },
        body3: {
            color: COLORS.textLightGray,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
        },
        body4: {
            color: COLORS.grayDark,
            fontSize: '16px',
            fontWeight: FONT_WEIGHT.regular,
        },
        body5: {
            color: COLORS.grayDark,
            fontSize: '16px',
            fontWeight: FONT_WEIGHT.medium,
        },
        body6: {
            color: COLORS.textDark,
            fontSize: '16px',
            fontWeight: FONT_WEIGHT.regular,
        },
        body7: {
            color: COLORS.grayDark,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
        },
        body8: {
            color: COLORS.grayDark,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
        },
        caption: {
            color: COLORS.grayNormal,
            fontSize: '12px',
            fontWeight: FONT_WEIGHT.regular,
        },
        caption2: {
            color: COLORS.grayDark,
            fontSize: '12px',
            fontWeight: FONT_WEIGHT.regular,
        },
        caption2Medium: {
            color: COLORS.grayDark,
            fontSize: '10px',
            fontWeight: FONT_WEIGHT.medium,
        },
        caption3: {
            color: COLORS.grayDark,
            fontSize: '12px',
            fontWeight: FONT_WEIGHT.medium,
        },
        caption4: {
            color: COLORS.textDark,
            fontSize: '12px',
            fontWeight: FONT_WEIGHT.regular,
        },
        dashboardBold: {
            fontSize: '24px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.bold,
        },
        dashboardRegular: {
            fontSize: '20px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.medium,
        },
        dashboardLight: {
            fontSize: '24px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.regular,
        },
        dashboardLightSmall: {
            fontSize: '20px',
            color: COLORS.grayNormal,
            fontWeight: FONT_WEIGHT.regular,
        },
        mediumGrayDark24: {
            fontSize: '24px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.medium,
        },
        mediumGrayDark14: {
            fontSize: '14px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.medium,
        },
        boldGrayDark14: {
            fontSize: '14px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.bold,
        },
        boldGrayDark36: {
            fontSize: '36px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.bold,
        },
        regularGrayDark14: {
            fontSize: '14px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.regular,
        },
        regularGrayDark20: {
            fontSize: '20px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.regular,
        },
        regularCathayJade16: {
            fontSize: '16px',
            color: COLORS.cathayJade,
            fontWeight: FONT_WEIGHT.regular,
        },
        mediumCathayJade18: {
            fontSize: '18px',
            color: COLORS.cathayJade,
            fontWeight: FONT_WEIGHT.medium,
        },
        mediumCathayJade14: {
            fontSize: '14px',
            color: COLORS.cathayJade,
            fontWeight: FONT_WEIGHT.medium,
        },
        mediumCathayJade20: {
            fontSize: '20px',
            color: COLORS.cathayJade,
            fontWeight: FONT_WEIGHT.medium,
        },
        mediumGrayNormal20: {
            fontSize: '20px',
            color: COLORS.grayNormal,
            fontWeight: FONT_WEIGHT.medium,
        },
        mediumHoverGreen20: {
            fontSize: '20px',
            color: COLORS.hoverGreen,
            fontWeight: FONT_WEIGHT.medium,
        },
        mediumGrayNormal12: {
            fontSize: '12px',
            color: COLORS.grayNormal,
            fontWeight: FONT_WEIGHT.medium,
        },
        boldGrayDark12: {
            fontSize: '12px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.bold,
        },
        boldGrayDark18: {
            fontSize: '18px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.bold,
        },
        mediumGrayDark18: {
            fontSize: '18px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.medium,
        },
        regularGrayDark18: {
            fontSize: '18px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.regular,
        },
        mediumLightGray14: {
            color: COLORS.textLightGray,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.medium,
        },
        boldLightGray14: {
            color: COLORS.textLightGray,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.bold,
        },
        regularGrayNormal14: {
            color: COLORS.grayNormal,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
        },
        regularShadowGray12: {
            fontSize: '12px',
            fontWeight: FONT_WEIGHT.regular,
            color: COLORS.shadowGray,
        },
        regularShadowGray14: {
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
            color: COLORS.shadowGray,
        },
        boldGrayDark16: {
            fontSize: '16px',
            color: COLORS.grayDark,
            fontWeight: FONT_WEIGHT.bold,
        },
        boldHoverGreen14: {
            fontSize: '14px',
            color: COLORS.hoverGreen,
            fontWeight: FONT_WEIGHT.bold,
        },
    },
    alertStatus: {
        error: COLORS.error,
        warning: COLORS.mainWarning,
        success: COLORS.cathayJade,
    },
    common: {
        backGroundColor: COLORS.backgroundWhite,
        backgroundGrayColor: COLORS.backgroundGray,
        borderGray: COLORS.borderGray,
        iconGray: COLORS.grayWhite,
        boxColor: COLORS.white,
        boxBorder: COLORS.grayLight,
        bodyTextColor: COLORS.grayDark,
        bodySubTextColor: COLORS.shadowGray,
        borderGoldColor: COLORS.borderGold,
        borderYellowColor: COLORS.borderYellow,
        borderRedColor: COLORS.borderRed,
        grayTextColor: COLORS.grayNormal,
    },
    etsColour: {
        HIGH: COLORS.error,
        MEDIUM: COLORS.secondError,
        DEFAULT: COLORS.grayDark,
    },
    eventColor: {
        AOG: COLORS.error,
        DEFAULT: COLORS.grayDark,
    },
    flightBar: {
        pax: COLORS.flightBarPax,
        paxIcon: COLORS.flightBarPaxIcon,
        freight: COLORS.freightBar,
        freightIcon: COLORS.freightBarIcon,
        other: COLORS.otherBar,
        otherIcon: COLORS.otherBarIcon,
        maintentence: COLORS.mtceBar,
        maintenanceIcon: COLORS.mtceBarIcon,
        disabledFlightBarColor: COLORS.backgroundWhite,
        cargoBg: COLORS.lightSand,
        cargoIndex: COLORS.cargoFlight,
        paxBg: COLORS.palePremium,
        paxIndex: COLORS.paxFlight,
        cancelledIcon: COLORS.grayNormal,
    },
    eventSummaryColor: {
        boxBgColor: {
            HIGH: COLORS.summaryBgRed,
            MEDIUM: COLORS.summaryBgYellow,
            DEFAULT: COLORS.summaryBg,
        },
        boxBgBrightGrayColor: COLORS.brightGray,
    },
    statusTagColor: {
        completedColor: COLORS.cathayJade,
        completedBgColor: COLORS.brightGray,
        inProgressColor: COLORS.secondError,
        inProgressBgColor: COLORS.lavenderLighten,
        closedColor: COLORS.grayDark,
        closedBgColor: COLORS.twoColorTable2Dark,
        cancelledColor: COLORS.white,
        cancelledBgColor: COLORS.shadowGray,
    },
    palette: {
        cathayJade: {
            light: COLORS.lightJade,
            main: COLORS.cathayJade,
        },
        ldYellow: {
            light: COLORS.ldYellow,
            main: COLORS.ldYellow,
        },
        gray: {
            light: COLORS.grayLight,
            main: COLORS.grayNormal,
            dark: COLORS.grayDark,
        },
        background: {
            default: COLORS.backgroundWhite,
        },
        error: {
            main: COLORS.error,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face ${lightFont}
                @font-face ${regularFont}
                @font-face ${mediumFont}
                @font-face ${boldFont}
            `,
        },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    '@media(min-width:600px)': {
                        minHeight: defaultSize.toolbarHeight,
                    },
                },
                root: ({ ownerState }) => {
                    if (ownerState.className === 'header-toolbar') {
                        return headerToolbar;
                    }
                    if (ownerState.className?.includes('back-icon headerFilterBar')) {
                        return {
                            ...headerFilterBarStyles,
                            paddingLeft: isMobile ? 0 : backIconWidth,
                        };
                    }
                    if (ownerState.className?.includes('back-icon header-toolbar')) {
                        return {
                            ...headerFilterBarStyles,
                            marginTop: isMobile ? 0 : `${statusBarHeight} !important`,
                        };
                    }
                    if (ownerState.className === 'headerFilterBar') {
                        return headerFilterBarStyles;
                    }
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    zIndex: 1200,
                    '@media(min-width:600px)': {
                        top: defaultSize.toolbarHeight,
                        left: '0px',
                        right: '0px',
                        height: '44px',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: COLORS.grayDark,
                    borderRadius: '0px',
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: () => ({
                    "[arial-name='eventInformationContent']": {
                        width: isMobile ? '100%' : '80%',
                        paddingRight: isMobile ? '4px' : '0px',
                    },
                }),
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (ownerState.className === 'fullScreen') {
                        return {
                            ...StyledDialogStyles.dialogFooter,
                            width: isMobile ? '90%' : '80%',
                        };
                    } else if (ownerState.className === 'small') {
                        return { ...StyledDialogStyles.dialogFooter };
                    }
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (ownerState.className === 'header-app-bar') {
                        return {
                            ...StyledDialogStyles.dialogHeader['& .MuiPaper-root'],
                            position: 'initial',
                        };
                    }
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    if (
                        ownerState.className.includes('fullScreen') &&
                        !ownerState.className.includes('with-back-action')
                    ) {
                        return {
                            ...StyledDialogStyles.dialogContent,
                            width: isMobile ? '94%' : '80%',
                        };
                    } else if (ownerState.className.includes('with-back-action')) {
                        return {
                            ...StyledDialogStyles.dialogContent,
                            width: isMobile ? '84%' : '80%',
                            paddingLeft: isMobile ? 0 : `${backIconWidth} !important`,
                        };
                    } else {
                        return {
                            ...StyledDialogStyles.dialogContent,
                        };
                    }
                },
            },
        },
    },
    breakpoints: {
        values: {
            ...defaultTheme.breakpoints.values,
            md: 835,
        },
    },
});

const checklistTheme = createTheme({
    // reference: https://mui.com/material-ui/customization/default-theme/
    typography: {
        fontWeightLight: FONT_WEIGHT.light,
        fontWeightRegular: FONT_WEIGHT.regular,
        fontWeightBold: FONT_WEIGHT.bold,
        fontWeightMedium: FONT_WEIGHT.medium,
        fontFamily: CATHAY_FONT_NAME,
        h6: {
            color: Colors.Text.grey900,
            fontSize: '20px',
            fontWeight: FONT_WEIGHT.medium,
        },
        subtitle: {
            color: Colors.Text.grey900,
            fontSize: '16px',
            fontWeight: FONT_WEIGHT.medium,
        },
        subtitle2: {
            color: Colors.Text.grey900,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.medium,
        },
        regularGrayDark12: {
            color: Colors.Text.grey600,
            fontSize: '12px',
            fontWeight: FONT_WEIGHT.regular,
        },
        regularGrayDark14: {
            color: Colors.Text.grey600,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
        },
        regular14: {
            color: Colors.Text.grey900,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
        },
        primaryRegular14: {
            color: Colors.Common.blue500,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
        },
        bold14: {
            color: Colors.Text.grey900,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.bold,
        },
        blackLight14: {
            color: Colors.Common.black,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.light
        },
        blackBold44: {
            color: Colors.Common.black,
            fontSize: '44px',
            fontWeight: FONT_WEIGHT.bold,
        },
        blackMedium18: {
            color: Colors.Common.black,
            fontSize: '18px',
            fontWeight: FONT_WEIGHT.medium,
        },
        blackRegular14: {
            color: Colors.Common.grey252,
            fontSize: '14px',
            fontWeight: FONT_WEIGHT.regular,
        },
        tableTip16: {
            color: Colors.Common.grey600,
            fontSize: '16px',
            fontWeight: FONT_WEIGHT.medium,
        }
    },
    palette: {
        primary: {
            main: Colors.Common.blue500,
            dark: Colors.Common.blue800,
        },
        gray: {
            main: Colors.Common.grey500,
        },
        grey: {
            100: Colors.Common.grey100,
            200: Colors.Common.grey200,
            300: Colors.Common.grey300,
            400: Colors.Common.grey400,
            500: Colors.Common.grey500,
            600: Colors.Common.grey600,
        },
        text: {
            primary: Colors.Text.grey900, // override the mui text default primary color
        },
    },
    components: {
        // reference: https://mui.com/material-ui/customization/theme-components/
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});

export { theme as default, defaultSize, checklistTheme };
