import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { RootState } from '../app/store';
import { getAlertsList } from '../services/alertModule';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, isMobileOrTablet } from '../helper/resourceVerifyHelper';
import {
    SortType,
    Severity,
    AlertDisplay,
    defaultFilter,
    pageDefaultFilter,
} from '../components/alertModule/constants';

export interface FilterAndSortOptions {
    orderedBy: string;
    order: SortType;
    severity: Severity[];
    acType?: string[];
    acSubtype?: string[];
    registration?: string[];
    alertType?: string[];
    keywords?: string;
    triggerFrom?: string;
    triggerTo?: string;
    page?: number;
    size?: number;
}

export interface AlertData {
    id: number;
    alertType: string;
    severity: Severity;
    registration: string;
    acSubtype: string;
    acType: string;
    name: string;
    description: string;
    legNo: number;
    nextFlightEtd: string;
    nextFlightNum: string;
    triggeredAt: string;
    updatedAt: string;
    alertId: string;
    ganttRange: {
        maintStart: DateTime;
        maintEnd: DateTime;
        ganttDate: DateTime | null;
    };
}

interface AlertSlice {
    alertPopoverList: { total: number; totalActiveAlerts: number; alerts: AlertData[] } | null;
    alertStandaloneList: { total: number; totalActiveAlerts: number; alerts: AlertData[] } | null;
    isPopoverAlertLoading: boolean;
    isStandaloneLoading: boolean;
    alertsListFilters: FilterAndSortOptions;
    alertsPageListFilters: FilterAndSortOptions;
}

export const getAlertsListThunk = createAsyncThunk<
{ total: number; totalActiveAlerts: number; alerts: AlertData[] },
{ filters: FilterAndSortOptions; loader?: boolean; display: AlertDisplay },
{ state: RootState; rejectValue: ApiErrorObj }
>('/alert/filter', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (isMobileOrTablet()) {
        return null;
    }
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/alert/filter')) {
        return rejectWithValue(ForbiddenObj);
    }

    const { filters } = params;
    const [err, data] = await getAlertsList(filters);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as { total: number; totalActiveAlerts: number; alerts: AlertData[] };
});

const initialState: AlertSlice = {
    alertPopoverList: null,
    alertStandaloneList: null,
    isPopoverAlertLoading: false,
    isStandaloneLoading: false,
    alertsListFilters: defaultFilter,
    alertsPageListFilters: pageDefaultFilter,
};

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        updateAlertsListFilters: (state, action: PayloadAction<Partial<FilterAndSortOptions>>) => {
            state.alertsListFilters = { ...state.alertsListFilters, ...action.payload };
        },
        updateAlertsPageListFilters: (state, action: PayloadAction<Partial<FilterAndSortOptions>>) => {
            state.alertsPageListFilters = { ...state.alertsPageListFilters, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAlertsListThunk.pending, (state, { meta }) => {
                const { loader = true, display } = meta.arg;

                if (loader) {
                    state.isPopoverAlertLoading = display === AlertDisplay.POPOVER;
                    state.isStandaloneLoading = display === AlertDisplay.STANDALONE;
                }
            })
            .addCase(getAlertsListThunk.fulfilled, (state, { payload, meta }) => {
                const { loader = true, display } = meta.arg;

                if (payload) {
                    if (display === AlertDisplay.POPOVER) {
                        if (loader) state.isPopoverAlertLoading = false;
                        if (JSON.stringify(state.alertPopoverList) === JSON.stringify(payload)) {
                            return state;
                        }
                        state.alertPopoverList = payload;
                    }

                    if (display === AlertDisplay.STANDALONE) {
                        if (loader) state.isStandaloneLoading = false;
                        if (JSON.stringify(state.alertStandaloneList) === JSON.stringify(payload)) {
                            return state;
                        }
                        state.alertStandaloneList = payload;
                    }
                }
            })
            .addCase(getAlertsListThunk.rejected, (state, { meta }) => {
                const { display, loader = true } = meta.arg;

                if (loader) {
                    if (display === AlertDisplay.STANDALONE) {
                        state.isStandaloneLoading = false;
                    }

                    if (display === AlertDisplay.POPOVER) {
                        state.isPopoverAlertLoading = false;
                    }
                }
            });
    },
});

const selectAlerts = (state: RootState) => state.alerts;

export default alertsSlice.reducer;

const { updateAlertsListFilters, updateAlertsPageListFilters } = alertsSlice.actions;

export { selectAlerts, updateAlertsListFilters, updateAlertsPageListFilters };
