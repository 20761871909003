import { v4 as uuidv4 } from 'uuid';
import { ChannelType } from '../checklistEvent/constants';

const checklistCreationFormDefaultValues = {
    title: '',
    description: '',
    taskTemplates: [
        { type: '', orderId: uuidv4() },
        { type: '', orderId: uuidv4() },
        { type: '', orderId: uuidv4() },
    ],
};

const taskTemplatesList = [
    { value: 'MANUAL', label: 'Manual task' },
    { value: 'OBS_MESSAGE', label: 'OBS message (Manual trigger)' },
    { value: 'OBS_TEAMS', label: 'Teams chat and meeting (Manual trigger)' },
];

const openStateChecklistTemplateIdPrefix = 'checklistTemplate:';
const openStateTaskTemplateIdPrefix = 'taskTemplate:';

const FORM_COMPONENT_DEFAULT_WIDTH = '500px';

enum TaskTriggerType {
    AUTO = 'Auto Trigger',
    MANUAL = 'Manual Trigger',
}

enum ChecklistType {
    user = 'USER',
    system = 'SYSTEM',
    IROPS = 'SYSTEM_IROPS',
    generic = 'SYSTEM_GENERIC',
}

const createChecklistBannerText = 'Checklist successfully created';
const updateChecklistBannerText = 'Checklist successfully saved';
enum createChecklistTemplatesFormFields {
    title = 'title',
    description = 'description',
    taskTemplates = 'taskTemplates',
}

const mediumList = [
    { label: 'Email', value: ChannelType.email },
    { label: 'SMS', value: ChannelType.sms },
    { label: 'Teams', value: ChannelType.teams },
    { label: 'WhatsApp', value: ChannelType.whatsapp },
];

enum TASK_TEMPLATE_FORM_FIELDS_KEYS {
    taskName = 'taskName',
    taskDescription = 'taskDescription',
    assignTo = 'assignTo',
    recipients = 'taskFields.recipients',
    subject = 'taskFields.subject',
    details = 'taskFields.details',
    content = 'taskFields.content',
    mediums = 'taskFields.mediums',
}

export {
    createChecklistBannerText,
    updateChecklistBannerText,
    TaskTriggerType,
    ChecklistType,
    checklistCreationFormDefaultValues,
    taskTemplatesList,
    createChecklistTemplatesFormFields,
    FORM_COMPONENT_DEFAULT_WIDTH,
    mediumList,
    TASK_TEMPLATE_FORM_FIELDS_KEYS,
    openStateChecklistTemplateIdPrefix,
    openStateTaskTemplateIdPrefix,
};
