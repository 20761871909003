import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, USER_PROFILE_API } from '../constants/api';
import { UserListRequestBody } from '../slices/userProfileSlice';

const getUserProfile = async () => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, USER_PROFILE_API.userProfile, {});

    if (err) {
        return [err];
    }

    const { status, data } = resp;

    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getUserGroups = async (galacxyIds: string[]) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, USER_PROFILE_API.userGroups, { galacxyIds });

    if (err) {
        return [err];
    }

    const { status, data } = resp;

    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getGroupsMapping = async () => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, USER_PROFILE_API.groupsMapping);

    if (err) {
        return [err];
    }

    const { status, data } = resp;

    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateUserGroup = async ({ targetGroupId }: { targetGroupId: number }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, USER_PROFILE_API.updateUserGroup, {
        targetGroupId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;

    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getUserListByGroups = async (params: UserListRequestBody) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, USER_PROFILE_API.userList, params);

    if (err) {
        return [err];
    }

    const { status, data } = resp;

    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getUserProfile, getUserGroups, getGroupsMapping, updateUserGroup, getUserListByGroups };
