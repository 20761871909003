interface ApiErrorObj {
    statusCode: number;
    title: string;
    caseId: number;
    detail: string;
}

interface ApiRespObj {
    error: ApiErrorObj;
    errors: ApiErrorObj;
    status: string;
}

interface ChatServerErrorObj {
    error: [
        {
            status: number;
            title: string;
            detail: string;
        }
    ];
}

class ServerError extends Error {
    statusCode: number;
    title: string;
    caseId: number;
    detail: string;

    constructor(responseObj: ApiRespObj) {
        super();

        const { errors, error: ioctAdminError } = responseObj || {};
        const { statusCode, title, caseId, detail } = errors || {};
        const { statusCode: ioctAdminStatusCode, title: ioctAdminTitle, caseId: ioctAdminCaseId, detail: ioctAdminDetail } = ioctAdminError || {};

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ServerError);
        }

        this.statusCode = statusCode || ioctAdminStatusCode;
        this.title = title || ioctAdminTitle;
        this.caseId = caseId || ioctAdminCaseId;
        this.detail = detail || ioctAdminDetail;
    }

    toJson() {
        return {
            statusCode: this.statusCode,
            title: this.title,
            caseId: this.caseId,
            detail: this.detail,
        };
    }
}

const ForbiddenObj: ApiErrorObj = {
    statusCode: 403,
    title: 'Access forbidden',
    caseId: 0,
    detail: 'you have no permission at this moment',
};

export { ServerError as default, ApiErrorObj, ChatServerErrorObj, ForbiddenObj };
