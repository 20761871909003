import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import { getEventTasksByEventIdAndType } from '../services/eventSubtask';
import { TaskType } from '../constants/constants';
import { WorkRequestDetail, CreateWrqParams, Log, WrqSubmitParams } from '../interface/WorkRequest';
import { createWrq } from '../services/workRequest';
import { downloadBlob } from './fileSlice';

interface WrqFormStatus {
    isOpen: boolean;
    eventId: string | null;
    data: CreateWrqParams | null;
    isCopied: boolean;
}

interface WrqPreviewStatus {
    isOpen: boolean;
    data: Log<WorkRequestDetail> | null;
}
interface WrqSliceProps {
    wrqFormStatus: WrqFormStatus;
    wrqPreviewStatus: WrqPreviewStatus;
    isSupersedeLoading: boolean;
    isWrqSubmitLoading: boolean;
}

export const getSupersedeListThunk = createAsyncThunk<
Log<WorkRequestDetail>[],
string,
{ state: RootState; rejectValue: ApiErrorObj }
>('getSupersedeList', async (eventId: string, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getEventTasksByEventIdAndType({ eventId: eventId, types: [TaskType.mcWrq] });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const createOrUpdateWrqThunk = createAsyncThunk<
{ blob: Blob; header: Headers } | null,
WrqSubmitParams,
{ rejectValue: ApiErrorObj }
>('createWrq', async (params, { rejectWithValue }) => {
    const { exportPdf } = params;
    const [err, data, header] = await createWrq(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    if (exportPdf) {
        return { blob: data, header };
    } else {
        return null;
    }
});

export const initialState: WrqSliceProps = {
    wrqFormStatus: { isOpen: false, eventId: null, data: null, isCopied: false },
    wrqPreviewStatus: { isOpen: false, data: null },
    isSupersedeLoading: false,
    isWrqSubmitLoading: false,
};

export const wrqSlice = createSlice({
    name: 'workRequest',
    initialState,
    reducers: {
        updateWrqFormStatus: (state, action: PayloadAction<WrqFormStatus>) => {
            state.wrqFormStatus = action.payload;
        },
        updateWrqPreviewStatus: (state, action: PayloadAction<WrqPreviewStatus>) => {
            state.wrqPreviewStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSupersedeListThunk.pending, (state) => {
                state.isSupersedeLoading = true;
            })
            .addCase(getSupersedeListThunk.fulfilled, (state) => {
                state.isSupersedeLoading = false;
            })
            .addCase(getSupersedeListThunk.rejected, (state) => {
                state.isSupersedeLoading = false;
            })
            .addCase(createOrUpdateWrqThunk.pending, (state) => {
                state.isWrqSubmitLoading = true;
            })
            .addCase(createOrUpdateWrqThunk.fulfilled, (state, { payload, meta }) => {
                const { exportPdf } = meta.arg;
                if (exportPdf) {
                    const { blob, header } = payload;
                    const encodedFileName = header['content-disposition']?.split('attachment;filename=')[1];
                    const fileName = decodeURI(encodedFileName);
                    downloadBlob({
                        blob,
                        fileName,
                    });
                }
                state.isWrqSubmitLoading = false;
            })
            .addCase(createOrUpdateWrqThunk.rejected, (state) => {
                state.isWrqSubmitLoading = false;
            });
    },
});

export const selectWrq = (state: RootState) => state.mcWorkRequest;

export default wrqSlice.reducer;
export const { updateWrqFormStatus, updateWrqPreviewStatus } = wrqSlice.actions;
