import React from 'react';
import { SxProps } from '@mui/material';

import StyledToggleButton from '../../Toggle';

import { Zone } from '../../../../helper/dateHelper';
import { EventTimeType } from '../../../../constants/constants';

const toggleZoneButtonListData = [
    {
        displayName: EventTimeType.utc,
        value: Zone.utc,
    },
    {
        displayName: EventTimeType.hkt,
        value: Zone.hkt,
    },
];

interface TimeZoneToggleProps {
    selectedZone?: Zone;
    sx?: SxProps;
    onChange?: (zone: Zone) => void;
}

const TimeZoneToggle = (props: TimeZoneToggleProps) => {
    const { selectedZone, sx, onChange } = props;
    return (
        <StyledToggleButton
            sx={sx}
            buttonNames={toggleZoneButtonListData}
            selectedValue={selectedZone}
            onChange={onChange}
        />
    );
};

export default TimeZoneToggle;
