import React, { useEffect, useState } from 'react';
import { InputAdornment, IconButton, SxProps, OutlinedInput } from '@mui/material';

import { ReactComponent as Clear } from '../../../../assets/icons/CloseNoBg.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/Search.svg';
import filterSearchBarStyles from './FilterSearchBarStyles';
import COLORS from '../../../../style/color';

type FilterSearchBarProps = {
    value?: string;
    placeholder?: string;
    onClearClick?: () => void;
    onChange?: (word: string) => void;
    sx?: SxProps;
};

const FilterSearchBar = (props: FilterSearchBarProps) => {
    const { value, placeholder, sx, onClearClick, onChange } = props;

    const [inputValue, setInputValue] = useState(value || '');
    const { classes } = filterSearchBarStyles();

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return (
        <OutlinedInput
            sx={sx}
            classes={classes}
            value={inputValue}
            onChange={(e) => {
                setInputValue(e.target.value);
                onChange?.(e.target.value);
            }}
            placeholder={placeholder}
            endAdornment={
                inputValue ? (
                    <InputAdornment position='end'>
                        <IconButton
                            sx={{ p: '6px' }}
                            onClick={() => {
                                setInputValue('');
                                onClearClick?.();
                            }}
                        >
                            <Clear width={12} height={12} />
                        </IconButton>
                    </InputAdornment>
                ) : null
            }
            startAdornment={
                !inputValue ? (
                    <InputAdornment position='start'>
                        <SearchIcon width={24} height={24} fill={COLORS.grayNormal} />
                    </InputAdornment>
                ) : null
            }
        />
    );
};

export { FilterSearchBar as default };
