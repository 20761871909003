import React from 'react';
import { Typography } from '@mui/material';
import { styled, SxProps } from '@mui/system';

import COLORS from '../../style/color';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
});

const SubtitleContainer = styled('div')({
    margin: '20px auto 60px auto',
});

const ErrorState = (props: {
    title?: string;
    titleSx?: SxProps;
    subtitle?: string;
    subTitleSx?: SxProps;
    actionButtons?: JSX.Element;
}) => {
    const {
        title = "Sorry, this page isn't available at the moment",
        subtitle = 'The page may be broken or removed.',
        actionButtons,
        titleSx,
        subTitleSx,
    } = props;

    return (
        <Container>
            <div>
                <Typography variant='h3' sx={{ color: COLORS.textDark, ...titleSx }}>
                    {title}
                </Typography>
            </div>
            <SubtitleContainer>
                <Typography variant='h2' sx={{ color: COLORS.textDark, ...subTitleSx }}>
                    {subtitle}
                </Typography>
            </SubtitleContainer>
            {actionButtons && actionButtons}
        </Container>
    );
};

export default ErrorState;
