import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj } from '../services/ServerError';
import {
    getAircraftDetailsByRegistration,
    getAircraftADDsByRegistration,
} from '../services/gantt';
import { AircraftDefect } from './ganttSlice';
import { PaxClasses } from './flightTabsPanelSlice';

export type AircraftDetailsData = {
    reg?: string;
    carrier?: string;
    subtype?: string;
    type?: string;
    seatAvailable?: PaxClasses;
    totalSeatAvailable?: number;
    flightNumber?: string;
    port?: string;
    acType?: string;
};

export type AircraftADDsData = {
    reg?: string;
    carrier?: string;
    subtype?: string;
    type?: string;
    openDefects?: AircraftDefect[];
    closedDefects?: AircraftDefect[];
};

interface AircraftTabsPanelSlice {
    isDetailLoading: boolean;
    aircraftDetails: AircraftDetailsData;
    aircraftADDs: AircraftADDsData;
    aircraftErrorObj: ApiErrorObj;
}

const initialState: AircraftTabsPanelSlice = {
    isDetailLoading: false,
    aircraftDetails: null,
    aircraftADDs: null,
    aircraftErrorObj: null,
};

export const getAircraftDetailsByRegistrationThunk = createAsyncThunk<
AircraftDetailsData,
{ registration: string },
{ rejectValue: ApiErrorObj }
>('aircraftTabsPanel/getDetails', async ({ registration }, { rejectWithValue }) => {
    const [err, data] = await getAircraftDetailsByRegistration({ registration });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const getAircraftADDsByRegistrationThunk = createAsyncThunk<
AircraftADDsData,
{ registration: string },
{ rejectValue: ApiErrorObj }
>('aircraftTabsPanel/getADD', async ({ registration }, { rejectWithValue }) => {
    const [err, data] = await getAircraftADDsByRegistration({ registration });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const aircraftTabsPanelSlice = createSlice({
    name: 'aircraftTabsPanel',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAircraftDetailsByRegistrationThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.aircraftErrorObj = null;
        });
        builder.addCase(getAircraftDetailsByRegistrationThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.aircraftErrorObj = payload;
        });
        builder.addCase(getAircraftDetailsByRegistrationThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.aircraftDetails = payload;
            state.aircraftErrorObj = null;
        });
        builder.addCase(getAircraftADDsByRegistrationThunk.pending, (state) => {
            state.isDetailLoading = true;
            state.aircraftErrorObj = null;
        });
        builder.addCase(getAircraftADDsByRegistrationThunk.rejected, (state, { payload }) => {
            state.isDetailLoading = false;
            state.aircraftErrorObj = payload;
        });
        builder.addCase(getAircraftADDsByRegistrationThunk.fulfilled, (state, { payload }) => {
            state.isDetailLoading = false;
            state.aircraftADDs = payload;
            state.aircraftErrorObj = null;
        });
    },
});

export const selectAircraftTabsPanel = (state: RootState) => state.aircraftTabsPanel;

export default aircraftTabsPanelSlice.reducer;
