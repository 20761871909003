import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import { ObsMessage } from '../interface/Obs';
import { sendObsMessage, getObsRecipients, getObsMessageList } from '../services/obs';
import { ResponseObsMessage, RequestObsMessage, EmailContact } from '../interface/Obs';
import { defaultFilters } from '../components/opsBroadcast/constants';

type Filters = RequestObsMessage;

interface OpsBroadcastStateProps {
    isOpsBroadcastLoading: boolean;
    messageList: ResponseObsMessage[];
    total: number;
    filters: Filters;
}

export const getObsRecipientsThunk = createAsyncThunk<
EmailContact[],
{ keywords: string },
{ state: RootState; rejectValue: ApiErrorObj }
>('/getObsRecipients', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/obs/emailList')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getObsRecipients(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const sendObsMessageThunk = createAsyncThunk<
{ broadcastMessageId: string },
ObsMessage,
{ state: RootState; rejectValue: ApiErrorObj }
>('sendObsMessage', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/obs/message', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await sendObsMessage(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const getObsMessageListThunk = createAsyncThunk<
{ results: ResponseObsMessage[]; total: number },
RequestObsMessage,
{ state: RootState; rejectValue: ApiErrorObj }
>('getObsMessageList', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/obs/messages', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getObsMessageList(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

const initialState: OpsBroadcastStateProps = {
    isOpsBroadcastLoading: false,
    messageList: [],
    total: 0,
    filters: defaultFilters,
};

const opsBroadcastSlice = createSlice({
    name: 'opsBroadcast',
    initialState,
    reducers: {
        updateFilters: (state, action: PayloadAction<Partial<Filters>>) => {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetFilters: (state) => {
            state.filters = defaultFilters;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendObsMessageThunk.pending, (state) => {
                state.isOpsBroadcastLoading = true;
            })
            .addCase(sendObsMessageThunk.fulfilled, (state) => {
                state.isOpsBroadcastLoading = false;
            })
            .addCase(sendObsMessageThunk.rejected, (state) => {
                state.isOpsBroadcastLoading = false;
            })
            .addCase(getObsMessageListThunk.pending, (state) => {
                state.isOpsBroadcastLoading = true;
            })
            .addCase(getObsMessageListThunk.fulfilled, (state, { payload }) => {
                const { total, results } = payload || {};
                state.messageList = results;
                state.total = total;
                state.isOpsBroadcastLoading = false;
            })
            .addCase(getObsMessageListThunk.rejected, (state) => {
                state.isOpsBroadcastLoading = false;
            });
    },
});

export const selectOpsBroadcast = (state: RootState) => state.opsBroadcast;
export const { updateFilters, resetFilters } = opsBroadcastSlice.actions;
export default opsBroadcastSlice.reducer;
