import React from 'react';
import { Typography } from '@mui/material';

import { selectFlight } from '../../slices/flightSlice';
import { useAppSelector } from '../../app/hooks';

const NumberOfFlight = () => {
    const { totalFlightBars = 0 } = useAppSelector(selectFlight);

    return (
        <Typography variant='caption2'>
            About <Typography variant='boldGrayDark12'>{totalFlightBars}</Typography> search results
        </Typography>
    );
};

export default NumberOfFlight;
