import COLORS from '../../style/color';
import { FONT_WEIGHT } from '../../style/font';

export const simpleButtonStyles = {
    textTransform: 'none',
    fontSize: '14px',
    margin: '0 4px',
    padding: '0 8px',
    height: '40px',
    lineHeight: '40px',
    color: COLORS.hoverGreen,
    backgroundColor: `${COLORS.transparent} !important`,
    whiteSpace: 'nowrap',
    fontWeight: FONT_WEIGHT.regular,
} as const;

const customStyles = {
    '&': {
        display: 'inline-flex',
        margin: '0 4px',
        padding: '0 8px',
        height: 40,
        borderRadius: '4px',
        fontFamily: 'CathaySans',
        fontSize: '14px',
        lineHeight: '40px',
        borderWidth: '1px',
        borderStyle: 'solid',
        textTransform: 'none',
        whiteSpace: 'nowrap',
    },
    '&.MuiButton-primary': {
        color: COLORS.white,
        background: COLORS.hoverGreen,
        borderColor: COLORS.transparent,
        '& svg': {
            fill: COLORS.white,
        },
        '&:hover': {
            border: `1px solid ${COLORS.transparent}`,
            backgroundColor: COLORS.buttonDarken40,
        },
        '&:active': {
            border: `1px solid ${COLORS.buttonDarken20}`,
            backgroundColor: COLORS.buttonBlue,
        },
        '&:disabled': {
            color: COLORS.grayNormal,
            backgroundColor: COLORS.grayLight,
            borderColor: COLORS.grayLight,
            cursor: 'not-allowed',
            '& svg': {
                fill: COLORS.grayNormal,
            },
        },
    },
    '&.MuiButton-secondary': {
        color: COLORS.hoverGreen,
        background: COLORS.white,
        border: `1px solid ${COLORS.hoverGreen}`,
        '& svg': {
            fill: COLORS.hoverGreen,
        },
        '&:hover': {
            border: `1px solid ${COLORS.hoverGreen}`,
            backgroundColor: COLORS.buttonLighten40,
        },
        '&:active': {
            border: `1px solid ${COLORS.unselectable}`,
            backgroundColor: COLORS.buttonLighten20,
        },
        '&:disabled': {
            color: COLORS.grayNormal,
            backgroundColor: COLORS.white,
            borderColor: COLORS.unselectableLighten20,
            cursor: 'not-allowed',
            '& svg': {
                fill: COLORS.grayNormal,
            },
        },
    },
    '&.MuiButton-outlined': {
        color: COLORS.hoverGreen,
        background: COLORS.white,
        borderWidth: '0px',
        borderStyle: 'none',
        '&:hover': {
            backgroundColor: COLORS.buttonLighten40,
        },
        '&:active': {
            backgroundColor: COLORS.buttonLighten20,
        },
        '&:disabled': {
            color: COLORS.grayNormal,
            backgroundColor: COLORS.white,
            borderColor: COLORS.unselectableLighten20,
            cursor: 'not-allowed',
            '& svg': {
                fill: COLORS.grayNormal,
            },
        },
    },
    '&.MuiButton-tertiary': {
        color: COLORS.hoverGreen,
        background: COLORS.white,
        borderWidth: '0px',
        borderStyle: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: COLORS.hoverText,
        },
        '&:active': {
            color: COLORS.activeText,
        },
        '&:disabled': {
            color: COLORS.grayNormal,
            cursor: 'not-allowed',
            '& svg': {
                fill: COLORS.grayNormal,
            },
        },
    },
    '& .MuiButton-startIcon': {
        marginLeft: '0px',
    },
};

export default customStyles;
