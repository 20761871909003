import { styled, Typography, TypographyTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

// To add back prop 'component' to Typepography after wrapping with styled();
// https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop

const LineClampTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== 'maxLine' })<{
    maxLine?: number;
}>(({ maxLine = 1 }) => ({
    display: '-webkit-box',
    overflow: ' hidden',
    lineClamp: maxLine,
    boxOrient: 'vertical',
    WebkitLineClamp: maxLine,
    WebkitBoxOrient: 'vertical',
    lineHeight: '23px',
    wordBreak: 'break-word',
})) as OverridableComponent<TypographyTypeMap<{ maxLine?: number }>>;

export default LineClampTypography;
