import React, { forwardRef, useEffect, useState } from 'react';
import { FilledTextFieldProps, TextField, styled } from '@mui/material';

import colors from '../../../../style/color';

interface TextInputProps extends Partial<FilledTextFieldProps> {
    inputRef?: React.Ref<any>;
}

/**
 * Styled text input containing all props that we have from MUI FilledTextField
 * https://mui.com/material-ui/api/text-field/
 *
 * Also support multiple line input.
 * May add custom props to TextInputProps interface for special logic when needed ONLY.
 */
const TextInput = forwardRef((props: TextInputProps, ref: React.Ref<any>) => {
    const { InputLabelProps, inputRef, InputProps, onFocus, onBlur, ...muiFilledTextFieldProps } = props;
    const [labelShrink, setLabelShrink] = useState(!!(props.value || props.defaultValue));
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        setLabelShrink(!!props.value || focused);
    }, [props.value]);

    return (
        <StyledTextField
            ref={ref}
            inputRef={inputRef}
            variant='filled'
            onFocus={(e) => {
                setLabelShrink(true);
                setFocused(true);
                onFocus?.(e);
            }}
            onBlur={(e) => {
                // When it's onBlur, the value in event.target is not update immediately.
                // The setTimeout is to make sure we get the right value when it's blurred.
                setTimeout(() => setLabelShrink(!!(e.target.value || props.value)), 100);
                setFocused(false);
                onBlur?.(e);
            }}
            InputLabelProps={{
                shrink: labelShrink,
                ...InputLabelProps,
                sx: {
                    ...(InputProps?.startAdornment && {
                        ml: '32px',
                        maxWidth: 'calc(100% - 56px)',
                        '&.MuiInputLabel-shrink': {
                            maxWidth: 'calc(133% - 65px)',
                        },
                    }),
                    ...InputLabelProps?.sx,
                },
            }}
            InputProps={{
                disableUnderline: true,
                ...InputProps,
            }}
            {...muiFilledTextFieldProps}
        />
    );
});

const StyledTextField = styled(TextField)({
    width: '100%',
    '& .MuiFilledInput-root': {
        color: colors.grayDark,
        padding: '0px',
        borderRadius: '4px',
        border: `1px solid ${colors.borderGray}`,
        backgroundColor: colors.white,
        '&.Mui-focused': {
            border: `1px solid ${colors.textDark}`,
            backgroundColor: colors.white,
        },
        '&:hover': {
            backgroundColor: colors.buttonBgWhite,
        },
        '& .MuiFilledInput-input': {
            padding: '15px 12px 3px',
            '&.Mui-disabled': {
                WebkitTextFillColor: 'unset',
            },
        },
        '&.Mui-error': {
            border: `1px solid ${colors.error}`,
        },
        '&.Mui-disabled': {
            backgroundColor: colors.grayLight,
            color: colors.grayDark,
        },
        '& .MuiInputAdornment-root': {
            margin: '0px',
            '&.MuiInputAdornment-positionEnd:not(.MuiInputAdornment-hiddenLabel)': {
                margin: '0px 12px 0px 0px',
            },
            '&.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)': {
                margin: '0px 0px 0px 12px',
            },
        },
    },
    '& .MuiInputLabel-root': {
        color: colors.grayNormal,
        top: '-6px',
        '&.MuiInputLabel-shrink': {
            top: '-4px',
        },
        '&.Mui-focused': {
            color: colors.grayNormal,
        },
        '&.Mui-error': {
            color: colors.grayNormal,
        },
    },
    '& .MuiFormHelperText-root': {
        marginLeft: '0px',
        color: colors.grayDark,
        '&.Mui-error': {
            color: colors.error,
        },
    },
});

export { TextInput as default, TextInputProps, StyledTextField };
