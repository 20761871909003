import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, DASHBOARD_API } from '../constants/api';
import { LiveDashboardRequestBody } from '../interface/Dashboard';


const getLiveDashboard = async (param: LiveDashboardRequestBody) => {
    const { airline, filters } = param;
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        DASHBOARD_API.getLiveDashboard,
        {
            airline,
            filters,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getLiveDashboard };
