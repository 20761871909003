import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, CHECKLIST_API } from '../constants/api';
import {
    IChecklistEventsRequest,
    IChecklistTaskRequest,
    IChecklistsRequest,
    IUpdateTaskRequest,
    IUpdateTaskMeetingTaskFieldsRequest,
    IEditIRopsMeetingTaskFieldsRequest,
    IGetEventByIdRequest,
    IReportIrOpsRequestParams,
    IRopsDeleteRequest,
    ICopyMeetingFieldsRequest,
    IUpdateChecklistRequest,
    IAddNewTasksRequest,
    IEventFormSubmitRequest,
    IAddNewGenericChecklistRequest,
    IAddNewIROPSChecklistRequest,
    IGetComments,
    IDeleteComments,
    ISaveComments,
    IUpdateSequenceRequest,
    IUpdateQNARequest,
} from '../interface/Checklist';

const getChecklistEvents = async (params: IChecklistEventsRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, CHECKLIST_API.getChecklistEvents, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getChecklistsById = async (params: IChecklistsRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, CHECKLIST_API.getChecklistsById, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getChecklistTaskById = async (params: IChecklistTaskRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, CHECKLIST_API.getChecklistTaskById, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};
const getEventById = async (params: IGetEventByIdRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, CHECKLIST_API.getChecklistEvent, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateTask = async (params: IUpdateTaskRequest | IEditIRopsMeetingTaskFieldsRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.updateTask, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const copyTeamMeeting = async (params: ICopyMeetingFieldsRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.copyChecklist, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateChecklist = async (params: IUpdateChecklistRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.updateChecklist, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const sendMeeting = async (params: IUpdateTaskMeetingTaskFieldsRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.sendMeeting, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const createEvent = async (params: IEventFormSubmitRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.createChecklistEvent, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};
const updateEvent = async (params: IEventFormSubmitRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.updateChecklistEvent, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const reportChecklistIRopsPDF = async (params: IReportIrOpsRequestParams) => {
    const [err, resp, header] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        CHECKLIST_API.reportChecklistIRopsPDF,
        params,
        {
            responseType: 'blob',
        }
    );

    if (err) {
        return [err];
    }

    return [null, resp, header];
};

const deleteIRopsChecklistAndTask = async (params: IRopsDeleteRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.deleteChecklistAndTask, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const addNewTasks = async (params: IAddNewTasksRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.addNewTasks, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const addNewChecklist = async (params: IAddNewGenericChecklistRequest | IAddNewIROPSChecklistRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.addNewChecklist, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getComments = async (params: IGetComments) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, CHECKLIST_API.getComments, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const deleteComments = async (params: IDeleteComments) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.deleteComment, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const saveComment = async (params: ISaveComments) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.getComments, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateSequence = async (params: IUpdateSequenceRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.updateSequence, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const updateQNA = async (params: IUpdateQNARequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_API.updateQNA, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export {
    updateChecklist,
    getChecklistEvents,
    getChecklistsById,
    getChecklistTaskById,
    updateTask,
    sendMeeting,
    updateEvent,
    createEvent,
    getEventById,
    reportChecklistIRopsPDF,
    deleteIRopsChecklistAndTask,
    copyTeamMeeting,
    addNewTasks,
    addNewChecklist,
    saveComment,
    deleteComments,
    getComments,
    updateSequence,
    updateQNA,
};
