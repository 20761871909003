import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { UseFormReturn } from 'react-hook-form';

import TitledSection from './TitledSection';
import InputField from '../../common/InputField';
import DelayField from './DelayField';
import DateTimeRow from './DateTimeRow';
import { useLocalTime } from '../../../app/hooks';

import { titleCase } from '../../../helper/stringHelper';
import { shouldShowDelayField, shouldShowScheduleInput } from '../../../helper/iocAlertHelper';
import { DestinationPortTypes } from '../../../interface/IocAlert';

import { TimeTypes } from '../../../constants/constants';
import {
    IOCAlertTypes,
    timeOptionsSTAOnly,
    arrivalTimeOptions,
    FIELD_WIDTH,
    timeOptionsETAATA,
    dptWatchFields,
    arrWatchFields,
    timeOptionsSTDOnly,
    timeOptionsETDATD,
    departureTimeOptions,
    DEBOUNCE_INTERVAL,
} from '../constants';

interface DestinationInfoProps
    extends Pick<UseFormReturn, 'getValues' | 'setValue' | 'control' | 'register' | 'unregister'> {
    currentAlertType: IOCAlertTypes;
    registerNamePrefix: string;
    portType: DestinationPortTypes;
}

const getTimeOptionSet = (portType: DestinationPortTypes, alertType: IOCAlertTypes) => {
    if (portType === 'departure') {
        return {
            estAndActual: timeOptionsETDATD,
            scheduleOnly: timeOptionsSTDOnly,
            full: departureTimeOptions,
        };
    } else {
        return {
            estAndActual: timeOptionsETAATA,
            scheduleOnly: timeOptionsSTAOnly,
            full: arrivalTimeOptions,
        };
    }
};

const DestinationInfo = (props: DestinationInfoProps) => {
    const { currentAlertType, getValues, setValue, control, register, unregister, registerNamePrefix, portType } =
        props;

    const registerNamePortType = `${registerNamePrefix}.${portType}`;
    const isDeparture = portType === 'departure';
    const scheduleTimeType = isDeparture ? TimeTypes.STD : TimeTypes.STA;
    const estimateTimeType = isDeparture ? TimeTypes.ETD : TimeTypes.ETA;
    const actualTimeType = isDeparture ? TimeTypes.ATD : TimeTypes.ATA;
    const watchFieldSet = isDeparture ? dptWatchFields : arrWatchFields;
    const shouldShowSchedule = shouldShowScheduleInput(currentAlertType);
    const shouldShowDelay = shouldShowDelayField(currentAlertType);

    const { estAndActual, scheduleOnly, full } = getTimeOptionSet(portType, currentAlertType);
    const nonFixedTimeTypeOptions = shouldShowDelay ? estAndActual : full;
    const { convertLocalTime, portTimeOffsets } = useLocalTime();
    const [inputSchedule, inputEstimate, inputActual] = useWatch({
        control,
        name: watchFieldSet.map((field) => `${registerNamePortType}.${field}`),
        defaultValue: ['', '', ''],
    });

    const [nonFixedDateTime, setNonFixedDateTime] = useState({ type: '', dateTime: '' });
    const [fixedDateTime, setFixedDateTime] = useState({
        type: scheduleTimeType,
        dateTime: '',
    });

    const updateLocalTime = useCallback(
        debounce(async (registerName: string, UTCDateTime: string) => {
            const localRegisterName = `${registerName}Local`;
            if (!UTCDateTime) {
                setValue(localRegisterName, '');
                return;
            }

            const port = getValues(`${registerNamePortType}.port`);
            convertLocalTime(port, [UTCDateTime]).then(([localTime]) => {
                setValue(localRegisterName, localTime);
            });
        }, DEBOUNCE_INTERVAL),
        [portTimeOffsets]
    );

    const handlePortChange = useCallback(
        debounce(async (port: string) => {
            convertLocalTime(port, [fixedDateTime.dateTime, nonFixedDateTime.dateTime]).then(
                ([fixedLocalDateTime, nonFixedLocalDateTime]) => {
                    setValue(`${registerNamePortType}.${fixedDateTime.type}Local`, fixedLocalDateTime);
                    setValue(`${registerNamePortType}.${nonFixedDateTime.type}Local`, nonFixedLocalDateTime);
                }
            );
        }, DEBOUNCE_INTERVAL),
        [fixedDateTime.dateTime, nonFixedDateTime.dateTime, portTimeOffsets]
    );

    useEffect(() => {
        if (shouldShowSchedule) {
            setFixedDateTime({ type: scheduleTimeType, dateTime: inputSchedule });

            // ignore NonFixedDateTime because we use only fixed STD for cancellation.
            if (currentAlertType === IOCAlertTypes.cancellation) return;

            if (inputActual) {
                setNonFixedDateTime({ type: actualTimeType, dateTime: inputActual });
                return;
            }

            if (inputEstimate) {
                setNonFixedDateTime({
                    type: estimateTimeType,
                    dateTime: inputEstimate,
                });
                return;
            }
        } else {
            setFixedDateTime({ type: scheduleTimeType, dateTime: '' });

            if (inputActual) {
                setNonFixedDateTime({ type: actualTimeType, dateTime: inputActual });
                return;
            }

            if (inputEstimate) {
                setNonFixedDateTime({
                    type: estimateTimeType,
                    dateTime: inputEstimate,
                });
                return;
            }

            if (inputSchedule) {
                setNonFixedDateTime({
                    type: scheduleTimeType,
                    dateTime: inputSchedule,
                });
                return;
            }
        }

        // Default to select ETD/ETA in dropdown if time value of available dropdown items is not valid.

        // if don't have any keys of the non fixed date time, it's from system reset.
        const timeValues = getValues(registerNamePortType);
        const shouldClear = nonFixedTimeTypeOptions
            .map((option) => option.value)
            .every((key) => !timeValues?.hasOwnProperty(key));
        if (shouldClear) {
            setNonFixedDateTime({ type: estimateTimeType, dateTime: '' });
        }
    }, [inputSchedule, inputEstimate, inputActual, currentAlertType]);

    return (
        <TitledSection title={`${titleCase(portType)} Information`}>
            <Controller
                name={`${registerNamePortType}.port`}
                defaultValue=''
                render={({ field: { value, onChange } }) => (
                    <InputField
                        label={`${titleCase(portType)} Port`}
                        width={FIELD_WIDTH}
                        value={value}
                        onChange={(e) => {
                            const input = e.target.value.replace(/[^a-zA-Z]/g, '').toUpperCase();
                            onChange(input);
                            handlePortChange(input);
                        }}
                    />
                )}
            />

            {shouldShowSchedule && (
                <DateTimeRow
                    dropdownLabel={titleCase(portType)}
                    registerNamePrefix={registerNamePortType}
                    isDropdownDisabled={true}
                    timeType={TimeTypes[fixedDateTime.type]}
                    dateTime={fixedDateTime.dateTime}
                    dropwDownList={scheduleOnly}
                    onChange={async (type, dateTime) => {
                        setValue(`${registerNamePortType}.${type}`, dateTime);
                        setFixedDateTime({ type, dateTime });
                        updateLocalTime(`${registerNamePortType}.${type}`, dateTime);
                    }}
                />
            )}

            {currentAlertType !== IOCAlertTypes.cancellation && (
                <DateTimeRow
                    dropdownLabel={titleCase(portType)}
                    registerNamePrefix={registerNamePortType}
                    timeType={TimeTypes[nonFixedDateTime.type]}
                    dateTime={nonFixedDateTime.dateTime}
                    dropwDownList={nonFixedTimeTypeOptions}
                    onChange={async (type, dateTime) => {
                        setValue(`${registerNamePortType}.${type}`, dateTime);
                        if (nonFixedDateTime?.type && nonFixedDateTime.type !== type) {
                            setValue(`${registerNamePortType}.${nonFixedDateTime.type}`, '');
                            setValue(`${registerNamePortType}.${nonFixedDateTime.type}Local`, '');
                        }
                        setNonFixedDateTime({ type, dateTime });
                        updateLocalTime(`${registerNamePortType}.${type}`, dateTime);
                    }}
                />
            )}

            {shouldShowDelay && (
                <DelayField
                    currentAlertType={currentAlertType}
                    inputWatchFieldSet={watchFieldSet}
                    portType={portType}
                    registerNamePrefix={registerNamePrefix}
                    {...{ register, unregister, setValue, getValues }}
                />
            )}
        </TitledSection>
    );
};

export default DestinationInfo;
