import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, REPORT_API } from '../constants/api';
import { ISendReport } from '../slices/reportSlice';
import { AogStatusReportRequest } from '../interface/AogStatusReport';

const getReportCardByEventId = async (id: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, REPORT_API.getReportCard, {
        eventId: id,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const sendReportByFiles = async ({
    group,
    emails,
    fileId,
    userId,
    eventId,
    version,
    dueTime,
    reportType,
}: ISendReport) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, REPORT_API.sendAogReport, {
        group,
        emails,
        fileId,
        userId,
        eventId,
        version,
        dueTime,
        reportType,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const generateAogStatusReport = async (params: AogStatusReportRequest) => {
    const [err, resp, header] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        REPORT_API.generateAogStatusReport,
        params,
        {
            responseType: 'blob',
        }
    );

    if (err) {
        return [err];
    }

    return [null, resp, header];
};

export { getReportCardByEventId, sendReportByFiles, generateAogStatusReport };

