import React from 'react';
import { Card, CardActionArea, Stack, SxProps, Typography } from '@mui/material';

import { ReactComponent as ReportIcon } from '../../../../assets/icons/Report.svg';
import COLORS from '../../../../style/color';
import theme from '../../../../style/theme';
import {ReportCardStatus, ReportCardLinkLabel} from '../../../../constants/constants';

export enum ReportThumbnailStatus {
    NORMAL = 'normal',
    WARNING = 'warning',
}

interface ReportThumbnailOptions {
    statusLabelColor?: string;
    statusLabelBgColor?: string;
    iconColor?: string;
    borderColor?: string;
    statusLabel?: string;
    actionLabel?: string;
    actionLabelColor?: string;
}

interface ReportCardProps {
    id?: number;
    content?: string[];
    status?: ReportThumbnailStatus | ReportCardStatus;
    options?: ReportThumbnailOptions;
    containerSx?: SxProps;
    disabled?: boolean;
    onClick?: (id: number) => void;
}

const getOptionsByStatus = (status: ReportThumbnailStatus | ReportCardStatus): ReportThumbnailOptions => {
    switch (status) {
        case ReportThumbnailStatus.NORMAL:
            return {
                statusLabelColor: COLORS.cathayJade,
                statusLabelBgColor: COLORS.brightGray,
                iconColor: COLORS.cathayJade,
                borderColor: COLORS.grayLight,
                statusLabel: 'Sent',
                actionLabel: 'View',
            };
        case ReportThumbnailStatus.WARNING:
            return {
                statusLabelColor: COLORS.secondError,
                statusLabelBgColor: COLORS.lavenderLighten,
                iconColor: COLORS.oxleyLighten,
                borderColor: COLORS.warning,
                statusLabel: 'Saved',
                actionLabel: 'View',
            };
        case ReportCardStatus.isInprogress:
            return {
                statusLabelColor: theme.statusTagColor.inProgressColor,
                statusLabelBgColor: theme.statusTagColor.inProgressBgColor,
                iconColor: COLORS.oxleyLighten,
                borderColor: COLORS.grayLight,
                statusLabel: status,
                actionLabel: ReportCardLinkLabel.view,
            };
        case ReportCardStatus.completed:
            return {
                statusLabelColor: theme.statusTagColor.completedColor,
                statusLabelBgColor: theme.statusTagColor.completedBgColor,
                iconColor: COLORS.cathayJade,
                borderColor: COLORS.grayLight,
                statusLabel: status,
                actionLabel: ReportCardLinkLabel.download,
            };
        case ReportCardStatus.upcoming:
            return {
                statusLabelColor: theme.statusTagColor.closedColor,
                statusLabelBgColor:  theme.statusTagColor.closedBgColor,
                iconColor: COLORS.grayLight,
                borderColor: COLORS.grayLight,
                statusLabel: status,
                actionLabel: ReportCardLinkLabel.view,
                actionLabelColor: COLORS.grayWhite,
            };
        default:
            return {
                statusLabelColor: '',
                statusLabelBgColor: COLORS.veryLightShadeGray,
                iconColor: COLORS.backgroundGray,
                borderColor: COLORS.grayLight,
                statusLabel: 'Status',
                actionLabel: 'action',
            };
    }
};

const ReportThumbnail = (props: ReportCardProps) => {
    const { status, id, content = [], options, containerSx, onClick, disabled } = props;
    const {
        statusLabelColor,
        statusLabelBgColor,
        iconColor,
        borderColor,
        statusLabel = '',
        actionLabel = '',
        actionLabelColor = COLORS.hoverGreen,
    } = {
        ...getOptionsByStatus(status),
        ...options,
    };

    return (
        <Card
            variant='outlined'
            sx={{
                border: `1px solid ${borderColor}`,
                background: COLORS.white,
                boxShadow: 'none',
            }}
        >
            <CardActionArea
                onClick={() => {
                    onClick?.(id);
                }}
                disabled={disabled}
            >
                <Stack
                    direction='column'
                    sx={{
                        padding: '20px 8px 8px 8px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        ...containerSx,
                    }}
                >
                    <ReportIcon fill={iconColor} />
                    <Typography
                        variant='caption3'
                        sx={{
                            padding: '6px 4px',
                            margin: '8px 0px',
                            backgroundColor: statusLabelBgColor,
                            color: statusLabelColor,
                            borderRadius: '2px',
                        }}
                    >
                        {statusLabel}
                    </Typography>
                    {content?.map((text: string, index: number) => {
                        if (!text) {
                            return null;
                        }
                        return (
                            <Typography
                                key={index}
                                variant='caption2Medium'
                                align='center'
                                sx={{ flexGrow: 2, whiteSpace: 'nowrap' }}
                            >
                                {text}
                            </Typography>
                        );
                    })}
                    <Typography variant='caption3' sx={{ mt: '4px', color: actionLabelColor }}>
                        {actionLabel}
                    </Typography>
                </Stack>
            </CardActionArea>
        </Card>
    );
};

export default ReportThumbnail;
