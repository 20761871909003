import { EVENTS_API, HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, IOC_ALERT_URL } from '../constants/api';
import { IOCAlertType } from '../interface/IocAlert';
import { sendHttpRequest } from './api';

const deleteAlertDraft = async (eventTaskId: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, IOC_ALERT_URL.deleteAlertDraft, {
        eventTaskId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const saveIOCAlert = async (param: { details: IOCAlertType; eventTaskId?: string; eventId: string; status: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.saveIOCAlert, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getIOCAlertById = async (alertId: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENTS_API.getIOCAlertById, {
        eventTaskId: alertId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const sendIOCAlert = async (param: { details: IOCAlertType; eventId: string; eventTaskId?: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, EVENTS_API.sendIOCAlert, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { deleteAlertDraft, saveIOCAlert, getIOCAlertById, sendIOCAlert };
