import COLORS from '../../style/color';
import { FONT_WEIGHT } from '../../style/font';

const customStyles = {
    height: '40px',
    '& .MuiToggleButtonGroup-groupedHorizontal': {
        borderColor: COLORS.grayNormal,
        color: COLORS.grayDark,
        fontWeight: FONT_WEIGHT.regular,
        '&:hover': {
            backgroundColor: COLORS.buttonBgWhite,
        },
    },
    '& .Mui-selected.MuiToggleButton-standard': {
        backgroundColor: COLORS.cathayJade,
        borderColor: COLORS.cathayJade,
        color: COLORS.white,
        fontWeight: 'bold',
        '&:hover': {
            color: COLORS.grayDark,
            borderColor: COLORS.grayNormal,
            backgroundColor: COLORS.buttonBgWhite,
            fontWeight: FONT_WEIGHT.regular,
        },
    },
};

export default customStyles;
