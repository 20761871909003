import React from 'react';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

import SortControl, { ArrowStyleProps } from './SortControl';

interface HeaderWithSortControlProps {
    sortable?: boolean;
    title: string;
    titleKey?: string;
    sortAsc?: boolean;
    sortDesc?: boolean;
    arrowStyles?: ArrowStyleProps;
    titleSx?: SxProps;
    sortControlSx?: SxProps;
    onSortChange?: (name: string) => void;
}

const HeaderWithSortControl = (props: HeaderWithSortControlProps) => {
    const {
        sortable = false,
        titleKey = '',
        title,
        onSortChange,
        sortAsc = false,
        sortDesc = false,
        titleSx,
        arrowStyles,
        sortControlSx,
    } = props;

    return (
        <Stack flexDirection='row' alignItems='center'>
            <Typography variant='boldGrayDark12' sx={titleSx}>
                {title}
            </Typography>
            {sortable && (
                <SortControl
                    sx={{ pl: 1, ...sortControlSx }}
                    titleKey={titleKey}
                    sortAsc={sortAsc}
                    sortDesc={sortDesc}
                    onSortChange={onSortChange}
                    arrowStyles={arrowStyles}
                />
            )}
        </Stack>
    );
};

export default HeaderWithSortControl;
