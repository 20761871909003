import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import { RootState } from '../app/store';
import { DefinitionData, PriorityFlightList, DefinitionList, DefinitionRequestBody } from '../interface/PriorityFlight';
import {
    getDefinitionList,
    getPriorityFlightList,
    sendPriorityFlightList,
    createDefinition,
    deleteDefinition,
} from '../services/priorityFlight';

export interface PriorityFlightSlice {
    definitionList: DefinitionData[];
    selectedDefinition: DefinitionData;
    totalDefinitions: number;
    isPriorityFlightLoading: boolean;
    priorityFlightList: PriorityFlightList;
}

const initialState: PriorityFlightSlice = {
    totalDefinitions: 0,
    definitionList: [],
    selectedDefinition: null,
    isPriorityFlightLoading: false,
    priorityFlightList: null,
};

export const getDefinitionListThunk = createAsyncThunk<
DefinitionList,
DefinitionRequestBody,
{ state: RootState; rejectValue: ApiErrorObj }
>('priorityFlight/getDefinitionList', async (DefinitionRequestBody, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/priorityFlight/definition', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getDefinitionList(DefinitionRequestBody);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data as DefinitionList;
});

export const getPriorityFlightListThunk = createAsyncThunk<
PriorityFlightList,
{ date: string; size?: number; page?: number },
{ state: RootState; rejectValue: ApiErrorObj }
>('priorityFlight/getFlightList', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/priorityFlight/list')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getPriorityFlightList(params);

    if (err) {
        return rejectWithValue(err);
    }

    return data;
});

export const sendPriorityFlightListThunk = createAsyncThunk<
null,
{ date: string },
{ state: RootState; rejectValue: ApiErrorObj }
>('priorityFlight/sendFlightList', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/priorityFlight/mail')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err] = await sendPriorityFlightList(params);

    if (err) {
        return rejectWithValue(err);
    }

    return;
});

export const createDefinitionThunk = createAsyncThunk<
{ definitionId: number },
DefinitionData,
{ state: RootState; rejectValue: ApiErrorObj }
>('/priorityFlight/createDefinition', async (param, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/priorityFlight/definition', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await createDefinition({ param });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const editDefinitionThunk = createAsyncThunk<
{ definitionId: number },
DefinitionData,
{ state: RootState; rejectValue: ApiErrorObj }
>('/priorityFlight/editDefinition', async (param, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/priorityFlight/definition', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await createDefinition({ param });
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const deleteDefinitionThunk = createAsyncThunk<
{ definitionId: number },
number,
{ state: RootState; rejectValue: ApiErrorObj }
>('/priorityFlight/deleteDefinition', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (
        !resourceCheck(
            currentPermissionList,
            ResourceType.API,
            '/priorityFlight/definition/delete',
            ResourceAction.POST
        )
    ) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await deleteDefinition(params);
    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const priorityFlightSlice = createSlice({
    name: 'priorityFlight',
    initialState,
    reducers: {
        selectDefinition: (state, action: PayloadAction<DefinitionData>) => {
            state.selectedDefinition = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDefinitionListThunk.pending, (state) => {
                state.isPriorityFlightLoading = true;
            })
            .addCase(getDefinitionListThunk.fulfilled, (state, { payload }) => {
                const { definitions, total } = payload;
                state.definitionList = definitions;
                state.totalDefinitions = total;
                state.isPriorityFlightLoading = false;
            })
            .addCase(getDefinitionListThunk.rejected, (state) => {
                state.isPriorityFlightLoading = false;
            })
            .addCase(getPriorityFlightListThunk.pending, (state) => {
                state.isPriorityFlightLoading = true;
            })
            .addCase(getPriorityFlightListThunk.fulfilled, (state, { payload }) => {
                state.priorityFlightList = payload;
                state.isPriorityFlightLoading = false;
            })
            .addCase(getPriorityFlightListThunk.rejected, (state) => {
                state.priorityFlightList = null;
                state.isPriorityFlightLoading = false;
            })
            .addCase(sendPriorityFlightListThunk.pending, (state) => {
                state.isPriorityFlightLoading = true;
            })
            .addCase(sendPriorityFlightListThunk.fulfilled, (state) => {
                state.isPriorityFlightLoading = false;
            })
            .addCase(sendPriorityFlightListThunk.rejected, (state) => {
                state.isPriorityFlightLoading = false;
            })
            .addCase(createDefinitionThunk.pending, (state) => {
                state.isPriorityFlightLoading = true;
            })
            .addCase(createDefinitionThunk.fulfilled, (state, { payload }) => {
                state.isPriorityFlightLoading = false;
            })
            .addCase(createDefinitionThunk.rejected, (state, { payload }) => {
                state.isPriorityFlightLoading = false;
            })
            .addCase(editDefinitionThunk.pending, (state) => {
                state.isPriorityFlightLoading = true;
            })
            .addCase(editDefinitionThunk.fulfilled, (state, { payload }) => {
                state.isPriorityFlightLoading = false;
            })
            .addCase(editDefinitionThunk.rejected, (state, { payload }) => {
                state.isPriorityFlightLoading = false;
            })
            .addCase(deleteDefinitionThunk.pending, (state) => {
                state.isPriorityFlightLoading = true;
            })
            .addCase(deleteDefinitionThunk.fulfilled, (state, { payload }) => {
                state.isPriorityFlightLoading = false;
            })
            .addCase(deleteDefinitionThunk.rejected, (state, { payload }) => {
                state.isPriorityFlightLoading = false;
            });
    },
});

export const selectPriorityFlight = (state: RootState) => state.priorityFlight;
export default priorityFlightSlice.reducer;

export const { selectDefinition } = priorityFlightSlice.actions;
