import { v4 as uuidv4 } from 'uuid';
import { StatusBarType, StatusBarTypes } from '../components/common/StatusBar';
import { ApiErrorObj } from '../services/ServerError';
import { uploadMessage } from '../constants/constants';

type StatusMsgCategory =
    | 'userProfile'
    | 'eventList'
    | 'eventTasks'
    | 'createEvent'
    | 'editEvent'
    | 'reopenEvent'
    | 'cancelEvent'
    | 'closeEvent'
    | 'editEventAssessment'
    | 'updateETS'
    | 'updateATS'
    | 'statusChange'
    | 'disMessage'
    | 'downloadFile'
    | 'uploadFile'
    | 'statusReport'
    | 'addDetails'
    | 'flightsInfo'
    | 'historyLog'
    | 'issueSummary'
    | 'recoveryPlan'
    | 'chat'
    | 'chatWs'
    | 'FlightChannels'
    | 'Log'
    | 'gantt'
    | 'liveDashboard'
    | 'dailyDashboard'
    | 'eventFollowUp'
    | 'others'
    | 'priorityFlight'
    | 'actionItem'
    | 'createDefinition'
    | 'editDefinition'
    | 'deleteDefinition'
    | 'dom'
    | 'saveEventNote'
    | 'saveEventTaskNote'
    | 'iocAlert'
    | 'createEventSetting'
    | 'saveTriggerFail'
    | 'saveTrigger'
    | 'workRequest'
    | 'alertModule'
    | 'obs';

type MessageScenario =
    | 'somethingsWrong'
    | 'getUserGroupFail'
    | 'getGroupsMappingFail'
    | 'getUserProfileFail'
    | 'getAllEventsFail'
    | 'getIocEventsFail'
    | 'getEventByIdFail'
    | 'getEventSettingFail'
    | 'getEventTasksByIdFail'
    | 'createEventSuccess'
    | 'createEventFail'
    | 'reopenEventFail'
    | 'reopenEventSuccess'
    | 'cancelEventFail'
    | 'cancelEventSuccess'
    | 'editEventSuccess'
    | 'editEventFail'
    | 'updateEtsFail'
    | 'updateEtsSuccess'
    | 'updateEtsConflict'
    | 'updateAtsFail'
    | 'updateIssueSummaryConflict'
    | 'updateStatusServiceable'
    | 'updateStatusUnserviceable'
    | 'getDisMessageFail'
    | 'sendDisMessageSuccess'
    | 'sendDisMessageFail'
    | 'downloadFileFail'
    | 'uploadFileFail'
    | 'exportLmttFileFail'
    | 'downloadCoePdfSuccess'
    | 'downloadCoePdfFail'
    | 'getAddFail'
    | 'getAllFlightsFail'
    | 'getDepartureFlightsFail'
    | 'getHistoryLogsFail'
    | 'uploadReportFail'
    | 'previewReportFail'
    | 'getReportsFail'
    | 'sendReportFail'
    | 'sendReportSuccess'
    | 'getAtaCodesFail'
    | 'getIssueHistoryFail'
    | 'saveIssueSummarySuccess'
    | 'saveIssueSummaryFail'
    | 'updateIssueSummarySuccess'
    | 'updateIssueSummaryFail'
    | 'getRecoveryPlanDetailsFail'
    | 'saveRecoveryPlanDetailsFail'
    | 'updateRecoveryPlanConflict'
    | 'saveRecoveryPlanDetailsSuccess'
    | 'chatFail'
    | 'getChatMetaFail'
    | 'loadChatMessageFail'
    | 'getChatImageFail'
    | 'chatWsFail'
    | 'getFlightByChatUfisFail'
    | 'getAirportListFail'
    | 'getLogListFail'
    | 'removeLogSuccess'
    | 'removeLogFail'
    | 'removeMcLogSuccess'
    | 'removeMcLogFail'
    | 'declareAogSuccess'
    | 'declareAogFail'
    | 'getFlightEventsFail'
    | 'getFlightGeneralDetailsFail'
    | 'getAircraftGeneralDetailsFail'
    | 'getSubfleetGeneralDetailsFail'
    | 'saveLogSuccess'
    | 'saveLogFail'
    | 'closeEventSuccess'
    | 'closeEventFail'
    | 'getCoeEventByIdFail'
    | 'updateFollowUpFail'
    | 'updateEventStatusFail'
    | 'updateEventTaskStatusFail'
    | 'getLiveDashboardFail'
    | 'getDailyDashboardFail'
    | 'others'
    | 'createActionItemFail'
    | 'createActionItemSuccess'
    | 'getPriorityDefinitonFail'
    | 'getPriorityFlightListFail'
    | 'sendPriorityFlightListFail'
    | 'sendPriorityFlightListSuccess'
    | 'createDefinitionSuccess'
    | 'createDefinitionFail'
    | 'editDefinitionSuccess'
    | 'editDefinitionFail'
    | 'getFlightEventsAndConstraintsFail'
    | 'getFlightScheduleFail'
    | 'getFlightAirportFail'
    | 'getFlightADDsFail'
    | 'getFlightCargoFail'
    | 'getFlightPlanningFail'
    | 'getFlightPaxFail'
    | 'getFlightCrewFail'
    | 'getFlightCargoPTSFail'
    | 'getAircraftDetailsByRegistrationFail'
    | 'getAircraftADDsByRegistrationFail'
    | 'getSubfleetSummaryFail'
    | 'getSubfleetAircraftPerformanceFail'
    | 'getAllAircraftTypeFail'
    | 'getDomTopCardFail'
    | 'getDomCrewListFail'
    | 'markAllReadFail'
    | 'deleteDefinitionSuccess'
    | 'deleteDefinitionFail'
    | 'editEventAssessmentSuccess'
    | 'editEventAssessmentFail'
    | 'updateUserGroupFail'
    | 'saveEventNoteSuccess'
    | 'saveEventNoteFail'
    | 'saveEventTaskNoteSuccess'
    | 'saveEventTaskNoteFail'
    | 'getZippedIOCReportFail'
    | 'deleteAlertSuccess'
    | 'deleteAlertFail'
    | 'sendAlertSuccess'
    | 'sendAlertFail'
    | 'getActionItemDetailFail'
    | 'saveIocAlertSuccess'
    | 'saveIocAlertFail'
    | 'getIocAlertFail'
    | 'getMcLogFail'
    | 'getIOCTOwnerListFail'
    | 'getMCOwnerListFail'
    | 'getEventLabelListFail'
    | 'createOrUpdateMcLogFail'
    | 'createOrUpdateMcLogSuccess'
    | 'saveTriggerFail'
    | 'createTriggerSuccess'
    | 'editTriggerSuccess'
    | 'deleteTriggerSuccess'
    | 'declareServiceableSuccess'
    | 'declareServiceableFail'
    | 'getMcilogEventsFail'
    | 'getUserListByGroupFail'
    | 'getSupersedeListFail'
    | 'getAircraftDetailsFail'
    | 'getRecipientsFail'
    | 'downloadHandoverNoteFail'
    | 'downloadHandoverNoteSuccess'
    | 'createOrUpdateWrqFail'
    | 'createOrUpdateWrqSuccess'
    | 'createOrUpdateWrqWithSendEmailSuccess'
    | 'createOrUpdateWrqWithExportPdfSuccess'
    | 'exportHandoverReportSuccess'
    | 'sendHandoverReportSuccess'
    | 'generateHandoverReportFail'
    | 'exportMultipleMCEventSuccess'
    | 'sendMultipleMCEventSuccess'
    | 'generateMultipleMCEventFail'
    | 'sendSingleMCEventSuccess'
    | 'exportSingleMCEventSuccess'
    | 'getAlertListFail'
    | 'sendObsMessageSuccess'
    | 'getObsMessagesFail'
    | 'sendObsMessageFail';

type StatusMessage = {
    id?: MessageScenario;
    statusBarType: StatusBarType; // error/warning/success
    statusMsgCategory: StatusMsgCategory;
    message: string | JSX.Element;
};

const GENERAL_ERROR_MESSGE = 'Your request cannot be proceed at this moment, please try again.';

const addMessageToList = (
    originalList: StatusMessage[],
    scenario: MessageScenario,
    errObjOfNewMessage?: ApiErrorObj,
    shortDesc?: string,
    eventId?: string
): StatusMessage[] => {
    const newMessageFromErrObj = generateStatusMessageObjByScenario(
        scenario,
        errObjOfNewMessage,
        null,
        shortDesc,
        eventId
    );
    const newList = originalList.filter((item) => item.statusMsgCategory !== newMessageFromErrObj.statusMsgCategory);
    newList.push(formatStatusMessage(newMessageFromErrObj));
    return newList;
};

const formatStatusMessage = ({ statusBarType, statusMsgCategory, message }: StatusMessage): StatusMessage => {
    return {
        id: uuidv4(),
        statusBarType,
        statusMsgCategory,
        message,
    };
};

const generateStatusMessageObjByScenario = (
    scenario: MessageScenario,
    errorObject?: ApiErrorObj,
    regis?: string,
    shortDesc?: string,
    eventId?: string
): StatusMessage => {
    const { caseId = -1, detail = GENERAL_ERROR_MESSGE } = errorObject || {};

    const errorMessage = `${detail} (Error Code: ${caseId})`;
    const generalErrorWithCode = `${GENERAL_ERROR_MESSGE} (Error Code: ${caseId})`;
    switch (scenario) {
        case 'getUserGroupFail':
        case 'getGroupsMappingFail':
        case 'getUserProfileFail':
        case 'updateUserGroupFail':
        case 'getUserListByGroupFail':
        case 'getRecipientsFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'userProfile',
                message: errorMessage,
            };
        case 'getAllEventsFail':
        case 'getIocEventsFail':
        case 'getEventByIdFail':
        case 'getCoeEventByIdFail':
        case 'getEventSettingFail':
        case 'getAirportListFail':
        case 'getMcilogEventsFail':
        case 'generateHandoverReportFail':
        case 'generateMultipleMCEventFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'eventList',
                message: errorMessage,
            };
        case 'exportHandoverReportSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'eventList',
                message: 'Handover Report successfully exported to PDF',
            };
        case 'sendHandoverReportSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'eventList',
                message: 'Handover Report successfully sent to recipients',
            };
        case 'exportMultipleMCEventSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'eventList',
                message: 'Your selection was successfully exported to PDF',
            };
        case 'sendMultipleMCEventSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'eventList',
                message: 'Your selection was successfully sent to recipients',
            };
        case 'sendSingleMCEventSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'eventList',
                message: 'Event successfully emailed to recipients',
            };
        case 'exportSingleMCEventSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'eventList',
                message: 'Event successfully exported to PDF',
            };
        case 'editEventAssessmentFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'editEventAssessment',
                message: errorMessage,
            };
        case 'editEventAssessmentSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'editEventAssessment',
                message: 'Event assessment has been successfully edited.',
            };
        case 'getEventTasksByIdFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'eventTasks',
                message: errorMessage,
            };
        case 'createEventSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'createEvent',
                message: `${shortDesc} (${eventId}) created successfully`,
            };
        case 'createEventFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'createEvent',
                message: errorMessage,
            };
        case 'sendObsMessageFail':
        case 'getObsMessagesFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'obs',
                message: errorMessage,
            };
        case 'sendObsMessageSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'obs',
                message: `Broadcast(ID${eventId}) successfully sent`,
            };
        case 'editEventSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'editEvent',
                message: `${shortDesc} (${eventId}) edited successfully`,
            };
        case 'editEventFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'editEvent',
                message: errorMessage,
            };
        case 'reopenEventSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'reopenEvent',
                message: `${shortDesc} (${eventId}) reopened successfully.`,
            };
        case 'reopenEventFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'reopenEvent',
                message: errorMessage,
            };
        case 'cancelEventSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'cancelEvent',
                message: `${shortDesc} (${eventId}) cancelled successfully.`,
            };
        case 'cancelEventFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'cancelEvent',
                message: errorMessage,
            };
        case 'closeEventSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'closeEvent',
                message: `${shortDesc} (${eventId}) closed successfully.`,
            };
        case 'closeEventFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'closeEvent',
                message: errorMessage,
            };
        case 'updateEtsFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'updateETS',
                message: errorMessage,
            };
        case 'updateEtsSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'updateETS',
                message: 'ETS revised successfully.',
            };
        case 'updateEtsConflict':
            return {
                statusBarType: StatusBarTypes.Info,
                statusMsgCategory: 'updateETS',
                message: 'ETS is successfully saved. AOG ETS impact detected.',
            };
        case 'updateIssueSummaryConflict':
            return {
                statusBarType: StatusBarTypes.Info,
                statusMsgCategory: 'updateETS',
                message: 'Issue Summary has been saved successfully. AOG ETS impact detected.',
            };
        case 'updateRecoveryPlanConflict':
            return {
                statusBarType: StatusBarTypes.Info,
                statusMsgCategory: 'updateETS',
                message: 'Recovery plan is successfully saved. AOG ETS impact detected.',
            };
        case 'updateAtsFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'updateATS',
                message: errorMessage,
            };
        case 'updateStatusServiceable':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'statusChange',
                message: `${regis} is changed to serviceable.`,
            };
        case 'updateStatusUnserviceable':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'statusChange',
                message: `${regis} is changed to unserviceable.`,
            };
        case 'getDisMessageFail':
        case 'sendDisMessageFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'disMessage',
                message: errorMessage,
            };
        case 'sendDisMessageSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'disMessage',
                message: 'Your email and Teams message have been successfully sent.',
            };
        case 'exportLmttFileFail':
        case 'downloadCoePdfSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'downloadFile',
                message: `Events successfully downloaded`,
            };
        case 'downloadHandoverNoteSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'downloadFile',
                message: `Handover Note were successfully exported to PDF`,
            };
        case 'downloadHandoverNoteFail':
        case 'getZippedIOCReportFail':
        case 'downloadCoePdfFail':
        case 'downloadFileFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'downloadFile',
                message: errorMessage,
            };
        case 'uploadFileFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'uploadFile',
                message: errorMessage,
            };
        case 'getAddFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'addDetails',
                message: errorMessage,
            };
        case 'getAllFlightsFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'flightsInfo',
                message: errorMessage,
            };
        case 'getDepartureFlightsFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'flightsInfo',
                message: errorMessage,
            };
        case 'getHistoryLogsFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'historyLog',
                message: errorMessage,
            };
        case 'uploadReportFail':
        case 'getReportsFail':
        case 'previewReportFail':
        case 'sendReportFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'statusReport',
                message: errorMessage,
            };
        case 'sendReportSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'statusReport',
                message: uploadMessage.success,
            };
        case 'getAtaCodesFail':
        case 'getIssueHistoryFail':
        case 'saveIssueSummaryFail':
        case 'updateIssueSummaryFail':
        case 'createOrUpdateMcLogFail':
        case 'getMcLogFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'issueSummary',
                message: errorMessage,
            };
        case 'createOrUpdateMcLogSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'issueSummary',
                message: 'Log entry saved successfully.',
            };
        case 'declareServiceableSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'issueSummary',
                message: 'AOG declared serviceable',
            };
        case 'updateIssueSummarySuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'issueSummary',
                message: 'Issue Summary has been updated successfully',
            };
        case 'saveIssueSummarySuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'issueSummary',
                message: 'Issue Summary has been saved successfully.',
            };
        case 'getRecoveryPlanDetailsFail':
        case 'saveRecoveryPlanDetailsFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'recoveryPlan',
                message: errorMessage,
            };
        case 'saveRecoveryPlanDetailsSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'recoveryPlan',
                message: 'Changes saved successfully',
            };
        case 'getChatMetaFail':
        case 'getChatImageFail':
        case 'loadChatMessageFail':
        case 'chatFail':
        case 'markAllReadFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'chat',
                message: 'Your request cannot be proceed at this moment, please try again.',
            };
        case 'chatWsFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'chatWs',
                message: 'Please check your internet connection and try again',
            };
        case 'getFlightByChatUfisFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'chat',
                message: errorMessage,
            };
        case 'removeLogSuccess':
        case 'removeMcLogSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'Log',
                message: 'Log entry removed successfully.',
            };
        case 'getLogListFail':
        case 'removeLogFail':
        case 'removeMcLogFail':
        case 'declareAogFail':
        case 'declareServiceableFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'Log',
                message: errorMessage,
            };
        case 'declareAogSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'Log',
                message: `Event ID ${eventId} successfully declared AOG.`,
            };
        case 'saveLogSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'Log',
                message: 'Log entry saved successfully.',
            };
        case 'saveLogFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'Log',
                message: generalErrorWithCode,
            };
        case 'getFlightEventsFail':
        case 'getFlightGeneralDetailsFail':
        case 'getAircraftGeneralDetailsFail':
        case 'getSubfleetGeneralDetailsFail':
        case 'getFlightEventsAndConstraintsFail':
        case 'getFlightScheduleFail':
        case 'getFlightAirportFail':
        case 'getFlightADDsFail':
        case 'getFlightCargoFail':
        case 'getFlightPlanningFail':
        case 'getFlightPaxFail':
        case 'getFlightCrewFail':
        case 'getFlightCargoPTSFail':
        case 'getAircraftDetailsByRegistrationFail':
        case 'getAircraftADDsByRegistrationFail':
        case 'getSubfleetSummaryFail':
        case 'getSubfleetAircraftPerformanceFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'gantt',
                message: errorMessage,
            };
        case 'updateFollowUpFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'eventFollowUp',
                message: errorMessage,
            };
        case 'updateEventStatusFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'actionItem',
                message: errorMessage,
            };
        case 'updateEventTaskStatusFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'eventList',
                message: errorMessage,
            };
        case 'getLiveDashboardFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'liveDashboard',
                message: errorMessage,
            };
        case 'getDailyDashboardFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'dailyDashboard',
                message: errorMessage,
            };
        case 'createActionItemFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'actionItem',
                message: errorMessage,
            };
        case 'createActionItemSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'actionItem',
                message: shortDesc,
            };
        case 'sendPriorityFlightListSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'priorityFlight',
                message: 'Priority Flight list has been successfully sent.',
            };
        case 'getPriorityDefinitonFail':
        case 'sendPriorityFlightListFail':
        case 'getPriorityFlightListFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'priorityFlight',
                message: errorMessage,
            };
        case 'createDefinitionSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'createDefinition',
                message: `A new definition has been successfully added.`,
            };
        case 'createDefinitionFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'createDefinition',
                message: errorMessage,
            };
        case 'editDefinitionSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'editDefinition',
                message: `You have successfully updated record ID ${eventId}`,
            };
        case 'editDefinitionFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'editDefinition',
                message: errorMessage,
            };
        case 'deleteDefinitionSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'deleteDefinition',
                message: `You have successfully deleted record ID ${eventId}`,
            };
        case 'deleteDefinitionFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'deleteDefinition',
                message: errorMessage,
            };
        case 'getAllAircraftTypeFail':
        case 'getDomTopCardFail':
        case 'getDomCrewListFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'dom',
                message: errorMessage,
            };
        case 'saveEventNoteFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'saveEventNote',
                message: errorMessage,
            };
        case 'saveEventNoteSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'saveEventNote',
                message: `Note ID (${eventId}) created successfully`,
            };
        case 'saveEventTaskNoteFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'saveEventTaskNote',
                message: errorMessage,
            };
        case 'saveEventTaskNoteSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'saveEventTaskNote',
                message: `Note ID (${eventId}) created successfully`,
            };
        case 'somethingsWrong':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'others',
                message: errorMessage,
            };
        case 'sendAlertSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'iocAlert',
                message: `IOC Alert (ID${eventId}) successfully sent`,
            };
        case 'deleteAlertSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'iocAlert',
                message: `IOC Alert (ID${eventId}) successfully deleted`,
            };
        case 'saveIocAlertSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'iocAlert',
                message: `IOC Alert (ID${eventId}) successfully saved`,
            };
        case 'sendAlertFail':
        case 'deleteAlertFail':
        case 'getIocAlertFail':
        case 'saveIocAlertFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'iocAlert',
                message: errorMessage,
            };
        case 'getIOCTOwnerListFail':
        case 'getMCOwnerListFail':
        case 'getEventLabelListFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'createEventSetting',
                message: errorMessage,
            };
        case 'saveTriggerFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'saveTrigger',
                message: errorMessage,
            };

        case 'createTriggerSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'saveTrigger',
                message: 'New event trigger successfully created',
            };
        case 'editTriggerSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'saveTrigger',
                message: 'Event trigger successfully edited',
            };
        case 'deleteTriggerSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'saveTrigger',
                message: 'Event trigger successfully deleted',
            };
        case 'getAircraftDetailsFail':
        case 'getSupersedeListFail':
        case 'createOrUpdateWrqFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'workRequest',
                message: errorMessage,
            };
        case 'createOrUpdateWrqSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'workRequest',
                message: 'Work Request were successfully saved to event',
            };
        case 'createOrUpdateWrqWithSendEmailSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'workRequest',
                message: 'Work Request were successfully sent to recipients',
            };
        case 'createOrUpdateWrqWithExportPdfSuccess':
            return {
                statusBarType: StatusBarTypes.Success,
                statusMsgCategory: 'workRequest',
                message: 'Work Request were successfully exported to PDF',
            };
        case 'getAlertListFail':
            return {
                statusBarType: StatusBarTypes.Error,
                statusMsgCategory: 'alertModule',
                message: generalErrorWithCode,
            };
        default:
            return {
                statusBarType: StatusBarTypes.Info,
                statusMsgCategory: 'others',
                message: '',
            };
    }
};

export {
    StatusMsgCategory,
    StatusMessage,
    MessageScenario,
    formatStatusMessage,
    generateStatusMessageObjByScenario,
    addMessageToList,
};
