import React from 'react';
import { Box } from '@mui/material';

const HoverLabel = ({ label }: { label: string }) => {
    return (
        <Box sx={{
            position: 'absolute',
            margin: '32px -22px 0px 0px',
            background: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '4px',
            zIndex: 9
        }}>
            {label}
        </Box>
    );
};
export default HoverLabel;
