import { RootState } from '../app/store';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { generateAogStatusReport, getReportCardByEventId, sendReportByFiles } from '../services/reports';
import { ReportCardStatus as reportStatus } from '../constants/constants';
import { IPdfType } from '../interface/Report';

import { getStatusReportBlob } from '../components/reportGenerate/ReportGenBase';
import { createdDate } from '../components/reportGenerate/ReportGenInfo';
import { downloadBlob } from './fileSlice';
import { ResourceAction, resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';
import { getCoePdfDisplayEvents } from '../helper/eventListHelper';
import { createCoeEventPDF } from '../components/reportGenerate/COEEventsPDF';
import { COEDetailedEvent } from './coeEventSlice';
import { AogStatusReportRequest } from '../interface/AogStatusReport';

export interface ISendReport {
    group: string;
    emails: string;
    fileId: number;
    userId: string;
    eventId: string;
    version: string;
    dueTime: string;
    reportType: string;
}
export interface SelectedReportCardType {
    version: string;
    dueTime: string;
    remain: number;
    reportType: string;
}
export interface ReportCardType extends SelectedReportCardType {
    status: reportStatus.completed | reportStatus.isInprogress | reportStatus.upcoming;
    view?: boolean;
    sentTime?: string;
    reportId?: number;
    warning?: boolean;
}
export interface AOGReportSection {
    displayCardList: ReportCardType[];
    totalReport: number;
}
export interface ReportSlice {
    isReportCardLoading: boolean;
    AOGReportSection: AOGReportSection;
    selectedReportCard: SelectedReportCardType;
    excludedIssueUpdates: { [key: string]: number[] };
    excludedPlans: { [key: string]: number[] };
    isReportGeneratingLoading: boolean;
    isSendReportLoading: boolean;
    sendReportResult: {
        sentReportId: number;
        errorObj: ApiErrorObj;
    };
}

interface DownloadCoePdf {
    events: COEDetailedEvent[];
    withActionItem: boolean;
}

const initialState: ReportSlice = {
    isReportCardLoading: false,
    AOGReportSection: {
        displayCardList: [],
        totalReport: null,
    },
    selectedReportCard: null,
    excludedIssueUpdates: {},
    excludedPlans: {},
    isReportGeneratingLoading: false,
    isSendReportLoading: false,
    sendReportResult: {
        sentReportId: -1,
        errorObj: null,
    },
};

export const previewReportThunk = createAsyncThunk<Blob, IPdfType, { state: RootState; rejectValue: ApiErrorObj }>(
    'report/previewReport',
    async (pdfSource: IPdfType, { rejectWithValue }) => {
        try {
            const pdfBlob = await getStatusReportBlob(pdfSource);
            return pdfBlob as Blob;
        } catch (err) {
            return rejectWithValue({} as ApiErrorObj);
        }
    }
);

export const sendReportByFileIdThunk = createAsyncThunk<
number,
ISendReport,
{ state: RootState; rejectValue: ApiErrorObj }
>(
    'report/sendReportByFiles',
    async (
        { group, emails, fileId, userId, eventId, version, dueTime, reportType }: ISendReport,
        { getState, rejectWithValue }
    ) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/sendAogReport')) {
            return rejectWithValue(ForbiddenObj);
        }

        const [err, data] = await sendReportByFiles({
            group,
            emails,
            fileId,
            userId,
            eventId,
            version,
            dueTime,
            reportType,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }
        return data as number;
    }
);

export const getReportCardByEventIdThunk = createAsyncThunk<
AOGReportSection,
string,
{ state: RootState; rejectValue: ApiErrorObj }
>('report/getReportCardByEventId', async (id: string, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/reportCard')) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getReportCardByEventId(id);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const downloadCoePdfThunk = createAsyncThunk<
{ blob: Blob },
DownloadCoePdf,
{ state: RootState; rejectValue: ApiErrorObj }
>('report/coeeventspdf', async ({ events, withActionItem }, { getState, rejectWithValue }) => {
    try {
        const ataList = getState().coeevent.ataCodesDisplayList || [];
        const coePdfEvents = getCoePdfDisplayEvents({ events, ataList });
        const pdfBlob = await createCoeEventPDF(coePdfEvents, withActionItem);
        return { blob: pdfBlob } as { blob: Blob };
    } catch (err) {
        return rejectWithValue({} as ApiErrorObj);
    }
});

export const generateAogStatusReportThunk = createAsyncThunk<
{ blob: Blob | null; header: Headers },
AogStatusReportRequest,
{ state: RootState; rejectValue: ApiErrorObj }
>('report/aogStatusReport', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/report/aogStatusReport', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, blob, header] = await generateAogStatusReport(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { blob, header };
});

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        selectReportCard: (state, action: PayloadAction<SelectedReportCardType>) => {
            state.selectedReportCard = action.payload;
            state.sendReportResult = initialState.sendReportResult;
        },
        addExcludeIssueUpdate: (state, action: PayloadAction<{ selectedEventId: string; id: number }>) => {
            const { selectedEventId, id } = action.payload;
            const issueUpdates = state.excludedIssueUpdates[selectedEventId] || [];
            const hasIssueId = issueUpdates.find((item) => item === id);
            if (!hasIssueId) {
                state.excludedIssueUpdates = {
                    [selectedEventId]: [...issueUpdates, id],
                };
            }
        },
        setExcludeIssueUpdate: (state, action: PayloadAction<{ [key: string]: number[] }>) => {
            state.excludedIssueUpdates = action.payload;
        },
        removeExcludeIssueUpdate: (state, action: PayloadAction<{ selectedEventId: string; id: number }>) => {
            const { selectedEventId, id } = action.payload;
            const issueUpdates = state.excludedIssueUpdates[selectedEventId] || [];
            const updatedIssues = issueUpdates.filter((item) => item !== id);
            state.excludedIssueUpdates = {
                [selectedEventId]: [...updatedIssues],
            };

        },
        addExcludePlan: (state, action: PayloadAction<{ selectedEventId: string; id: number }>) => {
            const { selectedEventId, id } = action.payload;
            const plans = state.excludedPlans[selectedEventId] || [];
            const hasPlanId = plans.find((item) => item === id);
            if (!hasPlanId) {
                state.excludedPlans = {
                    [selectedEventId]: [...plans, id],
                };
            }
        },
        removeExcludePlans: (state, action: PayloadAction<{ selectedEventId: string; ids: number[] }>) => {
            const { selectedEventId, ids } = action.payload;
            const plans = state.excludedPlans[selectedEventId] || [];

            const updatedPlans = plans.filter((item) => !ids.includes(item));

            if (updatedPlans.length === 0) {
                delete state.excludedPlans[selectedEventId];
            } else {
                state.excludedPlans = {
                    [selectedEventId]: [...updatedPlans],
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReportCardByEventIdThunk.pending, (state) => {
                state.isReportCardLoading = true;
            })
            .addCase(getReportCardByEventIdThunk.fulfilled, (state, { payload }) => {
                state.isReportCardLoading = false;
                state.AOGReportSection = payload;
            })
            .addCase(previewReportThunk.pending, (state, { payload }) => {
                state.isReportGeneratingLoading = true;
            })
            .addCase(previewReportThunk.fulfilled, (state, { payload }) => {
                state.isReportGeneratingLoading = false;
                downloadBlob({
                    blob: payload,
                    fileName: `AOG Status Report ${createdDate()}.pdf`,
                });
            })
            .addCase(previewReportThunk.rejected, (state, { payload }) => {
                state.isReportGeneratingLoading = false;
            })
            .addCase(getReportCardByEventIdThunk.rejected, (state) => {
                state.isReportCardLoading = false;
            })
            .addCase(sendReportByFileIdThunk.pending, (state, { payload }) => {
                state.isSendReportLoading = true;
                state.sendReportResult = initialState.sendReportResult;
            })
            .addCase(sendReportByFileIdThunk.fulfilled, (state, { payload }) => {
                state.sendReportResult.sentReportId = payload;
                state.isSendReportLoading = false;
            })
            .addCase(sendReportByFileIdThunk.rejected, (state, { payload }) => {
                state.sendReportResult.errorObj = payload;
                state.isSendReportLoading = false;
            })
            .addCase(downloadCoePdfThunk.pending, (state, { payload }) => {
                state.isReportGeneratingLoading = true;
            })
            .addCase(downloadCoePdfThunk.fulfilled, (state, { payload }) => {
                const { blob } = payload;
                downloadBlob({
                    blob,
                    fileName: `COE Events Report ${createdDate()}.pdf`,
                });
                state.isReportGeneratingLoading = false;
            })
            .addCase(downloadCoePdfThunk.rejected, (state) => {
                state.isReportGeneratingLoading = false;
            })
            .addCase(generateAogStatusReportThunk.pending, (state) => {
                state.isSendReportLoading = true;
            })
            .addCase(generateAogStatusReportThunk.rejected, (state, { payload, meta }) => {
                state.isSendReportLoading = false;
            })
            .addCase(generateAogStatusReportThunk.fulfilled, (state, { payload, meta }) => {
                const { exportPdf } = meta.arg;
                if (exportPdf) {
                    const { blob, header } = payload;
                    const encodedFileName = header['content-disposition']?.split('attachment;filename=')[1];
                    const fileName = decodeURI(encodedFileName);
                    downloadBlob({
                        blob,
                        fileName,
                    });
                }
                state.isSendReportLoading = false;
            });
    },
});

const { selectReportCard, addExcludeIssueUpdate, setExcludeIssueUpdate, removeExcludeIssueUpdate, addExcludePlan, removeExcludePlans } =
    reportSlice.actions;

const selectReport = (state: RootState) => state.report;

export {
    selectReport,
    selectReportCard,
    addExcludeIssueUpdate,
    setExcludeIssueUpdate,
    removeExcludeIssueUpdate,
    addExcludePlan,
    removeExcludePlans,
};

export default reportSlice.reducer;
