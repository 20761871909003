import { TableCell } from 'pdfmake/interfaces';
import { EventSubtypeStatus } from '../../slices/eventSlice';
import { GenPdfIssueUpdateLabel } from '../../constants/constants';
import { IGenPdfIssueUpdate } from '../../interface/Report';

const getIssueUpdateColLabel = () => {
    const header = [
        GenPdfIssueUpdateLabel.no,
        GenPdfIssueUpdateLabel.update,
        GenPdfIssueUpdateLabel.ets,
        GenPdfIssueUpdateLabel.updateTime,
    ];
    return header.map((item: string, index: number) => {
        const headerItem = { text: item, style: 'issueUpdateLabel' };
        if (index === 2 || index === 3) {
            headerItem['margin'] = [10, 0];
        }
        return headerItem as TableCell;
    }) as TableCell[];
};

const getIssueUpdateColData = (issueUpdateList: IGenPdfIssueUpdate[]) => {
    return issueUpdateList.map((item: IGenPdfIssueUpdate, index: number) => {
        return [index + 1, item.update, item.ets, item.dateAndTime].map((arrItem: string, arrIndex: number) => {
            const recordItem = arrItem || '--';
            const isItemServiceable = item.status === EventSubtypeStatus.serviceable;
            const issueItem = {
                text: isItemServiceable && arrIndex === 2 ? `Serviceable \n ${recordItem}` : recordItem,
                style: isItemServiceable ? 'issueUpdateTextServiceable' : 'issueUpdateText',
            };
            if (arrIndex === 2 || arrIndex === 3) {
                issueItem['margin'] = [10, 0];
            }
            return issueItem as TableCell;
        }) as TableCell[];
    }) as TableCell[][];
};

export { getIssueUpdateColLabel, getIssueUpdateColData };
