import React from 'react';
import { Stack } from '@mui/material';
import { styled, SxProps } from '@mui/system';

import { ReactComponent as ArrowUp } from '../../../../assets/icons/TableSortArrow.svg';
import COLORS from '../../../../style/color';

interface SortProps {
    sx?: SxProps;
    titleKey: string;
    sortAsc: boolean;
    sortDesc: boolean;
    arrowStyles?: ArrowStyleProps;
    onSortChange?: (name: string) => void;
}
export interface ArrowStyleProps {
    activeColor: string;
    inactiveColor: string;
}

const ArrowDown = styled(ArrowUp)({
    transform: 'rotate(180deg)',
});

const SortControl = (props: SortProps) => {
    const { sx, arrowStyles, titleKey = '', onSortChange, sortAsc, sortDesc } = props;

    return (
        <Stack
            sx={{ cursor: 'pointer', userSelect: 'none', rowGap: '8px', ...sx }}
            flexDirection='column'
            alignItems='center'
            onClick={() => onSortChange?.(titleKey)}
        >
            <ArrowUp
                fill={
                    sortAsc
                        ? arrowStyles?.activeColor || COLORS.grayDark
                        : arrowStyles?.inactiveColor || COLORS.tableSortArrow
                }
            />
            <ArrowDown
                fill={
                    sortDesc
                        ? arrowStyles?.activeColor || COLORS.grayDark
                        : arrowStyles?.inactiveColor || COLORS.tableSortArrow
                }
            />
        </Stack>
    );
};

export default SortControl;
