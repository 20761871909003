import React, { useContext } from 'react';
import { Stack } from '@mui/system';

import Sort from '../common/Sort';
import Filter from '../common/Filter';
import { flightFilteringItemList, flightSortingItemList } from '../../constants/constants';
import { FlightSearchMainContext } from './FlightSearch';

const Filters = () => {
    const {
        onSearch,
        filters: { airline: selectedAirlines },
    } = useContext(FlightSearchMainContext);

    return (
        <Stack flexDirection='row'>
            <Filter
                value={selectedAirlines || []}
                availableItems={flightFilteringItemList}
                onChange={(selected) => onSearch({ airline: selected })}
                sx={{ width: '225px', margin: '16px 8px 24px 0px' }}
            />
            <Sort
                variant='standardWithBorder'
                availableItems={flightSortingItemList}
                onChange={(selected) => onSearch({ sort: selected })}
                sx={{
                    margin: '16px 8px 24px 4px',
                }}
            />
        </Stack>
    );
};

export default Filters;
