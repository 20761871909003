import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { Controller, UseFieldArrayReturn, UseFormReturn, useWatch } from 'react-hook-form';

import Dropdown from '../../common/Dropdown';
import PaxCount from './PaxCountInfo';
import StopsInfo from './StopsInfo';
import ReferenceFlightInfo from './ReferenceFlightInfo';
import DestinationInfo from './DestinationInfo';
import CreateEventFormBlockLayout from '../../createEventFormBlock/CreateEventFormBlockLayout';
import TitledSection from './TitledSection';
import { Registration } from '../../common/selection';
import { useAppDispatch } from '../../../app/hooks';
import { PortInfo } from '../../../interface/Flight';
import { getAllFlightThunk, getFlightPortInfoThunk } from '../../../slices/flightSlice';
import {
    getBestTimeAndLocal,
    getTotalPaxCount,
    getPaxCountByCabinCode,
    shouldShowDelayField,
    shouldShowScheduleInput,
    shouldShowStopsInformation,
} from '../../../helper/iocAlertHelper';
import { backendLocalTimeToISO, getFormattedTimeDifference, DurationFormat } from '../../../helper/dateHelper';
import { getRegDescriptionWithPrefix } from '../../../helper/stringHelper';
import { ReferenceFlight } from '../../../interface/IocAlert';

import { TimeTypes } from '../../../constants/constants';
import { alertTypeOptions, FIELD_WIDTH, IOCAlertTypes, SECTION_SEPARATION } from '../constants';
import { constructFlightBarObjects } from '../../../helper/flightBarHelper';

interface FlightInfoProps
    extends Pick<UseFormReturn, 'setValue' | 'control' | 'getValues' | 'register' | 'unregister'>,
    Pick<UseFieldArrayReturn, 'remove'> {
    registerNamePrefix: string;
    index: number;
}

const AffectedFlightInfo = (props: FlightInfoProps) => {
    const { registerNamePrefix, index, setValue, control, getValues, remove, register, unregister } = props;

    const addBtnContainerRef = useRef();
    const dispatch = useAppDispatch();
    const currentAlertType = useWatch({ control, name: `${registerNamePrefix}.alertType` });
    const shouldShowStopsInfo = shouldShowStopsInformation(currentAlertType);

    const onRefreshPortInfo = (ufi: string) => {
        Promise.all([
            dispatch(getFlightPortInfoThunk([ufi])).unwrap(),
            dispatch(getAllFlightThunk({ ufi })).unwrap(),
        ]).then(([portInfos, flightInfo]) => {
            const { flights } = flightInfo || {};
            const [flight] = flights || [];
            if (flight) {
                setValue(`${registerNamePrefix}.flightBarInfo`, constructFlightBarObjects([flight])[0]);
            }

            const flightPortInfo = portInfos?.[ufi];
            if (flightPortInfo && currentAlertType) {
                setValue(`${registerNamePrefix}.flightPortInfo`, flightPortInfo);
                onChangeAlertType(currentAlertType);
            }
        });
    };

    const onChangeAlertType = useCallback((alertType: IOCAlertTypes) => {
        const flightPortInfo = getValues(`${registerNamePrefix}.flightPortInfo`) as PortInfo;
        const { legInfo, registration, paxCount } = flightPortInfo || {};
        const { F, W, Y, J } = getPaxCountByCabinCode(paxCount);
        const flightDepartureInfo = legInfo?.find((item) => item.type === 'departure');
        const flightArrivalInfo = legInfo?.find((item) => item.type === 'arrival');
        const totalPaxCount = getTotalPaxCount([F, W, Y, J]);
        const shouldShowDelay = shouldShowDelayField(alertType);
        const shouldShowSchedule = shouldShowScheduleInput(alertType);

        const shouldIgnoreSTD = [
            IOCAlertTypes.techStop,
            IOCAlertTypes.diversion,
            IOCAlertTypes.extensiveDelay,
            IOCAlertTypes.flightReturn,
        ].includes(alertType);

        if (flightDepartureInfo) {
            const formDepartureInfo: ReferenceFlight = {
                port: flightDepartureInfo.port,
                ...(alertType !== IOCAlertTypes.cancellation &&
                    getBestTimeAndLocal(flightDepartureInfo, 'departure', shouldIgnoreSTD)),
            };

            if (shouldShowSchedule) {
                formDepartureInfo[`${TimeTypes.STD}`] = flightDepartureInfo.STD;
                formDepartureInfo[`${TimeTypes.STD}Local`] = backendLocalTimeToISO(
                    flightDepartureInfo.STD,
                    flightDepartureInfo.STDLocal
                );
            }

            if (shouldShowDelay) {
                formDepartureInfo.delay = getFormattedTimeDifference(
                    formDepartureInfo[`${TimeTypes.ATD}`] || formDepartureInfo[`${TimeTypes.ETD}`],
                    formDepartureInfo[`${TimeTypes.STD}`] || flightDepartureInfo.STD,
                    DurationFormat.DayHourMinWithSuffix
                );
            }

            setValue(`${registerNamePrefix}.departure`, formDepartureInfo);
        }

        if (flightArrivalInfo) {
            const formArrivalInfo: ReferenceFlight = {
                port: flightArrivalInfo.port,
                ...(alertType !== IOCAlertTypes.cancellation &&
                    getBestTimeAndLocal(flightArrivalInfo, 'arrival', shouldIgnoreSTD)),
            };

            if (shouldShowSchedule) {
                formArrivalInfo[`${TimeTypes.STA}`] = flightArrivalInfo.STA;
                formArrivalInfo[`${TimeTypes.STA}Local`] = backendLocalTimeToISO(
                    flightArrivalInfo.STA,
                    flightArrivalInfo.STALocal
                );
            }

            if (shouldShowDelay) {
                formArrivalInfo.delay = getFormattedTimeDifference(
                    formArrivalInfo[`${TimeTypes.ATA}`] || formArrivalInfo[`${TimeTypes.ETA}`],
                    formArrivalInfo[`${TimeTypes.STA}`] || flightArrivalInfo.STA,
                    DurationFormat.DayHourMinWithSuffix
                );
            }

            setValue(`${registerNamePrefix}.arrival`, formArrivalInfo);
        }

        if (shouldShowStopsInformation(alertType)) {
            const flightStopInfo = legInfo.filter((item) => item.type === 'stop');

            const formStopsInfo: ReferenceFlight[] = flightStopInfo.map((item) => ({
                port: item.port,
                ...getBestTimeAndLocal(item, 'departure', true),
                ...getBestTimeAndLocal(item, 'arrival', true),
            }));

            if (formStopsInfo.length < 1) {
                formStopsInfo.push({});
            }

            setValue(`${registerNamePrefix}.stops`, formStopsInfo);
        }

        setValue(`${registerNamePrefix}.aircraftRegistration`, getRegDescriptionWithPrefix(registration));
        setValue(`${registerNamePrefix}.paxCount`, { F, W, Y, J, total: totalPaxCount });
        setValue(`${registerNamePrefix}.alertType`, alertType);
    }, []);

    useEffect(() => {
        if (currentAlertType === undefined) {
            onChangeAlertType(IOCAlertTypes.generalInfo);
        }
    }, [currentAlertType]);

    return (
        <CreateEventFormBlockLayout title='Alert Type' isRequired={false}>
            <Stack rowGap={SECTION_SEPARATION}>
                <Controller
                    name={`${registerNamePrefix}.alertType`}
                    render={({ field: { value, onChange } }) => (
                        <Dropdown
                            title='Select alert type'
                            dataList={alertTypeOptions}
                            value={value}
                            callback={(option) => {
                                onChange(option);
                                onChangeAlertType(option as IOCAlertTypes);
                            }}
                        />
                    )}
                />

                <ReferenceFlightInfo
                    onDeleteFlight={() => remove(index)}
                    getValues={getValues}
                    registerNamePrefix={registerNamePrefix}
                    onRefresh={onRefreshPortInfo}
                />

                <DestinationInfo
                    registerNamePrefix={registerNamePrefix}
                    portType='departure'
                    currentAlertType={currentAlertType}
                    {...{ control, getValues, setValue, register, unregister }}
                />

                <StopsInfo
                    currentAlertType={currentAlertType}
                    portalBoxRef={addBtnContainerRef.current}
                    shouldShowStopsInfo={shouldShowStopsInfo}
                    registerNamePrefix={registerNamePrefix}
                />

                <DestinationInfo
                    registerNamePrefix={registerNamePrefix}
                    portType='arrival'
                    currentAlertType={currentAlertType}
                    {...{ control, getValues, setValue, register, unregister }}
                />

                <Box ref={addBtnContainerRef} sx={{ display: shouldShowStopsInfo ? 'block' : 'none' }} />

                <TitledSection title='Aircraft Information'>
                    <Controller
                        name={`${registerNamePrefix}.aircraftRegistration`}
                        defaultValue=''
                        render={({ field: { value, onChange } }) => (
                            <Registration
                                label='Reg (e.g. B-LXA)'
                                sx={{ width: `${FIELD_WIDTH} !important` }}
                                value={value?.replace(/-/g, '')}
                                onChange={(option) => {
                                    onChange(getRegDescriptionWithPrefix(option.value));
                                }}
                            />
                        )}
                    />
                </TitledSection>

                <PaxCount registerNamePrefix={`${registerNamePrefix}.paxCount`} />
            </Stack>
        </CreateEventFormBlockLayout>
    );
};

export default AffectedFlightInfo;
