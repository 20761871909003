import { sendHttpRequest } from './api';

import { HTTP_REQUEST_TYPE, MC_WORK_REQUEST_API } from '../constants/api';
import { WrqSubmitParams } from '../interface/WorkRequest';

const createWrq = async (params: WrqSubmitParams) => {
    const { exportPdf } = params;
    const [err, resp, header] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, MC_WORK_REQUEST_API.wrq, params, {
        ...(exportPdf ? { responseType: 'blob' } : {}),
    });

    if (err) {
        return [err];
    }

    return [null, resp, header];
};

export { createWrq };
