import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, EVENT_TASK_HISTORY_LOGS_API } from '../constants/api';
import { sendHttpRequest } from './api';

const getEventTaskHistoryLogs = async ({ eventId, taskTypes }: { eventId: string; taskTypes?: string[] }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        EVENT_TASK_HISTORY_LOGS_API.getEventTaskHistoryLogs,
        {
            eventId,
            'taskType[]': taskTypes,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getEventTaskHistoryLogs };
