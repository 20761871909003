import React from 'react';
import { DateTime } from 'luxon';
import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import Dropdown, { DropdownData } from '../../common/Dropdown';
import DateTimeDurationBlock from '../../common/DateTimeDurationBlock';
import { DateFormat, timeStringToDateTime } from '../../../helper/dateHelper';

import { FIELD_WIDTH } from '../constants';
import { TimeTypes } from '../../../constants/constants';

interface DateTimeRowProps {
    isDropdownDisabled?: boolean;
    timeType: TimeTypes;
    dateTime: string;
    dropdownLabel: string;
    dropwDownList: DropdownData[];
    registerNamePrefix: string;
    onChange: (type: TimeTypes, dateTime: string) => Promise<void>;
}

const DateTimeRow = (props: DateTimeRowProps) => {
    const {
        isDropdownDisabled = false,
        dropwDownList,
        dropdownLabel,
        timeType,
        dateTime,
        onChange,
        registerNamePrefix,
    } = props;

    const { getValues } = useFormContext();
    const localTime = getValues(`${registerNamePrefix}.${timeType}Local`);
    const localTimeObj = DateTime.fromISO(localTime, { setZone: true });
    const localTimeDisplay = localTimeObj.isValid ? localTimeObj.toFormat(DateFormat.nonUtcFormat) : '--';

    return (
        <Stack direction='row'>
            <Dropdown
                dataList={dropwDownList}
                isDisabled={isDropdownDisabled}
                value={timeType}
                title={dropdownLabel}
                sx={{ width: FIELD_WIDTH }}
                callback={(option) => {
                    onChange(TimeTypes[option], dateTime);
                }}
            />
            <Stack>
                <DateTimeDurationBlock
                    startDateLabel='Date (UTC)'
                    startTimeLabel='Time (UTC HHMM)'
                    containerWidth='336px'
                    isClearable={true}
                    defaultStartDateTime={timeStringToDateTime(dateTime)}
                    onChange={(newDateTime) => {
                        if (dateTime !== newDateTime) {
                            onChange(timeType, newDateTime);
                        }
                    }}
                />
                <Typography sx={{ ml: '4px' }} variant='regularGrayDark14'>
                    Local Date &amp; Time: {localTimeDisplay}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default DateTimeRow;
