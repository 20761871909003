import { DestinationPortTypes, ImpactedFlightInfo } from '../interface/IocAlert';
import { LegInfo, PaxCountByCabinCode, PortInfo } from '../interface/Flight';
import { backendLocalTimeToISO } from './dateHelper';
import { IFlightBar } from '../components/common/FlightBarInfo';
import { TimeTypes } from '../constants/constants';
import { convertUfiToReadableFormat } from './stringHelper';
import { IOCAlertTypes } from '../components/iocAlert/constants';

const getBestTimeAndLocal = (times: LegInfo, portType: DestinationPortTypes, ignoreSchedule: boolean = false) => {
    const isDeparture = portType === 'departure';
    if (isDeparture) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { ATD, ATDLocal, ETD, ETDLocal, STD, STDLocal } = times;
        if (ATD)
            return {
                ATD,
                ATDLocal: backendLocalTimeToISO(ATD, ATDLocal),
            };

        if (ETD)
            return {
                ETD,
                ETDLocal: backendLocalTimeToISO(ETD, ETDLocal),
            };

        if (STD && !ignoreSchedule)
            return {
                STD,
                STDLocal: backendLocalTimeToISO(STD, STDLocal),
            };
    } else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { ATA, ATALocal, ETA, ETALocal, STA, STALocal } = times;
        if (ATA)
            return {
                ATA,
                ATALocal: backendLocalTimeToISO(ATA, ATALocal),
            };

        if (ETA)
            return {
                ETA,
                ETALocal: backendLocalTimeToISO(ETA, ETALocal),
            };

        if (STA && !ignoreSchedule)
            return {
                STA,
                STALocal: backendLocalTimeToISO(STA, STALocal),
            };
    }

    return {};
};

const getBestTime = (actual: string, estimate: string, schedule: string, portType: DestinationPortTypes) => {
    const isDeparture = portType === 'departure';
    if (actual)
        return {
            [isDeparture ? TimeTypes.ATD : TimeTypes.ATA]: actual,
        };

    if (estimate)
        return {
            [isDeparture ? TimeTypes.ETD : TimeTypes.ETA]: estimate,
        };

    if (schedule)
        return {
            [isDeparture ? TimeTypes.STD : TimeTypes.STA]: schedule,
        };

    return {};
};

const getPaxCountByCabinCode = (paxCount: PaxCountByCabinCode | PaxCountByCabinCode[]) => {
    const paxCountObj = Array.isArray(paxCount) ? paxCount.at(-1) : paxCount;
    const { F, W, Y, J } = paxCountObj || { F: null, W: null, Y: null, J: null };
    return { F, W, Y, J };
};

const getFormFlightInfo = (portInfos: { [key: string]: PortInfo }, flightBar: IFlightBar) => {
    const flightPortInfo = portInfos?.[flightBar.ufi];
    const { F, W, Y, J } = getPaxCountByCabinCode(flightPortInfo?.paxCount);
    const { ufi, flightNum, iataAircraftType, subtype, flightRegi } = flightBar;
    return {
        ufi,
        flightNumber: flightNum,
        iataAircraftType: iataAircraftType,
        iataAircraftSubtype: subtype,
        aircraftRegistration: flightRegi,
        flightBarInfo: flightBar,
        flightPortInfo,
        paxCount: { F, W, Y, J },
    };
};

const isValidCount = (count: string | number | null | undefined): boolean =>
    count !== '' && count !== undefined && count !== null && !isNaN(+count);

const getTotalPaxCount = (paxCounts: (number | string | null | undefined)[]): number | null => {
    const validCounts = paxCounts.some((count) => isValidCount(count));

    if (!validCounts) return null;

    const totalPaxCount = paxCounts.reduce<number>((acc, cur): number => {
        if (!isValidCount(cur)) return acc;

        if (typeof cur === 'string') return acc + parseInt(cur);
        return acc + cur;
    }, 0);

    return totalPaxCount;
};

const generateSubject = (flightList: ImpactedFlightInfo[], version: number): string => {
    const alertTitle = 'IOC Alert';
    const versionTxt = isNaN(version) ? '' : `_v${version + 1}`;
    if (!Array.isArray(flightList) || flightList.length < 1) {
        return `${alertTitle}${versionTxt}`;
    }

    const flightListString = flightList.map(
        (flight) => `${convertUfiToReadableFormat(flight.ufi)} ${flight.alertType}`
    );
    return `${alertTitle} - ${flightListString.join(',\n')}${versionTxt}`;
};

const shouldShowDelayField = (currentAlertType: IOCAlertTypes): boolean => {
    return [
        IOCAlertTypes.techStop,
        IOCAlertTypes.diversion,
        IOCAlertTypes.extensiveDelay,
        IOCAlertTypes.flightReturn,
    ].includes(currentAlertType);
};

const shouldShowScheduleInput = (currentAlertType: IOCAlertTypes): boolean => {
    return [IOCAlertTypes.extensiveDelay, IOCAlertTypes.cancellation].includes(currentAlertType);
};

const shouldShowStopsInformation = (currentAlertType: IOCAlertTypes): boolean => {
    return [IOCAlertTypes.diversion, IOCAlertTypes.techStop].includes(currentAlertType);
};

export {
    getBestTime,
    getBestTimeAndLocal,
    getFormFlightInfo,
    getTotalPaxCount,
    generateSubject,
    getPaxCountByCabinCode,
    shouldShowDelayField,
    shouldShowScheduleInput,
    shouldShowStopsInformation,
};
