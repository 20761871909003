import React from 'react';
import { Stack } from '@mui/material';

import { SubtitleDisplay } from '../../common/view/';
import { ReferenceFlight } from '../../../interface/IocAlert';
import { formatDefaultDisplay, formatLocalDisplay } from '../../../helper/dateHelper';
import { getDisplayText } from '../../../helper/stringHelper';

import { PREVIEW_COLUMN_WIDTH } from '../constants';

const PreviewArrivalBlock = (props: ReferenceFlight) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { port = '', ATALocal, ATA, STALocal, STA, ETALocal, ETA, delay } = props;

    return (
        <Stack direction='row'>
            <SubtitleDisplay
                label='Arrival Port'
                value={getDisplayText(port)}
                containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
            />
            {STA && (
                <SubtitleDisplay
                    label={`STA ${STALocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(STALocal ? formatLocalDisplay(STALocal) : formatDefaultDisplay(STA))}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {ETA && (
                <SubtitleDisplay
                    label={`ETA ${ETALocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(ETALocal ? formatLocalDisplay(ETALocal) : formatDefaultDisplay(ETA))}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {ATA && (
                <SubtitleDisplay
                    label={`ATA ${ATALocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(ATALocal ? formatLocalDisplay(ATALocal) : formatDefaultDisplay(ATA))}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {delay && (
                <SubtitleDisplay
                    label='Delay Duration'
                    value={getDisplayText(delay)}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
        </Stack>
    );
};

export default PreviewArrivalBlock;
