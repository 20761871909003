import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, Box, styled } from '@mui/material';
import { SxProps } from '@mui/system';

import { ReactComponent as Tick } from '../../assets/icons/Tick.svg';
import { ReactComponent as Warning } from '../../assets/icons/Warning.svg';
import { ReactComponent as Hazard } from '../../assets/icons/Hazard.svg';
import theme from '../../style/theme';
import { STATUS_BANNER_DISPLAY_SECONDS } from '../../constants/constants';

import statusBarStyles, { statusBarHeight } from './StatusBarStyles';

export const StatusBarTypes = {
    Success: theme.alertStatus.success,
    Info: theme.alertStatus.warning,
    Error: theme.alertStatus.error,
};

type StatusBarType = typeof StatusBarTypes[keyof typeof StatusBarTypes];

interface StyledAlertProps {
    barType: StatusBarType;
    message: string | JSX.Element;
    onClick?: () => void; // to navigate to another page
    sx?: SxProps;
    onDismiss?: () => void;
}
interface StyledStatusBarProps extends StyledAlertProps {
    showBar: boolean;
    autoHideDuration?: number; // bar will not disappear if no duration is provided
}

const StatusBarContainer = styled(Box)(statusBarStyles);

const getComponentIcon = (type: StatusBarType) => {
    switch (type) {
        case StatusBarTypes.Success:
            return <Tick />;
        case StatusBarTypes.Info:
            return <Warning />;
        case StatusBarTypes.Error:
            return <Hazard />;
        default:
            return <Tick />;
    }
};

const StatusBar = ({
    showBar = false,
    barType = null,
    autoHideDuration = barType === StatusBarTypes.Success ? STATUS_BANNER_DISPLAY_SECONDS : null,
    message = '',
    onClick,
    onDismiss,
    sx,
}: StyledStatusBarProps) => {
    const [open, setOpen] = useState(showBar);

    useEffect(() => {
        setOpen(showBar);
    }, [showBar]);

    return (
        <StatusBarContainer className={`status-bar ${barType && barType !== StatusBarTypes.Success ? 'persist' : ''}`}>
            <Snackbar
                sx={{
                    ...sx,
                    display: 'block',
                    fontSize: '16px',
                }}
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={(e?: React.SyntheticEvent, reason?: string) => {
                    // avoid trigger close when mouse click
                    if (reason === 'clickaway') {
                        return;
                    }
                    onDismiss?.();
                    if (!e) {
                        // only dismiss when it's not triggered by event
                        setOpen(false);
                    }
                }}
            >
                <Alert
                    icon={getComponentIcon(barType)}
                    variant='filled'
                    sx={{
                        backgroundColor: barType || 'transparent',
                        alignItems: 'center',
                        borderRadius: '0px',
                        fontSize: '16px',
                        fontWeight: theme.typography.fontWeightRegular,
                        height: `${statusBarHeight}px`,
                    }}
                    onClick={(e) => {
                        onClick && onClick();
                    }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </StatusBarContainer>
    );
};

export { StatusBar as default, StatusBarType };
