import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, PRIORITY_FLIGHT_API } from '../constants/api';
import { DefinitionData, DefinitionRequestBody } from '../interface/PriorityFlight';
import { sendHttpRequest } from './api';

const getDefinitionList = async (param: DefinitionRequestBody) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, PRIORITY_FLIGHT_API.getDefinitionList, param);
    if (err) {
        return [err];
    }

    const { status: respStatus, data } = resp;
    if (respStatus === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getPriorityFlightList = async (param: { date: string; size?: number; page?: number }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, PRIORITY_FLIGHT_API.getFlightList, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const sendPriorityFlightList = async (param: { date: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, PRIORITY_FLIGHT_API.sendFlightList, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const createDefinition = async ({ param, headers }: { param: DefinitionData; headers?: any }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, PRIORITY_FLIGHT_API.createDefinition, param, {
        headers,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const deleteDefinition = async (definitionId: number) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, PRIORITY_FLIGHT_API.deleteDefinition, {
        definitionId,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getDefinitionList, getPriorityFlightList, sendPriorityFlightList, createDefinition, deleteDefinition };
