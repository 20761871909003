import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, DOM_API_URL } from '../constants/api';
import { DomCrewParams } from '../interface/Dom';

const getAircraftType = async () => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, DOM_API_URL.getAllAircraftType, {});

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getDomCrews = async (params: DomCrewParams) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, DOM_API_URL.getDomCrews, params);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getAircraftType, getDomCrews };
