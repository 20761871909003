import React from 'react';
import { Stack, Typography, TypographyTypeMap, SxProps } from '@mui/material';

import colors from '../../../../style/color';

interface SubtitleDisplayProps {
    label: string;
    htmlValue?: string;
    value?: string | React.ReactNode;
    disabled?: boolean;
    labelVariant?: TypographyTypeMap['props']['variant'];
    valueVariant?: TypographyTypeMap['props']['variant'];
    containerSx?: SxProps;
    labelSx?: SxProps;
    valueSx?: SxProps;
}

const SubtitleDisplay = (props: SubtitleDisplayProps) => {
    const {
        label,
        value,
        htmlValue,
        disabled = false,
        labelVariant = 'body3',
        valueVariant = 'body5',
        containerSx = {},
        labelSx = {},
        valueSx = {},
    } = props;
    return (
        <Stack sx={containerSx}>
            <Typography
                variant={labelVariant}
                sx={{ whiteSpace: 'nowrap', color: colors.shadowGray, lineHeight: '16px', ...labelSx }}
            >
                {label}
            </Typography>
            <Typography
                variant={valueVariant}
                sx={{
                    whiteSpace: 'pre-line',
                    lineHeight: '20px',
                    ...(disabled && { color: colors.shadowGray }),
                    ...valueSx,
                }}
                {...(htmlValue && { dangerouslySetInnerHTML: { __html: htmlValue } })}
                {...(!htmlValue && { children: value })}
            />
        </Stack>
    );
};

export default SubtitleDisplay;
