import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

import CreateEventFormBlockLayout from '../createEventFormBlock/CreateEventFormBlockLayout';
import LinkText from '../common/LinkText';
import Dropdown from '../common/Dropdown';
import { InputArea } from '../common/InputField';

import { generateSubject } from '../../helper/iocAlertHelper';
import { ROW_MAX_WIDTH, alertReasonOptions, FIELD_OFFSET } from './constants';

interface AlertMessageProps {
    watchNamePrefix: string;
    registerNamePrefix: string;
    version: number;
}

const AlertMessage = (props: AlertMessageProps) => {
    const { watchNamePrefix, registerNamePrefix, version } = props;

    const emailRegisterName = `${registerNamePrefix}.emailSubject`;
    const { getValues, setValue } = useFormContext();
    const { isSubmitting } = useFormState();

    const updateSubject = () => {
        const flightList = getValues(watchNamePrefix);
        setValue(emailRegisterName, generateSubject(flightList, version));
    };

    useEffect(() => {
        if (isSubmitting) {
            const subjectLine = getValues(emailRegisterName);
            if (!subjectLine) {
                const flightList = getValues(watchNamePrefix);
                setValue(emailRegisterName, generateSubject(flightList, version));
            }
        }
    }, [isSubmitting]);

    return (
        <CreateEventFormBlockLayout key='Alert Message' title='Alert Message' isRequired={false}>
            <Stack
                direction='row'
                spacing={1}
                maxWidth={ROW_MAX_WIDTH}
                alignItems='flex-start'
                sx={{
                    '&>:not(style):not(style).MuiTextField-root': {
                        m: '0px 4px',
                    },
                }}
            >
                <Controller
                    name={emailRegisterName}
                    defaultValue=''
                    render={({ field: { value, onChange } }) => (
                        <InputArea
                            label='Email Subject'
                            minRows={1}
                            maxRows={4}
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                        />
                    )}
                />
                <LinkText
                    sx={{ fontSize: '14px', height: '40px', mt: `${FIELD_OFFSET} !important` }}
                    label='Update'
                    onClick={updateSubject}
                />
            </Stack>
            <Controller
                name={`${registerNamePrefix}.reason`}
                render={({ field: { value, onChange } }) => {
                    return (
                        <Dropdown
                            value={value}
                            title='Reason'
                            width={ROW_MAX_WIDTH}
                            dataList={alertReasonOptions}
                            callback={(value: string) => {
                                onChange && onChange(value);
                            }}
                        />
                    );
                }}
            />
            <Controller
                name={`${registerNamePrefix}.details`}
                defaultValue=''
                render={({ field: { value, onChange } }) => (
                    <InputArea
                        label='Details'
                        sx={{ width: ROW_MAX_WIDTH }}
                        maxRows={4}
                        minRows={4}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    />
                )}
            />
            <Controller
                name={`${registerNamePrefix}.serviceRecovery`}
                defaultValue=''
                render={({ field: { value, onChange } }) => (
                    <InputArea
                        label='Service Recovery'
                        sx={{ width: ROW_MAX_WIDTH }}
                        maxRows={4}
                        minRows={4}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    />
                )}
            />
        </CreateEventFormBlockLayout>
    );
};

export default AlertMessage;
