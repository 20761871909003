import React from 'react';
import { Box, Typography } from '@mui/material';
import StyledDialog from './StyledDialog';
import COLORS from '../../style/color';
import { ReactComponent as Warning } from '../../assets/icons/WarningNoBorder.svg';
import { StyledDialogSxProps } from './StyledDialogStyles';

type AlertDialogProps = {
    title: string;
    content?: JSX.Element;
    isDialogVisible: boolean;
    closeBtnLabel?: string;
    confirmBtnLabel?: string;
    showWarningIcon?: boolean;
    handleClose: () => void;
    handleConfirmRoute: () => void;
    sx?: StyledDialogSxProps;
    variant?: 'small' | 'default';
};

const AlertDialog = ({
    isDialogVisible,
    title,
    content,
    closeBtnLabel = 'Back',
    confirmBtnLabel = 'Confirm and send',
    showWarningIcon = true,
    handleClose,
    handleConfirmRoute,
    sx,
    variant = 'default',
}: AlertDialogProps) => {
    return (
        <StyledDialog
            title={title}
            isOpen={isDialogVisible}
            dialogSize='small'
            closeBtnLabel={closeBtnLabel}
            confirmBtnLabel={confirmBtnLabel}
            onClickCloseBtn={handleClose}
            onClickConfirmBtn={handleConfirmRoute}
            sx={{
                dialogContent: {
                    marginTop: '0!important',
                    ...(variant === 'small' && {
                        '&.small': {
                            height: '157px',
                            pt: '36px',
                            ml: '40px',
                        },
                    }),
                },
                dialogFooter: { width: '100%', paddingRight: '40px', paddingLeft: '40px' },
                ...sx,
            }}
        >
            <Box sx={{ my: '16px', display: 'flex' }}>
                {showWarningIcon && (
                    <Box sx={{ background: COLORS.warningYellow, height: '18px', width: '18px', mr: '16px' }}>
                        <Warning height='18px' width='18px' fill={COLORS.white} />
                    </Box>
                )}
                <Typography variant='body3'>{content}</Typography>
            </Box>
        </StyledDialog>
    );
};
export default AlertDialog;
