import { SxProps } from '@mui/system';
import cloneDeep from 'lodash/fp/cloneDeep';
import { FONT_WEIGHT } from '../../style/font';
import COLORS from '../../style/color';

const labelOffset = '-6px';

const getAdjustedTextFieldHeightStyle = (originalStyle: Object, numberOfRows: number): SxProps => {
    const newStyle = cloneDeep(originalStyle);
    const inputHeight = 20 * numberOfRows;
    const containerHeight = 20 + inputHeight;

    newStyle['height'] = `${containerHeight}px`;
    newStyle['& .MuiFilledInput-root'] = {
        ...newStyle['& .MuiFilledInput-root'],
        height: `${containerHeight - 8}px`,
        backgroundColor: COLORS.transparent,
    };
    newStyle['& .MuiFilledInput-root']['& textarea'] = {
        ...newStyle['& .MuiFilledInput-root']['& textarea'],
        height: `${inputHeight}px !important`,
        overflow: 'auto !important',
    };
    return newStyle;
};

const textFieldStyles = {
    container: {
        display: 'inline-block',
        margin: '0px 4px 0px 4px',
        '& .MuiFormControl-root': {
            margin: '4px 0px 4px',
        },
        '&.disabled': {
            '& .MuiFormControl-root': {
                backgroundColor: COLORS.grayLight,
            },
        },
    },
    input: {
        width: '100%',
        border: `1px solid ${COLORS.borderGray}`,
        borderRadius: '4px',
        bgcolor: COLORS.white,
        '&.search-bar': {
            width: '35vw',
            flex: 1,
        },
        '&.start-date-picker': {
            borderTopRightRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
        },
        '&.end-date-picker': {
            borderTopLeftRadius: '0px !important',
            borderBottomLeftRadius: '0px !important',
            marginLeft: '-9px',
        },
        '&.gantt-date-picker': {
            height: '30px',
            borderRadius: '2px',
            border: `1px solid ${COLORS.grayNormal}`,
            '& .MuiFilledInput-input': {
                paddingTop: '16px',
            },
        },
        '&:hover': {
            backgroundColor: COLORS.backgroundWhite,
            color: COLORS.textDark,
        },
        '&:focus-within': {
            border: `1px solid ${COLORS.textDark}`,
        },
        '& .MuiFilledInput-root': {
            backgroundColor: COLORS.transparent,
            borderRadius: '5px',
            '.MuiFilledInput-input': {
                color: COLORS.grayDark,
            },
            '&:hover': {
                backgroundColor: COLORS.transparent,
            },
            '&.Mui-focused': {
                backgroundColor: `${COLORS.transparent}`,
            },
            '&.Mui-disabled': {
                backgroundColor: `${COLORS.transparent}`,
            },
            '& .MuiFilledInput-input': {
                '&.Mui-disabled': {
                    WebkitTextFillColor: 'initial',
                },
            },
        },
        '& .MuiInputLabel-root': {
            color: COLORS.grayNormal,
            top: labelOffset,
            '&.Mui-focused': {
                color: COLORS.grayNormal,
            },
            '&.Mui-error': {
                color: COLORS.grayNormal,
            },
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '0px',
            color: COLORS.error,
        },
        '& .MuiInputAdornment-root .MuiTypography-root': {
            fontSize: '14px',
        },
        '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
            marginTop: '4px',
        },
        '& .adorned-with-icon .MuiInputAdornment-root': {
            marginTop: '4px !important',
        },
        '&.no-border': {
            border: '0px',
            '&:hover': {
                backgroundColor: COLORS.transparent,
                color: COLORS.textDark,
            },
            '& .MuiInputLabel-root': {
                marginLeft: '-10px !important',
            },
            '& .MuiFilledInput-root': {
                marginLeft: '-10px',
                fontWeight: FONT_WEIGHT.regular,
            },
        },
        '&.input-error': {
            border: `1px solid ${COLORS.error}`,
            borderRadius: '5px',
        },
    },
    textAreaInput: {
        margin: '4px',
        '& .MuiFilledInput-root': {
            backgroundColor: COLORS.transparent,
            border: `1px solid ${COLORS.borderGray}`,
            overflow: 'hidden',
            borderRadius: '4px',
            '&:hover': {
                backgroundColor: COLORS.backgroundWhite,
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: `1px solid ${COLORS.textDark}`,
            },

            '&:not(.MuiInputBase-hiddenLabel)': {
                padding: '15px 12px 3px',
            },

            '&.MuiInputBase-hiddenLabel': {
                padding: '5px 12px',
            },
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '0px',
            '&.Mui-error': {
                color: COLORS.error,
            },
        },
        '& .Mui-error': {
            borderColor: COLORS.error,
        },
        '& .MuiInputLabel-root': {
            top: labelOffset,
            color: COLORS.grayNormal,
            '&.Mui-focused': {
                color: COLORS.grayNormal,
            },
        },
        '&.no-border': {
            border: '0px',
            '&:hover': {
                backgroundColor: COLORS.transparent,
                color: COLORS.textDark,
            },
            '& .MuiInputLabel-root': {
                marginLeft: '-10px !important',
                pointerEvents: 'none',
            },
            '& .MuiFilledInput-root': {
                marginLeft: '-10px',
                border: 'none',
                '&:hover': {
                    backgroundColor: COLORS.transparent,
                },
            },
        },
    },
    inputPrefixIcon: {
        position: 'absolute',
        top: '25px',
        zIndex: 1,
        left: '18px',
        '&.end-date-picker': {
            marginLeft: '-9px',
        },
        '&.gantt-date-picker': {
            top: '5px',
            left: '8px',
            height: '30px',
        },
    } as const,
    errorMessage: {
        margin: '0px',
        color: COLORS.error,
        fontSize: '14px',
        opacity: 1,
        transition: 'all 0.25s',
        display: 'block',
        width: 'max-content',
    },
};

export { textFieldStyles as default, getAdjustedTextFieldHeightStyle };
