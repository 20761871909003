import React from 'react';
import { Stack } from '@mui/material';

import { SubtitleDisplay } from '../../common/view';
import { ReferenceFlight } from '../../../interface/IocAlert';
import { formatLocalDisplay, formatDefaultDisplay } from '../../../helper/dateHelper';
import { getDisplayText } from '../../../helper/stringHelper';

import { PREVIEW_COLUMN_WIDTH } from '../constants';

const PreviewStopBlock = (props: ReferenceFlight & { index: number }) => {
    const { port = '', ATD, STD, ETD, ATA, STA, ETA, index = 0 } = props;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { ATDLocal, STDLocal, ETDLocal, ATALocal, STALocal, ETALocal } = props;

    return (
        <Stack direction='row'>
            <SubtitleDisplay
                label={`Stop${index + 1}`}
                value={getDisplayText(port)}
                containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
            />
            {STA && (
                <SubtitleDisplay
                    label={`STA ${STALocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(STALocal ? formatLocalDisplay(STALocal) : formatDefaultDisplay(STA))}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {ETA && (
                <SubtitleDisplay
                    label={`ETA ${ETALocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(ETALocal ? formatLocalDisplay(ETALocal) : formatDefaultDisplay(ETA))}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {ATA && (
                <SubtitleDisplay
                    label={`ATA ${ATALocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(ATALocal ? formatLocalDisplay(ATALocal) : formatDefaultDisplay(ATA))}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {STD && (
                <SubtitleDisplay
                    label={`STD ${STDLocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(STDLocal ? formatLocalDisplay(STDLocal) : formatDefaultDisplay(STD))}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {ETD && (
                <SubtitleDisplay
                    label={`ETD ${ETDLocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(ETDLocal ? formatLocalDisplay(ETDLocal) : formatDefaultDisplay(ETD))}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {ATD && (
                <SubtitleDisplay
                    label={`ATD ${ATDLocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(ATDLocal ? formatLocalDisplay(ATDLocal) : formatDefaultDisplay(ATD))}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
        </Stack>
    );
};

export default PreviewStopBlock;
