import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
    StatusMessage,
    StatusMsgCategory,
    formatStatusMessage,
    addMessageToList,
    MessageScenario,
} from '../helper/statusBarHelper';
import { getAllAddThunk } from './addSlice';
import { getDisMessageThunk, sendDisMessageThunk } from './disMessageSlice';
import {
    uploadFilesThunk,
    downloadFileThunk,
    downloadFilesThunk,
    uploadReportThunk,
    exportLmttFileThunk,
} from './fileSlice';
import { getAllFlightThunk, getDepartureFlightThunk } from './flightSlice';
import { getEventTaskHistoryLogsThunk } from './historySlice';
import {
    sendReportByFileIdThunk,
    getReportCardByEventIdThunk,
    previewReportThunk,
    downloadCoePdfThunk,
    generateAogStatusReportThunk,
} from './reportSlice';
import { getAtaCodesThunk, getIssueHistoryThunk, saveIssueSummaryThunk, updateIssueThunk } from './issueSummarySlice';
import { getRecoveryPlanByEventIdAndTypeThunk, saveRecoveryPlanThunk } from './recoveryPlanSlice';
import {
    getUserGroupsThunk,
    getGroupsMappingThunk,
    updateUserGroupThunk,
    getUserListByGroupsThunk,
} from './userProfileSlice';
import {
    postChatMessageThunk,
    getChatAttachedImageThunk,
    getAndReadChatMessagesThunk,
    getChatMetaDataByAudienceThunk,
    getFlightByChatUfisThunk,
    markAllChatReadThunk,
    getChatMetaDataByAudienceInBackgroundThunk,
    getFlightByChatUfisInBackgroundThunk,
} from './chatSlice';
import {
    updateActionItemsThunk,
    editEventThunk as editCoeEventThunk,
    saveEventNoteThunk,
    saveEventTaskNoteThunk,
    DetailedActionItem,
    getActionItemDetailThunk,
    createEventThunk as createCOEEventThunk,
    updateFollowUpThunk,
    updateEventStatusThunk,
    updateEventTaskStatusThunk,
    getEventByIdThunk as getCoeEventByIdThunk,
} from './coeEventSlice';
import {
    getAllEventsThunk,
    getIocEventsThunk,
    getEventSettingThunk,
    createEventThunk,
    reopenEventThunk,
    cancelEventThunk,
    getEventByIdThunk,
    getEventTasksByIdThunk,
    updateEtsThunk,
    updateAtsThunk,
    getAirportListThunk,
    closeEventThunk,
    editEventThunk,
    editEventAssessmentThunk,
    getZippedIOCReportThunk,
    getIOCTOwnerListThunk,
    getMCOwnerListThunk,
    getEventLabelListThunk,
    updateEventDetailsThunk,
    updateIsHandoverReportByEventIdThunk,
    downloadHandoverNoteThunk,
    generateEventsReportThunk,
} from './eventSlice';
import { getLogListThunk, removeLogThunk, saveIocLog } from './iocLogSlice';
import { removeMcLogThunk, declareAogThunk, declareServiceableThunk } from './mcLogSlice';
import {
    getFlightEventsThunk,
    getFlightGeneralDetailsThunk,
    getAircraftGeneralDetailsThunk,
    getSubfleetGeneralDetailsThunk,
    getFleetSubtypeListThunk,
} from './ganttSlice';
import { getLiveDashboardThunk } from './liveDashboardSlice';
import { StatusBarType } from '../components/common/StatusBar';
import { ApiStatusCode } from '../constants/api';
import { getDailyDashboardThunk } from './dailyDashboardSlice';
import {
    getDefinitionListThunk,
    getPriorityFlightListThunk,
    sendPriorityFlightListThunk,
    createDefinitionThunk,
    editDefinitionThunk,
    deleteDefinitionThunk,
} from './priorityFlightSlice';

import {
    getFlightEventsAndConstraintsThunk,
    getFlightScheduleThunk,
    getFlightAirportThunk,
    getFlightADDsThunk,
    getFlightCargoThunk,
    getFlightPlanningThunk,
    getFlightPaxThunk,
    getFlightCrewThunk,
    getFlightCargoPTSThunk,
} from './flightTabsPanelSlice';
import { getMcLogListThunk, updateMcLogThunk } from './mcLogSlice';

import { getAircraftDetailsByRegistrationThunk, getAircraftADDsByRegistrationThunk } from './aircraftTabsPanelSlice';

import { getSubfleetSummaryThunk, getSubfleetAircraftPerformanceThunk } from './subfleetTabsPanelSlice';
import { getAircraftTypeThunk, getTopCardThunk, getDomCrewsThunk } from './domSlices';
import { isDateStrAfter } from '../helper/dateHelper';
import { saveIOCAlertThunk, deleteAlertDraftThunk, getIOCAlertByIdThunk, sendAlertThunk } from './iocAlertSlice';
import { saveTriggerThunk } from './coeEventManagementSlice';
import { getMcilogEventsThunk, generateHandoverReportThunk, getMcilogShiftViewEventsThunk } from './mcEventListSlice';
import { getSupersedeListThunk, createOrUpdateWrqThunk } from './mcWrqSlice';
import { getAircraftInfoThunk } from './commonSlice';
import { getObsRecipientsThunk } from './opsBroadcastSlice';
import { getAlertsListThunk } from './alertModuleSlice';
import { sendObsMessageThunk, getObsMessageListThunk } from './opsBroadcastSlice';

type GlobalUISlice = {
    isFullScreenDialogOpen: boolean;
    fullScreenDialogList: Array<String>;
    statusMessageList: Array<StatusMessage>;
    shouldResetGlobalUI: boolean;
};

interface Options {
    id?: MessageScenario | MessageScenario[];
    statusBarType?: StatusBarType | StatusBarType[]; // error/warning/success
    statusMsgCategory?: StatusMsgCategory | StatusMsgCategory[];
}

const initialState: GlobalUISlice = {
    isFullScreenDialogOpen: false,
    fullScreenDialogList: [],
    statusMessageList: [],
    shouldResetGlobalUI: true,
};

const globalUISlice = createSlice({
    name: 'globalUI',
    initialState,
    reducers: {
        resetGlobalUI: () => initialState,
        resetGlobalUIWhenPageUnmount: (state) => {
            state.statusMessageList = initialState.statusMessageList;
        },
        toggleFullScreenDialog: (state, action: PayloadAction<{ isOpen: boolean; dialogId: string }>) => {
            const { isOpen, dialogId } = action.payload;
            if (isOpen) {
                state.fullScreenDialogList.push(dialogId);
            } else {
                state.fullScreenDialogList = state.fullScreenDialogList.filter((id) => id !== dialogId);
            }
            state.isFullScreenDialogOpen = state.fullScreenDialogList.length > 0;
        },
        toggleResetGlobalUI: (state, action: PayloadAction<boolean>) => {
            state.shouldResetGlobalUI = action.payload;
        },
        addNewStatusMessage: (state, action: PayloadAction<StatusMessage>) => {
            // replace the message in the same category, e.g. both are related to updateETS
            const newStatusMsg = action.payload;
            const { statusMsgCategory } = newStatusMsg;
            state.statusMessageList = state.statusMessageList.filter(
                (item) => item.statusMsgCategory !== statusMsgCategory
            );
            state.statusMessageList.push(formatStatusMessage(newStatusMsg));
        },
        /**
         * @deprecated try to use removeStatusMessages
         */
        removeStatusMessageById: (state, action: PayloadAction<string>) => {
            state.statusMessageList = state.statusMessageList.filter((item) => item.id !== action.payload);
        },
        /**
         * @deprecated try to use removeStatusMessages
         */
        removeStatusMessageByCategory: (state, action: PayloadAction<StatusMsgCategory>) => {
            state.statusMessageList = state.statusMessageList.filter(
                (item) => item.statusMsgCategory !== action.payload
            );
        },
        /**
         * remove target status message by options, remove all if no options provided.
         *
         * @param state globalUiSlice state
         * @param action Options of target status to be removed. Suport array of props.
         * @returns update status banner list with target status messages removed.
         */
        removeStatusMessages: (state, action: PayloadAction<Options>) => {
            if (state.statusMessageList.length === 0) {
                return;
            }

            const msgOptions = action.payload;
            if (!msgOptions || Object.keys(msgOptions).length <= 0) {
                state.statusMessageList = [];
                return;
            }
            state.statusMessageList = state.statusMessageList.filter((item) => {
                if (msgOptions.id) {
                    if (Array.isArray(msgOptions.id)) {
                        if (!msgOptions.id.includes(item.id)) return true;
                    } else {
                        if (msgOptions.id !== item.id) return true;
                    }
                }

                if (msgOptions.statusBarType) {
                    if (Array.isArray(msgOptions.statusBarType)) {
                        if (!msgOptions.statusBarType.includes(item.statusBarType)) return true;
                    } else {
                        if (msgOptions.statusBarType !== item.statusBarType) return true;
                    }
                }

                if (msgOptions.statusMsgCategory) {
                    if (Array.isArray(msgOptions.statusMsgCategory)) {
                        if (!msgOptions.statusMsgCategory.includes(item.statusMsgCategory)) return true;
                    } else {
                        if (msgOptions.statusMsgCategory !== item.statusMsgCategory) return true;
                    }
                }

                return false;
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllEventsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getAllEventsFail', payload);
            })
            .addCase(getIocEventsThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'eventList'
                );
            })
            .addCase(getIocEventsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getIocEventsFail', payload);
            })
            .addCase(getEventSettingThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getEventSettingFail', payload);
            })
            .addCase(createEventThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'createEventFail', payload);
            })
            .addCase(getEventByIdThunk.fulfilled, (state, { payload }) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'eventList'
                );
            })
            .addCase(getEventByIdThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getEventByIdFail', payload);
            })
            .addCase(getEventTasksByIdThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getEventTasksByIdFail', payload);
            })
            .addCase(createEventThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { eventId, description } = payload?.event?.[0] || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'createEventSuccess',
                    null,
                    description || '',
                    eventId
                );
                state.shouldResetGlobalUI = false;
            })
            .addCase(updateEtsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateEtsFail', payload);
            })
            .addCase(updateAtsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateAtsFail', payload);
            })
            .addCase(getAllAddThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getAddFail', payload);
            })
            .addCase(getDisMessageThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getDisMessageFail', payload);
            })
            .addCase(sendDisMessageThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'sendDisMessageFail', payload);
            })
            .addCase(sendDisMessageThunk.fulfilled, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'sendDisMessageSuccess');
            })
            .addCase(uploadFilesThunk.rejected, (state, { payload, meta }) => {
                if (!meta.aborted) {
                    const { statusMessageList } = state;
                    state.statusMessageList = addMessageToList(statusMessageList, 'uploadFileFail', payload);
                }
            })
            .addCase(uploadFilesThunk.fulfilled, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'uploadFile'
                );
            })
            .addCase(downloadFileThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'downloadFileFail', payload);
            })
            .addCase(downloadFileThunk.fulfilled, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'downloadFile'
                );
            })
            .addCase(downloadFilesThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'downloadFileFail', payload);
            })
            .addCase(downloadFilesThunk.fulfilled, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'downloadFile'
                );
            })
            .addCase(exportLmttFileThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'exportLmttFileFail', payload);
            })
            .addCase(getAllFlightThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getAllFlightsFail', payload);
            })
            .addCase(getDepartureFlightThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'flightsInfo'
                );
            })
            .addCase(getDepartureFlightThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getDepartureFlightsFail', payload);
            })
            .addCase(getEventTaskHistoryLogsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getHistoryLogsFail', payload);
            })
            .addCase(uploadReportThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'uploadReportFail', payload);
            })
            .addCase(previewReportThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'previewReportFail', payload);
            })
            .addCase(getReportCardByEventIdThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getReportsFail', payload);
            })
            .addCase(sendReportByFileIdThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'sendReportFail', payload);
            })
            .addCase(sendReportByFileIdThunk.fulfilled, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'sendReportSuccess');
            })
            .addCase(getAtaCodesThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getAtaCodesFail', payload);
            })
            .addCase(getIssueHistoryThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getIssueHistoryFail', payload);
            })
            .addCase(saveIssueSummaryThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'saveIssueSummaryFail', payload);
            })
            .addCase(updateIssueThunk.fulfilled, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateIssueSummarySuccess');
            })
            .addCase(updateIssueThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateIssueSummaryFail', payload);
            })
            .addCase(getRecoveryPlanByEventIdAndTypeThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getRecoveryPlanDetailsFail', payload);
            })
            .addCase(saveRecoveryPlanThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'saveRecoveryPlanDetailsFail', payload);
            })
            .addCase(postChatMessageThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'chat');
            })
            .addCase(postChatMessageThunk.rejected, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'chatFail');
            })
            .addCase(getAndReadChatMessagesThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'chat');
            })
            .addCase(getAndReadChatMessagesThunk.rejected, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'loadChatMessageFail');
            })
            .addCase(getChatAttachedImageThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'chat');
            })
            .addCase(getChatAttachedImageThunk.rejected, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getChatImageFail');
            })
            .addCase(getChatMetaDataByAudienceThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'chat');
            })
            .addCase(getChatMetaDataByAudienceThunk.rejected, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getChatMetaFail');
            })
            .addCase(getChatMetaDataByAudienceInBackgroundThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'chat');
            })
            .addCase(getChatMetaDataByAudienceInBackgroundThunk.rejected, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getChatMetaFail');
            })
            .addCase(getFlightByChatUfisThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'chat');
            })
            .addCase(getFlightByChatUfisThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                const { statusCode } = payload;
                if (statusCode !== ApiStatusCode.FORBIDDEN) {
                    state.statusMessageList = addMessageToList(statusMessageList, 'getFlightByChatUfisFail', payload);
                }
            })
            .addCase(getFlightByChatUfisInBackgroundThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'chat');
            })
            .addCase(getFlightByChatUfisInBackgroundThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                const { statusCode } = payload;
                if (statusCode !== ApiStatusCode.FORBIDDEN) {
                    state.statusMessageList = addMessageToList(statusMessageList, 'getFlightByChatUfisFail', payload);
                }
            })
            .addCase(getUserGroupsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getUserGroupFail', payload);
            })
            .addCase(getGroupsMappingThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getGroupsMappingFail', payload);
            })
            .addCase(getAirportListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getAirportListFail', payload);
            })
            .addCase(getLogListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getLogListFail', payload);
            })
            .addCase(getActionItemDetailThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getActionItemDetailFail', payload);
            })
            .addCase(removeLogThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'Log');
            })
            .addCase(removeLogThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'removeLogFail', payload);
            })
            .addCase(removeMcLogThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'Log');
            })
            .addCase(removeMcLogThunk.fulfilled, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'removeMcLogSuccess');
            })
            .addCase(removeMcLogThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'removeMcLogFail', payload);
            })
            .addCase(declareAogThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'declareAogFail', payload);
            })
            .addCase(declareAogThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { eventId } = payload?.[0] || {};
                state.statusMessageList = addMessageToList(statusMessageList, 'declareAogSuccess', null, null, eventId);
            })
            .addCase(getFlightEventsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightEventsFail', payload);
            })
            .addCase(getFlightGeneralDetailsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightGeneralDetailsFail', payload);
            })
            .addCase(getAircraftGeneralDetailsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getAircraftGeneralDetailsFail', payload);
            })
            .addCase(getSubfleetGeneralDetailsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getSubfleetGeneralDetailsFail', payload);
            })
            .addCase(getFleetSubtypeListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getSubfleetGeneralDetailsFail', payload);
            })
            .addCase(saveIocLog.fulfilled, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'saveLogSuccess');
            })
            .addCase(saveIocLog.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'saveLogFail', payload);
            })
            .addCase(createCOEEventThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { eventId, description } = payload?.event?.[0] || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'createEventSuccess',
                    null,
                    description,
                    eventId
                );
                state.shouldResetGlobalUI = false;
            })
            .addCase(createCOEEventThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'createEventFail', payload);
            })
            .addCase(reopenEventThunk.fulfilled, (state, { meta: { arg } }) => {
                const { statusMessageList } = state;
                const { selectedEvent } = arg;
                const { eventId, description } = selectedEvent || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'reopenEventSuccess',
                    null,
                    description,
                    eventId
                );
            })
            .addCase(reopenEventThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'reopenEventFail', payload);
            })
            .addCase(cancelEventThunk.fulfilled, (state, { meta: { arg } }) => {
                const { statusMessageList } = state;
                const { selectedEvent } = arg;
                const { eventId, description } = selectedEvent || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'cancelEventSuccess',
                    null,
                    description,
                    eventId
                );
            })
            .addCase(cancelEventThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'cancelEventFail', payload);
            })
            .addCase(closeEventThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { id, description } = payload || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'closeEventSuccess',
                    null,
                    description || '',
                    id || ''
                );
            })
            .addCase(closeEventThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'closeEventFail', payload);
            })
            .addCase(editEventThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { eventId, description } = payload?.event?.[0] || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'editEventSuccess',
                    null,
                    description,
                    eventId
                );
                state.isFullScreenDialogOpen = false;
                state.fullScreenDialogList = [];
            })
            .addCase(editEventThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'editEventFail', payload);
            })
            .addCase(updateEventDetailsThunk.fulfilled, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateIssueSummarySuccess');
            })
            .addCase(updateEventDetailsThunk.rejected, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateIssueSummaryFail');
            })
            .addCase(editCoeEventThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { eventId, description } = payload?.event?.[0] || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'editEventSuccess',
                    null,
                    description,
                    eventId
                );
                state.isFullScreenDialogOpen = false;
                state.fullScreenDialogList = [];
            })
            .addCase(editCoeEventThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'editEventFail', payload);
            })
            .addCase(getCoeEventByIdThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getCoeEventByIdFail', payload);
            })
            .addCase(getCoeEventByIdThunk.fulfilled, (state, { payload }) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'eventList'
                );
            })
            .addCase(updateFollowUpThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                const { detail } = payload || {};

                if (detail && detail.includes('currentStatus')) {
                    const [message] = detail.split(', currentStatus:');
                    const newPayload = { ...payload, detail: message || '' };
                    state.statusMessageList = addMessageToList(statusMessageList, 'updateFollowUpFail', newPayload);
                } else {
                    state.statusMessageList = addMessageToList(statusMessageList, 'updateFollowUpFail', payload);
                }
            })
            .addCase(updateFollowUpThunk.fulfilled, (state, { payload }) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'eventFollowUp'
                );
            })
            .addCase(updateEventStatusThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateEventStatusFail', payload);
            })
            .addCase(updateEventTaskStatusThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateEventTaskStatusFail', payload);
            })
            .addCase(getLiveDashboardThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getLiveDashboardFail', payload);
            })
            .addCase(getDailyDashboardThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getDailyDashboardFail', payload);
            })
            .addCase(updateActionItemsThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'actionItem'
                );
            })
            .addCase(updateActionItemsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                const desc = payload?.detail || 'Create action item fail';
                state.statusMessageList = addMessageToList(statusMessageList, 'createActionItemFail', payload, desc);
            })
            .addCase(updateActionItemsThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const [latestActionItem] = [...payload].sort((a: DetailedActionItem, b: DetailedActionItem) =>
                    isDateStrAfter(b.createdAt, a.createdAt)
                );

                // when edit event and delete all action items, then latestActionItem is undefined.
                if (latestActionItem) {
                    const isUpdate = latestActionItem?.createdAt !== latestActionItem?.updatedAt;
                    const action = isUpdate ? 'edited' : 'created';
                    const desc = `Action item ID${latestActionItem?.id} was ${action} successfully.`;

                    state.statusMessageList = addMessageToList(
                        statusMessageList,
                        'createActionItemSuccess',
                        null,
                        desc,
                        null
                    );
                }
            })
            .addCase(getPriorityFlightListThunk.pending, (state, { payload }) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'priorityFlight'
                );
            })
            .addCase(getDefinitionListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getPriorityDefinitonFail', payload);
            })
            .addCase(getPriorityFlightListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getPriorityFlightListFail', payload);
            })
            .addCase(sendPriorityFlightListThunk.pending, (state, { payload }) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'priorityFlight'
                );
            })
            .addCase(sendPriorityFlightListThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'sendPriorityFlightListSuccess', null);
            })
            .addCase(sendPriorityFlightListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'sendPriorityFlightListFail', payload);
            })
            .addCase(createDefinitionThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { definitionId } = payload;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'createDefinitionSuccess',
                    null,
                    'Priority Flight Definition',
                    `${definitionId}`
                );
            })
            .addCase(createDefinitionThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'createDefinitionFail', payload);
            })
            .addCase(editDefinitionThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { definitionId } = payload;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'editDefinitionSuccess',
                    null,
                    'Priority Flight Definition',
                    `${definitionId}`
                );
            })
            .addCase(editDefinitionThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'editDefinitionFail', payload);
            })
            .addCase(getFlightEventsAndConstraintsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'getFlightEventsAndConstraintsFail',
                    payload
                );
            })
            .addCase(getFlightScheduleThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightScheduleFail', payload);
            })
            .addCase(getFlightAirportThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightAirportFail', payload);
            })
            .addCase(getFlightADDsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightADDsFail', payload);
            })
            .addCase(getFlightCargoThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightCargoFail', payload);
            })
            .addCase(getFlightPlanningThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightPlanningFail', payload);
            })
            .addCase(getFlightPaxThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightPaxFail', payload);
            })
            .addCase(getFlightCrewThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightCrewFail', payload);
            })
            .addCase(getFlightCargoPTSThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getFlightCargoPTSFail', payload);
            })
            .addCase(getAircraftDetailsByRegistrationThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'getAircraftDetailsByRegistrationFail',
                    payload
                );
            })
            .addCase(getAircraftADDsByRegistrationThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'getAircraftADDsByRegistrationFail',
                    payload
                );
            })
            .addCase(getSubfleetSummaryThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getSubfleetSummaryFail', payload);
            })
            .addCase(getSubfleetAircraftPerformanceThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'getSubfleetAircraftPerformanceFail',
                    payload
                );
            })
            .addCase(getAircraftTypeThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getAllAircraftTypeFail', payload);
            })
            .addCase(getTopCardThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getDomTopCardFail', payload);
            })
            .addCase(getDomCrewsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getDomCrewListFail', payload);
            })
            .addCase(markAllChatReadThunk.pending, (state, { payload }) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'chat');
            })
            .addCase(markAllChatReadThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'markAllReadFail', payload);
            })
            .addCase(deleteDefinitionThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'deleteDefinitionSuccess',
                    null,
                    null,
                    payload ? `${payload.definitionId}` : null
                );
            })
            .addCase(deleteDefinitionThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'deleteDefinitionFail', payload);
            })
            .addCase(editEventAssessmentThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'editEventAssessmentSuccess', null);
            })
            .addCase(editEventAssessmentThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'editEventAssessmentFail', payload);
            })
            .addCase(updateUserGroupThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateUserGroupFail', payload);
            })
            .addCase(saveEventNoteThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const [event] = payload['event'] || [];
                const [additionalNote] = event?.additionalNotes || [];
                const { id } = additionalNote || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'saveEventNoteSuccess',
                    null,
                    null,
                    `${id}`
                );
            })
            .addCase(saveEventNoteThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'saveEventNoteFail', payload);
            })
            .addCase(saveEventTaskNoteThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const [note] = payload['additionalNotes'] || [];
                const { id } = note || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'saveEventNoteSuccess',
                    null,
                    null,
                    `${id}`
                );
            })
            .addCase(saveEventTaskNoteThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'saveEventTaskNoteFail', payload);
            })
            .addCase(downloadCoePdfThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'downloadCoePdfSuccess', null);
            })
            .addCase(downloadCoePdfThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'downloadCoePdfFail', payload);
            })
            .addCase(getZippedIOCReportThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'downloadFile'
                );
            })
            .addCase(getZippedIOCReportThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getZippedIOCReportFail', payload);
            })
            .addCase(deleteAlertDraftThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'deleteAlertSuccess',
                    null,
                    null,
                    `${payload.id}`
                );
            })
            .addCase(deleteAlertDraftThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'deleteAlertFail', payload);
            })
            .addCase(sendAlertThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'sendAlertSuccess',
                    null,
                    null,
                    `${payload.id}`
                );
            })
            .addCase(sendAlertThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'sendAlertFail', payload);
            })
            .addCase(saveIOCAlertThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { id } = payload;
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'saveIocAlertSuccess',
                    null,
                    null,
                    id.toString()
                );
            })
            .addCase(saveIOCAlertThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'saveIocAlertFail', payload);
            })
            .addCase(getIOCAlertByIdThunk.fulfilled, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'iocAlert'
                );
            })
            .addCase(getIOCAlertByIdThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getIocAlertFail', payload);
            })
            .addCase(getMcLogListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getMcLogFail', payload);
            })
            .addCase(getIOCTOwnerListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getIOCTOwnerListFail', payload);
            })
            .addCase(getMCOwnerListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getMCOwnerListFail', payload);
            })
            .addCase(getEventLabelListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getEventLabelListFail', payload);
            })
            .addCase(updateMcLogThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;

                state.statusMessageList = addMessageToList(statusMessageList, 'createOrUpdateMcLogFail', payload);
            })
            .addCase(updateMcLogThunk.fulfilled, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'createOrUpdateMcLogSuccess');
            })
            .addCase(declareServiceableThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'declareServiceableFail', payload);
            })
            .addCase(declareServiceableThunk.fulfilled, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'declareServiceableSuccess');
            })
            .addCase(saveTriggerThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'saveTriggerFail', payload);
            })
            .addCase(saveTriggerThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { crdRules } = payload || {};
                const { createdAt, updatedAt, active } = crdRules?.[0] || {};
                const scenario = !active
                    ? 'deleteTriggerSuccess'
                    : createdAt === updatedAt
                        ? 'createTriggerSuccess'
                        : 'editTriggerSuccess';
                state.statusMessageList = addMessageToList(statusMessageList, scenario, null, null, null);
            })
            .addCase(getMcilogEventsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getMcilogEventsFail', payload);
            })
            .addCase(getMcilogEventsThunk.fulfilled, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'eventList'
                );
            })
            .addCase(getMcilogShiftViewEventsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getMcilogEventsFail', payload);
            })
            .addCase(getMcilogShiftViewEventsThunk.fulfilled, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'eventList'
                );
            })
            .addCase(getUserListByGroupsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getUserListByGroupFail', payload);
            })
            .addCase(getSupersedeListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getSupersedeListFail', payload);
            })
            .addCase(getAircraftInfoThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getAircraftDetailsFail', payload);
            })
            .addCase(getObsRecipientsThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getRecipientsFail', payload);
            })
            .addCase(getObsRecipientsThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'userProfile'
                );
            })
            .addCase(updateIsHandoverReportByEventIdThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'updateIssueSummaryFail', payload);
            })
            .addCase(updateIsHandoverReportByEventIdThunk.fulfilled, (state) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'issueSummary'
                );
            })
            .addCase(downloadHandoverNoteThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'downloadHandoverNoteFail', payload);
            })
            .addCase(downloadHandoverNoteThunk.fulfilled, (state) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'downloadHandoverNoteSuccess');
            })
            .addCase(createOrUpdateWrqThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'createOrUpdateWrqFail', payload);
            })
            .addCase(createOrUpdateWrqThunk.fulfilled, (state, { meta }) => {
                const { statusMessageList } = state;
                const { sendEmail, exportPdf } = meta.arg;
                if (sendEmail) {
                    state.statusMessageList = addMessageToList(
                        statusMessageList,
                        'createOrUpdateWrqWithSendEmailSuccess'
                    );
                } else if (exportPdf) {
                    state.statusMessageList = addMessageToList(
                        statusMessageList,
                        'createOrUpdateWrqWithExportPdfSuccess'
                    );
                } else {
                    state.statusMessageList = addMessageToList(statusMessageList, 'createOrUpdateWrqSuccess');
                }
            })
            .addCase(generateHandoverReportThunk.fulfilled, (state, { meta }) => {
                const { statusMessageList } = state;
                const { sendEmail } = meta.arg;
                if (sendEmail) {
                    state.statusMessageList = addMessageToList(statusMessageList, 'sendHandoverReportSuccess');
                } else {
                    state.statusMessageList = addMessageToList(statusMessageList, 'exportHandoverReportSuccess');
                }
            })
            .addCase(generateHandoverReportThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'generateHandoverReportFail', payload);
            })
            .addCase(generateEventsReportThunk.fulfilled, (state, { meta }) => {
                const { statusMessageList } = state;
                const { sendEmail, allowAttachments } = meta.arg;
                if (sendEmail) {
                    state.statusMessageList = addMessageToList(
                        statusMessageList,
                        allowAttachments ? 'sendSingleMCEventSuccess' : 'sendMultipleMCEventSuccess'
                    );
                } else {
                    state.statusMessageList = addMessageToList(
                        statusMessageList,
                        allowAttachments ? 'exportSingleMCEventSuccess' : 'exportMultipleMCEventSuccess'
                    );
                }
            })
            .addCase(generateEventsReportThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'generateMultipleMCEventFail', payload);
            })
            .addCase(generateAogStatusReportThunk.pending, (state, { payload }) => {
                state.statusMessageList = state.statusMessageList.filter(
                    (item) => item.statusMsgCategory !== 'statusReport'
                );
            })
            .addCase(generateAogStatusReportThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'sendReportFail', payload);
            })
            .addCase(generateAogStatusReportThunk.fulfilled, (state, { meta }) => {
                const { statusMessageList } = state;
                const { sendEmail } = meta.arg;
                if (sendEmail) {
                    state.statusMessageList = addMessageToList(statusMessageList, 'sendReportSuccess');
                }
            })
            .addCase(getAlertsListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getAlertListFail');
            })
            .addCase(sendObsMessageThunk.fulfilled, (state, { payload }) => {
                const { statusMessageList } = state;
                const { broadcastMessageId } = payload || {};
                state.statusMessageList = addMessageToList(
                    statusMessageList,
                    'sendObsMessageSuccess',
                    null,
                    null,
                    broadcastMessageId
                );
            })
            .addCase(sendObsMessageThunk.pending, (state) => {
                state.statusMessageList = state.statusMessageList.filter((item) => item.statusMsgCategory !== 'obs');
            })
            .addCase(sendObsMessageThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'sendObsMessageFail', payload);
            })
            .addCase(getObsMessageListThunk.rejected, (state, { payload }) => {
                const { statusMessageList } = state;
                state.statusMessageList = addMessageToList(statusMessageList, 'getObsMessagesFail', payload);
            });
    },
});

const {
    resetGlobalUI,
    toggleFullScreenDialog,
    toggleResetGlobalUI,
    addNewStatusMessage,
    removeStatusMessageById,
    removeStatusMessageByCategory,
    removeStatusMessages,
} = globalUISlice.actions;

const globalUI = (state: RootState) => state.globalUI;

const globalUIReducer = globalUISlice.reducer;

export {
    globalUIReducer as default,
    globalUI,
    // action
    resetGlobalUI,
    toggleFullScreenDialog,
    toggleResetGlobalUI,
    addNewStatusMessage,
    removeStatusMessageById,
    removeStatusMessageByCategory,
    removeStatusMessages,
};
