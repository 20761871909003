import { DisMessageObj } from '../slices/disMessageSlice';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, DISMESSAGE_API, EVENT_TASK_API } from '../constants/api';
import { sendHttpRequest } from './api';
import { TaskType } from '../constants/constants';

const getDisMessage = async (eventId: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, EVENT_TASK_API.eventTasks, {
        eventId,
        type: TaskType.disMessage,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;

    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }

    return [];
};

const sendDisMessage = async (param: DisMessageObj) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, DISMESSAGE_API.sendDisMessage, param);

    if (err) {
        return [err];
    }

    const { status, data } = resp;

    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { sendDisMessage, getDisMessage };
