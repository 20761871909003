import React, { useEffect } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import InputField from '../../common/InputField';
import { DestinationPortTypes } from '../../../interface/IocAlert';
import { DurationFormat, getFormattedTimeDifference } from '../../../helper/dateHelper';

import { LegInfo } from '../../../interface/Flight';
import { TimeTypes } from '../../../constants/constants';

interface DelayFieldProps extends Partial<UseFormReturn> {
    label?: string;
    currentAlertType: string;
    registerNamePrefix: string;
    inputWatchFieldSet: TimeTypes[];
    portType: DestinationPortTypes;
}

const DelayField = (props: DelayFieldProps) => {
    const {
        registerNamePrefix,
        label = 'Delay Duration (DD:HH:MM)',
        portType,
        inputWatchFieldSet,
        register,
        unregister,
        setValue,
        getValues,
    } = props;

    const [inputSchedule, inputEstimate, inputActual] = useWatch({
        name: inputWatchFieldSet.map((field) => `${registerNamePrefix}.${portType}.${field}`),
    });

    const { legInfo } = getValues(`${registerNamePrefix}.flightPortInfo`) || {};
    const { STD, STA } = legInfo.find((leg: LegInfo) => leg.type === portType) || {};
    const flightScheduleTime = portType === 'departure' ? STD : STA;
    const delayRegisterName = `${registerNamePrefix}.${portType}.delay`;
    const delayTime = getFormattedTimeDifference(inputActual || inputEstimate, inputSchedule || flightScheduleTime);

    useEffect(() => {
        setValue(
            delayRegisterName,
            getFormattedTimeDifference(
                inputActual || inputEstimate,
                inputSchedule || flightScheduleTime,
                DurationFormat.DayHourMinWithSuffix
            )
        );
    }, [delayTime]);

    useEffect(() => {
        return () => {
            unregister(delayRegisterName);
        };
    }, []);

    return (
        <>
            {delayTime ? (
                <>
                    <input type='hidden' {...register(delayRegisterName, { shouldUnregister: true })} />
                    <InputField label={label} width='496px' isDisabled={true} value={delayTime || ''} />
                </>
            ) : (
                false
            )}
        </>
    );
};

export default DelayField;
