import React from 'react';
import { Stack } from '@mui/material';

import LinkText from '../../common/LinkText';
import TitledSection from './TitledSection';
import FlightBarInfo, { ButtonTypes, IFlightBar } from '../../common/FlightBarInfo';

import { ROW_MAX_WIDTH, FIELD_OFFSET } from '../constants';
import { UseFormReturn, useWatch } from 'react-hook-form';

interface ReferenceFlightInfoProps extends Pick<UseFormReturn, 'getValues'> {
    onDeleteFlight?: (flight: IFlightBar) => void;
    registerNamePrefix: string;
    onRefresh?: (ufi: string) => void;
}

const ReferenceFlightInfo = (props: ReferenceFlightInfoProps) => {
    const { onDeleteFlight, registerNamePrefix, getValues, onRefresh } = props;

    const flight = useWatch({
        name: `${registerNamePrefix}.flightBarInfo`,
    });

    return (
        <TitledSection title='Reference Flight'>
            <Stack direction='row' spacing={1} maxWidth={ROW_MAX_WIDTH} alignItems='center' ml={FIELD_OFFSET}>
                <FlightBarInfo
                    flightBar={flight}
                    displayButtons={[
                        {
                            button: ButtonTypes.RUBBISH_BIN,
                            onClickAction: () => {
                                onDeleteFlight?.(flight);
                            },
                        },
                    ]}
                />
                <LinkText
                    sx={{ fontSize: '14px' }}
                    label='Refresh'
                    onClick={() => {
                        onRefresh?.(getValues(`${registerNamePrefix}.ufi`));
                    }}
                />
            </Stack>
        </TitledSection>
    );
};

export default ReferenceFlightInfo;
