import { CancelToken } from 'axios';
import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, FILES_API, EVENTS_API } from '../constants/api';

const uploadFile = async (param: FormData, cancelToken?: CancelToken) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, FILES_API.uploadFiles, param, {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...(cancelToken ? { cancelToken } : {}),
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const downloadFile = async (fileId: number) => {
    const [err, resp, respHeaders] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        FILES_API.downloadFile,
        { id: fileId },
        { responseType: 'blob' }
    );

    if (err) {
        return [err];
    }

    const contentDisposition = (respHeaders && respHeaders['content-disposition']) || null;
    const fileName =
        (contentDisposition &&
            contentDisposition
                .split(';')
                .find((item: string) => item.includes('filename='))
                .replace('filename=', '')
                .trim()
                .slice(1, -1)) ||
        null;
    return [null, resp, fileName];
};

const downloadFiles = async (fileIds: number[]) => {
    const [err, resp, respHeaders] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        FILES_API.downloadFiles,
        { ids: fileIds },
        { responseType: 'blob' }
    );

    if (err) {
        return [err];
    }

    const contentDisposition = (respHeaders && respHeaders['content-disposition']) || null;
    const fileName =
        (contentDisposition &&
            contentDisposition
                .split(';')
                .find((item: string) => item.includes('filename='))
                .replace('filename=', '')
                .trim()
                .slice(1, -1)) ||
        null;
    return [null, resp, fileName];
};

const exportFile = async ({
    status,
    subtypeStatus,
    startDate,
    endDate,
}: {
    status: string;
    subtypeStatus: string;
    startDate: string;
    endDate: string;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        EVENTS_API.exportEvents,
        {
            status,
            subtypeStatus,
            startDate,
            endDate,
        },
        { responseType: 'blob' }
    );

    if (err) {
        return [err];
    }

    return [null, resp];
};

const exportLmttFile = async ({
    eventIds,
    eventType,
    eventSubType,
    eventStatus,
    fleetSubType,
    registration,
    owner,
    startDate,
    endDate,
    actionItemStatus,
    actionItem,
    ataChapter,
    actionItemType,
    keyWords,
    actionItemFilter,
    commodity,
}: {
    eventIds?: number[];
    eventType?: number[];
    eventSubType?: number[];
    eventStatus?: string[];
    fleetSubType?: string[];
    registration?: string[];
    owner?: number[];
    startDate: string;
    endDate: string;
    actionItemStatus?: string[];
    actionItem: boolean;
    ataChapter?: string[];
    actionItemType?: string[];
    keyWords?: string;
    actionItemFilter?: boolean;
    commodity?: string[];
}) => {

    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        EVENTS_API.exportLmttEvents,
        {
            eventIds,
            eventType,
            eventSubType,
            eventStatus,
            fleetSubType,
            registration,
            owner,
            startDate,
            endDate,
            actionItemStatus,
            actionItem,
            ataChapter,
            actionItemType,
            keyWords,
            actionItemFilter,
            commodity,
        },
        { responseType: 'blob' }
    );

    if (err) {
        return [err];
    }

    return [null, resp];
};

export { uploadFile, downloadFile, exportFile, exportLmttFile, downloadFiles };
