import { styled, Chip } from '@mui/material';
import COLORS from '../../../../style/color';

const StyledChip = styled(Chip, { shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'disabled' })<{
    selected?: boolean;
    disabled?: boolean;
}>(({ selected, disabled }) => ({
    color: COLORS.mediumJade,
    borderColor: COLORS.tableBorder,
    background: COLORS.transparent,
    borderRadius: '100px',
    fontSize: '14px',
    fontWeight: 400,
    height: '40px',
    '& .MuiChip-label': {
        padding: '12px 16px',
    },
    ...(disabled && {
        color: COLORS.grayNormal,
        background: COLORS.grayLight,
        borderColor: COLORS.grayLight,
    }),
    ...(selected && {
        borderColor: COLORS.mediumJade,
        background: COLORS.chipJade,
    }),
}));

export default StyledChip;
