import { createElement } from 'react';
import { ReactComponent as MediumMail } from '../../assets/icons/MediumMail.svg';
import { ReactComponent as MediumSms } from '../../assets/icons/MediumSms.svg';
import { ReactComponent as MediumTeams } from '../../assets/icons/MediumTeams.svg';
import { ReactComponent as MediumWhatsapp } from '../../assets/icons/MediumWhatsapp.svg';

enum ChannelType {
    email = 'Email',
    sms = 'Sms',
    whatsapp = 'Whatsapp',
    teams = 'Teams',
    teamsGroup = 'TeamsGroupChat',
}

enum SortType {
    sentTimeDesc = 'sentTimestamp_DESC',
    sentTimeAsc = 'sentTimestamp_ASC',
}

enum TriggerSource {
    obsTrigger = 'obs-trigger',
}

const channelIconSequence = {
    [ChannelType.email]: 1,
    [ChannelType.sms]: 2,
    [ChannelType.teams]: 3,
    [ChannelType.whatsapp]: 4,
};

const channelsDisplayMap = {
    [ChannelType.teams]: createElement(MediumTeams, { key: ChannelType.teams }),
    [ChannelType.sms]: createElement(MediumSms, { key: ChannelType.sms }),
    [ChannelType.email]: createElement(MediumMail, { key: ChannelType.email }),
    [ChannelType.whatsapp]: createElement(MediumWhatsapp, { key: ChannelType.whatsapp }),
};

// validate channel icon sequence
if (new Set(Object.values(channelIconSequence)).size !== Object.values(channelIconSequence).length) {
    console.warn('Channel icon sequence may have duplicate sequence number.');
}

const mediumList = [
    { label: 'Email', value: ChannelType.email },
    { label: 'SMS', value: ChannelType.sms },
    { label: 'Teams', value: ChannelType.teams },
    { label: 'WhatsApp', value: ChannelType.whatsapp },
];

const contentLimitLength = 160;
const recipientsDisplayLimit = 23;
const defaultPageSize = 50;
const defaultFilters = {
    page: 1,
    size: defaultPageSize,
    sorting: [SortType.sentTimeDesc],
    keyword: '',
};

export {
    ChannelType,
    SortType,
    mediumList,
    channelsDisplayMap,
    contentLimitLength,
    channelIconSequence,
    recipientsDisplayLimit,
    defaultPageSize,
    defaultFilters,
    TriggerSource,
};
