import React from 'react';
import { Stack, Typography } from '@mui/material';

import { SubtitleDisplay } from '../../common/view/';
import PreviewDetailBlock from './PreviewDetailBlock';
import PreviewMessageBlock from './PreviewMessageBlock';
import BlockContainer from './BlockContainer';

import { useAppSelector } from '../../../app/hooks';
import { selectUserProfile } from '../../../slices/userProfileSlice';
import { getDisplayText } from '../../../helper/stringHelper';

import { PREVIEW_CONTAINER_WIDTH } from '../constants';

interface PreviewAlertProps {
    formData: any;
}

const titleStyles = { width: PREVIEW_CONTAINER_WIDTH };

const PreviewAlert = (props: PreviewAlertProps) => {
    const { formData } = props;
    const { primaryAffectedFlights, alertMessage, additionalAffectedFlights } = formData || {};
    const { emailSubject, alertType, reason, details, serviceRecovery } = alertMessage || {};
    const { userProfile } = useAppSelector(selectUserProfile);
    const { name, currentGroupName, galacxyId } = userProfile || {};

    const sender = `${name}, ${currentGroupName} (${galacxyId})`;

    return (
        <Stack sx={{ alignItems: 'flex-start' }} spacing='16px'>
            <SubtitleDisplay
                label='Email Subject Line'
                value={getDisplayText(emailSubject)}
                containerSx={titleStyles}
                valueSx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
            />
            <SubtitleDisplay label='Sender' value={sender} containerSx={titleStyles} />

            {primaryAffectedFlights?.length > 0 ? (
                <>
                    {primaryAffectedFlights?.map((flightInfo) => {
                        return (
                            <PreviewDetailBlock
                                key={flightInfo.ufi}
                                flightInfo={flightInfo}
                                flightType='Primary Affected Flight'
                            />
                        );
                    })}
                </>
            ) : (
                <BlockContainer title={alertType}>
                    <Typography variant='body5'>No flights added</Typography>
                </BlockContainer>
            )}

            <PreviewMessageBlock
                emailSubject={emailSubject}
                reason={reason}
                details={details}
                serviceRecovery={serviceRecovery}
            />
            {additionalAffectedFlights?.map((flightInfo) => {
                return (
                    <PreviewDetailBlock
                        key={flightInfo.ufi}
                        flightInfo={flightInfo}
                        flightType='Additional Impacted Flight'
                    />
                );
            })}
        </Stack>
    );
};

export default PreviewAlert;
