import React from 'react';
import { Box, Grid, Typography, SxProps, Stack } from '@mui/material';
import { ReactComponent as Asterisks } from '../../assets/icons/Asterisks.svg';

import colors from '../../style/color';

interface CreateEventFormBlockLayoutProps {
    title: string;
    subTitle?: string;
    isRequired: boolean;
    children: React.ReactNode;
    contentWidth?: string;
    sx?: SxProps;
}

const CreateEventFormBlockLayout = (props: CreateEventFormBlockLayoutProps) => {
    const { title, subTitle, isRequired, children, contentWidth = 'auto', sx } = props;

    return (
        <Grid container sx={sx}>
            <Grid item xs={4}>
                <Stack direction="row">
                    <Typography
                        variant='h2'
                        sx={{
                            fontSize: '20px',
                            color: colors.grayDark,
                            paddingRight: '4px',
                        }}
                    >
                        {title}
                        {isRequired && <Asterisks style={{ marginLeft: '8px', marginBottom: '8px' }} />}
                    </Typography>
                </Stack>
                {subTitle && (
                    <Typography variant='body3' sx={{ whiteSpace: 'pre-line' }}>
                        {subTitle}
                    </Typography>
                )}
            </Grid>
            <Grid container item xs={8}>
                <Box arial-name='eventInformationContent' sx={{ flexDirection: 'column', width: contentWidth }}>
                    {children}
                </Box>
            </Grid>
        </Grid>
    );
};

export default CreateEventFormBlockLayout;
