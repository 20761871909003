import React from 'react';
import { Stack } from '@mui/material';

import { AlertMessage } from '../../../interface/IocAlert';
import BlockContainer from './BlockContainer';
import { SubtitleDisplay } from '../../common/view/';
import { getDisplayText } from '../../../helper/stringHelper';

const PreviewMessageBlock = (props: AlertMessage) => {
    const { reason, details, serviceRecovery } = props;
    return (
        <BlockContainer title='Alert Message'>
            <Stack direction='column' spacing='32px'>
                <SubtitleDisplay label='Reason' value={getDisplayText(reason)} />
                <SubtitleDisplay
                    label='Details'
                    value={getDisplayText(details)}
                    valueSx={{ wordBreak: 'break-word' }}
                />
                <SubtitleDisplay
                    label='Service Recovery'
                    value={getDisplayText(serviceRecovery)}
                    valueSx={{ wordBreak: 'break-word' }}
                />
            </Stack>
        </BlockContainer>
    );
};

export default PreviewMessageBlock;
