import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { RootState } from '../app/store';
import { getLiveDashboard } from '../services/liveDashboard';
import { AirlineType } from '../constants/constants';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';
import { LiveDashboardDetailType, LiveDashboardRequestBody, LiveDashboardSlice } from '../interface/Dashboard';

export const getLiveDashboardThunk = createAsyncThunk<
LiveDashboardDetailType,
LiveDashboardRequestBody,
{ state: RootState; rejectValue: ApiErrorObj }
>('dashboard/getLiveDashboard', async (LiveDashboardRequestBody, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/performance/live')) {
        return rejectWithValue(ForbiddenObj);
    }
    const [err, data] = await getLiveDashboard(LiveDashboardRequestBody);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }
    return data as LiveDashboardDetailType;
});

const initialState: LiveDashboardSlice = {
    lastUpdatedAt: DateTime.now().toISO(),
    blocksToDisplay: {
        [AirlineType.CX]: {},
        [AirlineType.LD]: {},
    },
    isDashboardLoading: false,
};

export const liveDashboardSlice = createSlice({
    name: 'liveDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLiveDashboardThunk.pending, (state) => {
                state.isDashboardLoading = true;
            })
            .addCase(getLiveDashboardThunk.fulfilled, (state, action) => {
                const carrier = action.meta?.arg?.airline;
                state.lastUpdatedAt = DateTime.now().toISO();
                state.blocksToDisplay[carrier] = Object.assign(
                    state.blocksToDisplay[carrier] || {},
                    action.payload || {}
                );
                state.isDashboardLoading = false;
            })
            .addCase(getLiveDashboardThunk.rejected, (state) => {
                state.isDashboardLoading = false;
            });
    },
});

export const selectLiveDashboard = (state: RootState) => state.liveDashboard;
export default liveDashboardSlice.reducer;
