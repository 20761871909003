import { AircraftDetails } from '../../constants/constants';
import { TableCell } from 'pdfmake/interfaces';
import { IGenPdfDetail } from '../../interface/Report';

const assembleDetailsData = ({
    regNum = '--',
    latestETS = '--',
    latestATS = '--',
    location = '--',
    flightStatus = '--',
    nextFlightETD = '--',
    airline = '--',
    startDate = '--',
    ageOfEvent = '--',
    owner = '--',
    description = '--',
    isServiceable,
}: IGenPdfDetail) => {
    return [
        [
            {
                label: AircraftDetails.regNum,
                text: regNum,
            },
            {
                label: isServiceable ? AircraftDetails.latestATS : AircraftDetails.latestETS,
                text: isServiceable ? latestATS : latestETS,
            },
            {
                label: AircraftDetails.location,
                text: location,
            },
            {
                label: AircraftDetails.flightStatus,
                text: flightStatus,
            },
        ],
        [
            {
                label: AircraftDetails.nextFlightETD,
                text: nextFlightETD,
            },
            /* certain fields will be hidden based on the selection of transport method */
            !!airline && airline !== '--'
                ? {
                    label: AircraftDetails.airline,
                    text: airline,
                }
                : {},
            {
                label: AircraftDetails.startDate,
                text: startDate,
            },
            {
                label: AircraftDetails.ageOfEvent,
                text: ageOfEvent,
            },
        ],
        [
            {
                label: AircraftDetails.owner,
                text: owner,
                colSpan: 4,
            },
        ],
        [
            {
                label: AircraftDetails.description,
                text: description,
                colSpan: 4,
            },
        ],
    ];
};

const getDetailsColData = (detailTableData: IGenPdfDetail) => {
    const formatedData = assembleDetailsData(detailTableData);
    return formatedData.map((item, index) => {
        const isLast = index === formatedData.length - 1;
        return item.map((dataItem, dataIndex: number) => {
            if (JSON.stringify(dataItem) === '{}') {
                return {};
            }
            return {
                stack: [
                    { text: dataItem.label, style: dataIndex === 0 ? 'detailTableFirstColLabel' : 'detailTableLabel' },
                    { text: dataItem.text, style: dataIndex === 0 ? 'detailTableFirstColText' : 'detailTableText' },
                ],
                // Description's Row will have bottom margin
                margin: isLast ? [0, 10] : [0, 10, 0, 0],
                colSpan: dataItem.colSpan || 0,
            } as TableCell;
        }) as TableCell[];
    }) as TableCell[][];
};

export default getDetailsColData;
