import React from 'react';
import { Stack, Typography } from '@mui/material';

const ErrorPageFooter = () => {
    return (
        <Stack sx={{ justifyContent: 'center', marginBottom: '68px' }}>
            <Typography variant='mediumGrayNormal20'>If problem persist, please contact our IT service desk</Typography>
        </Stack>
    );
};
export default ErrorPageFooter;
