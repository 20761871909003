import React, { ChangeEvent, useEffect, useState } from 'react';
import { styled, SxProps } from '@mui/system';
import { Pagination, PaginationItem } from '@mui/material';

import { ArrowLeft, ArrowRight } from '../common/Arrow';

import colors from '../../style/color';

interface CustomPaginationProps {
    count: number;
    page?: number;
    sx?: SxProps;
    onChange?: (event: ChangeEvent<unknown>, page: number) => void;
}

const StyledPagination = styled(Pagination)({
    '& button.MuiPaginationItem-root': {
        border: `1px solid ${colors.borderGray}`,
        '&:hover': {
            backgroundColor: colors.buttonBgWhite,
        },
        fontSize: '14px',
        '&.Mui-selected': {
            backgroundColor: colors.textDark,
            color: colors.white,
        },
    },
});

const CustomPagination = (props: CustomPaginationProps) => {
    const { count, onChange, page, sx } = props;

    const [selectedPage, setSelectedPage] = useState(page || 1);

    useEffect(() => {
        if (isNaN(page)) return;
        setSelectedPage(page);
    }, [page]);

    return (
        <StyledPagination
            page={selectedPage}
            sx={sx}
            count={count}
            variant='outlined'
            shape='rounded'
            onChange={(event, page) => {
                onChange?.(event, page);
                setSelectedPage(page);
            }}
            renderItem={(item) => <PaginationItem sx={{width: '40px', height: '40px'}} components={{ previous: ArrowLeft, next: ArrowRight }} {...item} />}
        />
    );
};

export default CustomPagination;
