import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import {
    Autocomplete,
    styled,
    AutocompleteProps,
    Typography,
    AutocompleteRenderGetTagProps,
    ListItem,
} from '@mui/material';
import PopperPaperContainer from '../../view/v1/PopperPaperContainer';
import { StyledTextField as TextField } from '../../input';
import { FilterItem } from '..';
import { StyledChip } from '../../buttons';

export interface AutoCompleteSelectProps
    extends Omit<AutocompleteProps<FilterItem, boolean, true, false>, 'renderInput' | 'options' | 'onChange'> {
    label: string;
    dataList: FilterItem[] | ((inputValue: string) => Promise<FilterItem[]>);
    minPopperWidth?: string;
    minSearchChars?: number;
    onChange?: (values: FilterItem[]) => void;
    placeholder?: string;
    helperText?: string;
    hasError?: boolean;
}

const StyledTextField = styled(TextField, { shouldForwardProp: (props) => props !== 'showChipLabel' })(
    ({ showChipLabel }: { showChipLabel: boolean }) => ({
        ...(showChipLabel && {
            '& .MuiFilledInput-root': {
                paddingTop: '18px',
                '& .MuiFilledInput-input': {
                    padding: '7px 12px',
                },
            },
        }),
    })
);

const renderTags = (values: FilterItem[], getTagProps: AutocompleteRenderGetTagProps) => {
    return values.map((item, index) => {
        const { value } = item || {};
        return <StyledChip label={value} active={true} size='small' {...getTagProps({ index })} />;
    });
};

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: FilterItem) => {
    const { value } = option || {};
    return (
        <ListItem {...props}>
            <Typography variant='body7' children={value} />
        </ListItem>
    );
};

const AutoCompleteSelect = (props: AutoCompleteSelectProps) => {
    const {
        minSearchChars = 0,
        defaultValue,
        placeholder = '',
        label,
        dataList,
        minPopperWidth,
        onChange,
        hasError,
        helperText,
        multiple,
        value,
        ...otherProps
    } = props;
    const isAsyncData = typeof dataList === 'function';
    const initValue = value || defaultValue;
    const [options, setOptions] = useState<FilterItem[]>(isAsyncData ? [] : dataList);
    const [hasValue, setHasValue] = useState<boolean>(Array.isArray(initValue) ? initValue.length > 0 : false);
    const [loading, setLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);

    const getAsyncData = useCallback(
        debounce(async (keyWords: string) => {
            if (isAsyncData && keyWords.length >= minSearchChars) {
                dataList(keyWords)
                    .then((res) => {
                        setOptions(res);
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }, 750),
        [dataList, setOptions, setLoading]
    );

    useEffect(() => {
        if (Array.isArray(value)) {
            setHasValue(value?.length > 0);
        }
    }, [value]);

    const onTextInput = (value: string) => {
        const isShowOptions = value.length >= minSearchChars;
        setInputValue(value);
        setOpen(isShowOptions);
        if (isAsyncData) {
            setOptions([]);
            setLoading(isShowOptions);
            getAsyncData(value);
        }
    };

    const onAutocompleteChange = (event: React.SyntheticEvent, values: FilterItem[] | FilterItem) => {
        const isArray = Array.isArray(values);
        onChange?.(isArray ? values : [values]);
        setInputValue('');
        setOpen(false);
        if (multiple && !value) {
            // when it has `value`, handle on useEffect above
            setHasValue(isArray && values?.length > 0);
        }
    };

    return (
        <Autocomplete
            open={open}
            options={options}
            forcePopupIcon={false}
            defaultValue={defaultValue}
            loading={loading}
            PaperComponent={PopperPaperContainer}
            includeInputInList
            filterSelectedOptions
            multiple={multiple}
            value={value}
            inputValue={inputValue}
            noOptionsText={<Typography variant='regularGrayDark14'>No result(s)</Typography>}
            renderTags={renderTags}
            renderOption={renderOption}
            onInputChange={(event, value: string, reason) => {
                if (reason !== 'reset') {
                    onTextInput(value);
                }
            }}
            onChange={onAutocompleteChange}
            onClose={() => {
                isAsyncData && setOptions([]);
            }}
            onBlur={() => {
                setOpen(false);
                setInputValue('');
            }}
            componentsProps={{
                paper: {
                    sx: {
                        ...(minPopperWidth && { minWidth: minPopperWidth }),
                    },
                },
            }}
            renderInput={(params) => (
                <StyledTextField
                    showChipLabel={hasValue}
                    {...params}
                    variant='filled'
                    label={label}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                    }}
                    error={hasError}
                    helperText={helperText}
                />
            )}
            {...otherProps}
        />
    );
};

export default AutoCompleteSelect;
