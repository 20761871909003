import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, CHECKLIST_MANAGEMENT_API } from '../constants/api';
import {
    ICreateChecklistTemplateRequest,
    IChecklistTemplatesRequest,
    ISaveTaskTemplateRequest,
    ITemplateDeleteRequest,
} from '../interface/ChecklistManagement';

const getTemplates = async (params: IChecklistTemplatesRequest) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        CHECKLIST_MANAGEMENT_API.getChecklistTemplates,
        params
    );
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const createChecklistTemplate = async (params: ICreateChecklistTemplateRequest) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        CHECKLIST_MANAGEMENT_API.checklistTemplate,
        params
    );
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const deleteIRopsChecklistAndTaskTemplate = async (params: ITemplateDeleteRequest) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, CHECKLIST_MANAGEMENT_API.deleteTemplate, params);
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const saveTaskTemplate = async (params: ISaveTaskTemplateRequest) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.post,
        CHECKLIST_MANAGEMENT_API.saveTaskTemplate,
        params
    );
    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getTemplates, createChecklistTemplate, saveTaskTemplate, deleteIRopsChecklistAndTaskTemplate };
