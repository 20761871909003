import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertInfo } from '../interface/IocAlert';
import { deleteAlertDraft, getIOCAlertById, saveIOCAlert, sendIOCAlert } from '../services/iocAlert';
import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import { IOCAlertType } from '../interface/IocAlert';
import { EventTasks } from './eventSlice';
import { IOCAlertStatus } from '../components/iocAlert';

interface AlertFormStatus {
    isOpen: boolean;
    alertId?: number | null;
    alertStatus?: IOCAlertStatus;
    version?: number;
}
interface AlertSliceProps {
    alertFormStatus: AlertFormStatus;
    isIocAlertLoading: boolean;
}

export const initialState: AlertSliceProps = {
    alertFormStatus: { isOpen: false, alertId: null, alertStatus: null },
    isIocAlertLoading: false,
};

export const deleteAlertDraftThunk = createAsyncThunk<
AlertInfo,
string,
{ state: RootState; rejectValue: ApiErrorObj }
>('iocAlert/deleteAlertDraft', async (alertId, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile || {};

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTask/delete', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await deleteAlertDraft(alertId);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const getIOCAlertByIdThunk = createAsyncThunk<
EventTasks,
string,
{ state: RootState; rejectValue: ApiErrorObj }
>('iocAlert/getIOCAlertById', async (alertId, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile || {};

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/report/iocalert', ResourceAction.GET)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await getIOCAlertById(alertId);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const sendAlertThunk = createAsyncThunk<
EventTasks,
{ details: IOCAlertType; alertId?: string },
{ state: RootState; rejectValue: ApiErrorObj }
>('iocAlert/send', async ({ details, alertId }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/report/iocalert/send')) {
        return rejectWithValue(ForbiddenObj);
    }

    const { selectedEvent } = getState().event;
    const { id: eventId } = selectedEvent || {};

    const [err, data] = await sendIOCAlert({
        details,
        eventId: eventId,
        eventTaskId: alertId,
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

const saveIOCAlertThunk = createAsyncThunk<
EventTasks,
{ details: IOCAlertType; alertId?: string },
{ state: RootState; rejectValue: ApiErrorObj }
>('iocAlert/save', async ({ details, alertId }, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;

    if (!resourceCheck(currentPermissionList, ResourceType.API, '/event/report/iocalert/save')) {
        return rejectWithValue(ForbiddenObj);
    }

    const { selectedEvent } = getState().event;
    const { id: eventId } = selectedEvent || {};

    const [err, data] = await saveIOCAlert({
        details,
        eventTaskId: alertId,
        eventId: eventId,
        status: 'draft',
    });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        updateAlertFormStatus: (state, action: PayloadAction<AlertFormStatus>) => {
            state.alertFormStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteAlertDraftThunk.pending, (state) => {
                state.isIocAlertLoading = true;
            })
            .addCase(deleteAlertDraftThunk.fulfilled, (state) => {
                state.isIocAlertLoading = false;
            })
            .addCase(deleteAlertDraftThunk.rejected, (state) => {
                state.isIocAlertLoading = false;
            })
            .addCase(saveIOCAlertThunk.pending, (state) => {
                state.isIocAlertLoading = true;
            })
            .addCase(saveIOCAlertThunk.fulfilled, (state) => {
                state.isIocAlertLoading = false;
            })
            .addCase(saveIOCAlertThunk.rejected, (state) => {
                state.isIocAlertLoading = false;
            })
            .addCase(getIOCAlertByIdThunk.pending, (state) => {
                state.isIocAlertLoading = true;
            })
            .addCase(getIOCAlertByIdThunk.fulfilled, (state) => {
                state.isIocAlertLoading = false;
            })
            .addCase(getIOCAlertByIdThunk.rejected, (state) => {
                state.isIocAlertLoading = false;
            })
            .addCase(sendAlertThunk.pending, (state) => {
                state.isIocAlertLoading = true;
            })
            .addCase(sendAlertThunk.fulfilled, (state) => {
                state.isIocAlertLoading = false;
            })
            .addCase(sendAlertThunk.rejected, (state) => {
                state.isIocAlertLoading = false;
            });
    },
});

export const selectAlert = (state: RootState) => state.alert;

export default alertSlice.reducer;
export const { updateAlertFormStatus } = alertSlice.actions;
export { saveIOCAlertThunk };
