import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { Log, LogDetails } from '../interface/IOCLog';
import { RootState } from '../app/store';
import { TaskType, SORT_FIELD as SortOptions } from '../constants/constants';
import { formatDefaultDisplay, timeStringToDateTime } from '../helper/dateHelper';
import { getSortedLogList } from '../helper/iocLogHelper';
import { getEventTasksByEventIdAndType, updateEventTasksByEventId } from '../services/eventSubtask';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';

interface logStateProps {
    isLogLoading: boolean;
    logErrorObj: ApiErrorObj;
    logList: Log<LogDetails>[] | null;
    selectedLog: Log<LogDetails> | null;
    savedAt: string;
    savedBy: string;
    dialogStatus: {
        isOpen: boolean;
        logId: number;
    };
    selectedSortItem: string;
}

export interface LogListParam {
    eventId: string;
    types: TaskType[];
}

export interface updateLogParam {
    details: LogDetails;
    userId: number;
    eventId: number;
}

const initialState: logStateProps = {
    isLogLoading: false,
    logErrorObj: null,
    logList: null,
    selectedLog: null,
    savedAt: '--',
    savedBy: '--',
    dialogStatus: {
        isOpen: false,
        logId: null,
    },
    selectedSortItem: SortOptions.earliest,
};

export const getLogListThunk = createAsyncThunk<Log<LogDetails>[], LogListParam, { state: RootState; rejectValue: ApiErrorObj }>(
    'log/getEventTasks',
    async (param: LogListParam, { getState, rejectWithValue }) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.GET)) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await getEventTasksByEventIdAndType(param);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as Log<LogDetails>[];
    }
);
export const removeLogThunk = createAsyncThunk<Log<LogDetails>[], updateLogParam, { state: RootState; rejectValue: ApiErrorObj }>(
    'log/removeLogThunk',
    async (param: updateLogParam, { getState, rejectWithValue }) => {

        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.POST)) {
            return rejectWithValue(ForbiddenObj);
        }
        param.details.active = false; // set active to false to remove log
        const [err, data] = await updateEventTasksByEventId(param);

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as Log<LogDetails>[];
    }
);

export const saveIocLog = createAsyncThunk<
Log<LogDetails>[],
LogDetails,
{ state: RootState; rejectValue: ApiErrorObj }
>('log/saveIocLog', async (details: LogDetails, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { selectedEventId } = getState().event;
    const { userId, currentPermissionList } = userProfile || {};
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/eventTasks', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, data] = await updateEventTasksByEventId({ details: details, userId, eventId: selectedEventId });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const logSlice = createSlice({
    name: 'log',
    initialState,
    reducers: {
        updateSelectedLog: (state, action: PayloadAction<{ id: number }>) => {
            const { id } = action.payload;
            state.selectedLog = state.logList?.find((item) => item.id === id) ?? null;
        },
        setDialogStatus: (state, action: PayloadAction<{ isOpen: boolean; logId: number }>) => {
            const { isOpen, logId } = action.payload;
            state.dialogStatus = { isOpen, logId };
        },
        updateLogSorting: (state, action: PayloadAction<{ selectedSortItem: string }>) => {
            const { selectedSortItem } = action.payload;
            state.selectedSortItem = selectedSortItem;
            state.logList = getSortedLogList(state.logList, selectedSortItem);
        },
        resetLogList: (state) => {
            state.logList = initialState.logList;
            state.savedAt = initialState.savedAt;
            state.savedBy = initialState.savedBy;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLogListThunk.pending, (state) => {
                state.isLogLoading = true;
            })
            .addCase(getLogListThunk.fulfilled, (state, { payload }) => {
                state.isLogLoading = false;
                state.logList = getSortedLogList(payload, state.selectedSortItem);
                /* logic handling for savedAt & savedBy */
                const updateTimes = payload?.map((item) => timeStringToDateTime(item.updatedAt));
                const indexOfLatestUpdatedLogItem = updateTimes.indexOf(DateTime.max(...updateTimes));
                if (indexOfLatestUpdatedLogItem === -1) return;
                const lastLogItem = payload[indexOfLatestUpdatedLogItem];
                state.savedAt = formatDefaultDisplay(lastLogItem.updatedAt);
                state.savedBy =
                    lastLogItem.updatedBy?.userName && lastLogItem.updatedBy.group
                        ? `${lastLogItem.updatedBy.userName}, ${lastLogItem.updatedBy.group}`
                        : '--';
            })
            .addCase(getLogListThunk.rejected, (state) => {
                state.isLogLoading = false;
            })
            .addCase(removeLogThunk.pending, (state) => {
                state.isLogLoading = true;
            })
            .addCase(removeLogThunk.fulfilled, (state) => {
                state.isLogLoading = false;
            })
            .addCase(removeLogThunk.rejected, (state) => {
                state.isLogLoading = false;
            })
            .addCase(saveIocLog.pending, (state) => {
                state.isLogLoading = true;
            })
            .addCase(saveIocLog.fulfilled, (state) => {
                state.isLogLoading = false;
            })
            .addCase(saveIocLog.rejected, (state) => {
                state.isLogLoading = false;
            });
    },
});

export const selectLog = (state: RootState) => state.log;


export default logSlice.reducer;
export const { updateSelectedLog, setDialogStatus, updateLogSorting, resetLogList } = logSlice.actions;
