import { DateTime } from 'luxon';

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { ApiErrorObj } from '../services/ServerError';
import {
    getFlightEvents,
    getFlightGeneralDetails,
    getMaintBarByLegNo,
    getAircraftGeneralDetails,
    getSubfleetGeneralDetails,
    getFleetSubtypeList,
} from '../services/gantt';
import { DateFormat } from '../helper/dateHelper';
import { Maintenance } from './maintenanceSlice';

export enum FlightBarTypes {
    flightDetails = 'flightDetails',
    maintDetails = 'maintDetails',
    aircraftDetails = 'aircraftDetails',
    subfleetDetails = 'subfleetDetails',
}

export enum FilterType {
    crd = 'crd',
    ground = 'ground',
}

export type AircraftDefect = {
    no?: string;
    description?: string;
    deferType?: string;
    workOrderId?: number;
    melRefNo?: string;
    melCategory?: string;
    workPackageId?: string;
    flightNumber?: string;
    flightDate?: string;
    closed?: boolean;
    daysDue?: number;
    cyclesDue?: number;
    flyingHoursDue?: number;
    daysRemaining?: string;
    cyclesRemaining?: string;
    flyingHoursRemaining?: string;
    defectCode?: any[];
};

export interface FlightEventsRes {
    aircrafts: any[];
    returnReg: any[];
    allEventsByReg: Object;
}

export type AircraftGeneralDetails = {
    reg?: string;
    carrier?: string;
    subtype?: string;
    type?: string;
};

type SubfleetGeneralDetails = {
    reg?: string;
    carrier?: string;
    subtype?: string;
    type?: string;
};

type FlightGeneralDetails = {
    ufi?: string;
    flightNumber?: string;
    flightDate?: string;
    departure?: string;
    arrival?: string;
    type?: string;
    subtype?: string;
    serviceType?: string;
    reg?: string;
    regWithDash?: string;
    stat?: string;
    originFlightDateUtc?: string;
    originFlightDateLocal?: string;
    dooDate?: string;
    scheduledDepDate?: string;
    scheduledDepTime?: string;
    scheduledArrDate?: string;
    scheduledArrTime?: string;
    scheduledDepDateTime?: string;
    scheduledArrDateTime?: string;
    offBlock?: string;
    onBlock?: string;
    isFreightFlight?: boolean;
};

type SidePanelHeaderData = {
    ufi?: string;
    registration?: string;
    flightNumber?: string;
    subtype?: string;
    flightDate?: string;
    departure?: string;
    arrival?: string;
    checkName?: string;
    airport?: string;
    iataFleetType?: string;
    isFreightFlight?: boolean;
};

type MaintenanceBarDetails = {
    maint?: Maintenance;
    bayObject?: {
        aircraftRegNum?: string;
        bayNum?: string;
        timestamp?: string;
    };
    domPackage?: {
        etsUTC?: string | null;
        startTimeUTC?: string | null;
        endTimeUTC?: string | null;
    };
    techArea?: string | null;
    crdPackageInfo?: {
        resourcesTrafficLight?: string;
        resources?: number;
        sparesTrafficLight?: string;
        spares?: number;
        equipment?: number;
        equipmentTrafficLight?: string;
        tooling?: number;
        toolingTrafficLight?: string;
    };
    progress?: number;
    packageStatus?: string;
    acStatus?: string | null;
};

export interface TrafficLight {
    red?: boolean;
    amber?: boolean;
    green?: boolean;
}

export interface FlightEventsParam {
    startDate: string;
    endDate: string;
    lastQueryTime?: string;
    withLastQueryTime?: boolean;
    subType?: string[] | null;
    groundTime?: number; // in minutes
    filterStart?: string | null;
    filterEnd?: string | null;
    port?: string;
    resources?: TrafficLight;
    spares?: TrafficLight;
    tooling?: TrafficLight;
    equipment?: TrafficLight;
    any4M?: TrafficLight;
    filterType?: FilterType;
}

interface GanttSlice {
    isGanttLoading: boolean;
    isGeneralDetailsLoading: boolean;
    lastQueryTime?: string;
    flightEvents: FlightEventsRes;
    selectedGeneralDetails: any;
    selectedSidePanelHeaderData: SidePanelHeaderData;
    globalTimeZone: string;
}

const initialState: GanttSlice = {
    isGanttLoading: false,
    isGeneralDetailsLoading: false,
    lastQueryTime: null,
    flightEvents: null,
    selectedGeneralDetails: null,
    selectedSidePanelHeaderData: null,
    globalTimeZone: 'utc',
};

export const getFlightEventsThunk = createAsyncThunk<
FlightEventsRes,
FlightEventsParam,
{ state: RootState; rejectValue: ApiErrorObj }
>(
    'gantt/getFlightEvents',
    async (
        {
            startDate,
            endDate,
            lastQueryTime: manualStartQueryTime,
            withLastQueryTime = true,
            port,
            groundTime,
            filterStart,
            filterEnd,
            subType,
            resources,
            spares,
            tooling,
            equipment,
            any4M,
            filterType,
        },
        { getState, rejectWithValue }
    ) => {
        const { lastQueryTime } = getState().gantt;
        const [err, data] = await getFlightEvents({
            startDate,
            endDate,
            port,
            groundTime,
            filterStart,
            filterEnd,
            subType,
            resources,
            spares,
            tooling,
            equipment,
            any4M,
            filterType,
            ...(withLastQueryTime && { lastQueryTime: manualStartQueryTime || lastQueryTime }),
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }

        return data as FlightEventsRes;
    }
);

export const getFlightGeneralDetailsThunk = createAsyncThunk<
FlightGeneralDetails,
{ ufi: string },
{ rejectValue: ApiErrorObj }
>('gantt/getFlightGeneralDetails', async ({ ufi }, { rejectWithValue }) => {
    const [err, data] = await getFlightGeneralDetails({ ufi });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    const result = { ufi, ...data, dooDate: data?.DOODate };
    delete result.DOODate;
    return result;
});

export const getMaintBarByLegNoThunk = createAsyncThunk<
MaintenanceBarDetails,
{ legNo: number },
{ rejectValue: ApiErrorObj }
>('gantt/getMaintBarByLegNo', async ({ legNo }, { rejectWithValue }) => {
    const [err, data] = await getMaintBarByLegNo({ legNo });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { ...data };
});

export const getAircraftGeneralDetailsThunk = createAsyncThunk<
AircraftGeneralDetails,
{ registration: string },
{ rejectValue: ApiErrorObj }
>('gantt/getAircraftGeneralDetails', async ({ registration }, { rejectWithValue }) => {
    const [err, data] = await getAircraftGeneralDetails({ registration });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { ...data };
});

export const getSubfleetGeneralDetailsThunk = createAsyncThunk<
SubfleetGeneralDetails,
{ reg: string },
{ rejectValue: ApiErrorObj }
>('gantt/getSubfleetGeneralDetails', async ({ reg }, { rejectWithValue }) => {
    const [err, data] = await getSubfleetGeneralDetails({ reg });

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data as SubfleetGeneralDetails;
});

export const getFleetSubtypeListThunk = createAsyncThunk<
string[],
{ keySearch?: string },
{ rejectValue: ApiErrorObj }
>('gantt/getFleetSubtypeList', async ({ keySearch }, { rejectWithValue }) => {
    const [err, data] = await getFleetSubtypeList(keySearch);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return data;
});

export const ganttSlice = createSlice({
    name: 'gantt',
    initialState,
    reducers: {
        resetSidePanelHeaderData: (state) => {
            state.selectedSidePanelHeaderData = null;
        },
        clearLastQueryTime: (state) => {
            state.lastQueryTime = null;
        },
        toggleGlobalTimeZone: (state, action: PayloadAction<string>) => {
            if (action.payload !== null && action.payload !== undefined) {
                state.globalTimeZone = action.payload;
                return;
            }

            if (state.globalTimeZone === 'utc') {
                state.globalTimeZone = 'lt';
                return;
            }

            if (state.globalTimeZone === 'lt') {
                state.globalTimeZone = 'utc';
                return;
            }
        },
        setGanttLoading: (state, action: PayloadAction<boolean>) => {
            state.isGanttLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFlightEventsThunk.pending, (state) => {});
        builder.addCase(getFlightEventsThunk.rejected, (state) => {});
        builder.addCase(getFlightEventsThunk.fulfilled, (state, { payload }) => {
            state.flightEvents = payload;
            state.lastQueryTime = DateTime.now().toUTC().toFormat(DateFormat.DateTimeWithSec);
        });
        builder.addCase(getFlightGeneralDetailsThunk.pending, (state) => {
            state.isGeneralDetailsLoading = true;
        });
        builder.addCase(getFlightGeneralDetailsThunk.rejected, (state) => {
            state.isGeneralDetailsLoading = false;
        });
        builder.addCase(getFlightGeneralDetailsThunk.fulfilled, (state, { payload }) => {
            state.isGeneralDetailsLoading = false;
            state.selectedGeneralDetails = payload;
            const { ufi, regWithDash, flightNumber, subtype, dooDate, departure, arrival, isFreightFlight } = payload;

            state.selectedSidePanelHeaderData = {
                ufi,
                registration: regWithDash,
                flightNumber,
                subtype,
                flightDate: `${dooDate}z`,
                departure,
                arrival,
                isFreightFlight,
            };
        });
        builder.addCase(getMaintBarByLegNoThunk.pending, (state) => {
            state.isGeneralDetailsLoading = true;
        });
        builder.addCase(getMaintBarByLegNoThunk.rejected, (state) => {
            state.isGeneralDetailsLoading = false;
            state.selectedGeneralDetails = null;
            state.selectedSidePanelHeaderData = null;
        });
        builder.addCase(getMaintBarByLegNoThunk.fulfilled, (state, { payload }) => {
            state.isGeneralDetailsLoading = false;
            state.selectedGeneralDetails = payload;
            const { maint } = payload;
            const { aircraft, craft, checkBasic } = maint || {};
            const { subtype } = craft || {};
            const { checkName } = checkBasic || {};
            state.selectedSidePanelHeaderData = {
                checkName,
                subtype,
                registration: aircraft,
            };
        });
        builder.addCase(getAircraftGeneralDetailsThunk.pending, (state) => {
            state.isGeneralDetailsLoading = true;
        });
        builder.addCase(getAircraftGeneralDetailsThunk.rejected, (state) => {
            state.isGeneralDetailsLoading = false;
        });
        builder.addCase(getAircraftGeneralDetailsThunk.fulfilled, (state, { payload }) => {
            state.isGeneralDetailsLoading = false;
            state.selectedGeneralDetails = payload;
            const { reg, carrier, subtype, type } = payload;
            state.selectedSidePanelHeaderData = {
                registration: reg,
                subtype: `${carrier}${subtype}`,
                iataFleetType: type,
            };
        });
        builder.addCase(getSubfleetGeneralDetailsThunk.pending, (state) => {
            state.isGeneralDetailsLoading = true;
        });
        builder.addCase(getSubfleetGeneralDetailsThunk.rejected, (state) => {
            state.isGeneralDetailsLoading = false;
        });
        builder.addCase(getSubfleetGeneralDetailsThunk.fulfilled, (state, { payload }) => {
            state.isGeneralDetailsLoading = false;
            state.selectedGeneralDetails = payload;
            const { reg, type } = payload;
            state.selectedSidePanelHeaderData = {
                registration: reg,
                iataFleetType: type,
            };
        });
    },
});

export const selectGantt = (state: RootState) => state.gantt;
export const { resetSidePanelHeaderData, clearLastQueryTime, toggleGlobalTimeZone, setGanttLoading } =
    ganttSlice.actions;

export default ganttSlice.reducer;
