import React, { forwardRef } from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';

import { ReactComponent as CheckboxIcon } from '../../../../assets/icons/Checkbox.svg';
import { ReactComponent as CheckboxIconDisabled } from '../../../../assets/icons/DisabledCheckbox.svg';

import { ReactComponent as CheckboxIntermediate } from '../../../../assets/icons/CheckboxIndeterminate.svg';
import { ReactComponent as CheckboxIntermediateDisabled } from '../../../../assets/icons/DisabledCheckboxIndeterminate.svg';

import { ReactComponent as CheckboxChecked } from '../../../../assets/icons/CheckboxSelected.svg';
import { ReactComponent as CheckboxCheckedDisabled } from '../../../../assets/icons/DisabledCheckboxSelected.svg';

interface StyledCheckboxProps extends Omit<CheckboxProps, 'size'> {
    size?: string;
}

const StyledCheckbox = forwardRef((props: StyledCheckboxProps, ref: React.Ref<any>) => {
    const { size, sx, ...otherProps } = props;
    const { disabled } = otherProps;

    return (
        <Checkbox
            ref={ref}
            sx={{
                width: 'fit-content',
                height: 'fit-content',
                padding: '0px',
                ...sx,
            }}
            disableRipple={true}
            icon={
                disabled ? (
                    <CheckboxIconDisabled width={size} height={size} />
                ) : (
                    <CheckboxIcon width={size} height={size} />
                )
            }
            indeterminateIcon={
                disabled ? (
                    <CheckboxIntermediateDisabled width={size} height={size} />
                ) : (
                    <CheckboxIntermediate width={size} height={size} />
                )
            }
            checkedIcon={
                disabled ? (
                    <CheckboxCheckedDisabled width={size} height={size} />
                ) : (
                    <CheckboxChecked width={size} height={size} />
                )
            }
            {...otherProps}
        />
    );
});

export { StyledCheckbox as default, StyledCheckboxProps };
