import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, SxProps } from '@mui/system';

import toggleStyles from './ToggleStyles';

type ToggleProps = {
    buttonNames: string[] | { value: string; displayName: string }[];
    selectedValue: string;
    onChange?: (value: string) => void;
    sx?: SxProps;
};

const Component = styled(ToggleButtonGroup)({ ...toggleStyles });

const StyledToggleButton = (props: ToggleProps) => {
    const { buttonNames, selectedValue, sx } = props;
    const handleChange = (event: React.MouseEvent<HTMLElement>, newSelectedValue: string | null) => {
        newSelectedValue && newSelectedValue !== null && props.onChange(newSelectedValue);
    };
    return (
        <Component value={selectedValue} onChange={handleChange} exclusive sx={sx}>
            {buttonNames.map((data) => {
                if (typeof data === 'string') {
                    return (
                        <ToggleButton key={data} value={data}>
                            {data}
                        </ToggleButton>
                    );
                } else {
                    return (
                        <ToggleButton key={data?.value} value={data?.value}>
                            {data?.displayName}
                        </ToggleButton>
                    );
                }
            })}
        </Component>
    );
};

export default StyledToggleButton;
