import { sendHttpRequest } from './api';
import { HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, GANTT_API, AIRCRAFT_API } from '../constants/api';
import globalConfig from '../config/globalConfig';
import { FlightEventsParam } from '../slices/ganttSlice';
const { ioctAdminApiUrl } = globalConfig;

const getFlightEvents = async (params: Omit<FlightEventsParam, 'withLastQueryTime'>) => {
    Object.keys(params).forEach((k) => params[k] == null && delete params[k]);
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, GANTT_API.getFlightEvent, params, {
        baseURL: ioctAdminApiUrl,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightGeneralDetails = async ({ ufi }: { ufi: string }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getFlightGeneralDetails,
        {
            ufi,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getMaintBarByLegNo = async ({ legNo }: { legNo: number }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getMaintBarByLegNo,
        {
            legNo,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getAircraftGeneralDetails = async ({ registration }: { registration: string }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getAircraftGeneralDetails,
        {
            reg: registration,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getSubfleetGeneralDetails = async ({ reg }: { reg: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, AIRCRAFT_API.getSubfleetGeneralDetails, {
        reg,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getMaintBarEventsByLegNo = async ({ legNo }: { legNo: number }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getMaintBarEventsByLegNo,
        {
            legNo,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getMaintBarHistory = async ({ legNo }: { legNo: number }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getMaintBarHistory,
        {
            legNo,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getAircraftDetailsByRegistration = async ({
    registration,
}: {
    registration: string;
    requireFlight?: boolean;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getAircraftDetails,
        {
            tail: registration,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getAircraftADDsByRegistration = async ({ registration }: { registration: string }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getAircraftADDs,
        {
            tail: registration,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightADDs = async ({ ufi, registration }: { ufi: string; registration: string }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getFlightADDs,
        {
            ufi,
            reg: registration,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightAirport = async ({
    ufi,
    dept,
    arr,
    scheduledDepDateTime,
    scheduledArrDateTime,
}: {
    ufi: string;
    dept: string;
    arr: string;
    scheduledDepDateTime: string;
    scheduledArrDateTime: string;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getFlightAirport,
        {
            ufi,
            dept,
            arr,
            scheduledDepDateTime,
            scheduledArrDateTime,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightCargo = async ({ ufi, dept, arr }: { ufi: string; dept: string; arr: string }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getFlightCargo,
        {
            ufi,
            dept,
            arr,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightSchedule = async ({ ufi }: { ufi: string }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getFlightSchedule,
        {
            ufi,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightPax = async ({ ufi }: { ufi: string }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getFlightPax,
        {
            ufi,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }
    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightCrew = async ({ ufi }: { ufi: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, GANTT_API.getFlightCrew, {
        ufi,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightPlanning = async ({
    ufi,
    flightNumber,
    originFlightDateUtc,
}: {
    ufi: string;
    flightNumber: string;
    originFlightDateUtc: string;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getFlightPlanning,
        {
            ufi,
            flightNumber,
            originFlightDateUtc,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightEventsAndConstraints = async ({ ufi, registration }: { ufi?: string; registration?: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, GANTT_API.getFlightEventsAndConstraints, {
        ufi,
        tail: registration,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getSubfleetSummary = async ({ reg }: { reg: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, AIRCRAFT_API.getSubfleetSummaryDetails, {
        tail: reg,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getSubfleetAircraftPerformance = async ({ reg }: { reg: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, AIRCRAFT_API.getSubfleetAircraftPerformance, {
        tail: reg,
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFlightCargoPTS = async ({ ufi }: { ufi: string }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getFlightCargoPTS,
        {
            ufi,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getAllGroundTimeTasks = async ({
    legNo,
    includeLogs,
    limitToPackage,
    headers,
}: {
    legNo: number;
    includeLogs: boolean;
    limitToPackage?: boolean;
    headers?: any;
}) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getAllGroundTimeTasks,
        {
            legNo,
            includeLogs,
            limitToPackage,
        },
        {
            headers,
        }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getMaintenanceDueTolerance = async ({ legNo, registration }: { legNo: number; registration: string }) => {
    const [err, resp] = await sendHttpRequest(
        HTTP_REQUEST_TYPE.get,
        GANTT_API.getMaintenanceDueTolerance,
        {
            legNo,
            registration,
        },
        { baseURL: ioctAdminApiUrl }
    );

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getFleetSubtypeList = async (keySearch: string) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, GANTT_API.getFleetSubtypeList, {
        keySearch,
        acState: 'O',
    });

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export {
    getFlightEvents,
    getFlightGeneralDetails,
    getMaintBarByLegNo,
    getAircraftGeneralDetails,
    getSubfleetGeneralDetails,
    getAircraftDetailsByRegistration,
    getAircraftADDsByRegistration,
    getFlightADDs,
    getFlightAirport,
    getFlightPlanning,
    getFlightCargo,
    getFlightCrew,
    getFlightPax,
    getFlightSchedule,
    getFlightEventsAndConstraints,
    getSubfleetSummary,
    getSubfleetAircraftPerformance,
    getFlightCargoPTS,
    getMaintBarHistory,
    getMaintBarEventsByLegNo,
    getAllGroundTimeTasks,
    getMaintenanceDueTolerance,
    getFleetSubtypeList,
};
