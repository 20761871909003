import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { SxProps } from '@mui/material';
import InputField from '../../InputField';

interface DurationOutput {
    durationString: string;
    hours: number;
    minutes: number;
    durationInMinutes: number;
}
interface DurationProps {
    sx?: SxProps;
    label: string;
    defaultValue?: string;
    value?: string;
    hasError?: boolean;
    errorMessage?: string;
    onBlur?: (durationOutput: DurationOutput) => void;
    width?: string;
}

const DURATION_TEXT_REGEX = /[^0-9:]/g;
const MAX_HOURS = 999;
const MAX_MINUTES = 59;

const formatDurationInput = (value: string): DurationOutput => {
    const cleanedValue = value?.replace(DURATION_TEXT_REGEX, '');

    if (!cleanedValue)
        return {
            durationString: '',
            hours: 0,
            minutes: 0,
            durationInMinutes: 0,
        };

    const [hrs, mins] = cleanedValue.split(':');

    const hours = Math.min(parseInt(hrs || '0'), MAX_HOURS);
    const minutes = Math.min(parseInt(mins || '0'), MAX_MINUTES);

    const hoursString = hours.toString();
    const minutesString = minutes.toString().padStart(2, '0');

    return {
        durationString: `${hoursString}:${minutesString}`,
        hours,
        minutes,
        durationInMinutes: hours * 60 + minutes,
    };
};

const Duration = (props: DurationProps) => {
    const { sx, label, defaultValue, value, hasError = false, errorMessage, onBlur, width } = props;
    const durationInput = useRef<HTMLInputElement>();
    const [inputValue, setInputValue] = useState(formatDurationInput(defaultValue || value).durationString);

    const onInputBlur = (evt: ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatDurationInput(evt.target.value);
        setInputValue(formattedValue.durationString);
        onBlur?.(formattedValue);
    };

    const onInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const removeNonDurationText = evt.target.value.replace(DURATION_TEXT_REGEX, '');
        const [hrs, mins] = removeNonDurationText.split(':');

        const hours = parseInt(hrs) > MAX_HOURS ? MAX_HOURS.toString() : hrs;
        const minutes = parseInt(mins) > MAX_MINUTES ? MAX_MINUTES.toString() : mins;

        setInputValue(removeNonDurationText.includes(':') ? `${hours}:${minutes}` : hours);
    };

    const onKeyDown = (value: KeyboardEvent<HTMLInputElement>) => {
        if (value.key === 'Enter') durationInput.current.blur();
    };

    useEffect(() => {
        setInputValue(formatDurationInput(defaultValue || value).durationString);
    }, [value]);

    return (
        <InputField
            width={width}
            hasError={hasError}
            showErrorMessage={hasError}
            errorMessage={errorMessage}
            ref={durationInput}
            sx={sx}
            label={label}
            defaultInputValue={defaultValue}
            value={inputValue}
            onChange={onInputChange}
            onBlur={onInputBlur}
            onKeyDown={onKeyDown}
        />
    );
};

export { Duration as default, DurationOutput };
