import { COMMON_API, HTTP_REQUEST_TYPE, HTTP_RESPONSE_TYPE, AIRCRAFT_API } from '../constants/api';
import { sendHttpRequest } from './api';
import { ApiErrorObj } from './ServerError';

const getTimeOffsetByPort = async (ports: string[]): Promise<[ApiErrorObj, { [key: string]: number }]> => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, COMMON_API.getTimeOffset, { ports });

    if (err) {
        return [err, null];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getAircraftInfo = async (params: { aircraftTypes: string[]; registrations: string[] }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.post, AIRCRAFT_API.getAircraftInfo, params);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

const getRecipients = async (params: { keywords: string }) => {
    const [err, resp] = await sendHttpRequest(HTTP_REQUEST_TYPE.get, COMMON_API.getRecipients, params);

    if (err) {
        return [err];
    }

    const { status, data } = resp;
    if (status === HTTP_RESPONSE_TYPE.success) {
        return [null, data];
    }
};

export { getTimeOffsetByPort, getAircraftInfo, getRecipients };
