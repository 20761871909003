import colors from '../../style/color';
import theme from '../../style/theme';

const borderRadius = '3px';

const sectionHeaderStyle = {
    bgcolor: colors.ultraLightJade,
    pl: 3,
    pr: 3,
    pt: 2,
    pb: 2,
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
};

const sectionHeaderValueStyle = {
    color: colors.cathayJade,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '18px',
};

const contentStyle = {
    pl: 3,
    pr: 3,
    paddingTop: '12px',
    pb: 4,
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    borderLeft: `1px solid ${colors.grayLight}`,
    borderBottom: `1px solid ${colors.grayLight}`,
    borderRight: `1px solid ${colors.grayLight}`,
};

const subtitleStyle = {
    color: colors.shadowGray,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '14px',
};

const subtitleValueStyle = {
    color: colors.grayDark,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '16px',
};

const dividerStyle = { width: '100%', bgcolor: `1px solid ${colors.grayLight}` };

export { sectionHeaderStyle, sectionHeaderValueStyle, contentStyle, subtitleStyle, subtitleValueStyle, dividerStyle };
