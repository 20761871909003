import { makeStyles } from 'tss-react/mui';
import COLORS from '../../style/color';

const autoCompleteStyles = makeStyles()({
    root: {
        width: '100%',
        height: '40px',
        margin: '4px',
        border: `1px solid ${COLORS.borderGray}`,
        borderRadius: '4px',
        background: COLORS.white,
        '&.Mui-focused': {
            border: `1px solid ${COLORS.textDark}`,
        },
        '&:hover': {
            backgroundColor: COLORS.backgroundWhite,
        },
        '&.has-error': {
            border: `1px solid ${COLORS.error} !important`,
        },
        '&.is-disabled': {
            background: COLORS.grayLight,
            '& .MuiFilledInput-root.Mui-disabled': {
                backgroundColor: COLORS.transparent,
            },
        },
    },
    endAdornment: {
        top: 'calc(50% - 7px)',
    },
    hideEndAdornment: {
        display: 'none',
    },
    paper: {
        marginTop: '4px',
        marginBottom: '4px',
        border: `1px solid ${COLORS.grayNormal}`,
        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.36);',
    },
    inputRoot: {
        top: '-2px',
        '& .MuiInputLabel-root': {
            color: COLORS.grayNormal,
            '&.Mui-focused': {
                color: COLORS.grayNormal,
            },
        },
        '& .MuiFilledInput-root': {
            height: '40px',
            backgroundColor: 'transparent',
            '& .MuiFilledInput-input': {
                padding: '0px 4px !important',
            },
            '&:hover': {
                backgroundColor: COLORS.transparent,
            },
            '&.Mui-focused': {
                backgroundColor: COLORS.transparent,
            },
        },
    },
    option: {
        color: COLORS.grayDark,
        '&.MuiAutocomplete-option.Mui-focused': {
            backgroundColor: COLORS.buttonBgWhite,
        },
        '&[aria-selected="true"] ': {
            backgroundColor: `${COLORS.textDark} !important`,
            color: COLORS.white,
        },
    },
});

export default autoCompleteStyles;
