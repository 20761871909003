import React from 'react';
import { ChipProps, Chip, styled } from '@mui/material';
import { ReactComponent as Cross } from '../../../../assets/icons/Cross.svg';
import colors from '../../../../style/color';

/**
 * reusing the same behavior as Material UI.
 * For the delete Icon to show up. onDelete function has to be provided.
 */
interface StyledChipProps extends ChipProps {
    active?: boolean;
}

const StyledChip = (props: StyledChipProps) => {
    return (
        <StyledMuiChip
            variant='outlined'
            clickable={true}
            deleteIcon={<Cross fill={props.disabled ? colors.grayNormal : colors.mediumJade} />}
            {...props}
        />
    );
};

const StyledMuiChip = styled(Chip, { shouldForwardProp: (props) => props !== 'active' })(
    ({ active, onDelete, size }: ChipProps & { active?: boolean }) => ({
        color: colors.mediumJade,
        borderColor: `${active ? colors.mediumJade : colors.grayLight}`,
        backgroundColor: `${active ? colors.chipJade : colors.white}`,
        width: 'fit-content',
        fontSize: '14px',
        padding: `${size === 'small' ? '4px 16px' : '7px 16px'}`,
        height: 'unset',
        borderRadius: '100px',
        '&:hover': {
            backgroundColor: colors.buttonBgWhite,
            borderColor: colors.mediumJade,
        },
        '&.Mui-disabled': {
            opacity: 1,
            backgroundColor: colors.grayLight,
            color: `${onDelete ? colors.grayDark : colors.grayNormal}`,
            borderColor: colors.borderGray,
        },
        '& .MuiChip-label': {
            padding: 0,
        },
        '& .MuiChip-deleteIcon': {
            width: '24px',
            height: '24px',
            margin: '0 -8px 0 8px',
            padding: '5px',
        },
    })
);

export default StyledChip;
