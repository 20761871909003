import React from 'react';
import { Stack } from '@mui/material';

import { SubtitleDisplay } from '../../common/view/';
import { ReferenceFlight } from '../../../interface/IocAlert';
import { formatLocalDisplay, formatDefaultDisplay } from '../../../helper/dateHelper';
import { getDisplayText } from '../../../helper/stringHelper';

import { PREVIEW_COLUMN_WIDTH } from '../constants';

const PreviewDepartureBlock = (props: ReferenceFlight) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { port = '', STDLocal, STD, ETDLocal, ETD, ATDLocal, ATD, delay } = props;

    return (
        <Stack direction='row'>
            <SubtitleDisplay
                label='Departure Port'
                value={getDisplayText(port)}
                containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
            />
            {STD && (
                <SubtitleDisplay
                    label={`STD ${STDLocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(
                        getDisplayText(STDLocal ? formatLocalDisplay(STDLocal) : formatDefaultDisplay(STD))
                    )}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {ETD && (
                <SubtitleDisplay
                    label={`ETD ${ETDLocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(
                        getDisplayText(ETDLocal ? formatLocalDisplay(ETDLocal) : formatDefaultDisplay(ETD))
                    )}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {ATD && (
                <SubtitleDisplay
                    label={`ATD ${ATDLocal ? 'Local' : 'UTC'}`}
                    value={getDisplayText(
                        getDisplayText(ATDLocal ? formatLocalDisplay(ATDLocal) : formatDefaultDisplay(ATD))
                    )}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
            {delay && (
                <SubtitleDisplay
                    label='Delay Duration'
                    value={getDisplayText(delay)}
                    containerSx={{ width: PREVIEW_COLUMN_WIDTH }}
                />
            )}
        </Stack>
    );
};

export default PreviewDepartureBlock;
