import React from 'react';
import { Stack } from '@mui/material';

import { ImpactedFlightInfo } from '../../../interface/IocAlert';
import PreviewArrivalBlock from './PreviewArrivalBlock';
import BlockContainer from './BlockContainer';
import PreviewDepartureBlock from './PreviewDepartureBlock';
import PreviewStopBlock from './PreviewStopBlock';
import PreviewFlightBlock from './PreviewFlightBlock';
import PreviewPaxBlock from './PreviewPaxBlock';

interface PreviewDetailProps {
    flightType: string;
    flightInfo: ImpactedFlightInfo;
}

const PreviewDetailBlock = (props: PreviewDetailProps) => {
    const { flightInfo, flightType } = props;
    const {
        ufi,
        alertType,
        flightNumber,
        iataAircraftType,
        iataAircraftSubtype,
        aircraftRegistration,
        departure,
        arrival,
        stops,
        paxCount,
    } = flightInfo;
    return (
        <BlockContainer title={`${flightType}_${alertType}`}>
            {ufi ? (
                <Stack direction='column' spacing='32px'>
                    <PreviewFlightBlock
                        flightNumber={flightNumber}
                        iataAircraftType={iataAircraftType}
                        iataAircraftSubtype={iataAircraftSubtype}
                        aircraftRegistration={aircraftRegistration}
                    />
                    <PreviewDepartureBlock {...departure} />
                    {stops?.map((stop, index) => {
                        return <PreviewStopBlock key={index} {...stop} index={index} />;
                    })}
                    <PreviewArrivalBlock {...arrival} />
                    <PreviewPaxBlock {...paxCount} />
                </Stack>
            ) : (
                'No flights added'
            )}
        </BlockContainer>
    );
};

export default PreviewDetailBlock;
