import { TimeTypes } from '../../constants/constants';
import { DropdownData } from '../common/Dropdown';

const PRIMARY_REGISTER_NAME = 'primaryAffectedFlights';
const SECONDARY_REGISTER_NAME = 'additionalAffectedFlights';

const DEBOUNCE_INTERVAL = 600;

const FIELD_WIDTH = '160px';
const FIELD_WIDTH_SMALL = '82px';
const SECTION_SEPARATION = '24px';
const FIELD_OFFSET = '4px';
const ROW_MAX_WIDTH = '700px';

const PREVIEW_COLUMN_WIDTH = '131px';
const PREVIEW_CONTAINER_WIDTH = '559px';

enum IOCAlertTypes {
    generalInfo = 'General Info',
    extensiveDelay = 'Extensive Delay',
    cancellation = 'Cancellation',
    flightReturn = 'Flight Return',
    diversion = 'Diversion',
    techStop = 'Tech Stop',
}

enum IOCAlertStatus {
    draft = 'draft',
    delete = 'delete',
    sent = 'sent',
}

const alertTypeOptions: DropdownData[] = Object.values(IOCAlertTypes).map((alertType) => ({
    display: alertType,
    value: alertType,
}));

const timeOptionsSTDOnly: DropdownData[] = [
    {
        display: TimeTypes.STD,
        value: TimeTypes.STD,
    },
];

const timeOptionsETDATD: DropdownData[] = [
    {
        display: TimeTypes.ETD,
        value: TimeTypes.ETD,
    },
    {
        display: TimeTypes.ATD,
        value: TimeTypes.ATD,
    },
];

const departureTimeOptions: DropdownData[] = [...timeOptionsSTDOnly, ...timeOptionsETDATD];

const timeOptionsSTAOnly: DropdownData[] = [
    {
        display: TimeTypes.STA,
        value: TimeTypes.STA,
    },
];

const timeOptionsETAATA: DropdownData[] = [
    {
        display: TimeTypes.ETA,
        value: TimeTypes.ETA,
    },
    {
        display: TimeTypes.ATA,
        value: TimeTypes.ATA,
    },
];

const arrivalTimeOptions: DropdownData[] = [...timeOptionsSTAOnly, ...timeOptionsETAATA];

const alertReasonList = [
    'Airport Facility Issue',
    'Aircraft Damage',
    'Aircraft Rotation',
    'ATC Issues',
    'Commerical Requests',
    'Crew Issues',
    'Government Services Issue',
    'Ground Services Issue',
    'Mass Connection Issues',
    'Medical',
    'Natural Disaster Issues',
    'Ops Requirement',
    'Passenger Issues',
    'Technical Issues',
    'Political Issues',
    'Weather Issues',
    'Other Issues',
];

const alertReasonOptions: DropdownData[] = alertReasonList.map((reason) => ({
    display: reason,
    value: reason,
}));

const dptWatchFields = [TimeTypes.STD, TimeTypes.ETD, TimeTypes.ATD];
const arrWatchFields = [TimeTypes.STA, TimeTypes.ETA, TimeTypes.ATA];

const dptDelayWatchFields = [TimeTypes.STD, TimeTypes.ATD, TimeTypes.ETD];
const arrDelayWatchFields = [TimeTypes.STA, TimeTypes.ATA, TimeTypes.ETA];

export {
    PRIMARY_REGISTER_NAME,
    SECONDARY_REGISTER_NAME,
    DEBOUNCE_INTERVAL,
    ROW_MAX_WIDTH,
    FIELD_WIDTH,
    FIELD_WIDTH_SMALL,
    SECTION_SEPARATION,
    FIELD_OFFSET,
    alertTypeOptions,
    timeOptionsSTDOnly,
    timeOptionsETDATD,
    departureTimeOptions,
    timeOptionsSTAOnly,
    timeOptionsETAATA,
    arrivalTimeOptions,
    IOCAlertTypes,
    alertReasonOptions,
    PREVIEW_COLUMN_WIDTH,
    PREVIEW_CONTAINER_WIDTH,
    IOCAlertStatus,
    dptDelayWatchFields,
    arrDelayWatchFields,
    dptWatchFields,
    arrWatchFields,
};
