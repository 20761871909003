import { Chip, styled } from '@mui/material';
import colors from '../../style/color';

const ChipForDisplay = styled(Chip)({
    color: colors.mediumJade,
    borderColor: colors.mediumJade,
    background: colors.chipJade,
    height: '28px',
    '& .MuiChip-deleteIcon': {
        marginRight: '10px',
        marginLeft: '0px',
    },
    '& .MuiChip-label': {
        paddingLeft: '8px',
        paddingRight: '12px',
    },
});

export default ChipForDisplay;
