import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { getUserProfileThunk, getGroupsMappingThunk } from './userProfileSlice';

interface AuthSlice {
    isLogin: boolean;
    accessToken?: string;
    name: string;
    userEmail: string;
    galacxyId: string;
}

const initialState: AuthSlice = {
    isLogin: false,
    accessToken: '',
    name: '',
    userEmail: '',
    galacxyId: '',
};

interface ReturnType {
    accessToken: string;
    email: string;
    name: string;
    sAMAccountName: string;
}

interface Input {
    accessToken: string;
    email: string;
    name: string;
    sAMAccountName: string;
}

export const loginThunk = createAsyncThunk<ReturnType, Input, { state: RootState }>(
    'auth/login',
    async ({ email, name, accessToken, sAMAccountName }, thunkAPI) => {
        const { userProfile } = thunkAPI.getState().userProfile;
        const successResult = { accessToken, email, name, sAMAccountName };

        thunkAPI.dispatch(updateAccessToken(accessToken));

        if (!userProfile) {
            try {
                await thunkAPI.dispatch(getUserProfileThunk()).unwrap();
                await thunkAPI.dispatch(getGroupsMappingThunk()).unwrap();
                return successResult;
            } catch (err) {
                return thunkAPI.rejectWithValue('');
            }
        } else {
            return successResult;
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.pending, (state) => {
                state = initialState;
            })
            .addCase(loginThunk.fulfilled, (state, { payload }) => {
                const { name, email, sAMAccountName } = payload;
                state.isLogin = true;
                state.name = name;
                state.userEmail = email;
                state.galacxyId = sAMAccountName;
            });
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const { updateAccessToken } = authSlice.actions;

export default authSlice.reducer;
