import { TaskType, SORT_FIELD } from '../constants/constants';
import { Log, ChatDetails, LogDetails, ManualEntryDetails } from '../interface/IOCLog';
import { timeStringToDateTime } from './dateHelper';

const showNameOrGalaxyIdInLogItem = (taskType: TaskType, logDetails: Log<LogDetails>) => {
    if (taskType === TaskType.iocLogChat) {
        const { sender } = (logDetails.details as ChatDetails) || {};
        const { name = '--', groups } = sender || {};
        return `${name}${groups?.length > 0 ? ', ' + groups.join() : ''}`;
    } else {
        const { createdBy } = logDetails || {};
        const { userName = '--', group = '--' } = createdBy || {};
        return `${userName}, ${group}`;
    }
};

const getSortedLogList = (items: Log<LogDetails>[], selectedSortOption: string): Log<LogDetails>[] => {
    if (!items) return null;
    return [...items].sort((a, b) => {
        const aRecordTime = timeStringToDateTime(a?.details?.recordTime).toMillis() || 0;
        const bRecordTime = timeStringToDateTime(b?.details?.recordTime).toMillis() || 0;
        return selectedSortOption === SORT_FIELD.latest ? bRecordTime - aRecordTime : aRecordTime - bRecordTime;
    });
};

function isIOCLogChat(logItem): logItem is Log<ChatDetails> {
    return logItem?.taskType === TaskType.iocLogChat;
}

function isIOCLogManual(logItem): logItem is Log<ManualEntryDetails> {
    return logItem?.taskType === TaskType.iocLogManual;
}

export { showNameOrGalaxyIdInLogItem, getSortedLogList, isIOCLogChat, isIOCLogManual };
