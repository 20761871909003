import colors from '../../../../style/color';

const popperContainerStyles = {
    '& .react-datepicker-popper': {
        zIndex: 2147483647,
        '&[data-placement^=bottom]': {
            paddingTop: '4px',
        },
        '&[data-placement^=top]': {
            paddingBottom: '4px',
        },
    },
    '& .react-datepicker__navigation': {
        top: '58px',
    },
};

const calendarContainerStyles = {
    '& .react-datepicker__navigation-icon::before': {
        borderWidth: '2px 2px 0 0',
    },
    '& .react-datepicker__navigation--years-upcoming::before , & .react-datepicker__navigation--years-previous::before':
        {
            borderWidth: '2px 2px 0 0',
            borderColor: colors.black,
            borderStyle: 'solid',
            content: '""',
            display: 'block',
            height: '9px',
            width: '9px',
            position: 'absolute',
            top: 'calc(50% - 4px)',
            left: 'calc(50% - 4px)',
            transform: 'rotate(135deg)',
        },
    '& .react-datepicker__navigation--years-upcoming::before': {
        transform: 'rotate(-45deg)',
    },

    '& .react-datepicker__month-container': {
        backgroundColor: colors.white,
        border: colors.borderColor,
        borderRadius: '4px',
        padding: '8px',
        '& .react-datepicker__header ': {
            display: 'flex',
            flexDirection: 'column-reverse',
            backgroundColor: colors.white,
            padding: '4px 0',
            borderBottom: 'none',
            '& .react-datepicker__day-names': {
                marginBottom: '10px',
                borderBottom: `1px solid ${colors.paleGray}`,
                '& .react-datepicker__day-name': {
                    fontSize: '12px',
                    width: '40px',
                },
            },
            '& .react-datepicker__current-month': {
                display: 'none',
            },
            '& .react-datepicker__month-dropdown-container,& .react-datepicker__year-dropdown-container': {
                border: `1px solid ${colors.borderGray}`,
                margin: '0 5px',
                padding: '4px 20px 0 10px',
                borderRadius: '4px',
                textAlign: 'left',
                position: 'relative',
                color: colors.black,
                height: '28px',

                '& .react-datepicker__month-read-view , & .react-datepicker__year-read-view': {
                    visibility: 'visible !important',
                },
                '& .react-datepicker__month-read-view--down-arrow ,& .react-datepicker__year-read-view--down-arrow': {
                    borderWidth: '4px',
                    borderColor: `${colors.mediumGray} ${colors.transparent} ${colors.transparent} ${colors.transparent}`,
                    transform: 'rotate(0deg)',
                    top: '6px',
                },

                '& .react-datepicker__month-dropdown , & .react-datepicker__year-dropdown': {
                    background: colors.white,
                    width: '100%',
                    left: 0,
                    padding: '4px',
                    '& .react-datepicker__navigation': {
                        top: 0,
                    },
                    '& .react-datepicker__month-option , & .react-datepicker__year-option': {
                        borderRadius: '4px',
                        '&.react-datepicker__month-option--selected_month , &.react-datepicker__year-option--selected_year':
                            {
                                backgroundColor: colors.activeOptionBg,
                                color: colors.white,
                                '& .react-datepicker__month-option--selected , & .react-datepicker__year-option--selected':
                                    {
                                        display: 'none',
                                    },
                            },
                    },
                },
            },
        },

        '& .react-datepicker__month': {
            margin: '0px',
        },
        '& .react-datepicker__day': {
            width: '40px',
            height: '40px',
            outline: 'none',
            borderRadius: '4px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '2px',
            '&:hover, &.react-datepicker__day--today': {
                border: colors.borderColor,
            },
            '&:hover': {
                backgroundColor: colors.buttonBgWhite,
            },
            '&.react-datepicker__day--selected': {
                backgroundColor: colors.textDark,
            },
        },
    },
};

export { popperContainerStyles, calendarContainerStyles };
