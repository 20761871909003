import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { RootState } from '../app/store';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { getPackages } from '../services/packageId';
import { LMTTHeader } from './coeEventSlice';
import { formatDefaultDisplay } from '../helper/dateHelper';
import { resourceCheck, ResourceType } from '../helper/resourceVerifyHelper';

export type TaskIdObj = {
    id?: string;
    taskId?: string;
    workOrderId?: number;
    workOrderTask?: number;
    shortDescription?: string;
    defectKey?: string;
    packageInfo?: {
        packageId?: string;
        dateTime?: string;
        arriveLocation?: string;
        aircraft?: string;
        registration?: string;
        planStartDatetime?: string;
    };
};

export type PackageIdObj = {
    packageId: string;
    pkgShortDescription?: string;
    acSubtype?: string;
    arriveLocation?: string;
    registration?: string;
    dateTime?: string;
    planStartDatetime?: string;
    tasks?: TaskIdObj[];
};

type PackageIdApiData = {
    packages: { [key: string]: PackageIdApiObj[] };
    total: number;
};

export type PackageIdApiObj = {
    id?: string;
    packageId?: string;
    type?: string;
    departFrom?: string;
    departTo?: string;
    arriveLocation?: string;
    storeCode?: string;
    carrier?: string;
    flightNumber?: string;
    originalETD?: string;
    sectorId?: string;
    planStartDatetime?: string;
    planEndDatetime?: string;
    currentStartDatetime?: string;
    currentEndDatetime?: string;
    actualStartDatetime?: string | null;
    earliestDueDate?: string;
    earliestDueHour?: string;
    earliestDueCycle?: string;
    status?: number;
    pkgShortDescription?: string;
    workOrderId?: number;
    statusCode?: string;
    serialNumber?: string;
    registration?: string;
    openDatetime?: string;
    closeDatetime?: string | null;
    dueDate?: string;
    woShortDescription?: string;
    woDescription?: string;
    inspectionNumber?: string;
    defect?: Defect;
    procedureId?: string;
    task?: Task;
    defectKey?: string;
    createdAt?: string;
    updatedAt?: string;
    craft?: {
        acSubtype?: string;
    };
};

export type Defect = {
    id?: string;
    type?: string;
    extension?: number;
    dueDatetime?: string;
    serialNumber?: string;
    deferDatetime?: string;
    shortDescription?: string;
    deferDefectCategory?: string;
    minimumEquipmentListCode?: string;
};

type Task = {
    part?: string;
    serial?: string;
    complete?: string;
    dueDate?: string;
    inspection?: {
        type?: string;
        status?: string;
        description?: string;
        revisionNumber?: number;
        inspectionNumber?: string;
    };
    shortDescription?: string;
    workOrderTask?: number;
};

export type PackageIdFilter = {
    startDate: DateTime;
    endDate: DateTime;
    packageId?: string;
    procedureId?: string;
    addNumber?: string;
    woNumber?: string;
};

type PackageIdSlice = {
    isPackageIdLoading: boolean;
    packageIdSliceList: PackageIdObj[];
    totalPackageIds: number;
};

export type PackageRequestBody = {
    tail: string;
    packageId?: string;
    procedureId?: string;
    addNumber?: string;
    woNumber?: string;
    startDate: string;
    endDate: string;
    page?: number;
    size?: number;
};

const initialState: PackageIdSlice = {
    isPackageIdLoading: false,
    packageIdSliceList: [],
    totalPackageIds: 0,
};

export const getPackageIdTime = (planEndDatetime: string, planStartDatetime: string) => {
    const end = DateTime.fromISO(planEndDatetime);
    const start = DateTime.fromISO(planStartDatetime);
    const diffInMinutes = end.diff(start, 'minutes');
    const minute = diffInMinutes.toObject().minutes || 0;
    const hours = Math.trunc(minute / 60);
    const minutes = minute - hours * 60 >= 10 ? minute - hours * 60 : '0' + (minute - hours * 60);
    return `${formatDefaultDisplay(planStartDatetime)} (${hours}h${minutes})`;
};

export const getPackageIdThunk = createAsyncThunk<
PackageIdApiData,
PackageRequestBody,
{ state: RootState; rejectValue: ApiErrorObj }
>(
    'coeevents/getPackageId',
    async (
        { tail, packageId, procedureId, addNumber, woNumber, startDate, endDate, page, size },
        { getState, rejectWithValue }
    ) => {
        const { userProfile } = getState().userProfile;
        const { currentPermissionList } = userProfile;
        if (!resourceCheck(currentPermissionList, ResourceType.API, '/getPackages')) {
            return rejectWithValue(ForbiddenObj);
        }
        const [err, data] = await getPackages({
            filters: { tail, packageId, procedureId, addNumber, woNumber, startDate, endDate, page, size },
            headers: LMTTHeader,
        });

        if (err) {
            return rejectWithValue(err as ApiErrorObj);
        }
        return data as PackageIdApiData;
    }
);

export const packageIdSlice = createSlice({
    name: 'packageId',
    initialState,
    reducers: {
        cleanPackageIdsList: (state) => {
            state.packageIdSliceList = [];
            state.totalPackageIds = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPackageIdThunk.pending, (state) => {
                state.isPackageIdLoading = true;
            })
            .addCase(getPackageIdThunk.rejected, (state, { payload }) => {
                state.isPackageIdLoading = false;
                state.packageIdSliceList = [];
            })
            .addCase(getPackageIdThunk.fulfilled, (state, { payload }) => {
                const { packages, total } = payload;
                const packagesArr =
                    packages &&
                    Object.keys(packages).map((pkgId) => {
                        const [pkg] = packages[pkgId];
                        const {
                            craft,
                            arriveLocation,
                            registration,
                            pkgShortDescription,
                            planEndDatetime,
                            planStartDatetime,
                        } = pkg || {};
                        const dateTime =
                            planEndDatetime &&
                            planStartDatetime &&
                            getPackageIdTime(planEndDatetime, planStartDatetime);
                        const { acSubtype } = craft || {};

                        return {
                            packageId: pkgId === 'NA' ? 'N/A' : pkgId,
                            acSubtype,
                            arriveLocation,
                            registration,
                            pkgShortDescription: !pkgShortDescription ? '--' : pkgShortDescription,
                            dateTime,
                            planStartDatetime: formatDefaultDisplay(planStartDatetime),
                            tasks: packages[pkgId].map((item) => {
                                const { id, task, defectKey, procedureId, workOrderId } = item;
                                const key = defectKey === 'NA' ? procedureId : defectKey;
                                const { shortDescription, workOrderTask } = task || {};

                                return {
                                    id,
                                    taskId: key,
                                    defectKey,
                                    procedureId,
                                    workOrderId,
                                    workOrderTask,
                                    shortDescription,
                                    packageInfo: {
                                        packageId: pkgId,
                                        dateTime,
                                        arriveLocation,
                                        acSubtype,
                                        registration,
                                        planStartDatetime: formatDefaultDisplay(planStartDatetime),
                                    },
                                };
                            }),
                        };
                    });
                state.packageIdSliceList = packagesArr as PackageIdObj[];
                state.totalPackageIds = total;
                state.isPackageIdLoading = false;
            });
    },
});

export const { cleanPackageIdsList } = packageIdSlice.actions;
export const selectPackageId = (state: RootState) => state.packageId;

export default packageIdSlice.reducer;
