import { makeStyles } from 'tss-react/mui';
import COLORS from '../../../../style/color';

const filterSearchBarStyles = makeStyles<void, 'focused' | 'notchedOutline'>()((_theme, _params, classes) => ({
    root: {
        borderRadius: '4px',
        background: COLORS.white,
        padding: '8px',
        [`&.${classes.focused} .${classes.notchedOutline}`]: {
            border: `1px solid ${COLORS.textDark}`,
        },
    },
    focused: {},
    notchedOutline: {},
    input: { padding: 0 },
}));

export default filterSearchBarStyles;
