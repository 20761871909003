import { DateTime } from 'luxon';
import { DetailedEvent } from '../slices/eventSlice';
import { formatDefaultDisplay, isDateStrAfter, timeStringToDateTime } from './dateHelper';
import { getFlightNumberDateOriginDestinationFromUfi } from './stringHelper';
import { COEDetailedEvent, LastStatusLog, RelatedActivity, ActionItemStatusType } from '../slices/coeEventSlice';
import { AddObj } from '../slices/addSlice';
import { MaintenanceObj } from '../slices/maintenanceSlice';
import { TaskIdObj, PackageIdObj } from '../slices/packageIdSlice';
import { Groups } from '../slices/userProfileSlice';
import {
    SORT_FIELD,
    COE_SORT_FIELD,
    coeEventStatusList,
    relatedActivityTypeDisplayNames,
    EventStatusType,
    RelatedActivityType,
    EventType,
    EventTypeIds,
} from '../constants/constants';

const filterByEventStatus = (eventList: DetailedEvent[], eventStatusFilter: string[]) => {
    if (!eventList) return [];
    if (eventStatusFilter.length > 0) return eventList.filter((event) => eventStatusFilter.includes(event.state));
    else return eventList;
};

const filterByEventTypes = (eventList: DetailedEvent[], eventTypesFilter: string[]) => {
    if (!eventList) return [];
    if (eventTypesFilter.length > 0)
        return eventList?.filter((event) => eventTypesFilter.includes(event?.typeId?.toString())) || [];
    else return eventList;
};

const filterByAssessment = (eventList: DetailedEvent[], isAssessmentCheck: boolean) => {
    if (!eventList) return [];
    if (isAssessmentCheck)
        return eventList.filter(
            (event) => !!event.assessmentDetails?.assessment && event.assessmentDetails?.assessment.length > 0
        );
    else return eventList;
};

const getSortedEvents = (eventList: DetailedEvent[], selectedSortItem: string) => {
    if (!eventList) return [];
    return [...eventList].sort((a: DetailedEvent, b: DetailedEvent) => {
        return selectedSortItem === SORT_FIELD.latest
            ? isDateStrAfter(b.startTime, a.startTime)
            : isDateStrAfter(a.startTime, b.startTime);
    });
};

const getCoeSortedEvents = (eventList: COEDetailedEvent[], selectedSortItem: string) => {
    if (!eventList) return [];
    if (selectedSortItem === COE_SORT_FIELD.latestStartTime || selectedSortItem === COE_SORT_FIELD.earliestStartTime) {
        return [...eventList].sort((a: COEDetailedEvent, b: COEDetailedEvent) => {
            return selectedSortItem === COE_SORT_FIELD.latestStartTime
                ? isDateStrAfter(b.startTime, a.startTime)
                : isDateStrAfter(a.startTime, b.startTime);
        });
    } else if (
        selectedSortItem === COE_SORT_FIELD.latestEndTime ||
        selectedSortItem === COE_SORT_FIELD.earliestEndTime
    ) {
        return [...eventList].sort((a: COEDetailedEvent, b: COEDetailedEvent) => {
            return selectedSortItem === COE_SORT_FIELD.latestEndTime
                ? isDateStrAfter(b.etsTime, a.etsTime)
                : isDateStrAfter(a.etsTime, b.etsTime);
        });
    } else {
        return [...eventList].sort((a: COEDetailedEvent, b: COEDetailedEvent) => {
            return selectedSortItem === COE_SORT_FIELD.regAscending
                ? a?.registration.localeCompare(b?.registration)
                : b?.registration.localeCompare(a?.registration);
        });
    }
};

interface AdditionalNotes {
    note: string;
    userInfo: string;
}

interface CoeEventTasksPdf {
    id: number;
    details: { type: string; owner: Groups; title: string };
    startTimeDisplay: string;
    etsTimeDisplay: string;
    status: string;
    statusRemarks: string;
    additionalNotes: AdditionalNotes[];
}

export interface CoePdf {
    eventId: string;
    registration: string;
    startTimeDisplay: string;
    etsTimeDisplay: string;
    type: string;
    subType: string;
    nextFlightDept: string;
    eventTitle: string;
    eventStatusDisplay: string;
    statusRemarks: string;
    techArea: string;
    ataChapterDisplay: string;
    owner: string;
    partNumber: string;
    description: string;
    relatedActivityType: string;
    relatedActivityValue: string;
    additionalNotes: AdditionalNotes[];
    commodity: string;
    mtceCode: string;
    isAog: boolean;
    eventTasks?: CoeEventTasksPdf[];
}

const getRelatedActivitiesVal = (data: RelatedActivity) => {
    let vals = '';
    const { details, relatedActivityType, freeTextId } = data;
    const { packageId, arriveLocation, planStartDatetime } = (details as PackageIdObj) || {};
    const { name, dateTime, port } = (details as MaintenanceObj) || {};
    const maintenanceDateTime = dateTime ? `${dateTime}z` : '--';
    const { flightNumber, dateStr, originPort, destinationPort } = getFlightNumberDateOriginDestinationFromUfi(
        data?.ufi
    );
    const { taskId, workOrderId, packageInfo } = (details as TaskIdObj) || {};
    const {
        packageId: taskPackageId,
        planStartDatetime: taskPlanStartDatetime,
        arriveLocation: taskArriveLocation,
    } = packageInfo || {};
    const { deferDisplay, melReference, melCategory } = (details as AddObj) || {};
    const melCat = melCategory && /[a-z]/i.test(melCategory) ? melCategory : '--';
    const melRef = melReference && /[0-9a-z]/i.test(melReference) ? melReference : '--';

    switch (relatedActivityType) {
        case RelatedActivityType.flight:
            vals = `${flightNumber}/${dateStr}/${originPort}-${destinationPort}`;
            break;
        case RelatedActivityType.add:
            vals = `${deferDisplay || '--'}/MEL CAT ${melCat || '--'}/${melRef || '--'}`;
            break;
        case RelatedActivityType.maintenance:
            vals = `${name || '--'}/${maintenanceDateTime}/${port || '--'}`;
            break;

        case RelatedActivityType.packageFreeTextId:
            vals = `${freeTextId || '--'}`;
            break;
        case RelatedActivityType.packageId:
            vals = `${packageId || '--'}/${planStartDatetime || '--'}/${arriveLocation || '--'}`;
            break;
        case RelatedActivityType.procedureOrAdd:
            vals = `${taskPackageId || '--'}/${taskId || '--'}/${workOrderId || '--'}/${
                taskPlanStartDatetime || '--'
            }/${taskArriveLocation || '--'}`;
            break;
        case RelatedActivityType.none:
            vals = '--';
            break;
        default:
            vals = '--';
            break;
    }
    return vals;
};

const getAdditionalNotesList = (tempNotes: LastStatusLog[]) => {
    const additionaLNotesArr = [...tempNotes]
        .sort((a: LastStatusLog, b: LastStatusLog) => {
            return isDateStrAfter(a.createdAt, b.createdAt);
        })
        .map((item) => {
            const { remarks, createdBy, createdAt } = item;
            const { group, userName } = createdBy || {};
            const createDateTime = (createdAt && formatDefaultDisplay(createdAt)) || '--';

            return {
                note: remarks || '--',
                userInfo: `${userName || '--'}, ${group || '--'} ${createDateTime}`,
            };
        });
    return additionaLNotesArr as AdditionalNotes[];
};

const getCoePdfDisplayEvents = ({
    events,
    ataList,
}: {
    events: COEDetailedEvent[];
    ataList: { display: string; value: string }[];
}) => {
    return events.map((event) => {
        const {
            eventId,
            registration,
            startTime,
            etsTime,
            type,
            subType,
            nextFlightDept,
            eventTitle,
            state,
            ataChapter,
            owner,
            partNumber,
            description,
            techArea,
            eventTasks,
            relatedActivities,
            additionalNotes,
            commodityDisplay,
            mtceCode,
            isAog,
            statusRemarks,
        } = event;

        const [activity] = relatedActivities || [];
        const { relatedActivityType } = activity || {};

        const mappedState = state === EventStatusType.open ? EventStatusType.outstanding : state;
        const eventStatusDisplay =
            coeEventStatusList.general.find((item) => item.value === mappedState)?.display || '--';
        const relatedActityTypeDisplay =
            (relatedActivityType && relatedActivityTypeDisplayNames[relatedActivityType]) || '--';
        const relatedActivityValueDisplay = (activity && getRelatedActivitiesVal(activity)) || '--';
        const { display: ataChapterDisplay } = (ataChapter && ataList.find((item) => item.value === ataChapter)) || {};

        const startTimeDisplay = (startTime && formatDefaultDisplay(startTime)) || '--';
        const etsTimeDisplay = (etsTime && formatDefaultDisplay(etsTime)) || '--';

        const tempNotes = additionalNotes || [];
        const additionaLNotesArr = getAdditionalNotesList(tempNotes);
        const mtceCodeDisplay = mtceCode || '--';
        const partNumberDisplay = partNumber?.join('\n') || '--/--';
        const actionItems = eventTasks?.map((actionItem) => {
            const {
                statusRemarks,
                startTimeUTC,
                etsTimeUTC,
                id,
                details,
                status,
                additionalNotes: actionItemNotes,
            } = actionItem;
            const tempNotes = actionItemNotes || [];
            const additionaLNotesArr = getAdditionalNotesList(tempNotes);
            const startTimeDisplay = (startTimeUTC && formatDefaultDisplay(startTimeUTC)) || '--';
            const etsTimeDisplay = (etsTimeUTC && formatDefaultDisplay(etsTimeUTC)) || '--';
            const mappedState = status === ActionItemStatusType.open ? ActionItemStatusType.outstanding : status;
            const actionItemStatusDisplay =
                coeEventStatusList.general.find((item) => item.value === mappedState)?.display || '--';

            return {
                id,
                details,
                status: actionItemStatusDisplay,
                statusRemarks,
                additionalNotes: additionaLNotesArr,
                startTimeDisplay,
                etsTimeDisplay,
            };
        }) as CoeEventTasksPdf[];
        return {
            eventId,
            registration,
            startTimeDisplay,
            etsTimeDisplay,
            type,
            subType,
            nextFlightDept,
            eventTitle,
            eventStatusDisplay,
            statusRemarks,
            techArea,
            ataChapterDisplay,
            owner: owner?.displayName,
            partNumber: partNumberDisplay,
            description,
            relatedActivityType: relatedActityTypeDisplay,
            relatedActivityValue: relatedActivityValueDisplay,
            additionalNotes: additionaLNotesArr,
            mtceCode: mtceCodeDisplay,
            commodity: commodityDisplay,
            isAog,
            eventTasks: actionItems,
        } as CoePdf;
    });
};

const getCoeEventStatusDropdownList = ({ type, status }: { type: string; status: string }) => {
    let result: { display: string; value: string }[] = [];
    switch (type) {
        case EventType.deferralHX:
        case EventType.operationalHX:
        case EventType.requestHX:
        case EventType.melExtensionHX:
            result = coeEventStatusList.deferralHx;
            break;
        case EventType.crdHX:
        case EventType.crdSys:
            if (status !== EventStatusType.closed && status !== EventStatusType.cancelled) {
                result = coeEventStatusList.crdHxAndSystem;
            }
            break;
        case EventType.crd:
            if (status === EventStatusType.closed) {
                result = coeEventStatusList.close;
            } else if (status !== EventStatusType.cancelled) {
                result = coeEventStatusList.crd;
            }
            break;
        case EventType.eventToHx:
            if (status === EventStatusType.closed) {
                result = coeEventStatusList.close;
            } else if (status !== EventStatusType.cancelled) {
                result = coeEventStatusList.closeAndCancelled;
            }
            break;
        default:
            if (status === EventStatusType.closed) {
                result = coeEventStatusList.close;
            } else if (status !== EventStatusType.cancelled) {
                result = coeEventStatusList.general;
            }
            break;
    }
    return result;
};

const ALERT_DUE_DATE_RANGE = 3;
const checkDueDateStates: string[] = [
    EventStatusType.inProgress,
    EventStatusType.onHold,
    EventStatusType.open,
    EventStatusType.outstanding,
];

const isNeedDueDateAlert = ( state?: string, typeId?: number, taskDueDate?: string): boolean => {
    let isNeeded = false;
    const isDeferral = typeId === EventTypeIds.deferralHX;
    if (isDeferral && state && checkDueDateStates.includes(state)) {
        const dueDate = taskDueDate && timeStringToDateTime(taskDueDate);
        if ((dueDate && dueDate.diff(DateTime.now(), 'days').days <= ALERT_DUE_DATE_RANGE) || !dueDate) {
            isNeeded = true;
        }
    }
    return isNeeded;
};

export {
    getSortedEvents,
    getCoeSortedEvents,
    filterByEventStatus,
    filterByEventTypes,
    filterByAssessment,
    getCoePdfDisplayEvents,
    getRelatedActivitiesVal,
    getCoeEventStatusDropdownList,
    isNeedDueDateAlert,
};
