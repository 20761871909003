import React, { useState } from 'react';
import { FormControlLabel, Radio, SxProps, Stack, styled } from '@mui/material';

import HoverLabel from './HoverLabel';
import { ReactComponent as RadioIcon } from '../../assets/icons/Radio.svg';
import { ReactComponent as RadioIconChecked } from '../../assets/icons/RadioChecked.svg';
import COLORS from '../../style/color';
import Colors from '../../style/checklistColor';

type RadioButtonProps = {
    label?: string;
    name: string;
    value: string;
    disabled?: boolean;
    default?: boolean;
    selectedValue?: string;
    error?: boolean;
    isChecklist?: boolean;
    sx?: SxProps;
    radioSx?: SxProps;
    handleOptionClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const ChecklistIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 2px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 2px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#FBFBFB',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // outline: '2px solid #D4D4D4',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:disabled ~ &': {
        background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : Colors.Common.grey100,
    },
    'input:disabled ~ &::before': {
        width: '16px',
        height: '16px',
        backgroundImage: 'radial-gradient(#959595,#959595 24%,transparent 32%)'
    },
}));

const ChecklistBpCheckedIcon = styled(ChecklistIcon)({
    backgroundColor: Colors.Common.blue500,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: '16px',
        height: '16px',
        backgroundImage: 'radial-gradient(#fff,#fff 24%,transparent 32%)',
        content: '""',
    },
});

const StyledRadioButton = (props: RadioButtonProps) => {
    const {
        error = false,
        label,
        value,
        name,
        handleOptionClick,
        selectedValue,
        isChecklist = false,
        disabled = false,
        sx,
        radioSx,
    } = props;
    const [showLabel, setShowLabel] = useState(false);

    return (
        <Stack sx={sx}>
            <FormControlLabel
                arial-name='radioButtonVal'
                value={value}
                disabled={disabled}
                control={
                    <Radio
                        checkedIcon={isChecklist ? <ChecklistBpCheckedIcon /> : <RadioIconChecked />}
                        icon={
                            isChecklist ? (
                                <ChecklistIcon />
                            ) : (
                                <RadioIcon stroke={error ? COLORS.error : COLORS.grayWhite} />
                            )
                        }
                        sx={radioSx}
                    />
                }
                label={name}
                key={value}
                onClick={handleOptionClick}
                checked={selectedValue === value}
                sx={{
                    margin: '0px',
                    paddingLeft: '12px',
                    paddingRight: '21px',
                    fontSize: '14px',
                    color: error ? COLORS.error : COLORS.grayDark,
                    '&:hover': {
                        backgroundColor: label ? COLORS.white : COLORS.buttonBgWhite,
                    },
                    ...sx,
                }}
                disableTypography={true}
                onMouseOver={() => setShowLabel(true)}
                onMouseOut={() => setShowLabel(false)}
            />
            {showLabel && label && <HoverLabel label={label} />}
        </Stack>
    );
};

export default StyledRadioButton;
