import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
    McEventDetails,
    EventListFilters,
    EventListShiftViewFilters,
    EventListShiftViewRequestBody,
    ShiftViewEventListResponse,
    ShiftViewStatusFilter,
} from '../interface/MCEventList';
import { IExportEventOptions, IUpdateExportEventOptionsPayload } from '../interface/MCExportEvent';
import { ApiErrorObj, ForbiddenObj } from '../services/ServerError';
import { resourceCheck, ResourceType, ResourceAction } from '../helper/resourceVerifyHelper';
import { generateHandoverReport, getMcilogEvents, getMcilogShiftViewEvents } from '../services/events';
import { HandoverReportRequestParams } from '../interface/HandoverReport';
import { downloadBlob } from './fileSlice';
import {
    DEFAULT_FILTER_SIZE,
    DEFAULT_FILTER_PAGE,
    SortBy,
    TabNameMapIndex,
    TabName,
    SortFiltersName,
} from '../components/mcList/constants';
import { SortDirection } from '../components/common/sort/index';

interface MCEventListStateProps {
    eventListData: McEventDetails[];
    isMcEventListLoading: boolean;
    total: number;
    filters: EventListFilters | null;
    exportEventOptions: IExportEventOptions;
    shiftViewFilters: EventListShiftViewFilters;
    shiftViewList: McEventDetails[];
    shiftViewTotal: number;
    shiftViewAogTotal: number;
    shiftViewNonAogTotal: number;
    shiftViewAllyNonAogTotal: number;
    activeTabIndex: number;
}

const getShiftViewRequestBody = (data: EventListShiftViewFilters): EventListShiftViewRequestBody => {
    const { aogSort, nonAogSort, allyNonAogSort, ...rest } = data;
    const sorting = [
        `${aogSort.sortBy}_${aogSort.sortDirection}_aog`,
        `${nonAogSort.sortBy}_${nonAogSort.sortDirection}_nonAog`,
        `${allyNonAogSort.sortBy}_${allyNonAogSort.sortDirection}_allyNonAog`,
    ];
    return { ...rest, sorting };
};

export const getMcilogEventsThunk = createAsyncThunk<
{ events: McEventDetails[]; total: number },
EventListFilters,
{ state: RootState; rejectValue: ApiErrorObj }
>('events/mcilogEventList', async (params, { getState, rejectWithValue, dispatch }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/events/mcilog', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    let err,
        data: { events: McEventDetails[]; total: number },
        page = params.page;

    do {
        [err, data] = await getMcilogEvents({ ...params, page });
        if (!err && data?.total === 0) {
            page = 1;
        }
        if (!err && data?.total > 0 && data?.events?.length === 0) {
            const targetPage = Math.max(1, Math.ceil(data.total / params.size));
            page = targetPage;
        }
    } while (!err && data?.total > 0 && data?.events?.length === 0);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    dispatch(updateFilters({ ...params, page }));

    return data;
});

export const getMcilogShiftViewEventsThunk = createAsyncThunk<
ShiftViewEventListResponse,
EventListShiftViewFilters,
{ state: RootState; rejectValue: ApiErrorObj }
>('events/mcilogShiftViewEventList', async (params, { getState, rejectWithValue, dispatch }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/events/mcilog/shiftView', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }
    const requestParams = getShiftViewRequestBody(params);

    let err,
        data: ShiftViewEventListResponse,
        page = params.page;
    do {
        [err, data] = await getMcilogShiftViewEvents({ ...requestParams, page });
        if (!err && data?.total === 0) {
            page = 1;
        }
        if (!err && data?.total > 0 && data?.data?.length === 0) {
            const targetPage = Math.max(1, Math.ceil(data.total / params.size));
            page = targetPage;
        }
    } while (!err && data?.total > 0 && data?.data?.length === 0);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    dispatch(updateShiftViewFilters({ ...params, page }));

    return data;
});

export const generateHandoverReportThunk = createAsyncThunk<
{ blob: Blob | null; header: Headers },
HandoverReportRequestParams,
{ state: RootState; rejectValue: ApiErrorObj }
>('report/handover', async (params, { getState, rejectWithValue }) => {
    const { userProfile } = getState().userProfile;
    const { currentPermissionList } = userProfile;
    if (!resourceCheck(currentPermissionList, ResourceType.API, '/report/handover', ResourceAction.POST)) {
        return rejectWithValue(ForbiddenObj);
    }

    const [err, blob, header] = await generateHandoverReport(params);

    if (err) {
        return rejectWithValue(err as ApiErrorObj);
    }

    return { blob, header };
});

const initialState: MCEventListStateProps = {
    eventListData: [],
    isMcEventListLoading: false,
    total: 0,
    filters: null,
    activeTabIndex: TabNameMapIndex[TabName.shiftView],
    shiftViewFilters: {
        status: ShiftViewStatusFilter.open,
        page: DEFAULT_FILTER_PAGE,
        size: DEFAULT_FILTER_SIZE,
        [SortFiltersName.aogSort]: {
            sortBy: SortBy.date,
            sortDirection: SortDirection.DESC,
        },
        [SortFiltersName.nonAogSort]: {
            sortBy: SortBy.date,
            sortDirection: SortDirection.DESC,
        },
        [SortFiltersName.allyNonAogSort]: {
            sortBy: SortBy.date,
            sortDirection: SortDirection.DESC,
        },
    },
    shiftViewList: [],
    shiftViewTotal: 0,
    shiftViewAogTotal: 0,
    shiftViewNonAogTotal: 0,
    shiftViewAllyNonAogTotal: null,
    exportEventOptions: {},
};

const mcEventListSlice = createSlice({
    name: 'mcEventList',
    initialState,
    reducers: {
        updateFilters: (state, action: PayloadAction<EventListFilters>) => {
            state.filters = { ...(state.filters ?? {}), ...action.payload };
        },
        updateTabActiveIndex: (state, action: PayloadAction<number>) => {
            state.activeTabIndex = action.payload;
        },
        updateShiftViewFilters: (state, action: PayloadAction<Partial<EventListShiftViewFilters>>) => {
            state.shiftViewFilters = { ...state.shiftViewFilters, ...action.payload };
        },
        updateIsHandoverReportByEventId: (
            state,
            action: PayloadAction<{ eventId: string; isHandoverReport: boolean; isShowShiftView: boolean }>
        ) => {
            const { eventId, isHandoverReport, isShowShiftView } = action.payload;
            state[isShowShiftView ? 'shiftViewList' : 'eventListData'] = state[
                isShowShiftView ? 'shiftViewList' : 'eventListData'
            ].map((event) => {
                if (event.id === eventId) {
                    return { ...event, isHandoverReport: isHandoverReport };
                } else {
                    return event;
                }
            });
        },
        updateExportEventOptions: (state, action: PayloadAction<IUpdateExportEventOptionsPayload>) => {
            const { logs, logId, eventId, checked, attachments } = action.payload;
            if (logs) {
                const options = {};
                logs.forEach((log) => {
                    const { logId, checked, attachments } = log;
                    options[logId] = { checked, attachments };
                });
                state.exportEventOptions[eventId] = options;
            } else {
                state.exportEventOptions[eventId][logId] = { checked: checked ?? true, attachments };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMcilogEventsThunk.pending, (state) => {
                state.isMcEventListLoading = true;
            })
            .addCase(getMcilogEventsThunk.fulfilled, (state, { payload }) => {
                state.eventListData = payload.events;
                state.total = payload.total;
                state.isMcEventListLoading = false;
            })
            .addCase(getMcilogEventsThunk.rejected, (state) => {
                state.isMcEventListLoading = false;
            })
            .addCase(getMcilogShiftViewEventsThunk.pending, (state) => {
                state.isMcEventListLoading = true;
            })
            .addCase(getMcilogShiftViewEventsThunk.fulfilled, (state, { payload }) => {
                state.shiftViewList = payload.data;
                state.shiftViewTotal = payload.total;
                state.shiftViewAogTotal = payload.aogTotal;
                state.shiftViewNonAogTotal = payload.nonAogTotal;
                state.shiftViewAllyNonAogTotal = payload.allyNonAogTotal;
                state.isMcEventListLoading = false;
            })
            .addCase(getMcilogShiftViewEventsThunk.rejected, (state) => {
                state.isMcEventListLoading = false;
            })
            .addCase(generateHandoverReportThunk.pending, (state) => {
                state.isMcEventListLoading = true;
            })
            .addCase(generateHandoverReportThunk.rejected, (state) => {
                state.isMcEventListLoading = false;
            })
            .addCase(generateHandoverReportThunk.fulfilled, (state, { payload, meta }) => {
                const { exportPdf } = meta.arg;
                if (exportPdf) {
                    const { blob, header } = payload;
                    const encodedFileName = header['content-disposition']?.split('attachment;filename=')[1];
                    const fileName = decodeURI(encodedFileName);
                    downloadBlob({
                        blob,
                        fileName,
                    });
                }
                state.isMcEventListLoading = false;
            });
    },
});

export const selectMCEvent = (state: RootState) => state.mcEventList;
export const {
    updateFilters,
    updateIsHandoverReportByEventId,
    updateExportEventOptions,
    updateShiftViewFilters,
    updateTabActiveIndex,
} = mcEventListSlice.actions;
export default mcEventListSlice.reducer;
