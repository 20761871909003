import { DateTime, DateTimeUnit, FixedOffsetZone } from 'luxon';
import { isEmpty } from 'lodash/fp';

enum DateFormat {
    DateDisplayFormatWithoutTime = 'ddMMMyy',
    Utcformat = "ddMMMyy HHmm'z'",
    Hktformat = 'ddMMMyy HHmm',
    Localformat = "ddMMMyy HHmm'L'",
    nonUtcFormat = 'ddMMMyy HHmm',
    ServerQueryFormat = 'yyyy-MM-dd',
    dateOnly = 'ddMMMyy',
    dateOnlyUTC = "ddMMMyy'z'",
    dateOnlyYYYYMMDD = 'yyyyMMdd',
    DateTimeWithSec = 'yyyy-MM-dd HH:mm:ss',
    timeOnly = 'HHmm',
    isoFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
    dateWithWeek = 'dd MMM yy (cccc)',
    ganttSidePanelFormat = "ddMMMyy HHmm 'UTC'",
    commentFormat = "MMMM d, yyyy 'at' h:mma",
    teamsChatSubjectFormat = "dd/MMM/yy"
}

enum Zone {
    utc = 'utc',
    hkt = 'Asia/Hong_Kong',
}

enum DurationFormat {
    DayHourMinWithColon = 'dd:<br>hh:<br>mm',
    DayHourMinWithSuffix = "dd'd'<br>hh'h'<br>mm'm'",
    DayHourMinWithSuffixColon = "dd'D':<br>hh'H':<br>mm'M'",
}

// input yyyy-MM-ddTHH:mm:ss.SSSZ <string> to displayFormat <string> unless specify
const formatDefaultDisplay = (date?: string, format: DateFormat = DateFormat.Utcformat, zone: string = Zone.utc) => {
    if (!date || date === '--') {
        return null;
    }

    const str = timeStringToDateTime(date, zone).toFormat(format);
    return str;
};

const formatLocalDisplay = (date: string, format: DateFormat = DateFormat.nonUtcFormat) => {
    if (!date || date === '--') {
        return null;
    }

    const dateTimeObj = DateTime.fromISO(date, { setZone: true });

    if (!dateTimeObj.isValid) return null;

    return dateTimeObj.toFormat(format);
};

const transformFormat = (
    date: string | null | undefined,
    fromFormat: DateFormat,
    toFormat: DateFormat
): string | null => {
    if (!date || date === '--') {
        return null;
    }

    return DateTime.fromFormat(date, fromFormat).toFormat(toFormat);
};

// input 2021-11-28T16:00:00.000Z<string> to <DateTime>
const timeStringToDateTime = (dateTimeDisplay: string, zone: string = Zone.utc): DateTime => {
    if (!dateTimeDisplay || dateTimeDisplay === '--') {
        return null;
    }
    return DateTime.fromISO(dateTimeDisplay, { zone: zone });
};

const getDateOnly = (date: Date | DateTime): DateTime => {
    const zeroTimeConfig = { hour: 0, minute: 0, second: 0, millisecond: 0 };
    if (date instanceof Date) return DateTime.fromJSDate(date).set(zeroTimeConfig);

    return date.set(zeroTimeConfig);
};

/**
 * Since backend won't store local time in ISO format. It's always in UTC format but it's actually local time.
 * This function is to convert the local tiem in ISO format by calculating the difference between
 * the actual UTC and local time given.
 *
 * @param refUTCDateTime actual UTC time of the local time.
 * @param localDateTime local time given by backend.
 * @returns the local time is ISO format e.g. 2023-03-31T10:00:00.000+8:00;
 */
const backendLocalTimeToISO = (refUTCDateTime: string, localDateTime: string): string => {
    if (!refUTCDateTime || !localDateTime) return '';
    const refDateTimeObj = DateTime.fromISO(refUTCDateTime, { zone: Zone.utc });
    const localDateTimeObj = DateTime.fromISO(localDateTime, { zone: Zone.utc });

    const timeOffset = localDateTimeObj.diff(refDateTimeObj, 'minutes').toObject().minutes;
    const zone = FixedOffsetZone.instance(timeOffset);

    return DateTime.fromISO(refUTCDateTime, { zone: zone.name }).toISO();
};

/**
 * @deprecated try isDateStrAfterOther();
 * return > 0, a is later than b.
 * return = 0, a is eqaul to b.
 * return < 0, a is earlier than b.
 */
const isDateStrAfter = (dateStrA: string, dateStrB: string) => {
    const aTimestamp = timeStringToDateTime(dateStrA) || DateTime.now();
    const bTimestamp = timeStringToDateTime(dateStrB) || DateTime.now();
    return aTimestamp.diff(bTimestamp).milliseconds;
};

/**
 * Similar to isDateStrAfter except no dateTime fallback when there is invalid time,
 * instead moving invalid time value to the end of list.
 *
 * @param dateStrA - ISO format string;
 * @param dateStrB - ISO format string;
 * @param startOf - DateTimeUni from luxon. Compare unit, e.g. 'day' will ignore hour, minutes, seconds diff
 * @returns number indicating difference
 * return > 0, a is later than b.
 * return = 0, a is eqaul to b.
 * return < 0, a is earlier than b.
 */
const isDateStrAfterOther = (dateStrA: string, dateStrB: string, startOf: DateTimeUnit = 'millisecond'): number => {
    if (!dateStrA) return 1;
    if (!dateStrB) return -1;
    const aTimestamp = timeStringToDateTime(dateStrA).startOf(startOf);
    const bTimestamp = timeStringToDateTime(dateStrB).startOf(startOf);
    return aTimestamp.diff(bTimestamp).milliseconds;
};

const getMinOrMaxDateTime = (compareType: 'min' | 'max', dateArr: DateTime[]) => {
    const array = dateArr.filter((item) => !isEmpty(item));
    if (compareType === 'min') {
        return DateTime.min(...array);
    } else {
        return DateTime.max(...array);
    }
};

/**
 * @param currentTime - format: [DateTime 2022-05-22T00:00:00]
 * @param latestTime - format: [DateTime 2022-05-23T00:00:00]
 * @returns dayDifference - format: +1 (in day) latestTime + diff = currentTime
 */
const getDayDifference = (currentTime: DateTime, latestTime: DateTime): string => {
    if (!currentTime || !latestTime) return;
    const dayDifference = latestTime.diff(currentTime, 'days').days;

    const sign = Math.sign(dayDifference) > 0 ? '+' : '';
    return Math.abs(dayDifference) > 0 ? `${sign}${dayDifference}` : '';
};

/**
 * @param dateStrA - format: 2022-05-22 00:00:00z
 * @param dateStrB - format: 2022-05-22 00:10:00z
 * @returns dayDifference - format: 10 (in minutes)
 */
const getTimeDifference = (dateStrA: string, dateStrB: string) => {
    if (!dateStrA || !dateStrB) return;
    const dayDifference = timeStringToDateTime(dateStrB)
        .diff(timeStringToDateTime(dateStrA), ['minutes'])
        .toObject().minutes;

    return dayDifference;
};

/**
 * calculate the time difference of timeA - timeB in targeted format.
 *
 * @param timeA format: 2021-11-28T16:00:00.000Z
 * @param timeB format: 2021-11-28T16:00:00.000Z
 * @param format the target returned format. EMS convention - if day is required but difference is less than 1 than,
 * will return string without day. i.e. if dd:hh:mm received, return hh:mm if difference less than 1 day
 * @returns the time difference of timeA - timeB in targeted format.
 *
 * limitation: follow the sequence days - hours - minutes for short form.
 */
const getFormattedTimeDifference = (
    timeA: string,
    timeB: string,
    format: DurationFormat = DurationFormat.DayHourMinWithColon
): string | null => {
    if (!timeA || !timeB) return null;

    const timeDifference = timeStringToDateTime(timeA).diff(timeStringToDateTime(timeB), ['days', 'hours', 'minutes']);
    const { days, minutes, hours } = timeDifference;

    const formatPortions = format.split(/<br>/);
    const hourPortion = formatPortions.find((portion) => portion.includes('hh'));
    const minsPortion = formatPortions.find((portion) => portion.includes('mm'));
    const shortFormat = `${hourPortion}${minsPortion}`;
    const cleanFullFormat = format.replace(/<br>/g, '');
    if (days !== 0) {
        // Luxon date time returns format -dd:-hh:-mm when it's negative, the following fix the format into -dd:hh:mm
        return days < 0
            ? `-${timeDifference.negate().toFormat(cleanFullFormat)}`
            : timeDifference.toFormat(cleanFullFormat);
    }

    return hours < 0 || minutes < 0
        ? `-${timeDifference.negate().toFormat(shortFormat)}`
        : timeDifference.toFormat(shortFormat);
};

const getOffsetDay = (
    offsetType?: 'start' | 'end',
    offsetDayNum?: number,
    offsetUnit?: DateTimeUnit,
    format?: DateFormat
) => {
    let time = DateTime.now();
    if (offsetDayNum !== undefined && offsetDayNum > 0) {
        time = time.plus({ day: offsetDayNum });
    }
    if (offsetDayNum !== undefined && offsetDayNum < 0) {
        time = time.minus({ day: Math.abs(offsetDayNum) });
    }
    if (offsetType && offsetType === 'start' && offsetUnit) {
        time = time.startOf(offsetUnit);
    }
    if (offsetType && offsetType === 'end' && offsetUnit) {
        time = time.endOf(offsetUnit);
    }
    return time.toFormat(format || DateFormat.DateTimeWithSec);
};

const getDefaultMeetingTime = (isUTC?: boolean) => {
    const now = DateTime.local();
    const dividerTime = DateTime.local(now.year, now.month, now.day, 23, 30);
    let start: DateTime = now;
    if (now > dividerTime) {
        // if current time is after 23:30, set the start time to tomorrow
        start = now.plus({ days: 1 }).startOf('day');
    } else {
        // if current time is before 23:30, set the start time to today
        if (now.minute > 30) {
            start = DateTime.local(now.year, now.month, now.day, now.hour + 1, 0);
        } else {
            start = DateTime.local(now.year, now.month, now.day, now.hour, 30);
        }
    }
    const end = start.plus({ minutes: 30 });
    return {
        end: (isUTC ? end.toUTC().toISO() : end.toString()) || '',
        start: (isUTC ? start.toUTC().toISO() : start.toString()) || '',
    };
};

export {
    getDateOnly,
    DateFormat,
    DurationFormat,
    Zone,
    formatDefaultDisplay,
    formatLocalDisplay,
    timeStringToDateTime,
    isDateStrAfter,
    isDateStrAfterOther,
    getMinOrMaxDateTime,
    getDayDifference,
    getTimeDifference,
    getOffsetDay,
    transformFormat,
    getFormattedTimeDifference,
    backendLocalTimeToISO,
    getDefaultMeetingTime,
};
